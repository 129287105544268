import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpKeyBunifikasi.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import fileSaver from 'file-saver'

const OpKeyBunifikasi = ({
  className       = '',
  showCommandbar  = true,
  modalStore,

}) => {
  const [data, setData] = useState([])
  const [dialogData, setDialogData] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-keyBunifikasi'
  const basePath = 'keyBunifikasi'
  const [fetchParam, setFetchParam] = useState([])
  const [dialog, setDialog] = useState(false)

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function exportCsvExc(item) {
    try {
        var proses = t.translate('words.exportCsv')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d].id))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries([1], async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.exportCsvBulk(selectedData)
              const blob = new Blob([proccedThis.data], {type: "text/csv"})
              fileSaver.saveAs(blob, 'key-bunifikasi.csv');
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function exportCsv(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length > 0){
        modalStore.showConfirm({
            title : t.translate('words.exportCsv'),
            children : t.translate('words.confirmexportCsv'),
            onSubmit : (callback) => {
              exportCsvExc(item)
              callback()
            }
        })
    } else {
        setDialog(true)
    }
  }

  return (
    <>
    <Dialog
        visible={dialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialog(false)
        }}
        style={{
          width: 800,
        }}
    >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportCsv')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
            <DataForm
                baseId={`${baseId}-export-csv`}
                defaultData={dialogData}
                hintShowIcon={true}
                definitions={[
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.name`),
                        key: 'name',
                        type: 'text',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.aliasName`),
                        key: 'aliasName',
                        type: 'text',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.npwp`),
                        key: 'npwp',
                        type: 'text',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.address`),
                        key: 'address',
                        type: 'text',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.city`),
                        key: 'city',
                        type: 'text',
                    },
                    {
                        inputType: inputTypes.INPUT,
                        label: t.translate(`words.email`),
                        key: 'email',
                        type: 'text',
                    },
                ]}
                additionalAction={[
                  {
                    show: true,
                    render: () => (
                      <Button style={{ marginRight: 15 }} onClick={() => setDialog(false)}>{t.translate('words.close')}</Button>
                    )
                  }
                ]}
                submitLabel={t.translate('words.kirim')}
                onSubmit={async (values, callback) => {
                    var proses = t.translate('words.exportCsv')
                    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                    try {
                        let proccedThis = await service.exportCsvParam(values)
                        const blob = new Blob([proccedThis.data], {type: "text/csv"})
                        fileSaver.saveAs(blob, 'key-bunifikasi.csv');
                        setPloading({ loading: false, message: `` })
                        iziToast.success({
                            title: proses,
                            message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                        })
                        callback("", false, false)
                    } catch(e){
                      errorService(e)
                      callback("", false, false)
                    }
                    setDialog(false)
                }}
            />
          </DialogContent>
    </Dialog>
    <TableWrapper useFilter={false}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable: true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable: true,
          key: 'name',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.name)
        },
        {
          label: t.translate('words.aliasName'),
          searchable: true,
          sortable: true,
          key: 'aliasName',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.aliasName)
        },
        {
          label: t.translate('words.expired'),
          searchable: true,
          sortable: true,
          key: 'expired',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.expired)
        },
        {
            label: t.translate('words.active'),
            searchable: true,
            sortable: true,
            key: 'active',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if (item.active) {
                return <Chip>YA</Chip>
              } else {
                return <Chip>TIDAK</Chip>
              }
            }
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable: true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable: true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable: true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable: true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', (item) => {exportCsv(item)}, true),
      ]}
      itemActions={[]}
      setPage={search.page} onFetchData={(params)=> {
        return (
            new Promise(async (resolve, reject) => {
                params = {...params,...search}
                let getPage = null
                try {
                    getPage = await service.get(params)
                    setFetchParam(params)
                    setData(getPage.data)
                    resolve(getPage)
                } catch(e){
                    setFetchParam(params)
                    resolve({ data: [] })
                    errorService(e)
                }
            })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
      sideHeader={
        <DataForm
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName='mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          onChange={(formData, key, val)=> {
            setFetchParam(formData)
          }}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button style={{ marginRight: 15 }} onClick={() => {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(() => {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback) => {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(() => {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.aliasName`),
              key: 'aliasName.contains',
              type: 'text'
            },
          ]}
        />
      }
    />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpKeyBunifikasi))
