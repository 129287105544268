import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import Flex from '../Flex'
import { FontIcon, MenuItemLink, MenuItem } from 'react-md'
import { inject, observer } from 'mobx-react'
import { themes } from "../../config/constant";

const Group = ({
  item = [],
  collapsible = false,
  defaultCollapse = true,
  nextGroup = null,
  defaultPath = null,
  isTranslate = false,
  ...props
}) => {
  const [collapse, setCollapse] = useState(collapsible ? defaultCollapse : true)
  const [currentPath, setCurrentPath] = useState(defaultPath || window.location.pathname)
  const isParent = item.children && item.children.length > 0

  const handleCollapse = () => {
    if (collapsible) {
      setCollapse(!collapse)
    }
  }

  useEffect(() => {
    if (defaultPath) setCurrentPath(defaultPath)
  }, [defaultPath])

  return isParent ? (
    <div className={`menu-group ${isParent && collapsible ? 'collapsible' : ''}`}>
      <Flex
        className="subheader mpk-padding-N padding-top padding-bottom mpk-full full-width"
        onClick={(e)=> {
          handleCollapse()
          props.handlePick(props.index)
        }}
        align={Flex.properties.align.CENTER}
        justify={Flex.properties.justify.BETWEEN}
      >
        <div
          className='mpk-flex direction-row align-center'>
          {item.iconClassName &&
            <FontIcon iconClassName={item.iconClassName} className="flex mpk-margin-N margin-right" />
          }
          <div className=" mpk-font weight-B">{item.translate ? t.translate(item.label) : item.label}</div>
        </div>
        {isParent && collapsible && <FontIcon iconClassName={`mdi mdi-menu-${collapse ? 'up' : 'down'}`} />}
      </Flex>
      {collapse && (
        <div className="menus mpk-animation slide-in-down">
          {item.children.map(d => {
            return d.path ? (
              <MenuItemLink
                key={`${item.label}-${d.label}`}
                href={d.path}
                className={`menu-item ${currentPath === d.path ? 'active' : (d.childPath && d.childPath.length > 0 && currentPath.match(new RegExp(d.childPath.toString().replace(/,/g, '|'), 'ig')) !== null ? 'active' : '')}`}
                style={((currentPath === d.path) || (d.childPath && d.childPath.length > 0 && currentPath.match(new RegExp(d.childPath.toString().replace(/,/g, '|'), 'ig')) !== null)) && props.envStore.env.theme === themes.SOBATPAJAK ? {
                  color: '#689f38',
                  fontWeight: 600
                } : null}
                onClick={e => {
                  if (!d.path.match(/http|https|www/g)) {
                    e.stopPropagation()
                    e.preventDefault()
                    setCurrentPath(d.path)
                    props.temporaryStore.setProperties('defaultPath', d.path)
                    props.navigationStore.redirectTo(d.path)
                    document.title = `${props.envStore.env.appInfo.publicName} : ${item.label ? `${item.label} |` : ''} ${d.label}`
                  }
                }}
              >
                <Flex
                  align={Flex.properties.align.CENTER}
                  justify={Flex.properties.justify.BETWEEN}
                >
                  <FontIcon iconClassName={d.iconClassName} style={((currentPath === d.path) || (d.childPath && d.childPath.length > 0 && currentPath.match(new RegExp(d.childPath.toString().replace(/,/g, '|'), 'ig')) !== null)) && props.envStore.env.theme === themes.SOBATPAJAK ? {
                    color: '#689f38',
                  } : null} />
                  <div className="flex mpk-margin-N margin-left">{d.translate ? t.translate(d.label) : d.label}</div>
                </Flex>
              </MenuItemLink>
            ) : (
              <MenuItem
                key={`${item.label}-${d.label}`}
                className={`menu-item`}
                onClick={e => {
                  if (props.envStore.widget.active) {
                    props.envStore.widgetPost(d.index, d)
                  } else {
                    dispatchEvent(new CustomEvent('custom-message', { detail: { d } }))
                  }
                }}
              >
                <Flex
                  align={Flex.properties.align.CENTER}
                  justify={Flex.properties.justify.BETWEEN}
                >
                  <FontIcon iconClassName={d.iconClassName} />
                  <div className="flex mpk-margin-N margin-left">{d.translate ? t.translate(d.label) : d.label}</div>
                </Flex>
              </MenuItem>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    nextGroup && nextGroup.children && nextGroup.children.length > 0 && (
      <div className="menu-group-divider mpk-font size-NS mpk-padding-S padding-top padding-bottom">
        {item.translate ? t.translate(item.label) : item.label}
      </div>
    )
  )
}

export default inject('navigationStore', 'envStore', 'temporaryStore')(observer(Group))
