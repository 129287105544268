import React from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'

const FormSample = ({envStore}) => {
  const { inputTypes, definition } = FormWrapper
  return (
    <FormWrapper
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/${envStore.env.applicationType}/table`}
      baseId="mod-form-sample"
      title={t.translate('modules.form.title')}
      hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={{
        name: 'Test Nama',
        email: 'test@mail.com',
        password: '000000',
        confirmPassword: '000000'
      }}
      definitions={[
        // =====
        // CONTOH MENGGUNAKAN CLASS FORM DEFINITION
        new definition(inputTypes.INPUT, t.translate('column.name'), 'name', 'min:3'),
        // -----
        // =====
        // BISA JUGA MENGGUNAKAN OBJECT SECARA LANGSUNG
        {
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'email',
          required: true,
          validation: 'email'
        },
        // -----
        {
          inputType: inputTypes.INPUT,
          className: 'mpk-padding-N padding-right',
          label: 'Password',
          key: 'password',
          type: 'password',
          width: '50%',
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Confirm Password',
          key: 'confirmPassword',
          type: 'password',
          width: '50%',
          required: true,
        },{
          render :(<h1></h1>)       
        
        }
      ]}
      onSubmit={(data, callback) => {
        setTimeout(callback, 2000)
      }}
    />
  )
}

export default inject('envStore')(observer(FormSample))
