import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './BpArchive.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Divider, Button, FontIcon } from 'react-md'
import { clearFormat } from './../../libs/react-mpk/services/number.service'
import RSelect from 'react-select'

const Bp3Form = ({ authStore, envStore, temporaryStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [taxobjectCode, setTaxObjectCode] = useState([])
  const [tarif, setTarif] = useState(true)
  const [other, setOther] = useState(false)
  const [skt, setSkt] = useState(false)
  const [skb, setSkb] = useState(false)
  const [dtp, setDtp] = useState(false)
  const [pph, setPph] = useState(true)
  const [tanpaFasilitas, setTanpaFasilitas] = useState(false)
  const [tanpaFasilitas2, setTanpaFasilitas2] = useState(false)
  const [customRules, setCustomRules] = useState({})
  const [showTaxobjectCode, setShowTaxobjectCode] = useState(true)
  const [khusus4a2, setKhusus4a2] = useState(true)
  const [isHidden, setIsHidden] = useState(false)
  const [kop, setKop] = useState(false)
  const [tarif100Persen, setTarif100Persen] = useState(false)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      var bp = JSON.parse(localStorage.getItem('bpform'))
      setData({ loading: false, content: bp })
      // console.log(bp, "TELL ME")
      if(bp.skt){
        setSkt(true)
        setKhusus4a2(false)
      } else {
        setSkt(false)
        setKhusus4a2(true)
      }
    }
    getTaxObjectCode()
    initData()
    if(match.params.id != 'new'){
      var bp = JSON.parse(localStorage.getItem('bpform'))
      if(bp.identity.length==16){
        var xcode = bp.objectCode;
        var bannedCode2x = ["28-403-02", "28-405-01", "28-409-08", "28-409-09", "28-409-10", "28-409-11", "28-409-12", "28-409-13", "28-409-14", "28-417-01", "28-417-02", "28-419-01", "28-423-01", "28-410-02", "28-411-02", "29-101-01", "28-421-01", "28-421-02", "28-421-03", "22-401-01", "22-401-02"]
        var exist = bannedCode2x.includes(xcode)
        if(exist){
          bp.rates = bp.rates + ""
          bp.ratesValue = bp.rates + "";
          setTarif100Persen(false);
        } else {
          // formData.rates = bp.rates * 2 + "";
          bp.rates = bp.rates + "";
          bp.ratesValue = bp.rates * 2 + "";
          setTarif100Persen(true);
        }
      }
      setIsHidden(true)
    }
  }, [])

  async function getTaxObjectCode(type){
    setShowTaxobjectCode(false)
    setTimeout(async ()=> {
      let res = await service.getTaxObjectCode(type)
      var taxobjectCode = []
      res.data.map((d)=> {
        if(d.ss == false){
          if(type == 'PPH4-2'){
            var bannedCode = ['28-404-01', '28-404-02', '28-404-03', '28-404-04', '28-404-05', '28-404-06', '28-404-07', '28-404-08', '28-404-09', '28-404-10', '28-404-11', '28-401-01', '28-401-02', '28-407-01', '28-407-02', ]
            var codeInBanned = bannedCode.filter((bannedD)=> {
              return bannedD == d.code
            })
            if(codeInBanned.length > 0){} else {
              taxobjectCode.push({ value: d.code, label: `${d.code} - ${d.description}`, rates: d.rates })
            }
          } else {
            taxobjectCode.push({ value: d.code, label: `${d.code} - ${d.description}`, rates: d.rates })
          }
          setTaxObjectCode(taxobjectCode)
        }
      })
      setShowTaxobjectCode(true)
    }, 500)
  }

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  var onSubmit = async (data, callback) => {
    data.form3valid = true
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    bpform = Object.assign(bpform, data)
    localStorage.setItem('bpform', JSON.stringify(bpform))
    props.activePanel()
  }
  try {
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(bpform.status == 'CANCELED' || bpform.status == 'DELETED' || spt.status == 'SUBMIT' || spt.status == 'FINISH'){ 
      onSubmit = false
    }
  } catch(e){}
  
  return (
    <>
      <FormWrapper
        submitLabel={t.translate('words.next')}
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        onChange={async (formData, key, value)=> {
          if(key == 'type'){
            await getTaxObjectCode(value)
          }
          if(key == 'objectCode'){
            var x = taxobjectCode.filter((d)=> {
              return value == d.label
            })
            if(x.length > 0){
              if(formData.identityAs == "1"){
                var xcode = x[0].value
                var bannedCode2x = ["28-403-02", "28-405-01", "28-409-08", "28-409-09", "28-409-10", "28-409-11", "28-409-12", "28-409-13", "28-409-14", "28-417-01", "28-417-02", "28-419-01", "28-423-01", "28-410-02", "28-411-02", "29-101-01", "28-421-01", "28-421-02", "28-421-03", "22-401-01", "22-401-02"]
                var exist = bannedCode2x.includes(xcode)
                if(exist){
                  formData.rates = x[0].rates + ""
                  formData.ratesValue = x[0].rates + "";
                  setTarif100Persen(false);
                } else {
                  // formData.rates = x[0].rates * 2 + ""
                  formData.rates = x[0].rates + "";
                  formData.ratesValue = x[0].rates * 2 + "";
                  setTarif100Persen(true);
                }
              } else {
                formData.rates = x[0].rates + ""
                setTarif100Persen(false);
                formData.ratesValue = x[0].rates + "";

              }
            }
          }
          if(key == 'objectCode'){
            var x = taxobjectCode.filter((d)=> {
              return value == d.value
            })
            if(x.length > 0){
              if(formData.identityAs == "1"){
                formData.rates = x[0].rates * 2 + ""
                formData.ratesValue = x[0].rates * 2 + "";
                setTarif100Persen(true);
              } else {
                formData.rates = x[0].rates + ""
                formData.ratesValue = x[0].rates + "";
                setTarif100Persen(false);
              }
            }
            formData['pph'] = Math.floor((formData['ratesValue']/100) * formData['bruto']) ;
          }
          if(key == 'other' && value){
            formData['skt'] = false; setSkt(false)
            setOther(true)
            setTarif(false)
          } else if(key == 'other' && !value) {
            setOther(false)
            setTarif(true)
          }
          if(key == 'skt' && value){
            setSkt(true)
            formData['withoutIncome'] = false
            customRules['sktNo'] = 'required'
            setCustomRules(customRules)
          } else if(key == 'skt' && !value) {
            setSkt(false)
            customRules['sktNo'] = 'required'
            setCustomRules(customRules)
          }
          if(key == 'skb' && value){
            formData['skt'] = false; setSkt(false)
            setSkb(true)
            formData['withoutIncome'] = false
            customRules['skbNo'] = 'required'
            setCustomRules(customRules)
          } else if(key == 'skb' && !value) {
            setSkb(false)
            delete customRules['skbNo']
            setCustomRules(customRules)
          }
          if(key == 'dtp' && value){
            setDtp(true)
            formData['skt'] = false; setSkt(false)
            formData['withoutIncome'] = false
            customRules['dtpNo'] = 'required'
            customRules['ntpnDtp'] = 'required'
            setCustomRules(customRules)
          } else if(key == 'dtp' && !value) {
            setDtp(false)
            delete customRules['dtpNo']
            delete customRules['ntpnDtp']
            setCustomRules(customRules)
          }
          if(key == 'rates'){
            formData['pph'] = Math.floor((value/100) * formData['bruto']) 
          }
          if(key == 'bruto'){
            if(skb == true){

            } else {
              //formData['pph'] = Math.floor((formData['rates']/100) * value) 
              formData['pph'] = Math.floor((formData['ratesValue']/100) * value) 
            }
          }
          if(key == 'withoutIncome' || key == 'skt' || key == 'dtp' ){
            // if(formData['bruto'] && formData['rates']){
            //   formData['pph'] = Math.floor((formData['rates']/100) * formData['bruto']) 
            // }
            if(formData['bruto'] && formData['ratesValue']){
              formData['pph'] = Math.floor((formData['ratesValue']/100) * formData['bruto']) 
            }
          }
          if(key == 'skb' && value){
            formData['pph'] = 0
          }
          if(key == 'other' && value){
            setPph(false)
            formData['withoutIncome'] = false
          } else if(key == 'other' && !value) {
            setPph(true)
          }
          if(key == 'withoutIncome' && value){
            formData['skt'] = false; setSkt(false)
            setTanpaFasilitas(true)
          } else if(key == 'withoutIncome' && !value) {
            setTanpaFasilitas(false)
          }
          if(key == "ntpnDtp"){
            if(value.length > 16){
              formData["ntpnDtp"] = value.slice(0,16)
            }
          }
          // SKT
          var objectCodeOnly = ''
          if(key == 'objectCode'){
            objectCodeOnly = value.split(' - ')[0]
            if(objectCodeOnly == '28-423-01'){
              setTarif(false)
              setKhusus4a2(false)
              setTanpaFasilitas2(true)
            } else {
              setTarif(true)
              formData['withoutIncome'] = false
              setTanpaFasilitas2(false)
              setKhusus4a2(true)
            }
          }
          // SKT
        }}
        customRules={customRules}
        definitions={[
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptNpwp`),
          //   key: 'sptNpwp',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptRev`),
          //   key: 'sptRev',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptMonth`),
          //   key: 'sptMonth',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptYear`),
          //   key: 'sptYear',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   render: (
          //     <Divider style={{ marginBottom: 25 }}/>
          //   )
          // },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.typeBP`),
            validation: 'required',
            key: 'type',
            type: 'text',
            options: [
              {label: "PPh Pasal 15", value: "PPH15"},
              {label: "PPh Pasal 22", value: "PPH22"},
              {label: "PPh Pasal 23", value: "PPH23"},
              {label: "PPh Pasal 4a2", value: "PPH4-2"}
            ]
          },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.kodeObjekPajak`),
            key: 'objectCode',
            validation: 'required',
            labelKey: 'label', 
            id : "test",
            valueKey: 'label',
            type : 'search',
            data: taxobjectCode,
            show: showTaxobjectCode,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.jumlahPenghasilanBruto`),
            key: 'bruto',
            type: 'text',
            width: '100%',
            validation: 'required',
            allowNegative: false,
            isAllowed: (values)=> {
              if(values.floatValue == 0){
                return false
              } else {
                return true 
              }
            }
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.tarif`),
            key: 'rates',
            type: 'text',
            width: '20%',
            validation: 'required',
            disabled: tarif
          },
          {
            render: ( 
              tarif100Persen?
              <div>
              <p style={{marginLeft: "10px",marginTop:"15px"}}><b>Tarif 100% Lebih Tinggi</b></p><br/>
              </div>:<div></div>
            )
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.pphYangDipotongAtauDTP`),
            key: 'pph',
            type: 'text',
            width: '100%',
            validation: 'required',
            disabled: pph
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.withoutIncome`),
            key: 'withoutIncome',
            type: 'text',
            disabled: skb || dtp || other || tanpaFasilitas2
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.suratKeteranganBebas`),
            key: 'skb',
            type: 'text',
            width: '100%',
            disabled: tanpaFasilitas || dtp || other
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.suratKeteranganBebasL`),
            key: 'skbNo',
            type: 'text',
            width: '50%',
            show: skb
          },
          {
            inputType: inputTypes.DATEPICKER,
            label: t.translate(`words.suratKeteranganBebasLL`),
            key: 'skbDate',
            type: 'date',
            width: '50%',
            show: skb,
            disabled: true
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.ditanggungPemerintahDTP`),
            key: 'dtp',
            type: 'text',
            width: '100%',
            disabled: tanpaFasilitas || skb || other
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.ditanggungPemerintahDTPL`),
            key: 'dtpNo',
            type: 'text',
            width: '50%',
            show: dtp
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.ditanggungPemerintahDTPNTPN`),
            key: 'ntpnDtp',
            type: 'text',
            width: '50%',
            show: dtp,
            validation: 'min:16|max:16',
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.suratKeteranganPP`),
            key: 'skt',
            type: 'text',
            width: '100%',
            disabled: tanpaFasilitas || skb || dtp || other || khusus4a2
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.suratKeteranganPPL`),
            key: 'sktNo',
            type: 'text',
            show: skt
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.fasilitasLainnya`),
            key: 'other',
            type: 'text',
            width: '50%',
            disabled: tanpaFasilitas || skb || dtp
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.fasilitasLainnyaL`),
            key: 'otherNo',
            type: 'text',
            show: other
          },
        ]}
        // onSubmit={onSubmit}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(Bp3Form))
