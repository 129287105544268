import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'

let service = new CrudService('/bp/dopp/etc');

service.checkDokumenLain = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `/bp/doppss/document/${data.id}?active=${data.active}`,
      data
    })
}

export default service;