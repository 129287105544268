import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from '../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpLogin.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Dialog,
  DialogContainer,
  DialogContent,
  DialogFooter,
  Button
} from 'react-md'
import ErrorService from '../../services/errorService'
import izitoast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import errorService from '../../services/errorService'
import qs from 'qs'
import markdownFile from './toc-pajakku.md'
import ReactMarkdown from 'react-markdown'

const OpLogin = ({ authStore, modalStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [md, setMd] = useState()
  const [dialog, setDialog] = useState(false)
  const [useMfa, setUseMfa] = useState(false)
  const [loginData, setLoginData] = useState({})

  const baseName = `login`
  const basePath = `login`

  useEffect(() => {
    fetch(markdownFile).then((response) => response.text()).then((text) => {
      setMd(text)
    })

    try {

    } catch(e){}
  }, [])

  var syarat = async ()=> {
    setDialog(true)
  }

  return (
    <>
      <div className="App mpk-full viewport-height account">
        <div className="bg">
          <div className="parallelogram-1"></div>
          <div className="parallelogram-2"></div>
          <div className="parallelogram-3"></div>
          <div className="parallelogram-4"></div>
          <div className="parallelogram-5"></div>
        <Dialog
          visible={dialog}
          disableFocusOnMount={true}
          style={{
            width: 800
          }}
          onRequestClose={()=> setDialog(false)}
        >
          <DialogContent>
            <div ><ReactMarkdown children={md} /></div>
          </DialogContent>
          <DialogFooter>
            <Button onClick={()=> setDialog(false)}>{t.translate(`words.tutup`)}</Button>
          </DialogFooter>
        </Dialog>
        <LoadingOverlay
          style={{
            position: 'inherit',
            background: 'red',
          }}
          active={ploading.loading}
          spinner
          text={ploading.message}
          >
        </LoadingOverlay>
        <div style={{ textAlign: 'center', marginTop: '100px', height: 54, }}>
          <img src="./images/unifikasi-picturemark-d.svg" style={{ position: 'relative', height: 64, width: 92 }} />
          <img src="./images/e-Buni-logotype-d.svg" style={{ position: 'relative', height: 30, margin: 9.5 }} />
          <img src="./images/logo-pajakku.svg" style={{ position: 'relative', height: 45}} />
        </div>
        <FormWrapper
          showCommandbar={false}
          style={{
            maxWidth: '20%',
            padding: 40,
            position: 'relative',
            marginTop: '36px',
          }}
          className="fullWidth"
          baseId={`mod-form-${baseName}`}
          defaultData={loginData}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.username`),
              key: 'username',
              type: 'text',
              validation: 'required',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.password`),
              key: 'password',
              type: 'password',
              validation: 'required',
            },
            {
              show: useMfa,
              inputType: inputTypes.INPUT,
              label: t.translate(`words.otp`),
              key: 'otp',
              type: 'password',
            },
            {
              render: (
                <p style={{ textAlign: 'center' }}>
                  Dengan melakukan sign-in, saya menyatakan telah membaca, mengerti, dan menyetujui <a style={{ color: 'blue' }} onClick={()=> syarat()}>syarat dan ketentuan</a> yang berlaku
                </p>
              )
            }
          ]}
          submitLabel="Sign In"
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button onClick={()=> window.location.href=`/#/${basePath}/forgetPassword`}>{t.translate("words.forgetPassword")}</Button>
              )
            }
          ]}
          onSubmit={ async (values, callback )=> {
            // var useMfa = false;
            // try {
            //   var getMfa = await service.checkMfa(values.username)
            //   if(getMfa && getMfa.data){
            //     setUseMfa(true)
            //     setLoginData(values)
            //     callback('', false, false, false)
            //     if(values.otp == "" || values.otp == undefined || values.otp == null){
            //       return
            //     } else {
            //       setUseMfa(true)
            //     }
            //   } else {
            //     setUseMfa(false)
            //     setLoginData(values)
            //   }
            // } catch(e){}
            // Normal Login
            try {
              let res = await service.login(qs.stringify(
                {
                  ...values,
                  grant_type: 'password',
                  scope: 'read write'
                }
              ))
              let me = await service.me()
              if(me){
                window.location.href = '/#/onpremise'
              } else {
                window.location.href = '/#/login'
              }
            } catch(e){
              errorService(e)
            }
            callback('', false, false, false)
            // Normal Login
          }}
        >
      </FormWrapper>
      </div>
    </div>
    </>
  )
}

export default inject('authStore', 'envStore', 'modalStore')(observer(OpLogin))
