import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import ErrorService from '../../services/errorService'
import SSPService from './../Ssp/Ssp.service'
import Service from './Dashboard.service'
import { format } from './../../libs/react-mpk/services/number.service'
import { Grid, GridCell, Button, Chip, Fieldset, ListItem, List, Card, FontIcon, Divider  } from 'react-md'
import iziToast from 'izitoast'
import { Container, Row, Col } from 'react-grid-system';
import AnimatedNumber from "animated-number-react";
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import service from './../DaftarSpt/DaftarSpt.service'
import DashboardSpt from './../DashboardSpt/DashboardSpt'
import AppMode from '../../AppMode'

const InquiryForm = ({ authStore, modalStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: []})
  const [organization, setOrganization] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const [summary, setSummary] = useState({
    pbk: 0,
    ssp: 0,
    pph: 0,
    total: 0
  })
  const [total, setTotal] = useState({
    debt: 0,
    deposit: 0,
    total: 0
  })

  const baseName = `dashboard`
  const basePath = `dashboard-spt`
  const baseId = 'mod-dashboard-spt'
  const redirectSsp = 'ssp'

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  useEffect(() => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData(){
    try {
      var spt = JSON.parse(localStorage.getItem('spt'))
      var bp = JSON.parse(localStorage.getItem('bp'))
      // await inquiryRun()
      // let res = await SSPService.getInquiry({
      //   sptNpwp: spt.npwp,
      //   sptYear: spt.year,
      //   sptMonth: spt.month,
      //   sptRev: spt.rev,
      // })
      let resTotal = await SSPService.summaryTotal({
        sptNpwp: spt.npwp,
        sptYear: spt.year,
        sptMonth: spt.month,
        sptRev: spt.rev,
      })
      setTotal(resTotal.data)
      if(bp){
        setData({
          loading: false,
          content: bp.data
        })
      }
      // let summary = await Service.summary({
      //   sptNpwp : spt.npwp,
      //   sptYear : spt.year,
      //   sptMonth : spt.month,
      //   sptRev : spt.rev,
      // })
      // setSummary(summary.data)
    } catch(e){
      ErrorService(e)
    }
  }

  async function inquiryExec(item){
    try {
      let res = await SSPService.getInquiryByID(item.id)
      iziToast.success({
        message: t.translate('words.terproses')
      })
      initData()
    } catch(e){
      ErrorService(e)
    }
  }

  async function inquiryConfirm(item){
    modalStore.showConfirm({
      title: t.translate('words.inquiry'),
      children: t.translate('words.askConfirm'),
      onSubmit: (callback)=> {
        inquiryExec(item)
        callback()
      }
    })
  }

  async function inquiryRun(item){
    try {
      var spt = JSON.parse(localStorage.getItem('spt'))
      let res = await SSPService.getInquiryRun({
        sptNpwp: spt.npwp,
        sptYear: spt.year,
        sptMonth: spt.month,
        sptRev: spt.rev,
      })
      TableWrapper.reload('mod-dashboard-sample')
    } catch(e){
      ErrorService(e)
    }
  }

  async function inquiryRunConfirm(item){
    modalStore.showConfirm({
      title: t.translate('words.inquiryRun'),
      children: t.translate('words.askConfirm'),
      onSubmit: (callback)=> {
        inquiryRun(item)
        callback()
      }
    })
  }

  var formatValue = (value) => format(value.toFixed(0));

  async function postExc(item) {
    try {
      var proses = t.translate('words.postSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      var spt = JSON.parse(localStorage.getItem('spt'))
      selectedData.push(spt)
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.postingSpt(di.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
          }
        }, 1000 * di2)
      })
    } catch(e) {
        errorService(e)
    }
  }

  async function post(item) {
      modalStore.showConfirm({
          title : t.translate('words.postSPT'),
          children : t.translate('words.confirmPostSpt'),
          onSubmit : (callback) => {
          postExc(item)
          callback()
          }
      })
  }

  var searchDef = [
    {
      render: (
        <div className="mpk-data-filter">
          <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
            <p>{t.translate(`words.pencarian`)}</p>
          </div>
        </div>
      )
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.kap`),
      key: 'kap.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.kjs`),
      key: 'kjs.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.debt`),
      key: 'debt.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.deposit`),
      key: 'deposit.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.typeTax`),
      key: 'type.equals',
      type: 'text',
      options: [
        {label: "PPh Pasal 15", value: "PPH15"},
        {label: "PPh Pasal 22", value: "PPH22"},
        {label: "PPh Pasal 23", value: "PPH23"},
        {label: "PPh Pasal 26", value: "PPH26"},
        {label: "PPh Pasal 4a2", value: "PPH4-2"}
      ]
    },
  ]

  var columns = [
    {
      label: t.translate('words.type'),
      searchable: true,
      sortable : true,
      key: 'type',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.type)
    },
    {
      label: t.translate('words.kap'),
      searchable: true,
      sortable : true,
      key: 'kap',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.kap)
    },
    {
      label: t.translate('words.kjs'),
      searchable: true,
      sortable : true,
      key: 'kjs',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.kjs)
    },
    {
      label: t.translate('words.debt'),
      searchable: true,
      sortable : true,
      key: 'debt',
      type: TableWrapper.dataTypes.STRING,
      render: item => <div style={{ textAlign: 'right' }}>{format(item.debt)}</div>
    },
    {
      label: t.translate('words.deposit'),
      searchable: true,
      sortable : true,
      key: 'deposit',
      type: TableWrapper.dataTypes.STRING,
      render: item => <div style={{ textAlign: 'right' }}>{format(item.deposit)}</div>
    },
    {
      label: t.translate('words.selisih'),
      searchable: true,
      sortable : true,
      key: 'total',
      type: TableWrapper.dataTypes.STRING,
      render: item => <div style={{ textAlign: 'right' }}>{format(item.deposit - item.debt)}</div>
    },
    // {
    //   label : t.translate('words.typeTax'),
    //   searchable : true,
    //   sortable : true,
    //   key : 'type',
    //   type: TableWrapper.dataTypes.STRING,
    //   render: item => (item.type)
    // }
    // {
    //   label: t.translate('words.inquiry'),
    //   searchable: true,
    //   sortable : true,
    //   key: 'inquiry',
    //   type: TableWrapper.dataTypes.BOOLEAN,
    //   render: item => {
    //     if(item.inquiry){
    //       return (
    //         <Chip onClick={()=> inquiryConfirm(item)} style={{ background: "#8BC34A", color: 'white' }} primary>{item.inquiry.toString().toUpperCase()}</Chip>
    //       )
    //     } else {
    //       return (
    //         <Chip onClick={()=> inquiryConfirm(item)} style={{ background: "#F44336", color: 'white' }} >{item.inquiry.toString().toUpperCase()}</Chip>
    //       )
    //     }
    //   }
    // },
  ]

  if(AppMode.onPremise) delete searchDef[5]
  if(AppMode.onPremise) delete columns[0]
  
  return (
    <>
    <LoadingOverlay
      style={{
        position: 'inherit',
        background: 'red'
      }}
      active={ploading.loading}
      spinner
      text={ploading.message}
      >
    </LoadingOverlay>
    <TableWrapper useFilter={false}
        showCommandbar ={false}
        tableHeader={
          <>
          {/* <DashboardSpt match={match} /> */}
          <div style={{ width: '100%', paddingBottom: '14px' }}>
              <Row>
                <Col sm={4}>
                  <List className="mpk-paper">
                    <ListItem rightAddon={
                      <Button
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label="Add"
                      >
                        <FontIcon className="mdi mdi-minus"></FontIcon>
                      </Button>
                    } 
                    style={{
                      fontSize: 30,
                      fontWeight: 500
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={total.debt}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.debt')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
                </Col>
                <Col sm={4} style={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}>
                  <List className="mpk-paper">
                    <ListItem rightAddon={
                      <Button
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label="Add"
                      >
                        <FontIcon>add</FontIcon>
                      </Button>
                    } 
                    style={{
                      fontSize: 30,
                      fontWeight: 500
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={total.deposit}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.deposit')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
                </Col>
                <Col sm={4}>
                  <List className="mpk-paper">
                    <ListItem rightAddon={
                      <Button
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label="Add"
                      >
                        <FontIcon className="mdi mdi-tag-text"></FontIcon>
                      </Button>
                    } 
                    style={{
                      fontSize: 30,
                      fontWeight: 500
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={total.total}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.selisih')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
                </Col>
              </Row>
          </div>
          </>
        }
        actions={[
          // new TableWrapper.action(`${t.translate('words.postSPT')}`, 'mdi mdi-flash', (item) => { post(item) }, true),
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.ssp')}`, 'mdi mdi-import', (item) =>{ 
            localStorage.setItem('essp', JSON.stringify(item))
            window.location.href = `${redirectSsp}-new-dashboard`}
          )
        ]}
        baseId="mod-inquiry"
        // title={t.translate('modules.dashboard.title')}
        selectable={false}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try{
              var spt = JSON.parse(localStorage.getItem('spt'))
              let getPage = await SSPService.getInquiryRun({
                sptNpwp: spt.npwp,
                sptYear: spt.year,
                sptMonth: spt.month,
                sptRev: spt.rev,
                ...params,
              })
              await initData()
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              //Tambahan Uci //UNI-2488
              try{
                if(params['debt.contains']){
                  getPage.data = getPage.data.filter(d =>{ return  (d.debt+"").includes(params['debt.contains'])})
                }
                if(params['deposit.contains']){
                  getPage.data = getPage.data.filter(d =>{ return  (d.deposit+"").includes(params['deposit.contains'])})
                }
                if(params['type.equals']){
                  getPage.data = getPage.data.filter(d =>{ return  d.type === params['type.equals']})
                }
              }catch(e){}
              //Tambahan Uci //UNI-2488
              resolve(getPage)
            } catch(e){
              resolve()
              ErrorService(e)
            }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              if(values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={searchDef}
          />
        }
        columns={columns}
    />
    </>
  )
}

export default inject('authStore', 'envStore', 'modalStore')(observer(InquiryForm))
