import React, { useEffect, useState } from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from './../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { TabsWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './RincianObjekPajak.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import RincianObjekPajakPph42Form from './RincianObjekPajak.pph4(2).form'
import RincianObjekPajakPph22Form from './RincianObjekPajak.pph22.form'
import RincianObjekPajakPph26Form from './RincianObjekPajak.pph26.form'
import RincianObjekPajakPph23Form from './RincianObjekPajak.pph23.form'
import RincianObjekPajakPph15Form from './RincianObjekPajak.pph15.form'
import ErrorService from './../../services/errorService'

const RincianObjekPajak = ({ authStore, envStore, match, history }) => {
    const { inputTypes, definition } = FormWrapper
    const [data, setData] = useState({ loading: true, content: {}, total: {} })

    const baseName = `rincianDopp`
    const basePath = `rincianDopp`

    useEffect(() => {
        initData()
    }, [])

    async function initData() {
        try {
            setData({ loading: true })
            let spt = JSON.parse(localStorage.getItem('spt'))
            const res = await service.getDefault({
                sptNpwp: spt.npwp,
                sptYear: spt.year,
                sptMonth: spt.month,
                sptRev: spt.rev
            })
            var newDopp = []
            var dTotalDpp = 0;
            var dTotalPph = 0;
            res.data.dopp.map((d)=> {
                if(d.type == "PPH4-2"){
                    if(d.no.indexOf("6") != -1){
                        if(d.no == "6"){
                            newDopp.push(d)
                        }
                        dTotalDpp += d.dpp
                        dTotalPph += d.pph
                    } else {
                        newDopp.push(d)
                    }
                } else {
                    newDopp.push(d)
                }
            })
            res.data.dopp.map((d)=> {
                if(d.type == "PPH4-2"){
                    if(d.no.indexOf("6") != -1){
                        if(d.no == "6"){
                            d.dpp = dTotalDpp
                            d.pph = dTotalPph
                        }
                    }
                }
            })
            res.data.dopp = newDopp
            setData({ loading: false, content: res.data.dopp, total: res.data.doppTotal })
        } catch (e) {
            ErrorService(e);
            setData({ loading: false })
        }
    }

    var hintMessage = t.translate('words.belumDiPosting')
    try {
        var spt = JSON.parse(localStorage.getItem('spt'))
        if (window.location.href.indexOf('open') != -1) {
            var c = spt.postingDate
            if (c) {
                hintMessage = t.translate('words.tanggalPosting') + spt.postingDate
            }
        } else {
            hintMessage = ''
        }
    } catch (e) { }

    return (
        <FormWrapper
            tableForm={false}
            style={{
                maxWidth: '100%'
            }}
            hintMessage={hintMessage}
            actions={[
                {
                    label: t.translate('words.reload'),
                    iconClassName: 'mdi mdi-reload',
                    onClick: initData
                }
            ]}
            className="fullWidth"
            loading={data.loading}
            onChange={async (formData, key, value) => { }}
            baseId={`mod-form-${baseName}`}
            title={t.translate(`modules.${baseName}.formTitle`)}
            defaultData={data.content}
            definitions={[
                {
                    render: (
                        <TabsWrapper
                            showCommandbar={false}
                            baseId="mod-rincian-setor-sendiri-sample"
                            tabs={[
                                {
                                    label: t.translate('modules.pph22.title'),
                                    render: (
                                        <RincianObjekPajakPph22Form data={data.content} total={data.total} />
                                    )
                                },
                                {
                                    label: t.translate('modules.pph26.title'),
                                    render: (
                                        <RincianObjekPajakPph26Form data={data.content} total={data.total} />
                                    )
                                },
                                {
                                    label: t.translate('modules.pph23.title'),
                                    render: (
                                        <RincianObjekPajakPph23Form data={data.content} total={data.total} />
                                    )
                                },
                                {
                                    label: t.translate('modules.pph4a2.title'),
                                    render: (
                                        <RincianObjekPajakPph42Form data={data.content} total={data.total} />
                                    )
                                },
                                {
                                    label: t.translate('modules.pph15.title'),
                                    render: (
                                        <RincianObjekPajakPph15Form data={data.content} total={data.total} />
                                    )
                                }
                            ]}
                        />
                    )
                }
            ]}
        >
        </FormWrapper>
    )
}

export default RincianObjekPajak