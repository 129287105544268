import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './DataEssp.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import DaftarSptService from './../DaftarSpt/DaftarSpt.service'
import Select from 'react-select';
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import AppMode from '../../AppMode'

const DataEsspForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: {}})
  const [organization, setOrganization] = useState([])
  const [customRules, setCustomRules] = useState({})

  const baseName = `dataEssp`
  const basePath = `essp`

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await DaftarSptService.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  useEffect( async () => {
    if(AppMode.onPremise){
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise){
    backTo = `/onpremise/${basePath}`
  }

  return (
    <FormWrapper
      loading={data.loading}
      fullWidth={true}
      actions={[]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.formTitle`)}
      defaultData={data.content}
      onChange={async (formData, key, value)=> {
        try{
          if(formData.allNpwps == false){
            customRules.organization = 'required'
          } else {
            delete customRules.organization
          }
        }catch(e){}
      }}
      defaultData={{}}
      style={{
        maxWidth: '80%'
      }}
      customRules={customRules}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.month`),
          key: 'month',
          type: 'text',
          width: '50%',
          validation: 'required',
          options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.year`),
          key: 'year',
          width: '50%',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.rev`),
          key: 'rev',
          width: '50%',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.allNpwps`),
          key: 'allNpwps',
          width: '50%',
          type: 'text'
        },
        {
          isMulti: true,
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'organization',
          // validation: all,
          labelKey: 'label', 
          valueKey: 'label',
          data: organization,
          options: organization,
          async: true,
          defaultOptions: organization,
          loadOptions: async (inputValues)=> {
            if(AppMode.onPremise){
              if(inputValues.length % 2){
                var options = await getOrganizationsOp(inputValues)
                return options
              } else {
                return organization
              }
            } else {
              if(inputValues.length % 2){
                var options = await getOrganizations(inputValues)
                return options
              } else {
                return organization
              }
            }
          },
        },
      ]}
      onSubmit={ async (values, callback)=> {
        try {
          // console.log(values, "TELL ME")
          var formData = Object.assign({}, values)
          delete formData.organization
          var npwps = []
          try {
            values.organization.map((d)=> {
              npwps.push(d.value.npwp)
            })
          } catch(e){}
          formData.npwps = npwps
          let res = null
          if(AppMode.onPremise){
            res =  await service.esspCalculateOp(formData)
          } else {
            res =  await service.esspCalculate(formData)
          }
          if(AppMode.onPremise){
            history.push(`/onpremise/essp`)
          } else {
            history.push(`/product/company/${match.params.companyId}/essp`)
          }
          callback(res.data.message, false, false, false)
        } catch(e){
          errorService(e)
          callback("", false, false, false)
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DataEsspForm))
