import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './EksporData.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Select
} from 'react-md'

const EksporDataForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: {}})

  const baseName = `ekspor-data`
  const basePath = `export`

  useEffect(() => {
    async function initData(){
      if(match.params.id == 'new') {
        setData({loading: false, content: {}})
        return true
      } else {
        const res = await service.getOne(match.params.id)
        res.data.month = res.data.month + ""
        setData({loading: false, content:res.data})
      }
    }
    initData()
  }, [])

  
  return (
    <>
      <TableWrapper useFilter={false}
        title={t.translate('modules.ekspor-data.title')}
        baseId="mod-export"
        render={(data) => (
          <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
            <FormWrapper
              tableForm={true}
              loading={data.loading}
              style={{
                maxWidth: '100%'
              }}
              className="fullHeight"
              baseId={`mod-form-${baseName}`}
              title={t.translate(`modules.${baseName}.title`)}
              defaultData={data.content}
              definitions={{
                header: [],
                body: [
                  [
                    {
                      render: (
                        <TableCell hAlign="center"><strong>{t.translate(`words.jumlahData`)}</strong></TableCell>
                      )
                    },
                    {
                      render: (
                        <TableCell hAlign="center"><strong>{t.translate(`words.typeFile`)}</strong></TableCell>
                      )
                    },
                    {
                      render: (
                        <TableCell hAlign="center"><strong>{t.translate(`words.jenisData`)}</strong></TableCell>
                      )
                    }
                  ],
                  [
                    {
                      inputType: inputTypes.INPUT,
                      label: '',
                      key: 'sample',
                      type: 'text',
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: '',
                      key: 'typefile',
                      type: 'text',
                      options: [
                        { label: t.translate(`words.excel`), value: 'excel' },
                        { label: t.translate(`words.csv`), value: 'csv' },
                      ]
                    },
                    {
                      render: (
                        <TableCell hAlign="center"><Button type="submit">{t.translate(`words.jenisdata1`)}</Button> </TableCell>
                      )
                    },
                  ],
                  [
                    {
                      inputType: inputTypes.INPUT,
                      label: '',
                      key: 'sample',
                      type: 'text',
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: '',
                      key: 'typefile',
                      type: 'text',
                      options: [
                        { label: t.translate(`words.excel`), value: 'excel' },
                        { label: t.translate(`words.csv`), value: 'csv' },
                      ]
                    },
                    {
                      render: (
                        <TableCell hAlign="center"><Button type="submit">{t.translate(`words.jenisdata2`)}</Button> </TableCell>
                      )
                    },
                  ],
                  [
                    {
                      inputType: inputTypes.INPUT,
                      label: '',
                      key: 'sample',
                      type: 'text',
                    },
                    {
                      inputType: inputTypes.SELECT,
                      label: '',
                      key: 'typefile',
                      type: 'text',
                      options: [
                        { label: t.translate(`words.excel`), value: 'excel' },
                        { label: t.translate(`words.csv`), value: 'csv' },
                      ]
                    },
                    {
                      render: (
                        <TableCell hAlign="center"><Button type="submit">{t.translate(`words.jenisdata3`)}</Button> </TableCell>
                      ) 
                    },
                  ]
                ]
              }}
              showCommandbar={false}
            />
          </div>
        )}
      />
    </>
  )
}

export default inject('authStore','envStore')(observer(EksporDataForm))
