import CrudService from '../../services/crudService'
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/api/eunifikasi/sertel/manage')
let serviceUri = '/file'

service.getSertelOp = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/sertel/manage`,
    query
  })
}

service.getSertelExpiredOp = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/sertel/manage/npwp-expired-2-months`,
    query
  })
}

service.getSertelSummaryOp = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/sertel/manage/summary`,
    query
  })
}

service.getSertelByNpwpOp = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/sertel/manage/npwp`, //need params npwp
    query
  })
}

service.getSertelExpiredOp = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/sertel/manage/npwp-expired`,
    query
  })
}

service.getSertelNotHaveCertOp = async(query)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/sertel/manage/npwp-not-have-cert`,
    query
  })
}

export default service