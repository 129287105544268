import React, { useEffect, useState } from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from '../RincianObjekPajak/RincianObjekPajak.service'
import { autorun, toJs } from 'mobx'
import UtilService from '../../services/utilsService'
import _ from 'lodash'
import {
    TableContainer,
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Tab,
  } from 'react-md'

  const RincianObjekPajakPph4a2Form = ({ authStore, envStore, match, history, ...props }) => {
      const { inputTypes, definition } = FormWrapper
      const [data, setData] = useState({loading: true, content: {}})
      const [tableBody, setTableBody] = useState([])

      const baseName = `pph4a2`
      const basePath = `pph4a2`

      useEffect(() => {
        async function initData(){
            var disabled = true
            try {
                var pasals = props.data.filter((d)=> {
                    return d.type === "PPH4-2"
                })
                var formData = {}
                pasals.map((d, indexD)=> {
                    let isnum = /^\d+$/.test(d.no);
                    if (d.no.length == 1 || !isnum){
                        if(!isnum){
                            if(d.no.length == "2"){
                                d.no = "0" + d.no
                            }
                        } else {
                            d.no = "0" + d.no
                        }
                    }
                    if(d.code == "00-000-00") d.code = " "
                    // if(d.no == "1") d.no = "01" 
                    // if(d.no == "2") d.no = "02" 
                    // if(d.no == "2a") d.no = "02a" 
                    // if(d.no == "2b") d.no = "02b" 
                    // if(d.no == "3") d.no = "03" 
                    // if(d.no == "4") d.no = "04" 
                    // if(d.no == "5") d.no = "05" 
                    // if(d.no == "5a") d.no = "05a" 
                    // if(d.no == "5b") d.no = "05b" 
                    // if(d.no == "6a") d.no = "06a" 
                    // if(d.no == "6b") d.no = "06b" 
                    // if(d.no == "6c") d.no = "06c" 
                    // if(d.no == "6d") d.no = "06d" 
                    // if(d.no == "6e") d.no = "06e" 
                    // if(d.no == "6f") d.no = "06f" 
                    // if(d.no == "6g") d.no = "06g" 
                    // if(d.no == "7") d.no = "07" 
                    // if(d.no == "8") d.no = "08" 
                    // if(d.no == "9") d.no = "09" 
                    formData[`dpp${d.no}`] = d.dpp
                    formData[`pph${d.no}`] = d.pph
                })
                var pasal = _.sortBy(pasals, "no") 
                try {
                    formData['dppTotal'] = props.total.pph42.bruto
                    formData['pphTotal'] = props.total.pph42.pph
                } catch(e){}
                setData({ loading: false, content: formData })
                var formData = {}
                tableBody.push([
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.no`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell style={{ width: 500 }}><strong>{t.translate(`words.uraian`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.kodeObjekPajak`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.jumlahDpp`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.jumlahPph`)}</strong></TableCell>
                        )
                    },
                ])
                pasal.map((q, index)=> {
                    var no = (index) + 1
                    var qcode = q.code
                    if(index == 0 || index == 1 || index == 4) qcode = ""
                    if(index == 2 || index == 3) no = ""
                    if(index == 4 || index == 8 || index == 9 || index == 10 || index == 11 || index == 12 || index == 13 || index == 14) no = ""
                    if(index == 5) no = "3"
                    if(index == 6) no = "4"
                    if(index == 7) no = "5"
                    if(index == 8) no = "6"
                    if(index == 16) no = "7"
                    if(index == 17) no = "13"
                    if(index == 18) no = "9"
                    if(index == 19) no = "10"
                    if(index == 20) no = "11"
                    if(index == 21) no = "12"
                    if(index == 22) no = "13"
                    // if(q.no == '1a') no == ""
                    if(qcode == "28-404-XX"){
                        qcode = ""
                    }
                    if(no == 3) no =""
                    if(qcode == "28-407-01"){ 
                        qcode = ""
                        no = ""
                    }
                    if(qcode == "28-406-01"){ 
                        qcode = ""
                        no = ""
                    }
                    if(qcode == "28-401-XX"){
                        qcode = ""
                        no = "3"
                    }
                    if(qcode == "28-403-02"){
                        no = "4"
                    }
                    if(qcode == "28-405-01"){
                        no = "5"
                        q.description = q.description + " (Yang Diterima Wajib Pajak Dalam Negeri)"
                    }
                    if(qcode == "28-405-02"){
                        no = ""
                        q.description = q.description + " (Yang Diterima Wajib Pajak Luar Negeri)"
                    }
                    if(q.description == "Jasa Konstruksi") no = "6"
                    if(q.code == "28-409-08" || q.code == "28-409-09" || q.code == "28-409-10" || q.code == "28-409-11" || q.code == "28-409-12" || q.code == "28-409-13" || q.code == "28-409-14") no = ""
                    if(q.code == "28-409-22" || q.code == "28-409-23" || q.code == "28-409-24" || q.code == "28-409-25" || q.code == "28-409-26" || q.code == "28-409-27") no = ""
                    if(q.code == "28-417-01") no = "7"
                    if(q.code == "28-417-02") no = "8"
                    if(q.code == "28-419-01") no = "9"
                    if(q.code == "28-421-01") no = "10"
                    if(q.code == "28-421-02") no = "11"
                    if(q.code == "28-421-03") no = "12"
                    if(q.code == "28-423-01") no = "13"
                    var row = [
                        {
                            render: (
                                <TableCell style={{ whiteSpace: 'pre-wrap' }}>{no}</TableCell>
                            )
                        },
                        {
                            render: (
                                <TableCell style={{ whiteSpace: 'pre-wrap' }}>{q.description}</TableCell>
                            )
                        },
                        {
                            render: (
                                <TableCell>{qcode}</TableCell>
                            )
                        }
                    ]
                    if(q.show){
                        row.push(
                            {
                                render: (
                                    <TableCell></TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell></TableCell>
                                )
                            }
                        )
                    } else {
                        row.push(
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                thousandSeparator: thousandSeparator,
                                decimalSeparator: decimalSeparator,
                                isNumericString: true,
                                label: '',
                                key: `dpp${q.no}`,
                                type: 'text',
                                disabled: disabled,
                                className : 'inputRight'
                            },
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                thousandSeparator: thousandSeparator,
                                decimalSeparator: decimalSeparator,
                                isNumericString: true,
                                label: '',
                                key: `pph${q.no}`,
                                type: 'text',
                                disabled: disabled,
                                className : 'inputRight'
                            },
                        )
                    }
                    tableBody.push(row)
                })
                // total
                tableBody.push([
                    {
                        render: (
                            <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{t.translate('words.total')}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell></TableCell>
                        )
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        thousandSeparator: thousandSeparator,
                        decimalSeparator: decimalSeparator,
                        isNumericString: true,
                        label: '',
                        key: `dppTotal`,
                        type: 'text',
                        disabled: disabled,
                        className: 'totalBold inputRight',
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        thousandSeparator: thousandSeparator,
                        decimalSeparator: decimalSeparator,
                        isNumericString: true,
                        label: '',
                        key: `pphTotal`,
                        type: 'text',
                        disabled: disabled,
                        className: 'totalBold inputRight'
                    },
                ])
                // total
                setTableBody(tableBody)
            } catch(e){}
        }
        initData()
      }, [])

    var thousandSeparator = "."
    var decimalSeparator  = ","
    try {  
        if(localStorage.getItem('lang') == 'en'){
        thousandSeparator = ","
        decimalSeparator  = "."
        }
    } catch(e){}

      return (
          <>
            <FormWrapper 
                tableForm={true}
                style={{
                    maxWidth: '100%'
                }}
                className="fullWidth"
                loading={data.loading}
                actions={[]}
                onChange={(a,b,c)=> {
                    // // console.log(a,b,c)
                }}
                baseId={`mod-form-${baseName}`}
                hintMessage={t.translate(`modules.${baseName}.formTitle`)}
                defaultData={data.content}
                definitions={{
                    header: [
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.no`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.uraian`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.kodeObjekPajak`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.jumlahDpp`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.jumlahPph`)}</TableCell>
                        //     )
                        // }
                    ],
                    body: tableBody
                }}
                showCommandbar={false}
            />
          </>
      )
  }

  export default inject('authStore', 'envStore')(observer(RincianObjekPajakPph4a2Form))