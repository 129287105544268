import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/organization-licenses');

service.exportCsvBulk = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `/api/organization-licenses/csv/bulk`,
      data
    })
}

service.exportCsvParam = async(data)=> {
    return http.request({
      method: http.methods.GET,
      url: `/api/organization-licenses/csv/param`,
      data
    })
}

export default service;