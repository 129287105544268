import React, { useEffect, useState } from 'react'
import { FormWrapper, TabsWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpProfile.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'
import DataForm from '../../libs/react-mpk/components/DataForm'
import errorService from './../../services/errorService'
import { Button } from 'react-md'
import iziToast from 'izitoast'

const OpProfile = ({
    match,
    navigationStore
}) => {
    const { inputTypes, definition } = FormWrapper
    const [data, setData] = useState({ loading: true, content: { user: {} } })
    const [customRules, setCustomRules] = useState({})
    const [useMfa, setUseMfa] = useState(false)

    const basePath = 'profile'
    const baseId = 'mod-profile-form'

    useEffect(() => {
        async function initData() {
            var user = await service.me()
            if(user && user.data && user.data.useMfa){
                setUseMfa(true)
            } else {
                setUseMfa(false)
            }
            setData({
                content: {
                    user: user.data
                }, loading: false
            })
        }
        initData()
    }, [])

    const handleSendQr = async ()=> {
        try {
            let res = await service.sendQr()
            iziToast.success({
                title: t.translate('words.sendQr'),
                message: `${t.translate('words.prosesDone')}`
            })
        } catch(e){
            ErrorService(e)
        }
    }

    return (
        <>
            <TabsWrapper
                style={{
                    background: 'red'
                }}
                loading={data.loading}
                baseId="mod-bp-sample"
                title={t.translate('menu.profile')}
                tabs={[
                    {
                        label: t.translate('menu.editProfile'),
                        render: (tabProps) => {
                            return (
                                <DataForm
                                    onSubmit={async (values, callback) => {
                                        try {
                                            let res = await service.saveAccount(values)
                                            callback(t.translate('sentences.tersimpan'), false, false, false)
                                        } catch (e) {
                                            errorService(e)
                                            callback("", false, false, false)
                                        }
                                    }}
                                    onChange={(formData, key, value)=> {
                                        if(key == "useMfa" && value){
                                            setUseMfa(true)
                                        } else {
                                            setUseMfa(false)
                                        }
                                    }}
                                    hintMessage={t.translate('words.sendQrHint')}
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    defaultData={data.content.user}
                                    definitions={[
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.firstName`),
                                            key: 'firstName',
                                            type: 'text',
                                            validation: 'required',
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.lastName`),
                                            key: 'lastName',
                                            type: 'text',
                                            validation: 'required',
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.email`),
                                            key: 'email',
                                            type: 'text',
                                            validation: 'required',
                                        },
                                        {
                                            inputType: inputTypes.CHECKBOX,
                                            label: t.translate(`words.useMFA`),
                                            key: 'useMfa',
                                            type: 'text',
                                        },
                                        {
                                            show: useMfa,
                                            render: (
                                                <Button onClick={(e)=> { handleSendQr() }} theme="secondary" themeType="outline">{t.translate(`words.sendQr`)}</Button>
                                            )
                                        }
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        label: t.translate('menu.editPassword'),
                        render: (tabProps) => {
                            return (
                                <DataForm
                                    onSubmit={async (values, callback) => {
                                        try {
                                            let res = await service.changePassword(values)
                                            callback(t.translate('sentences.tersimpan'), false, false, false)
                                        } catch (e) {
                                            errorService(e)
                                            callback("", false, false, false)
                                        }
                                    }}
                                    defaultData={{}}
                                    // hintMessage={t.translate('menu.editPassword')}
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    definitions={[
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.currentPassword`),
                                            key: 'currentPassword',
                                            type: 'password',
                                            validation: 'required',
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.password`),
                                            key: 'password',
                                            type: 'password',
                                            validation: 'required',
                                        },
                                        {
                                            inputType: inputTypes.INPUT,
                                            label: t.translate(`words.passwordConfirmation`),
                                            key: 'passwordConfirmation',
                                            type: 'password',
                                            validation: 'required',
                                        },
                                    ]}
                                />
                            )
                        }
                    }
                ]}
            />
        </>
    )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpProfile))
