import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { toast } from '../../libs/react-mpk/services'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import Modal from '../../libs/react-mpk/components/Modal'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Table, TableBody, TableHeader, TableRow, TableCell, Card, Button, FontIcon, Divider, Dialog } from 'react-md'
import BpDokumenForm from './Bp.dokumen.form';

const Bp2Form = ({ authStore, envStore, temporaryStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [dokumens, setDokumens] = useState([])
  const [dialogDokumen, setDialogDokumen] = useState(false)
  const [dialogData, setDialogData] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      if(match.params.id == 'new') {
        setData({ loading: false, content: bpform })
        if(bpform.refs){
          setDokumens(bpform.refs);  
        } else {
          setDokumens([]);
        }
      } else {
        setData({ loading: false, content: bpform })
        setDokumens(bpform.refs);
      }
    }
    initData()
    if(match.params.id != 'new'){
      try {
        var bpform = JSON.parse(localStorage.getItem('bpform'))
        var spt = JSON.parse(localStorage.getItem('spt'))
        // console.log(spt.status, bpform.status)
        if(spt.status == 'SUBMIT' || spt.status == 'FINISH' && bpform.status == 'FINISH' || bpform.status == 'DELETED' || bpform.status == 'CANCELED'){ 
          onSubmit = false
          setIsHidden(true)
        } else {
          setIsHidden(false)
        }
      } catch(e){}
    }
  }, [])

  function _closeDialog() {
    setDialogDokumen(false)
  }

  function _setDokumens(dokumens){
    setDokumens(dokumens)
  }

  function deleteItem(index){
    var refs = []
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    refs = bpform.refs
    refs = refs.filter((d, i)=> {
      return i != index
    })
    bpform.refs = refs
    setDokumens(bpform.refs);
    localStorage.setItem(JSON.parse(localStorage.setItem('bpform', JSON.stringify(bpform))))
  }

  function editItem(index){
    setDialogDokumen(true)
    var refs = []
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    refs = bpform.refs
    var dialogData = refs[index]
    dialogData.id = index + ""
    setDialogData(dialogData)
  }

  function addItem(){
    setDialogDokumen(true)
    setDialogData({})
  }

  var onSubmit = async (data, callback) => {
    if(dokumens.length == 0){
      toast.warning(t.translate('words.b5FromValidation'))
      callback("", false, false)
      return
    } else {
      data.form2valid = true
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      data.refs = dokumens
      bpform = Object.assign(bpform, data)
      localStorage.setItem('bpform', JSON.stringify(bpform))
      props.activePanel()
    }
  }

  try {
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(bpform.status == 'CANCELED' || bpform.status == 'DELETED' || spt.status == 'SUBMIT' || spt.status == 'FINISH'){ 
      onSubmit = false
    }
  } catch(e){}
  
  return (
    <>
      <Modal
        id="dokumen-form"
        aria-label="dokumen-form"
        title={t.translate('words.formulirDasarPemotong')}
        visible={dialogDokumen}
        onRequestClose={_closeDialog}
        style={{
          width: 800, 
          height: 500
        }}
        disableFocusOnMount={true}
      >
        <BpDokumenForm _closeDialog={_closeDialog} _setDokumens={_setDokumens} data={dialogData} />
      </Modal>
      <FormWrapper
        submitLabel={t.translate('words.next')}
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        definitions={[
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptNpwp`),
          //   key: 'sptNpwp',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptRev`),
          //   key: 'sptRev',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptMonth`),
          //   key: 'sptMonth',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptYear`),
          //   key: 'sptYear',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   render: (
          //     <Divider style={{ marginBottom: 25 }}/>
          //   )
          // },
          {
            render: (
              <div style={{ width: '100%' }}>
                <Button theme="primary" themeType="contained" style={{ margin: 15 }} onClick={()=> addItem()} hidden={isHidden}><FontIcon iconClassName="mdi mdi-plus"/>{t.translate("words.Add")}</Button>
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell>{t.translate('words.non')}</TableCell>
                      <TableCell>{t.translate('words.docType')}</TableCell>
                      <TableCell>{t.translate('words.docNo')}</TableCell>
                      <TableCell>{t.translate('words.docDate')}</TableCell>
                      <TableCell>{t.translate('words.actions')}</TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {dokumens.map((d, index)=> {
                      return (  
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{d.type}</TableCell>
                          <TableCell>{d.docNo}</TableCell>
                          <TableCell>{d.docDate}</TableCell>
                          <TableCell>
                            <Button onClick={()=> editItem(index)}>{t.translate("words.edit")}</Button>
                            <Button onClick={()=> deleteItem(index)}>{t.translate("words.delete")}</Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            )
          },
          {
            render: (
              <div style={{ margin: 10 }}></div>
            )
          },
        ]}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(Bp2Form))
