import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpPaymentOrder.service'
import { Chip, ListItem, List, Button, DialogContent, Dialog, Table, TableBody, TableRow, TableCell, TableHeader, TableFooter} from 'react-md'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import errorServiceGet from './../../services/errorServiceGet'
import errorService from './../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import servicePo from './../OpLogCetakOrder/OpLogCetakOrder.service'
import LoadingOverlay from 'react-loading-overlay'
import _ from 'lodash'
import OpPaymentOrderSelected from './OpPaymentOrderSelected.table'
import { format } from '../../libs/react-mpk/services/number.service'

const OpPaymentOrder = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  temporaryStore,
  match
}) => {
  const [data, setData] = useState([])
  const [getStatus, setStatus] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [dialog, setDialog] = useState(false)
  const [dialogPo, setDialogPo] = useState(false)
  const [dialogLaporan, setDialogLaporan] = useState(false)
  const [orderAll, setOrderAll] = useState(false)
  const [banks, setBanks] = useState([])
  let [dialogItem, setDialogItem] = useState({})
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-payment-order'
  const [basePath, setBasePath] = useState('paymentOrder')

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, []) 

  async function initData() {
    try{
      const status = await service.coreStatus()
      setStatus(status.data)
      const getBanks = await service.getBank()
      getBanks.data.map((d)=> {
        d.label = d.name
        d.value = d
      })
      setBanks(getBanks.data)
      // console.log(banks)
    } catch (e){
      errorService(e)
    }
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.deleteBulk([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function printPoExc(item) {
    try {
      var proses = t.translate('words.cetakBilling')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = [1]
      // if(selected.length == 0){
      //   selectedData = [1]
      // } else {
      //   selected.map((d)=> selectedData.push(data[d].id))
      // }
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            if(selected.length == 0){
              let proccedThis = await service.generatePoBulk(item)
            } else {
              let proccedThis = await service.generatePo(item)
            }
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.donePo')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
      errorService(e)
    }
    setDialogPo(false)
  }

  async function printPo(item) {
    setDialogPo(true)
  }

  async function laporanExc(item) {
    try {
      var proses = t.translate('words.printLapBilling')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = ["1"]
      // selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.generate(item)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneInquiry')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
      errorService(e)
    }
    setDialogLaporan(false)
  }

  async function laporan(item) {
    setDialogLaporan(true)
  }

  async function retryBulkExc(item) {
    try {
      var proses = ''
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.retry(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneRetry')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function retryBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.retry'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        retryBulkExc(item)
        callback()
      }
    })
  }

  async function downloadByIdExc(item) {
    try {
      let ids = [item.id]
      let res = await servicePo.generateBulk(ids)
      const blob = new Blob([res.data], {type: res.type})
      fileSaver.saveAs(blob, 'download.csv');
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function downloadById(item) {
    modalStore.showConfirm({
      title : t.translate('words.download'),
      children : t.translate('words.confirmDownload'),
      onSubmit : (callback) => {
        downloadByIdExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await servicePo.delete(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  async function retryByIdExc(item) {
    try {
      let ids = []
      if(!item.name || !item.idBilling){
        ids.push(item.code)
      }
      let res = await service.retry(ids)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function retryById(item) {
    modalStore.showConfirm({
      title : t.translate('words.retry'),
      children : t.translate('mpk.confirm.message'),
      onSubmit : (callback) => {
        retryByIdExc(item)
        callback()
      }
    })
  }

  const showDialog = async (item)=>{
    setDialog(true)
  }

  var selected = null
  try {
    selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    var total = 0
    selected.map((d)=> {
      total += data[d].amount
      selectedData.push(data[d])
    })
  } catch(e){}

  return (
    <>
    <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
    <Dialog
      id="print-po"
      visible={dialogPo}
      disableFocusOnMount={true}
      onRequestClose={()=> setDialogPo(false)}
      style={{
        width: 800
      }}
    >
      <DialogContent>
        <div>
          <FormWrapper
            showCommandbar={false}
            hintMessage={t.translate(`words.printPo`)}
            onSubmit={async (values, callback)=> {
              await printPoExc(values)
            }}
            submitLabel={t.translate(`words.cetak`)}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> setDialogPo(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.npwp'),
                key: 'npwp',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisPajak'),
                key: 'jenisPajak',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisSetoran'),
                key: 'jenisSetoran',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan1'),
                key: 'bulan1',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan2'),
                key: 'bulan2',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.tahunPajak'),
                key: 'year',
                type: 'text',
              },
            ]}
          />
        </div>
      </DialogContent>
    </Dialog>
    <Dialog
        id="laporan-exec"
        visible={dialogLaporan}
        disableFocusOnMount={true}
        onRequestClose={()=> setDialogLaporan(false)}
        style={{
          width: 800
      }}
    >
      <DialogContent>
          <div>
            <FormWrapper
              showCommandbar={false}
              hintMessage={t.translate(`words.printLapBilling`)}
              onSubmit={async (values, callback)=> {
                if(values.toDate) values.toDate = new Date(values.toDate).toISOString()
                if(values.fromDate) values.fromDate = new Date(values.fromDate).toISOString()
                await laporanExc(values)
              }}
              submitLabel={t.translate(`words.cetak`)}
              additionalAction={[
                {
                  show: true,
                  render: ()=> (
                    <Button style={{ marginRight: 15 }} onClick={()=> setDialogLaporan(false)}>{t.translate('words.close')}</Button>
                  )
                }
              ]}
              definitions={[
                {
                  inputType: inputTypes.DATEPICKER,
                  label: t.translate('words.fromDate'),
                  key: 'fromDate',
                  type: 'date',
                  width: '50%'
                },
                {
                  inputType: inputTypes.DATEPICKER,
                  label: t.translate('words.toDate'),
                  key: 'toDate',
                  type: 'date',
                  width: '50%'
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('words.npwp'),
                  key: 'npwp',
                  type: 'text',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('words.jenisPajak'),
                  key: 'jenisPajak',
                  type: 'text',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('words.jenisSetoran'),
                  key: 'jenisSetoran',
                  type: 'text',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('words.bulan1'),
                  key: 'bulan1',
                  type: 'text',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('words.bulan2'),
                  key: 'bulan2',
                  type: 'text',
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate('words.tahunPajak'),
                  key: 'year',
                  type: 'text',
                },
              ]}
            />
          </div>
        </DialogContent>
    </Dialog>
    <Dialog 
      disableFocusOnMount={true}
      initialFocus="po-dialog"
      id="po-dialog"
      onFocus={()=>{}}
      visible={dialog}
      onRequestClose={()=> {
          setDialog(false)
      }}
      style={{
          width: 1024,
          margin: '0px !important',
          height: 600
      }}
      width={1024}
      aria-labelledby="po-dialog"
    >
      <DialogContent>
          <FormWrapper 
            style={{
              margin: '0px !important',
              width: '100%',
              maxWidth: '100%'
            }}
            defaultData={{
              npwp: dialogItem.fileName
            }}
            showCommandbar={false}
            baseId = "mod-sertel-form"
            hintMessage={t.translate(`modules.${basePath}.title`)}
            definitions={[
              {
                inputType : inputTypes.REACT_SELECT,
                label : t.translate('words.paymentMethod'),
                key : 'paymentMethod',
                type : 'text',
                options : banks
              },
              {
                render: (
                  <>
                    {!orderAll &&
                      <Table style={{ width: '100%' }}>
                        <TableHeader>
                          <TableRow>
                              <TableCell>{t.translate('words.no')}</TableCell>
                              <TableCell>{t.translate('words.code')}</TableCell>
                              <TableCell>{t.translate('words.idBilling')}</TableCell>
                              <TableCell>{t.translate('words.npwp')}</TableCell>
                              <TableCell>{t.translate('words.name')}</TableCell>
                              <TableCell>{t.translate('words.typeTax')}</TableCell>
                              <TableCell>{t.translate('words.jenisSetoran')}</TableCell>
                              <TableCell>{t.translate('words.masaPajak')}</TableCell>
                              <TableCell>{t.translate('words.jumlahSetoran')}</TableCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {selectedData.map((d, k)=> {
                            try {
                              return (
                                <TableRow key={k}>
                                  <TableCell>{k + 1}</TableCell>
                                  <TableCell>{d.code}</TableCell>
                                  <TableCell>{d.idBilling}</TableCell>
                                  <TableCell>{d.npwp}</TableCell>
                                  <TableCell>{d.name}</TableCell>
                                  <TableCell>{d.jpCode}</TableCell>
                                  <TableCell>{d.jsCode}</TableCell>
                                  <TableCell>{d.bulan1 + ' - ' + d.bulan2 + ' ' + d.year}</TableCell>
                                  <TableCell>{format(d.amount)}</TableCell>
                              </TableRow>
                              )
                            } catch(e){
                              return (<div></div>)
                            }
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                              <TableCell colSpan={7}><b>{t.translate('words.total')}</b></TableCell>
                              <TableCell colSpan={7}><b>{format(total)}</b></TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    }
                  </>
                )
              }
            ]}
            onSubmit={async (values, callback)=> {
              var formData = Object.assign({}, values)
              var obj = {
                orderAll: orderAll,
                paymentMethod: {
                  code: values.paymentMethod.code,
                  id: values.paymentMethod.id
                },
                sspList: selectedData
              }
              localStorage.setItem('paymentOrder', JSON.stringify(obj))
              if(formData.paymentMethod && formData.paymentMethod.code){
                if(formData.paymentMethod.code ==  'BNIDIRECT'){
                  history.push(`/onpremise/${basePath}/bniDirect`)
                } else if(formData.paymentMethod.code ==  'BRICMS'){
                  history.push(`/onpremise/${basePath}/briCms`)
                } else if(formData.paymentMethod.code ==  'MANDIRIMCM'){
                  history.push(`/onpremise/${basePath}/mandiriCms`)
                } else if(formData.paymentMethod.code ==  'FINPAY'){
                  history.push(`/onpremise/${basePath}/finpay`)
                } else {
                  callback(`${t.translate('words.paymentMetodeTidakTersedia')}`, true, false, false)
                }
              }
            }}
          />
        </DialogContent>
    </Dialog>
    <TableWrapper useFilter={false}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      setPage={search.page} onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              params.sortBy = params.sort.split(',')[0]
              let getPage = await service.findAll(params)
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render : (
                <div>
                  {getStatus.success &&
                    <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.connected")}</Chip>
                  }
                  {!getStatus.success &&
                    <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.notConnected")}</Chip>
                  }
                </div>
              )
            },
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.codeSsp`),
              key: 'code.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              type: 'text',
              mask: '##.###.###.#-###.###',
              maskChar: '_',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.objectCode`),
              key: 'jpCode.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.jenisSetoran`),
              key: 'jsCode.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.month1`),
              key: 'bulan1.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.month2`),
              key: 'bulan2.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.year`),
              key: 'yaer.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.username`),
              key: 'additionalUsername.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.code'),
          searchable: true,
          sortable : true,
          key:'code',
          render: item => (item.code)
        },
        {
          label: t.translate('words.idBilling'),
          searchable: true,
          sortable : true,
          key:'idBilling',
          render: item => {
            if(item.idBilling){
              return item.idBilling
            } 
            if(item.messageBilling || item.messageInquiry){
              return item.messageBilling || item.messageInquiry
            } else {
              return '-'
            }
          }
        },
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable : true,
          key:'npwp',
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key: 'name',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.name){
              return item.name
            } else if(item.errorBilling){
              return item.errorBilling
            } else {
              return '-'
            }
          }
        },
        {
          label: t.translate('words.typeTax'),
          searchable: true,
          sortable : true,
          key: 'jpCode',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.jpCode)
        },
        {
          label: t.translate('words.jenisSetoran'),
          searchable: true,
          sortable : true,
          key: 'jsCode',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.jsCode)
        },
        {
          label: t.translate('words.masaPajak'),
          searchable: true,
          sortable : true,
          key: 'masaPajak',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            return item.bulan1 + ' - ' + item.bulan2 + ' ' + item.year;
          }
        },
        {
          label: t.translate('words.jumlahSetoran'),
          searchable: true,
          sortable : true,
          key: 'amount',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.amount)
        },
        {
          label: t.translate('words.activeUntil'),
          searchable: true,
          sortable : true,
          key: 'activeUntil',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.activeUntil !== null){
              return item.activeUntil
            } else{
              return "-"
            }
          }
        },
        // {
        //   label: t.translate('words.username'),
        //   searchable: true,
        //   sortable : true,
        //   key: 'additionalUsername',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.additionalUsername)
        // },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'additionalUsername',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.additionalUsername)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
        // new TableWrapper.action(`${t.translate('words.removeChecked')}`, 'mdi mdi-minus-box', () => removeChecked(), true),
        // new TableWrapper.action(`${t.translate('words.addChecked')}`, 'mdi mdi-plus-box', () => {addChecked()}, true),
        new TableWrapper.action(`${t.translate('words.retry')}`, 'mdi mdi-play', (item) => { retryBulk()}, true),
        new TableWrapper.action(`${t.translate('words.order')}`, 'mdi mdi-shopping-music', (item) => {
          showDialog(item)
          setOrderAll(false)
        }, true),
        new TableWrapper.action(`${t.translate('words.orderAll')}`, 'mdi mdi-clipboard-play-multiple', (item) => {
          showDialog(item)
          setOrderAll(true)
        }, true),
        new TableWrapper.action(`${t.translate('words.cetakBilling')}`, 'mdi mdi-printer', (item) => {printPo()}, true),
        new TableWrapper.action(`${t.translate('words.printLapBilling')}`, 'mdi mdi-book', (item) => {laporan()}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => history.push(`${basePath}/${item.id}`)),
        // new TableWrapper.action(`${t.translate('words.retry')}`, 'mdi mdi-play', (item) => {retryById(item)}, (item)=>{
        //   if(!item.name || !item.idBilling){
        //     return true
        //   }
        //   return false
        // }),
        // new TableWrapper.action(`${t.translate('words.cetak')}`, 'mdi mdi-printer', (item) => {downloadById(item)}, (item)=>{
        //   if(item.idBilling){
        //     return true
        //   }
        //   return false
        // }),
        // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteById(item)}, true),
      ]}
      showCommandbar={showCommandbar}
    />
  </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpPaymentOrder))
