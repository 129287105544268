import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpScheduleEmail.service'
import { Chip, Button} from 'react-md'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import errorService from './../../services/errorService'

const OpScheduleEmail = ({
  className       = '',
  showCommandbar  = true,
  history         = '',

}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-schedule-email'
  const basePath = 'schedule-email'

  useEffect(async () => {
    try {
      var company = JSON.parse(window.localStorage.getItem("user"))
      var companyId = company.company
      var id = companyId.id
      if(company.authorities[0].name == 'ROLE_EPPT_OWNER'){
        history.push(`${basePath}/${id}`)
      }
    } catch(e){}
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(localStorage.getItem(`${baseId}-saved-search`))
    }
  }, [])

  async function initData(){}

  return (
    <TableWrapper useFilter={false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      sideHeader = {
        <DataForm
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values['searchKey'] = values['id.equals'] || values['company.equals'] || values['subject.equals']
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.id`),
              key: 'id.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.company`),
              key: 'company.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.active`),
              key: 'active.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.limitPerDay`),
              key: 'limitPerDay.equals',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('menu.companies'),
          searchable: true,
          sortable : true,
          key:'company',
          render: item => {
            try {
              return item.company.nama
            } catch(e){
              return "-"
            }
          }
        },
        {
          label: t.translate('words.active'),
          searchable: true,
          sortable : true,
          key: 'active',
          type: TableWrapper.dataTypes.BOOLEAN,
          render: item => {
            if(item.active){
              return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
            } else {
              return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
            }
          }
        },
        {
          label: t.translate('words.limitPerDay'),
          searchable: true,
          sortable : true,
          key: 'limitPerDay',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.limitPerDay)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
          localStorage.setItem('schedule', JSON.stringify(item))
          history.push(`${basePath}/${item.company.id}`)}, true),
      ]}
      setPage={search.page} onFetchData={ (params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let user = JSON.parse(localStorage.getItem('user'))
              let getPage = {}
              if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
                getPage = await service.get(params)
              } else {
                var companyId = user.company.id
                getPage = await service.findAll(params, companyId)
              }
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpScheduleEmail))
