// POST_SPT_Service_POST_SPT

var PermissionConstant = {
    "GET_Approval_Cancel" : "GET::/approval/cancel",
    "GET_Approval_Pengajuan" : "GET::/123123",
    "GET_Approval_Approve" : "GET::/approval/aprove",
    "GET_Approval_Setting" : "GET::/approval",
    "GET_Master_Service_GET_Negara_Page": "GET::/master/country",
    "GET_Support_Spt_Service_GET_BPE_List_by_SPT": "GET::/spt/manage/bpe/spt",
    "POST_Export_Service_Create_POST_Summary_BP": "POST::/export/spt/summary/bp",
    "GET_BP_Service_GET_BP_Page_DN": "GET::/bp/dn",
    "GET_Signer_Service_GET_Signer_Check": "GET::/signer/check",
    "GET_SSP_Service_GET_SSP_Summary": "GET::/ssp/summary/ssp",
    "GET_BP_Service_View_GET_Bp_Setor_Sendiri_Reupload": "GET::/bp/ss2/reupload/{id}",
    "GET_LT_Service_GET_LT_SPT": "GET::/lt/spt",
    "GET_Support_BP_Service_GET_BP_Reupload_DN_by_ID": "GET::/bp/manage/dn/reupload/{id}",
    "GET_SPT_Service_GET_SPT_Debt": "GET::/spt/debt",
    "POST_File_Service_POST_Create_File_Upload_Excel": "POST::/file/upload/excel",
    "GET_BP_Service_GET_BP_LN_Report_By_ID": "GET::/bp/ln/report/{id}",
    "PUT_Singer_Service_PUT_Signer": "PUT::/signer/{id}",
    "GET_BP_Service_GET_BP_LN_History_By_ID": "GET::/bp/ln/history/{id}",
    "GET_BP_Service_GET_BP_Report_DN_by_ID": "GET::/bp/dn/report/{id}",
    "GET_BP_Setor_Sendiri_Service_GET_BP_Setor_Sendiri_SPT_Summary": "GET::/bp/ss2/spt/summary",
    "GET_Dashboard_Support_BP_Count_By_Date_Service_GET_": "GET::/bp/manage/dashboard/countSubmitBpMonthYearBetweenStartAndEnd",
    "POST_Bp_Service_Create_POST_BP_DN_Email_Bulk": "POST::/bp/dn/send/search",
    "PUT_BP_Setor_Sendiri_2_Service_PUT_Update": "PUT::/bp/ss2/{id}",
    "GET_File_Service_View_GET_Download_Upload_CSV": "GET::/file/download/upload/csv/{fileId}",
    "PUT_BP_Service_PUT_BP_Update_DN": "PUT::/bp/dn/{id}",
    "GET_SSP_Service_GET_SSP_SPT_Summary": "GET::/ssp/spt/summary/ssp",
    "GET_LT_Service_View_GET_Export_Lawan_Transaksi_by_Search": "GET::/lt/export/search",
    "GET_Support_BP_Setor_Sendiri_Service_GET_BP_Setor_Sendiri_History_By_ID": "GET::/bp/manage/ss2/history/{id}",
    "GET_Export_Service_View_GET_Log_Export_by_Page": "GET::/export/log/export",
    "DELETE_SSP_Service_DELETE_SSP": "DELETE::/ssp/{id}",
    "GET_Support_SPT_Service_GET_SPT_By_ID": "GET::/spt/manage/{id}",
    "POST_Support_Bp_Service_Create_POST_BP_LN_Report_Bulk": "POST::/bp/manage/ln/report",
    "GET_SSP_Service_GET_SSP_Inquiry": "GET::/ssp/inquiry",
    "GET_BP_Service_GET_BP_DN_SPT_Summary": "GET::/bp/dn/spt/summary",
    "POST_BP_Service_POST_BP_Create_DN": "POST::/bp/dn",
    "POST_BP_Service_POST_BP_Create_LN": "POST::/bp/ln",
    "PUT_Support_BP_Service_PUT_BP_Update_LN": "PUT::/bp/manage/ln/{id}",
    "GET_Master_Service_GET_Negara_By_ID": "GET::/master/country/{id}",
    "POST_LT_Service_Create_POST_Export_Lawan_Transaksi_by_ID": "POST::/lt/export",
    "GET_SPT_Service_GET_SPT_Summary": "GET::/spt/summary",
    "GET_BP_Service_View_GET_SPT_BP_DN_Export_Search": "GET::​/bp/dn​/export​/search",
    "DELETE_Support_BP_Service_DELETE_BP_Delete_LN": "DELETE::/bp/manage/ln/{id}",
    "GET_BP_Setor_Sendiri_Service_GET_Detail_By_ID": "GET::/bp/ss/detail",
    "PUT_Support_BP_Service_PUT_BP_Update_DN": "PUT::/bp/manage/dn/{id}",
    "GET_BP_Setor_Sendiri_2_Service_GET_By_ID": "GET::/bp/ss2/{id}",
    "GET_Support_BP_Service_GET_BP_DN_SPT_Summary": "GET::/bp/manage/dn/spt/summary",
    "GET_Support_BP_Setor_Sendiri_2_Service_GET_By_ID": "GET::/bp/manage/ss2/{id}",
    "PUT_SSP_Service_PUT_SSP": "PUT::/ssp/{id}",
    "GET_Core_Service_GET_Sertel_by_Search_NPWP": "GET::/core/cert/search",
    "GET_BP_Induk_Service_GET_By_ID": "GET::/bp/induk",
    "GET_BP_Service_GET_BP_LN_Summary": "GET::/bp/ln/summary",
    "GET_Support_BP_Service_GET_BP_Page_LN": "GET::/bp/manage/ln",
    "GET_File_Service_View_GET_File_Validation_Upload_Excel_By_File_ID": "GET::/file/validation/upload/excel/{fileId}",
    "GET_Support_BP_Service_GET_BP_Report_DN_by_ID": "GET::/bp/manage/dn/report/{id}",
    "GET_SPT_Service_GET_SPT_Submit_By_ID": "GET::/spt/submit/{id}",
    "POST_Support_Bp_Service_Create_POST_BP_LN_Email_Bulk": "POST::/bp/manage/ln/send/search",
    "GET_BP_Service_GET_Object_Pajak": "GET::/bp/object",
    "GET_SPT_Service_GET_SPT_Page": "GET::/spt",
    "GET_SPT_Service_GET_SPT_Download_By_Search": "GET::/spt/download/search",
    "GET_Support_BP_Service_GET_BP_LN_Request_Report_By_ID": "GET::/bp/manage/ln/request/report/{id}",
    "GET_Support_Spt_Service_GET_BPE_List": "GET::/spt/manage/bpe",
    "GET_BP_Service_View_GET_SPT_BP_LN_Export_Search": "GET::​/bp/ln/export​/search",
    "GET_SSP_Service_GET_SSP_Page_PBK_Only": "GET::/ssp/spt/pbk",
    "GET_LT_Service_GET_LT": "GET::/lt/{id}",
    "PUT_BP_Induk_Service_PUT_Update": "PUT::/bp/induk/{id}",
    "POST_Bp_Service_Create_POST_BP_DN_Email": "POST::/bp/dn/send",
    "POST_Support_Bp_Service_Create_POST_BP_DN_Email_Bulk": "POST::/bp/manage/dn/send/search",
    "GET_SPT_Service_GET_SPT_Posting_By_ID": "GET::/spt/posting/{id}",
    "GET_SSP_Service_GET_SSP_Summary_Total": "GET::/ssp/spt/summary/total",
    "GET_Master_Service_GET_City_Page": "GET::/master/city",
    "GET_Spt_Service_GET_BPE_List_by_SPT": "GET::/spt/bpe/spt",
    "POST_SPT_Service_POST_SPT_Download": "POST::/spt/download",
    "POST_SSP_Service_POST_Create_SSP_Export": "POST::/ssp/export",
    "GET_Support_Spt_Service_GET_BP_Cetak": "GET::/spt/manage/bpe/report/{id}",
    "POST_Support_SPT_Service_POST_SPT_Check": "POST::/spt/manage/check",
    "GET_Support_BP_Setor_Sendiri_2_Service_GET_": "GET::/bp/manage/ss2",
    "GET_SSP_Service_GET_SSP_History_By_ID": "GET::/ssp/history/{id}",
    "POST_Support_BP_Setor_Sendiri_2_Service_POST": "POST::/bp/manage/ss2",
    "GET_File_Service_View_GET_File_Log_Upload_Excel": "GET::/file/log/upload/excel",
    "GET_Support_SPT_Service_GET_BP_by_ID": "GET::/spt/manage/bpe/{id}",
    "GET_File_Service_View_GET_File_Validation_Upload_CSV_": "GET::/file/validation/upload/csv/{fileId}",
    "GET_BP_Setor_Sendiri_Service_GET_BP_Setor_Sendiri_Summary": "GET::/bp/ss2/summary",
    "GET_Support_Spt_Service_GET_SPT_Pembetulan_by_ID": "GET::/spt/manage/rev/{id}",
    "POST_SSP_Service_POST_SSP": "POST::/ssp",
    "GET_Master_Service_GET_Province_Page": "GET::/master/province",
    "PUT_Support_BP_Setor_Sendiri_2_Service_PUT_Update": "PUT::/bp/manage/ss2/{id}",
    "PUT_Bp_Service_Create_POST_Setting_Email": "PUT::/core/setting/mail",
    "GET_Spt_Service_GET_BPE_Request_Cetak": "GET::/spt/bpe/request/{id}",
    "GET_BP_Service_GET_BP_Induk_Report": "GET::/bp/induk/report",
    "GET_Support_BP_Service_GET_BP_DN_Summary": "GET::/bp/manage/dn/summary",
    "GET_SPT_Service_GET_Object_Pajak_by_ID": "GET::/spt/object/{id}",
    "GET_BP_Service_View_GET_Setor_Sendiri_Export_by_Search": "GET::/bp/ss2/export/search",
    "GET_Support_BP_Service_GET_BP_LN_SPT_Summary": "GET::/bp/manage/ln/spt/summary",
    "DELETE_Signer_Service_DELETE_Signer": "DELETE::/signer/{id}",
    "POST_BP_Service_POST_Create_Setor_Sendiri_Export": "POST::/bp/ss2/export",
    "GET_SPT_Service_GET_SPT_History_By_ID": "GET::/spt/history/{id}",
    "POST_SPT_Service_POST_Create_SPT_Dashboard": "POST::/spt/summary",
    "GET_Signer_Service_GET_Signer_Page": "GET::/signer",
    "POST_BP_Service_Create_POST_SPT_BP_LN_Export": "POST::/bp/ln/export",
    "GET_SSP_Service_GET_Data_ESSP": "GET::/ssp/ebilling/data-essp",
    "GET_SSP_Service_GET_SSP_Inquiry_By_ID": "GET::/ssp/inquiry/{id}",
    "PUT_BP_DOPP_ETC_Service_PUT_Update": "PUT::/bp/dopp/etc/{id}",
    "GET_SPT_Service_GET_SPT_By_ID": "GET::/spt/{id}",
    "GET_SSP_Service_View_GET_SSP_Export_by_Search": "GET::/ssp/export/search",
    "GET_Support_SPT_Service_GET_SPT_Debt": "GET::/spt/manage/debt",
    "POST_Bp_Service_Create_POST_BP_LN_Report_Bulk": "POST::/bp/ln/report",
    "GET_File_Service_View_GET_Download_Template": "GET::/file/download/template",
    "GET_Support_BP_Service_GET_BP_DN_History_By_ID": "GET::/bp/manage/dn/history/{id}",
    "GET_Signer_Service_GET_Signer_Recon_ID": "GET::/signer/recon/{id}",
    "POST_SPT_Service_POST_SPT": "POST::/spt",
    "GET_BP_Service_GET_BP_Reupload_LN_by_ID": "GET::/bp/ln/reupload/{id}",
    "PUT_BP_Service_PUT_BP_Update_LN": "PUT::/bp/ln/{id}",
    "PUT_BP_Setor_Sendiri_Service_PUT_Update": "PUT::/bp/ss1/{id}",
    "GET_Support_BP_Service_GET_BP_Reupload_LN_by_ID": "GET::/bp/manage/ln/reupload/{id}",
    "GET_Export_Service_View_GET_Export_PDF": "GET::/export/spt/pdf/{sptId}",
    "GET_SSP_Service_GET_PBK_SPT_Summary": "GET::/ssp/spt/summary/pbk",
    "GET_Support_BP_Service_GET_BP_LN_History_By_ID": "GET::/bp/manage/ln/history/{id}",
    "GET_SPT_Service_GET_SPT_Download_By_ID": "GET::/spt/download/{id}",
    "GET_Spt_Service_GET_BPE_List": "GET::/spt/bpe",
    "PUT_LT_Service_PUT_LT": "PUT::/lt/{id}",
    "GET_Support_BP_Service_View_GET_Bp_Setor_Sendiri_Reupload": "GET::/bp/manage/ss2/reupload/{id}",
    "DELETE_Support_BP_Setor_Sendiri_2_Service_DELETE_by_ID": "DELETE::/bp/manage/ss2/{id}",
    "GET_Bp_Service_View_GET_Log_Mail_by_SPT": "GET::/bp/log/mail/spt/{sptId}",
    "GET_File_Service_View_GET_File_Upload_Csv_Spt": "GET::/file/log/upload/csv/spt",
    "GET_Support_Spt_Service_GET_BPE_Request_Cetak": "GET::/spt/manage/bpe/request/{id}",
    "GET_Support_BP_Setor_Sendiri_Service_GET_BP_Setor_Sendiri_Summary": "GET::/bp/manage/ss2/summary",
    "POST_SPT_Service_POST_SPT_Check": "POST::/spt/check",
    "GET_BP_Service_BP_GET_By_ID_LN": "GET::/bp/ln/{id}",
    "GET_File_Service_View_GET_Download_Upload_XLS": "GET::/file/download/upload/xls/{fileId}",
    "DELETE_File_Service_Remove_DELETE_Upload_Csv_By_ID": "DELETE::/file/log/upload/csv/{id}",
    "GET_Support_BP_Service_BP_GET_By_ID_DN": "GET::/bp/manage/dn/{id}",
    "GET_BP_Setor_Sendiri_Service_GET_BP_Setor_Sendiri_History_By_ID": "GET::/bp/ss2/history/{id}",
    "GET_Spt_Service_GET_BP_Cetak": "GET::/spt/bpe/report/{id}",
    "POST_SSP_Service_POST_Create_Data_ESSP_Generate": "POST::/ssp/ebilling/data-essp/generate",
    "PUT_BP_Setor_Sendiri_Service_PUT_Detail_Update": "PUT::/bp/ss/detail/{id}",
    "POST_SPT_Service_POST_Create_SPT_Bpe_Report_Bulk": "POST::/spt/bpe/report",
    "GET_Master_Service_GET_District_Page": "GET::/master/district",
    "GET_BP_DOPP_ETC_Service_GET": "GET::/bp/dopp/etc",
    "GET_Support_SPT_Service_GET_SPT_Submit_By_ID": "GET::/spt/manage/submit/{id}",
    "GET_Support_BP_Service_GET_BP_Page_DN": "GET::/bp/manage/dn",
    "GET_SSP_Service_GET_SSP_Inquiry_Run": "GET::/ssp/inquiry/run",
    "GET_Support_SPT_Service_GET_Object_Pajak_by_ID": "GET::/spt/manage/object/{id}",
    "GET_SSP_Service_GET_SSP_Page_SSP_Only": "GET::/ssp/spt/ssp",
    "DELETE_BP_Service_DELETE_BP_Delete_DN": "DELETE::/bp/dn/{id}",
    "POST_Support_BP_Service_POST_BP_Create_DN": "POST::/bp/manage/dn",
    "DELETE_SSP_Service_DELETE_Remove_Data_ESSP": "DELETE::/ssp/ebilling/data-essp/{id}",
    "GET_Export_Service_View_GET_Summary_BP_By_SPT_ID": "GET::/export/spt/summary/bp/{sptId}",
    "GET_BP_Service_View_GET_Log_Mail_History_by_ID": "GET::/bp/log/mail/history/{id}",
    "POST_SSP_Service_POST_Create_Data_ESSP_PULL": "POST::/ssp/ebilling/data-essp/pull",
    "GET_SSP_Service_GET_By_ID": "GET::/ssp/{id}",
    "POST_Signer_Service_POST_Signer": "POST::/signer",
    "GET_BP_DOPP_Service_GET_": "GET::/bp/dopp/detail",
    "GET_BP_Service_View_GET_Setting_Email_": "GET::/core/setting/mail/company/{companyId}",
    "GET_Master_Service_GET_Subdistrict_Page": "GET::/master/subdistrict",
    "POST_BP_Service_Create_POST_SPT_BP_DN_Export": "POST::/bp/dn/export",
    "GET_Support_SPT_Service_GET_SPT_History_By_ID": "GET::/spt/manage/history/{id}",
    "GET_BP_Service_GET_BP_DN_History_By_ID": "GET::/bp/dn/history/{id}",
    "GET_Support_SPT_Service_GET_SPT_Page": "GET::/spt/manage",
    "POST_Support_Bp_Service_Create_POST_BP_LN_Email": "POST::/bp/manage/ln/send",
    "GET_Support_SPT_Service_GET_SPT_Summary": "GET::/spt/manage/summary",
    "POST_Support_SPT_Service_POST_SPT": "POST::/spt/manage/",
    "DELETE_BP_Setor_Sendiri_2_Service_DELETE_by_ID": "DELETE::/bp/ss2/{id}",
    "POST_SSP_Service_POST_Create_ESSP_Billing_Calculate": "POST::/ssp/ebilling/calculate",
    "GET_LT_Service_GET_Page": "GET::/lt",
    "GET_BP_Setor_Sendiri_Service_GET_By_ID": "GET::/bp/ss1",
    "GET_File_Service_View_GET_File_Log_Upload_Excel_By_SPT": "GET::/file/log/upload/excel/spt",
    "GET_BP_Service_GET_BP_Reupload_DN_by_ID": "GET::/bp/dn/reupload/{id}",
    "POST_File_Service_Create_POST_Upload_Csv": "POST::/file/upload/csv",
    "GET_BP_Service_BP_GET_Page_By_SPT": "GET::/bp/dn/spt",
    "GET_Signer_Service_GET_Signer": "GET::/signer/{id}",
    "GET_Support_BP_Setor_Sendiri_Service_GET_BP_Setor_Sendiri_SPT_Summary": "GET::/bp/manage/ss2/spt/summary",
    "GET_Support_SPT_Service_GET_SPT_Posting_By_ID": "GET::/spt/manage/posting/{id}",
    "GET_BP_Service_GET_BP_DN_Summary": "GET::/bp/dn/summary",
    "GET_BP_Service_GET_BP_DN_Report_By_ID": "GET::/bp/dn/report/{id}",
    "GET_Support_BP_Service_BP_GET_Page_By_SPT": "GET::/bp/manage/dn/spt",
    "GET_Export_Service_View_GET_Log_Export_by_ID": "GET::/export/log/export/{id}",
    "POST_Bp_Service_Create_POST_BP_LN_Email": "POST::/bp/ln/send",
    "DELETE_Support_BP_Service_DELETE_BP_Delete_DN": "DELETE::/bp/manage/dn/{id}",
    "GET_SSP_Service_GET_PBK_Summary": "GET::/ssp/summary/pbk",
    "GET_BP_Service_GET_BP_LN_SPT_Summary": "GET::/bp/ln/spt/summary",
    "POST_Bp_Service_Create_POST_BP_LN_Email_Bulk": "POST::/bp/ln/send/search",
    "GET_File_Service_View_GET_Log_Upload_CSV": "GET::/file/log/upload/csv",
    "GET_BP_Service_GET_DOPPSS_Detail": "GET::/bp/doppss/detail",
    "DELETE_BP_Service_DELETE_BP_Delete_LN": "DELETE::/bp/ln/{id}",
    "POST_Support_Bp_Service_Create_POST_BP_DN_Report_Bulk": "POST::/bp/manage/dn/report",
    "POST_BP_Setor_Sendiri_2_Service_POST": "POST::/bp/ss2",
    "GET_BP_Service_GET_DOPPSS": "GET::/bp/doppss",
    "GET_BP_Setor_Sendiri_Service_GET_Setor_Sendiri_By_SPT": "GET::/bp/ss2/spt",
    "GET_SSP_Service_GET_SSP_Page": "GET::/ssp",
    "GET_SPT_Service_View_GET_SPT_Dashboard": "GET::/spt/summary",
    "GET_BP_Service_GET_BP_Page_LN": "GET::/bp/ln",
    "DELETE_Export_Service_Remove_Delete_Log_Export_By_ID": "DELETE::/export/log/export/{id}",
    "POST_Support_Bp_Service_Create_POST_BP_DN_Email": "POST::/bp/manage/dn/send",
    "GET_Support_BP_Service_BP_GET_By_ID_LN": "GET::/bp/manage/ln/{id}",
    "POST_Bp_Service_POST_Create_DOPPSS": "POST::/bp/doppss",
    "GET_Support_BP_Service_GET_BP_DN_Request_Report_By_ID": "GET::/bp/manage/dn/request/report/{id}",
    "GET_File_Service_View_GET_File_Log_Upload_Excel_By_ID": "GET::/file/log/upload/excel/{id}",
    "GET_BP_Service_GET_BP_DN_Request_Report_By_ID": "GET::/bp/dn/request/report/{id}",
    "GET_Support_BP_Service_GET_BP_LN_Report_By_ID": "GET::/bp/manage/ln/report/{id}",
    "GET_Spt_Service_GET_SPT_Pembetulan_by_ID": "GET::/spt/rev/{id}",
    "GET_Signer_Service_GET_Set_Default_By_ID": "GET::/signer/default/{id}",
    "GET_Support_BP_Service_GET_BP_DN_Report_By_ID": "GET::/bp/manage/dn/report/{id}",
    "GET_BP_Service_GET_BP_LN_Request_Report_By_ID": "GET::/bp/ln/request/report/{id}",
    "POST_LT_Service_POST_LT": "POST::/lt",
    "DELETE_LT_Service_DELETE_LT": "DELETE::/lt/{id}",
    "POST_Bp_Service_Create_POST_BP_DN_Report_Bulk": "POST::/bp/dn/report",
    "POST_Support_BP_Service_POST_BP_Create_LN": "POST::/bp/manage/ln",
    "GET_BP_Setor_Sendiri_2_Service_GET_": "GET::/bp/ss2",
    "GET_Bp_Service_View_GET_Log_Mail": "GET::/bp/log/mail",
    "GET_Support_BP_Setor_Sendiri_Service_GET_Setor_Sendiri_By_SPT": "GET::/bp/manage/ss2/spt",
    "GET_SSP_Service_GET_SSP_by_SPT": "GET::/ssp/spt/{sptId}",
    "DELETE_File_Service_Remove_DELETE_Upload_Excel_By_ID": "DELETE::/file/log/upload/excel/{id}",
    "GET_Signer_Service_GET_Active_Default_By_ID": "GET::/signer/active/{id}",
    "GET_BP_Service_BP_GET_By_ID_DN": "GET::/bp/dn/{id}",
    "GET_Support_BP_Service_GET_BP_LN_Summary": "GET::/bp/manage/ln/summary"
}

module.exports = PermissionConstant;