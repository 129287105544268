import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './LawanTransaksi.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import AsyncSelect from 'react-select/async'
import AppMode from '../../AppMode'
import DaftarSptService from '../DaftarSpt/DaftarSpt.service'

const LawanTransaksiForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [organization, setOrganization] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [qq, setQQ] = useState(false)
  const [showBp23, setShowBp23] = useState(false)
  const [showBp23Nik, setShowBp23Nik] = useState(false)
  const [customRules, setCustomRules] = useState({})
  const [negara, setNegara] = useState([])
  const [province, setProvince] = useState([])
  const [city, setCity] = useState([])
  const [showcity, setShowCity] = useState(true)
  const [district, setDistrict] = useState([])
  const [showdistrict, setShowDistrict] = useState(true)
  const [subdistrict, setSubDistrict] = useState([])
  const [subshowdistrict, setShowSubDistrict] = useState(true)
  const [inputMasks, setInputMasks] = useState('##.###.###.#-###.###')
  const [isTin, setIsTin] = useState(false)

  const baseName = `lawanTransaksi`
  const basePath = `lawan-transaksi`

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await DaftarSptService.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  useEffect(() => {
    async function initData(){
      if(match.path.indexOf('open') != -1){

      } else {
        if(AppMode.onPremise){
          await getOrganizationsOp()
        } else {
          await getOrganizations()
        }
      }
      var negaras = await getNegara()
      var provinces = await getProvince()
      if(match.params.id == 'new') {
        if(match.path.indexOf('open') != -1){
          var spt = JSON.parse(localStorage.getItem('spt'))
          setData({loading: false, content: {
            npwpProfile: spt.npwp,
            qq: false,
            identityAs: "0",
            address: "-",
            phone: "0",
            postalCode: "0",
            province: "-",
            city: "-",
            district: "-",
            subDistrict: "-",
          }})
        } else {
          setData({loading: false, content: {
            qq: false,
            identityAs: "0",
            address: "-",
            phone: "0",
            postalCode: "0",
            province: "-",
            city: "-",
            district: "-",
            subDistrict: "-",
          }})
        }
        setCustomRules({ identity: ['required', 'min:15'] })
        setShowBp23(true)
      } else {
        const res = await service.getOne(match.params.id)
        // filterOrg 
        var organizations = await getOrganizations()
        organizations.map((d)=> {
          if(res.data.npwpProfile == d.npwp) res.data.npwpProfile = d.npwp + " - " + d.name
        })
        // filterCountry 
        negaras.map((d)=> {
          if(res.data.country == d.alias) res.data.country = d.name
        })
        // filterKota
        provinces.map((d)=> {
          if(res.data.province == d.name) getCity(null, d.code)
        })
        // filterDistrict
        try {
          let getCityz = await service.getCity(res.data.city)
          getDistrict(null, getCityz.data[0].code);
        } catch(e){}
        // filterSubDistrict
        try {
          let getDistrictz = await service.getDistrict(res.data.district)
          getSubDistrict(null, getDistrictz.data[0].code);
        } catch(e){}
        //
        // res.data.npwpProfile = res.data.npwp
        res.data.identityAs = res.data.identityAs + ""
        if(res.data.qq == true){
          customRules.qqName = 'required'
        } else {
          delete customRules.qqName
        }
        if(res.data.identityAs == '0'){
          setCustomRules({ identity: ['required', 'min:15'] })
          setInputMasks('##.###.###.#-###.###')
          setIsTin(false)
          setShowBp23(true)
          setShowBp23Nik(false)
        }
        if(res.data.identityAs == '1'){
          setCustomRules({ identity: ['required', 'min:16'] })
          setInputMasks('################')
          setIsTin(false)
          setShowBp23(true)
          setShowBp23Nik(true)
        }
        if(res.data.identityAs == '2'){
          setCustomRules({ identity: ['required'], country: ['required'] })
          setIsTin(true)
          setInputMasks(null)
          setShowBp23(false)
          setShowBp23Nik(false)
        }
        setQQ(res.data.qq)
        setData({loading: false, content:res.data})
      }
    }
    initData()
  }, [])

  async function getNegara(query){
    try {
      var negara = await service.getNegara(query)
      negara.data.map((d)=> {
        d.label = d.name
        d.value = d.name
      })
      setNegara(negara.data)
      return negara.data
    } catch(e){
      ErrorService(e)
    }
  }

  async function getProvince(query){
    try {
      var province = await service.getProvince(query)
      setProvince(province.data)
      return province.data
    } catch(e){
      ErrorService(e)
    }
  }

  async function getCity(query, provinceId){
    try {
      setShowCity(false)
      setTimeout(async ()=> {
        var city = await service.getCity(query, provinceId)
        setCity(city.data)
        setShowCity(true)
        return city.data
      }, 500)
    } catch(e){
      ErrorService(e)
    }
  }

  async function getDistrict(query, cityId){
    try {
      setShowDistrict(false)
      setTimeout(async ()=> {
        var district = await service.getDistrict(query, cityId)
        setDistrict(district.data)
        setShowDistrict(true)
        return district.data
      }, 500)
    } catch(e){
      ErrorService(e)
    }
  }

  async function getSubDistrict(query, districtId){
    try {
      setShowSubDistrict(false)
      setTimeout(async ()=> {
        var subDistrict = await service.getSubDistrict(query, districtId)
        setSubDistrict(subDistrict.data)
        setShowSubDistrict(true)
        return subDistrict.data
      }, 500)
    } catch(e){
      ErrorService(e)
    }
  }

  let backTo = ``
  var showSptIn;
  if(match.path.indexOf('open') != -1){
    if(AppMode.onPremise){
      backTo = `/onpremise/daftar-spt/open/${match.params.sptId}/${basePath}`
    } else {
      backTo = `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/${basePath}`
    }
    showSptIn = true
  } else {
    if(AppMode.onPremise){
      backTo = `/onpremise/${basePath}`
    } else {
      backTo = `/product/company/${match.params.companyId}/${basePath}`
    }
    showSptIn = false
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
        maxWidth: '80%'
      }}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.formTitle`)}
      defaultData={data.content}
      onBeforeChange={(key, value)=> {
        if(key == 'countryName'){
          if(value.length % 2 == 0){
            getNegara(value)
          }
        }
        return value
      }}
      onChange={(formData, key, value)=> {
        // PVC
        // try {
        //   if(formData.identityAs == "1"){
        //     if(!formData.id){
        //         if(formData.province == "-"){
        //           formData.province = null
        //         }
        //     }
        //     if(!formData.id){
        //       if(formData.city == "-"){
        //         formData.city = null
        //       }
        //     }
        //   }
        //   if(formData.identityAs == "0"){
        //     if(!formData.id){
        //         if(formData.province == null){
        //           formData.province = "-"
        //         }
        //     }
        //     if(!formData.id){
        //       if(formData.city == null){
        //         formData.city = "-"
        //       }
        //     }
        //   }
        //   if(formData.identityAs == "2"){
        //     if(!formData.id){
        //         if(formData.province == null){
        //           formData.province = "-"
        //         }
        //     }
        //     if(!formData.id){
        //       if(formData.city == null){
        //         formData.city = "-"
        //       }
        //     }
        //   }
        // } catch(e){}
        // PVC
        if(key == "qq") setQQ(value)
        if(formData.qq == true){
          customRules.qqName = 'required'
        } else {
          delete customRules.qqName
        }
        if(key == 'identityAs' && value == '0'){
          setCustomRules({ identity: ['required', 'min:15'] })
          setInputMasks('##.###.###.#-###.###')
          setShowBp23(true)
          setShowBp23Nik(false)
          setIsTin(false)
        }
        if(key == 'identityAs' && value == '1'){
          setCustomRules({ identity: ['required', 'min:16'] })
          setInputMasks('################')
          setShowBp23(true)
          setShowBp23Nik(true)
          setIsTin(false)
        }
        if(key == 'identityAs' && value == '2'){
          setCustomRules({ identity: ['required'], country: 'required' })
          setInputMasks(null)
          setShowBp23(false)
          setShowBp23Nik(false)
          setIsTin(true)
        }
        if(key == 'countryName'){
          negara.map((d)=> {
            if(d.name == value) formData.country = d.code
          })
        }
        if(key == 'province'){
          formData['city'] = null
          formData['district'] = null
          formData['subDistrict'] = null
          province.map((d)=> {
            if(d.name == value) getCity(null, d.code)
          })
        }
        if(key == 'city'){
          formData['district'] = null
          formData['subDistrict'] = null
          city.map((d)=> {
            if(d.name == value) getDistrict(null, d.code)
          })
        }
        if(key == 'district'){
          formData['subDistrict'] = null
          district.map((d)=> {
            if(d.name == value) getSubDistrict(null, d.code)
          })
        }
      }}
      customRules={customRules}
      definitions={[
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.identityNpwp`),
          key: 'identityAs',
          name: 'identityAs',
          width: '50%',
          value: "0",
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.identityNik`),
          key: 'identityAs',
          name: 'identityAs',
          width: '50%',
          value: "1",
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.identityTin`),
          key: 'identityAs',
          name: 'identityAs',
          width: '50%',
          value: "2",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.identity`),
          key: 'identity',
          type: 'text',
          mask: inputMasks,
          maskChar: '_',
          maskValue: 'string',
          show: !isTin
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.identity`),
          key: 'identity',
          type: 'text',
          show: isTin
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.name`),
          key: 'name',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.reference`),
          key: 'referensi',
          type: 'text',
          width: '50%',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.qq`),
          key: 'qq',
          type: 'text',
          width: '20%',
          style: {
            width: '10%'
          },
          show: (showBp23 && !showBp23Nik)
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.qqName`),
          key: 'qqName',
          type: 'text',
          width : '90%',
          disabled: !qq,
          show: (showBp23 && !showBp23Nik)
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.email`),
          key: 'email',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.address`),
          key: 'address',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.phone`),
          key: 'phone',
          type: 'text',
          width: '50%',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.postalCode`),
          key: 'postalCode',
          type: 'text',
          width: '50%',
          show: showBp23
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.country`),
          key: 'countryName',
          colSpan: 3,
          data: negara,
          show: !showBp23
        },
        // {
        //   render: (
        //     <div class="mpk-custom-input input mpk-margin-N margin-bottom flex-none" style={{ width: "100%" }}>
        //       <AsyncSelect placeholder="Negara" class="rmd-text-field rmd-text-field--floating" style={{ width: '100%' }} cacheOptions defaultOptions loadOptions={getNegara}>
                
        //       </AsyncSelect>
        //     </div>
        //   )
        // },
        // {
        //   render: (
        //     <p>{JSON.stringify(negara)}</p>
        //   )
        // },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.province`),
          key: 'province',
          colSpan: 3,
          data: province,
          labelKey: 'name', 
          valueKey: 'name',
          width: '50%',
          show: showBp23,
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.city`),
          key: 'city',
          colSpan: 3,
          data: city,
          labelKey: 'name', 
          valueKey: 'name',
          width: '50%',
          show: showBp23 && showcity,
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.district`),
          key: 'district',
          colSpan: 3,
          data: district,
          labelKey: 'name', 
          valueKey: 'name',
          width: '50%',
          validation : 'required',
          show: showBp23 && showdistrict,
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate(`words.subDistrict`),
          key: 'subDistrict',
          colSpan: 3,
          data: subdistrict,
          labelKey: 'name', 
          valueKey: 'name',
          width: '50%',
          validation : 'required',
          show: showBp23 && subshowdistrict,
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          // console.log(data, "TELL ME")
          var res = {}
          var formData = Object.assign({}, data)
          // filterCountry 
          negara.map((d)=> {
            if(d.name == formData.countryName) formData.country = d.alias
          })
          delete formData.organization
          //
          if(match.params.id == 'new') {
            // formData.npwpProfile = formData.npwpProfile.split(' - ')[0]
            data.npwpProfile = data.npwpProfile.npwp
            res = await service.post(formData)
          } else {            
            res = await service.put(formData.id, formData)
          }
          callback(t.translate('sentences.tersimpan'), false, false, false)
          history.push(backTo) 
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        }      
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(LawanTransaksiForm))
