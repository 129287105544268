import React, { useEffect, useState } from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJs } from 'mobx'
import UtilService from '../../services/utilsService'
import _ from 'lodash'
import {
    TableContainer,
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Tab,
  } from 'react-md'

  const RincianObjekPajakPph15Form = ({ authStore, envStore, match, history, ...props }) => {
      const { inputTypes, definition } = FormWrapper
      const [data, setData] = useState({loading: true, content: {}})
      const [tableBody, setTableBody] = useState([])

      const baseName = `pph15`
      const basePath = `pph15`

      useEffect(() => {
        async function initData(){
            var disabled = true
            try {
                var pasals = props.data.filter((d)=> {
                    return d.type == "PPH15"
                })
                var formData = {}
                pasals.map((d, indexD)=> {
                    if(d.no == "1") d.no = "01" 
                    if(d.no == "2") d.no = "02" 
                    if(d.no == "3") d.no = "03" 
                    formData[`dpp${d.no}`] = d.dpp
                    formData[`pph${d.no}`] = d.pph
                })
                var pasal = _.sortBy(pasals, "no")  
                try {
                    formData['dppTotal'] = props.total.pph15.bruto
                    formData['pphTotal'] = props.total.pph15.pph
                } catch(e){}
                setData({ loading: false, content: formData })
                var formData = {}
                tableBody.push([
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.no`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell style={{ width: 500 }}><strong>{t.translate(`words.uraian`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.kodeObjekPajak`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.jumlahDpp`)}</strong></TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell><strong>{t.translate(`words.jumlahPph`)}</strong></TableCell>
                        )
                    },
                ])
                pasal.map((q, index)=> {
                    tableBody.push([
                        {
                            render: (
                                <TableCell style={{ whiteSpace: 'pre-wrap' }}>{(index) + 1}</TableCell>
                            )
                        },
                        {
                            render: (
                                <TableCell style={{ whiteSpace: 'pre-wrap' }}>{q.description}</TableCell>
                            )
                        },
                        {
                            render: (
                                <TableCell>{q.code}</TableCell>
                            )
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            thousandSeparator: thousandSeparator,
                            decimalSeparator: decimalSeparator,
                            isNumericString: true,
                            label: '',
                            key: `dpp${q.no}`,
                            type: 'text',
                            disabled: disabled,
                            className : 'inputRight'
                        },
                        {
                            inputType: inputTypes.INPUT_MASK_NUMBER,
                            thousandSeparator: thousandSeparator,
                            decimalSeparator: decimalSeparator,
                            isNumericString: true,
                            label: '',
                            key: `pph${q.no}`,
                            type: 'text',
                            className : 'inputRight',
                            disabled: disabled,
                        },
                    ])
                })
                // total
                tableBody.push([
                    {
                        render: (
                            <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>4</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{t.translate('words.total')}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell></TableCell>
                        )
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        thousandSeparator: thousandSeparator,
                        decimalSeparator: decimalSeparator,
                        isNumericString: true,
                        label: '',
                        key: `dppTotal`,
                        type: 'text',
                        disabled: disabled,
                        className: 'totalBold inputRight'
                    },
                    {
                        inputType: inputTypes.INPUT_MASK_NUMBER,
                        thousandSeparator: thousandSeparator,
                        decimalSeparator: decimalSeparator,
                        isNumericString: true,
                        label: '',
                        key: `pphTotal`,
                        type: 'text',
                        disabled: disabled,
                        className: 'totalBold inputRight'
                    },
                ])
                // total
                setTableBody(tableBody)
            } catch(e){}
        }
        initData()
      }, [])

    var thousandSeparator = "."
    var decimalSeparator  = ","
    try {  
        if(localStorage.getItem('lang') == 'en'){
        thousandSeparator = ","
        decimalSeparator  = "."
        }
    } catch(e){}

      return (
          <>
            <FormWrapper 
                tableForm={true}
                style={{
                    maxWidth: '100%'
                }}
                className="fullWidth"
                loading={data.loading}
                actions={[]}
                onChange={(a,b,c)=> {
                    // // console.log(a,b,c)
                }}
                baseId={`mod-form-${baseName}`}
                hintMessage={t.translate(`modules.${baseName}.formTitle`)}
                defaultData={data.content}
                definitions={{
                    header: [
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.no`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell style={{ width: 500 }}>{t.translate(`words.uraian`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.kodeObjekPajak`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.jumlahDpp`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.jumlahPph`)}</TableCell>
                        //     )
                        // }
                    ],
                    body: tableBody
                }}
                showCommandbar={false}
            />
          </>
      )
  }

  export default inject('authStore', 'envStore')(observer(RincianObjekPajakPph15Form))