import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'

let service = null
var serviceUrl = '/approval/flow'
if(AppMode.onPremise){
  service = new CrudService('/api/mainFlow');
  serviceUrl = '/api/mainFlow'
} else {
  service = new CrudService('/approval/flow/company');
}

service.post = async(data)=>{
  return http.request({
    method : http.methods.POST,
    url : `/approval/flow`,
    data
  })
}

service.put = async(data)=>{
  return http.request({
    method : http.methods.PUT,
    url : `/approval/flow`,
    data
  })
}

service.setDefault = async(id)=> {
  return http.request({
      method: http.methods.GET,
      url: `${serviceUrl}/set-default/${id}`
  })
}

service.getFlowById = async(id)=>{
  return http.request({
    method : http.methods.GET,
    url : `/approval/flow/${id}`,
  })
}

service.bulkDelete = async(id)=> {
  return http.request({
      method: http.methods.DELETE,
      url: `/approval/flow/${id}`
  })
}

// service.deleteById = async(id)=>{
//   return http.request({
//     method : http.methods.DELETE,
//     url :  `/approval/flow/${id}`
//   })
// }

// service.createOne = async(data)=> {
//   return http.request({
//       method: http.methods.POST,
//       url: `/approval/flow`,
//       data
//   })
// }

// service.bulkDelete = async(data)=> {
//   return http.request({
//       method: http.methods.POST,
//       url: `${serviceUrl}/delete`,
//       data
//   })
// }

// service.getCompany = async(query)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/core/host/organization`,
//     query
//   })
// }

// service.getUsers = async(query)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/core/host/user?page=0&size=20`,
//     query
//   })
// }

// service.getUserSearch = async(query)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/core/host/user/search`,
//     query
//   })
// }

export default service;