import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Divider, Button } from 'react-md'

const BpDokumenForm = ({ authStore, envStore, temporaryStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isHidden, setIsHidden] = useState(false)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      setData({
        loading: false,
        content: props.data
      })
    }
    initData()
    try{
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      var spt = JSON.parse(localStorage.getItem('spt'))
      if(spt.status == 'SUBMIT' || spt.status == 'FINISH' && bpform.status == 'FINISH' || bpform.status == 'DELETED' || bpform.status == 'CANCELED'){ 
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
    }catch(e){}
  }, [])
  
  return (
    <>
      <FormWrapper
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        definitions={[
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.docType`),
            key: 'type',
            width: '100%',
            type: 'text',
            validation: 'required',
            options: [
                { value: "01", label: "Faktur Pajak" },
                { value: "02", label: "Invoice" },
                { value: "03", label: "Pengumuman" },
                { value: "04", label: "Surat Perjanjian" },
                { value: "05", label: "Bukti Pembayaran" },
                { value: "06", label: "Akta Perikatan" },
                { value: "07", label: "Akta RUPS" },
                { value: "08", label: "Surat Pernyataan" }
            ]
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.docNo`),
            key: 'docNo',
            width: '100%',
            type: 'text',
            validation: 'required'
          },
          {
            inputType: inputTypes.DATETIME,
            label: t.translate(`words.docDate`),
            key: 'docDate',
            width: '100%',
            type: 'date',
            validation: 'required',
            // portalId : 'root-portal'
          },
        ]}
        additionalAction={[
          {
            show: true,
            render: ()=> (
              <Button style={{ marginRight: 15 }} hidden={isHidden} onClick={()=> props._closeDialog()}>{t.translate('words.close')}</Button>
            )
          }
          // <Button style={{ marginRight: 15 }} onClick={()=> props._closeDialog()}>{t.translate('words.close')}</Button>
        ]}
        onSubmit={async (data, callback) => {
            var bpForm = JSON.parse(localStorage.getItem('bpform'))
            var refs = bpForm.refs
            if(data.type == "01") data.docName = "Faktur Pajak"
            if(data.type == "02") data.docName = "Invoice"
            if(data.type == "03") data.docName = "Pengumuman"
            if(data.type == "04") data.docName = "Surat Perjanjian"
            if(data.type == "05") data.docName = "Bukti Pembayaran"
            if(data.type == "06") data.docName = "Akta Perikatan"
            if(data.type == "07") data.docName = "Akta RUPS"
            if(data.type == "08") data.docName = "Surat Pernyataan"
            if(data.id){
              delete data.id
              refs[props.data.id] = data
            } else {
              refs.push(data)
            }
            bpForm.refs = refs
            localStorage.setItem('bpform', JSON.stringify(bpForm))
            props._setDokumens(refs)
            props._closeDialog();
        }}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(BpDokumenForm))
