import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService('/api/eunifikasi/organization-users');

service.findAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/eunifikasi/organization-users/page`,
    query
  })
}

service.exportCsvBlob = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/eunifikasi/organization-users/csv/param`,
    query
  })
}

service.createdOne= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/organization-users`,
    data,
  })
}

service.editById = async(id, data)=>{
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/organization-users`,
    data,
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/organization-users/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/eunifikasi/organization-users/${id}`,
  })
}

service.getOrganizationOp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/organization`,
    query
})
}

service.getUser = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/users`,
    query
})
}

service.exportCsv = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/organization-users/csv/bulk`,
    data
  })
}

export default service;