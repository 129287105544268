import React, {useEffect, useState} from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../libs/react-mpk/wrapper'
import { sso } from '../libs/react-mpk/hocs'
import { DashboardSample, TableSample, FormSample, SspDashboardForm, ImportLogCsv, ImportLog, ImportBp, ImportDossDopp, ImportSetorSendiri, ImportSspPbk, ArchiveDaftarBpeView, Dashboard, ArchivePbkHistory, ArchiveBp26View, ArchiveBp26Form, ArchiveBpView, ArchiveDoss2HistoryView, ArchiveBp26History, ArchiveBpHistory, ArchiveSspHistory, ArchiveBpForm, Bp1Form, Bp2Form, Bp3Form, Bp4Form, DoppDokumenLainForm, ArchiveIndukForm, ImportDataIn, SignerForm, SignerView, LawanTransaksiForm, LawanTransaksiView, ArchiveSspView, ArchiveSspForm, ArchivePbkView, ArchivePbkForm, ArchiveDoss1Form, ArchiveDoss2View, ArchiveDoss2Form, RincianSetorSendiri, ArchiveRincianSetorSendiri, ArchiveRincianObjekPajak, EksporDataInForm,  EksporData, ImportData, BpBulkArchiveForm, Bp26BulkArchiveForm } from '../modules'
import { autorun, toJS } from 'mobx';
import { List, ListItem, FontIcon } from 'react-md'
import t from 'counterpart'
import onp from '../libs/custom/onp';
import OpLoginService from '../modules/OpLogin/OpLogin.service'
import axios from 'axios'
import AppMode from '../AppMode'
import Notification from '../modules/CustomComponent/Notification'

const OnpremiseSptArchive = ({match, history}) => {

    let [user, setUser] = useState({})
    let [activeSidebar, setActiveSidebar] = useState([])

    useEffect(async () => {
        try {
          // set header
          var res = JSON.parse(localStorage.getItem('token'))
          axios.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`
          axios.defaults.withCredentials = true;
          let me = await OpLoginService.me()
          setUser(me.data)
          localStorage.setItem('user', JSON.stringify(me.data))
        } catch(e){
          window.location.href = '/#/login'
          localStorage.removeItem('notifSertel')
        }
        try {
            document.getElementsByClassName('mpk-appbar-shortcut')[0].style.display = 'none'
        } catch(e){}
    }, [])
    
    useEffect(async () => {
      sidebarInit()
    }, [user])

    var sidebarInit = ()=> {
        var sidebarMenuActive = []
        var sidebarMenu = [
            {
                "label":`${t.translate('menu.mainMenu')}`
            },
            // {
            //     "label": `${t.translate('menu.manajemen')}`,
            //     "children":[
            //     {
            //         "label":`${t.translate('menu.dashboard')}`,
            //         "index":"dashboard",
            //         "resourceUri":"",
            //         "iconClassName":"mdi mdi-layout",
            //         "path":`/onpremise/daftar-spt-archive/open/${sptId}/dashboard`,
            //         "childPath":[]
            //     }
            //     ]
            // },
            {
                "label": `${t.translate('menu.bp')}`,
                "children":[
                {
                    "label":`${t.translate('menu.bpDn')}`,
                    "index":"bp, dn, dalam, negeri, dalam negeri, dalam-negeri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/bp`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.bpLn')}`,
                    "index":"bp, ln, luar, negeri, luar negeri, luar-negeri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/bp26`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.doss2')}`,
                    "index":"pph, setor, sendiri, setor sendiri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/doss2`,
                    "childPath":[]
                },
                ]
            },
            {
                "label": `${t.translate('menu.lampiranSpt')}`,
                "children":[
                {
                    "label":`${t.translate('menu.doss/dopp')}`,
                    "index":"dopp dokumen lain, dopp, objek, daftar, potong, pungut, objek potong, objek pungut, objek-potong, objek-pungut, dokumen, lain, dokumen-lain, doss 1, pph, satu, daftar, objek, setor, sendiri, daftar-objek-setor-sendiri, setor sendiri, daftar objek, daftar objek setor sendiri, setor-sendiri, ss",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/doss1`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.doss')}`,
                    "index":"doss 2, daftar, doss2, doss, objek, pph, rincian, rincian pph, rincian-pph, ss, setor, sendiri,setor-sendiri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/rincian-setor-sendiri`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.dopp')}`,
                    "index":"rincian daftar object pajak, rincian, daftar, objek, pajak, objek pajak, objek-pajak, rincian objek, dopp",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/rincian-objek-pajak`,
                    "childPath":[]
                }
                ]
            },
            {
                "label": `${t.translate('menu.induk')}`,
                "children":[
                {
                    "label":`${t.translate('menu.induk')}`,
                    "index":"spt, induk, surat, pemberitahuan, tahunan, surat-pemberitahuan-tahunan, pajak",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/spt-induk`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.bpe')}`,
                    "index":"bpe",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/daftar-bpe`,
                    "childPath":[]
                }
                ]
            },
            {
                "label": `${t.translate('menu.daftarSsp')}`,
                "children":[
                {
                    "label":`${t.translate('menu.ssp')}`,
                    "index":"ssp, daftar, surat, setoran, pajak",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/ssp`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.pbk')}`,
                    "index":"daftar, pbk, pemindahbukuan, buku, pindah",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/pbk`,
                    "childPath":[]
                },
                ]
            },
            // {
            //     "label": `${t.translate('menu.setting')}`,
            //     "children":[
            //     {
            //         "label":`${t.translate('menu.lt')}`,
            //         "index":"lawan-transaksi, pengaturan, transaksi, lawan, lt",
            //         "resourceUri":"",
            //         "iconClassName":"mdi mdi-layout",
            //         "path":`/onpremise/daftar-spt-archive/open/${sptId}/lawan-transaksi`,
            //         "childPath":[]
            //     },
            //     {
            //         "label":`${t.translate('menu.signer')}`,
            //         "index":"penandatangan, signer, tanda, tangan, tandatangan, sign",
            //         "resourceUri":"",
            //         "iconClassName":"mdi mdi-layout",
            //         "path":`/onpremise/daftar-spt-archive/open/${sptId}/signer`,
            //         "childPath":[]
            //     },
            //     ]
            // }
        ]

        var sidebarMenuDjpId = [
            {
                "label":`${t.translate('menu.mainMenu')}`
            },
            // {
            //     "label": `${t.translate('menu.manajemen')}`,
            //     "children":[
            //     {
            //         "label":`${t.translate('menu.dashboard')}`,
            //         "index":"dashboard",
            //         "resourceUri":"",
            //         "iconClassName":"mdi mdi-layout",
            //         "path":`/onpremise/daftar-spt-archive/open/${sptId}/dashboard`,
            //         "childPath":[]
            //     }
            //     ]
            // },
            {
                "label": `${t.translate('menu.bp')}`,
                "children":[
                {
                    "label":`${t.translate('menu.bpDn')}`,
                    "index":"bp, dn, dalam, negeri, dalam negeri, dalam-negeri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/bp`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.bpLn')}`,
                    "index":"bp, ln, luar, negeri, luar negeri, luar-negeri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/bp26`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.doss2')}`,
                    "index":"pph, setor, sendiri, setor sendiri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/doss2`,
                    "childPath":[]
                },
                ]
            },
            {
                "label": `${t.translate('menu.lampiranSpt')}`,
                "children":[
                {
                    "label":`${t.translate('menu.doss')}`,
                    "index":"doss 2, daftar, doss2, doss, objek, pph, rincian, rincian pph, rincian-pph, ss, setor, sendiri,setor-sendiri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/rincian-setor-sendiri`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.dopp')}`,
                    "index":"rincian daftar object pajak, rincian, daftar, objek, pajak, objek pajak, objek-pajak, rincian objek, dopp",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/rincian-objek-pajak`,
                    "childPath":[]
                }
                ]
            },
            {
                "label": `${t.translate('menu.induk')}`,
                "children":[
                {
                    "label":`${t.translate('menu.induk')}`,
                    "index":"spt, induk, surat, pemberitahuan, tahunan, surat-pemberitahuan-tahunan, pajak",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/spt-induk`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.bpe')}`,
                    "index":"bpe",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/daftar-bpe`,
                    "childPath":[]
                }
                ]
            },
            {
                "label": `${t.translate('menu.daftarSsp')}`,
                "children":[
                {
                    "label":`${t.translate('menu.ssp')}`,
                    "index":"ssp, daftar, surat, setoran, pajak",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/ssp`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.pbk')}`,
                    "index":"daftar, pbk, pemindahbukuan, buku, pindah",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/pbk`,
                    "childPath":[]
                },
              ]
            },
            // {
            //     "label": `${t.translate('menu.setting')}`,
            //     "children":[
            //     {
            //         "label":`${t.translate('menu.lt')}`,
            //         "index":"lawan-transaksi, pengaturan, transaksi, lawan, lt",
            //         "resourceUri":"",
            //         "iconClassName":"mdi mdi-layout",
            //         "path":`/onpremise/daftar-spt-archive/open/${sptId}/lawan-transaksi`,
            //         "childPath":[]
            //     },
            //     {
            //         "label":`${t.translate('menu.signer')}`,
            //         "index":"penandatangan, signer, tanda, tangan, tandatangan, sign",
            //         "resourceUri":"",
            //         "iconClassName":"mdi mdi-layout",
            //         "path":`/onpremise/daftar-spt-archive/open/${sptId}/signer`,
            //         "childPath":[]
            //     },
            //     ]
            // },
            {
                "label": `${t.translate('menu.import')}`,
                "children":[
                {
                    "label":`${t.translate('menu.importBpExcel')}`,
                    "index":"import, bukti potong",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/import`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.importSetorSendiri')}`,
                    "index":"import, setor, sendiri",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/import-setor-sendiri`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.importDossDopp')}`,
                    "index":"import, doss, dopp",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/import-doss-dopp`,
                    "childPath":[]
                },
                {
                    "label":`${t.translate('menu.importSspPbk')}`,
                    "index":"import, ssp, pbk",
                    "resourceUri":"",
                    "iconClassName":"mdi mdi-layout",
                    "path":`/onpremise/daftar-spt-archive/open/${sptId}/import-ssp-pbk`,
                    "childPath":[]
                },
                ]
            },
        ]

        try {
            var getspt = JSON.parse(localStorage.getItem('spt'))
            if(!getspt.djpId){
            sidebarMenuActive = sidebarMenuDjpId
            } else {
            sidebarMenuActive = sidebarMenu
            }
        } catch(e){
            sidebarMenuActive = sidebarMenuDjpId
        }
        setActiveSidebar(sidebarMenuActive)
    }

    const historys = toJS(history)
    var companyId = match.params.companyId
    var sptId = match.params.sptId
    var spt = {
        month: '-',
        year: '-',
        rev: '-'
    }
    try {
        var getspt = JSON.parse(localStorage.getItem('spt'))
        if(getspt){
        spt.year  = getspt.year
        spt.month = getspt.month
        spt.rev   = getspt.rev
        }
    } catch(e){
        // console.log(e, 'getSpt')
    }

    return (
        <PageWrapper
            appbar={{
                additionalNotif:<Notification />
            }}
            sidebarCollapsible={true}
            sidebarDefaultCollapse={false}
            inverseTheme={true}
            style={{background: '#D14C21'}}
            sidebarMenu={activeSidebar}
            useSidebar={true}
            username={user.login}
            handleLogout={async ()=> {
                let res = await OpLoginService.logout();
                localStorage.removeItem('token')
                localStorage.removeItem('session')
                localStorage.removeItem('notifSertel')
                window.location.href = '/#/login'
            }}
            sidebarHeader={(
              <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
                <ListItem style={{ marginTop: '-3px' }} secondaryText={`${t.translate('words.pembetulan')} ${spt.rev}`} rightAddon={<FontIcon onClick={()=>{
                  window.location.href = `/#/onpremise/dashboardSpt`
                  //history.push(`/product/company/${companyId}/daftar-spt-archive`)
                }} iconClassName="mdi mdi-close"></FontIcon>}>
                  {`${spt.year} - ${spt.month}`}
                </ListItem>
              </List>
            )}
            handleProfile={()=> {
                window.location.href = `/#/onpremise/profile`
            }}
        >
            <Router history={history}>
                <Switch>
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/dashboard'
                    render={props => (
                        <Dashboard {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/ssp/:id/history'
                    render={props => (
                        <ArchiveSspHistory {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/ssp/:id'
                    render={props => (
                        <ArchiveSspForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/ssp'
                    render={props => (
                        <ArchiveSspView {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/ssp-new-dashboard'
                    render={props => (
                        <SspDashboardForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/daftar-bpe'
                    render={props => (
                        <ArchiveDaftarBpeView {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/pbk/:id/history'
                    render={props => (
                        <ArchivePbkHistory {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/pbk/:id'
                    render={props => (
                        <ArchivePbkForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/pbk'
                    render={props => (
                        <ArchivePbkView {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/rincian-setor-sendiri'
                    render={props => (
                        <ArchiveRincianSetorSendiri {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/rincian-objek-pajak'
                    render={props => (
                        <ArchiveRincianObjekPajak {...props}/>
                    )}
                    />
                    <Route
                    path='/onpremise/daftar-spt-archive/open/:sptId/doss2/:id/history'
                    render={props => (
                        <ArchiveDoss2HistoryView {...props}/>
                    )}
                    />
                    <Route
                    path='/onpremise/daftar-spt-archive/open/:sptId/doss2/:id'
                    render={props => (
                        <ArchiveDoss2Form {...props}/>
                    )}
                    />
                    <Route
                    path='/onpremise/daftar-spt-archive/open/:sptId/doss2'
                    render={props => (
                        <ArchiveDoss2View {...props}/>
                    )}
                    />
                    <Route
                    path='/onpremise/daftar-spt-archive/open/:sptId/doss1'
                    render={props => (
                        <ArchiveDoss1Form {...props}/>
                    )}
                    />
                    <Route
                    path='/onpremise/daftar-spt-archive/open/:sptId/index'
                    render={props => (
                        <div />
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/lawan-transaksi/:id'
                    render={props => (
                        <LawanTransaksiForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/lawan-transaksi'
                    render={props => (
                        <LawanTransaksiView {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/signer/:id'
                    render={props => (
                        <SignerForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/signer'
                    render={props => (
                        <SignerView {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/spt-induk'
                    render={props => (
                        <ArchiveIndukForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/dopp-dokumen-lain'
                    render={props => (
                        <DoppDokumenLainForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp-1/:id'
                    render={props => (
                        <Bp1Form {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp-2/:id'
                    render={props => (
                        <Bp2Form {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp-3/:id'
                    render={props => (
                        <Bp3Form {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp-4/:id'
                    render={props => (
                        <Bp4Form {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp/:id/history'
                    render={props => (
                        <ArchiveBpHistory {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp/:id'
                    render={props => (
                        <ArchiveBpForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp/bp-bulk'
                    render={props => (
                        <BpBulkArchiveForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp'
                    render={props => (
                        <ArchiveBpView {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp26/:id/history'
                    render={props => (
                        <ArchiveBp26History {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp26/:id'
                    render={props => (
                        <ArchiveBp26Form {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp26/bp-bulk'
                    render={props => (
                        <Bp26BulkArchiveForm {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/bp26'
                    render={props => (
                        <ArchiveBp26View {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import/:id/import-detail-csv'
                    render={props => (
                        <ImportLogCsv {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import/:id/import-detail'
                    render={props => (
                        <ImportLog {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import'
                    render={props => (
                        <ImportData {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/export'
                    render={props => (
                        <EksporData {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import-bp'
                    render={props => (
                        <ImportBp {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import-setor-sendiri'
                    render={props => (
                        <ImportSetorSendiri {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import-doss-dopp'
                    render={props => (
                        <ImportDossDopp {...props}/>
                    )}
                    />
                    <Route 
                    path='/onpremise/daftar-spt-archive/open/:sptId/import-ssp-pbk'
                    render={props => (
                        <ImportSspPbk {...props}/>
                    )}
                    />
                </Switch>
            </Router>
        </PageWrapper>
    )
}


export default onp({
  basePath: '/onpremise/daftar-spt-archive/open/:sptId'
})(OnpremiseSptArchive);
