import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './ImportData.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import { FontIcon, Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, TextField } from 'react-md'
import ErrorService from './../../services/errorService'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import AppMode from '../../AppMode'
import SptService from '../DaftarSpt/DaftarSpt.service'
import UtilsService from './../../services/utilsService'

const ImportData = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore,
  temporaryStore,
  authStore
}) => {

  const basePath = `import-xls`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = `mod-import-xls`
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  const [ organization, setOrganization] = useState({})
  var insideSpt = false
  const [exportDialog, setExportDialog] = useState(false);
  const [pencarian, setPencarian] = useState([]) //Tambahan uci

  useEffect(()=> {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      var x = JSON.parse(localStorage.getItem(`${baseId}-saved-search`))
      delete x['npwpProfile.contains']
      delete x['npwp.contains']
      setSearch(x)
      multiSearch(x)
    }
    if(AppMode.onPremise){
      getOrganizationsOp()
    } else {
      getOrganizations()
    }
  }, [])

  const multiSearch = (values) =>{
    try{
      var formData = Object.assign({}, values)
      if(values){
        Object.keys(values).map((d)=>{
          var kolom = d.split('.')[0]
          var params = d.split('.')[1]
          if(kolom && params){
            if(params === 'in'){
              if(kolom === "rev" || kolom === 'year' || kolom === 'type' || kolom === 'status' || kolom === 'refFileId'){
                d = kolom+".equals"
                formData[d] = values[kolom+"."+params]
                delete formData[kolom+"."+params]
              }else{
                d = kolom+".contains"
                formData[d] = formData[kolom+"."+params]
                delete formData[kolom+"."+params]
              }
            }
          }
        })

        setPencarian(formData)
      }
    }catch(e){}
  }

  async function importData(item) {
    setDialogItem(item)
    setDialog(true)
  }

  async function hapusExc(item) {
    try {
        var proses = t.translate('words.hapus')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.delete(di.id)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function downloadLogExc(item) {
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    setTimeout(async ()=> {
      try {
        let res = await service.downloadLog(item.id);
        if(res.data.status == 1){
          setPloading({ loading: false, message: `` });
          window.open(res.data.data.url);
        } else {
          setPloading({ loading: false, message: `` });
          iziToast.info({
            message: res.data.message
          })
        }
      } catch (e) {
        setPloading({ loading: false, message: `` });
        errorService(e);
      }
    })
  }

  async function downloadLog(item) {
    modalStore.showConfirm({
      title : t.translate('words.importLog'),
      children : t.translate('words.confirmSptDownload'),
      onSubmit : (callback) => {
        downloadLogExc(item)
        callback()
      }
    })
  }

  async function downloadTemplate(){
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}`})
    try {
      let res = await service.download({
        type: 'BP'
      })
      if(res.data.data.url){
      setPloading({ loading: false, message: `` })
      window.open(res.data.data.url)
    }
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  async function downloadTemplateFile(item){
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    setTimeout(async ()=> {
      try {
        let res = await service.downloadFile(item.id)
        if(res.data.data.url){
          setPloading({ loading: false, message: `` })
          window.open(res.data.data.url)
        }
      } catch(e){
        setPloading({ loading: false, message: `` })
        errorService(e)
      }
    }, 1000)
  }

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  var columns = [
    {
      label: t.translate('words.success'),
      searchable: true,
      sortable : true,
      key: 'success',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if(item.success){
          return <Chip style={{ background: "#049e51", color: 'white'}}>{t.translate(`words.ya`)}</Chip>
        } else {
          return <Chip style={{ background: "#F44336", color: 'white'}}>{t.translate(`words.tidak`)}</Chip>
        }
      }
    },
    {
      label: t.translate('words.status'),
      searchable: true,
      sortable : true,
      key: 'status',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if(item.status && item.status == "COMPLETED"){
          return <Chip style={{ background: "#049e51", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
        } 
        if(item.status && item.status == "FAILED"){
          return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
        }
        if(item.status && item.status == "PROCESS"){
          return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
        }else {
          return <Chip>{(item.status+"").toUpperCase()}</Chip>
        } 
      }
    },
    {
      label: t.translate('words.progress'),
      searchable: true,
      sortable : true,
      key: 'progress',
      type: TableWrapper.dataTypes.NUMBER,
      render: (item) => {
        if(item.progress > 100) item.progress = 100
        return (
          <div className='progress'>
            {item.status == "FAILED" &&
              <Progress 
                percent={100}
                strokeWidth ={6}
                status = "error"
              />
            }
            {item.status !== "FAILED" &&
              <Progress 
                percent={item.progress}
                strokeWidth ={6}
                theme={{
                  success: {
                    color: '#049e51',
                    trailColor : '#e0e0e0'
                  },
                  active: {
                    color: '#FFC108',
                    trailColor : '#e0e0e0'
                  },
                  default: {
                    color: 'blue',
                    trailColor : '#e0e0e0'
                  }
                }}
              />
            }
          </div>
        )
      }
    },
    {
      label: t.translate('words.counter'),
      searchable: true,
      sortable : true,
      key: 'counter',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        try {
          return item.counter + " / " + item.total
        } catch(e){
          return ""
        }
      }
    },
    {
      label: t.translate('words.npwp'),
      searchable: true,
      sortable : true,
      key: 'npwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.npwp)
    },
    {
      label: t.translate('words.filename'),
      searchable: true,
      sortable : true,
      key: 'originalFilename',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.originalFilename)
    },
    {
      label: t.translate('words.md5'),
      searchable: true,
      sortable : true,
      key: 'md5',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.md5)
    },
    {
      label: t.translate('words.fileSize'),
      searchable: true,
      sortable : true,
      key: 'fileSize',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.fileSize)
    },
    {
      label: t.translate('words.message'),
      searchable: true,
      sortable : true,
      key: 'message',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.message)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable : true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable : true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => ((item.createdDate))
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable : true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable : true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  if(AppMode.onPremise) delete columns[2]

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = () => {

  }

  var exportAll = () => {

  }

  async function retryLogExc(item) {
    setPloading({
      loading: true,
      message: `${t.translate("words.prosesData")}`,
    });
    try {
      var proses = t.translate("words.retry");

      let proccedThis = await service.retryLog(item.id);

      if(proccedThis.data){
        iziToast.success({
          title: proses,
          message: proccedThis.data.message ? proccedThis.data.message : "Berhasil"
        })
      }

    } catch (e) {
      errorService(e);
    }
    setPloading({
      loading: false,
    });
  }

  async function retryLog(item, type) {
    modalStore.showConfirm({
      title: t.translate("words.retry"),
      children: t.translate("words.retryConfirm"),
      onSubmit: (callback) => {
        retryLogExc(item);
        callback();
      },
    });
  }
  
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog 
          disableFocusOnMount={true}
          initialFocus="file-import-excel-dialog"
          id="file-import-excel-dialog"
          onFocus={()=>{}}
          visible={dialog}
          onRequestClose={()=> {
            setDialog(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="file-import-excel-dialog"
        >
          <DialogContent>
            <FormWrapper 
              style={{
                margin: '0px !important',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={{
                npwp: dialogItem.npwp,
                isValidateFirst : true,
              }}
              hintMessage={'Pastikan isi File Import anda sudah benar dan format file sesuai dengan npwp_nama file.xls !'}
              showCommandbar={false}
              baseId = "mod-file-import-excel-form"
              title={t.translate('modules.organization.title')}
              definitions={[
                {
                  inputType : inputTypes.FILE_INPUT,
                  label : t.translate('words.file'),
                  key : 'file',
                  type : 'file',
                  validation: 'required',
                  multiple: true,
                  multiline: true,
                  accept: '.xls,.xlsx'
                },
                {
                  inputType : inputTypes.CHECKBOX,
                  label : t.translate('words.isValidateFirst'),
                  key : 'isValidateFirst',
                  type : 'text',
                }
              ]}
              onSubmit={async (values, callback)=> {
                try {
                    var proses = t.translate('words.import')
                    var selected = JSON.parse(TableWrapper.getSelected(baseId))
                    var selectedData = values.file
                    selected.map((d)=> selectedData.push(data[d]))
                    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                    var errors = []
                    bluebird.mapSeries(values.file, async (di, di2)=> {
                      setTimeout(async ()=> {
                        try {
                          var formData = new FormData()
                          formData.append('file', di.file)
                          formData.append('isValidateFirst', values.isValidateFirst)
                          await service.importData(formData)
                        } catch(e){
                          errors.push(await errorServiceGet(e, di, di2))
                        }
                        setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
                        if((di2+1) == selectedData.length){
                          setPloading({ loading: false, message: `` })
                          if(errors.length == 0){
                            iziToast.success({
                              title: proses,
                              message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                            })
                          } else {
                            modalStore.showInfo({
                              title: '',
                              children: (
                                <List>
                                  {errors.map((er)=> {
                                    return <ListItem primaryText={''} secondaryText={er}></ListItem>
                                  })}
                                </List>
                              )  
                            })
                          }
                          setDialog(false)
                          try{
                            var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                            if(checkboxRoot.checked){
                              if(checkboxRoot.getAttribute('aria-checked')){
                                checkboxRoot.click()
                                checkboxRoot.click()
                              } else {
                                checkboxRoot.click()
                              }
                            }
                            document.getElementsByClassName('mdi-reload')[0].click()
                          }catch(e){}
                        }
                      }, 1000 * di2)
                    })
                } catch(e) {
                    setDialog(false)
                    errorService(e)
                }
              }}
            />
          </DialogContent>
      </Dialog>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.importData.title')}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try {
                let getPage = null
                if(window.location.href.indexOf('open') != -1){
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  params.npwpProfile = spt.npwp
                  getPage = await service.getBySpt(params)
                } else {
                  getPage = await service.get(params)
                }
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                setData(getPage.data)
                resolve(getPage)
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={pencarian}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setPencarian(newsearch)
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              var formData = Object.assign({}, values)
              if(values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if(values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              if(AppMode.onPremise){
                if(values['sptNpwp.in'] && values['sptNpwp.in'].length > 0){
                  var stringIn = ""
                  values['sptNpwp.in'].map((d)=> {
                    stringIn += d.value + ","
                  })
                  values['sptNpwp.in'] = stringIn
                }
              } else {
                if(values['npwp.in'] && values['npwp.in'].length > 0){
                  var stringIn = ""
                  values['npwp.in'].map((d)=> {
                    stringIn += d.value.npwp + ","
                  })
                  values['npwp.in'] = stringIn
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['npwp.in'] = allNpwpString
                  delete values.allNpwps
                }
                if((values['originalFilename.contains']+"").indexOf(',') != -1){
                  values['originalFilename.in'] = values['originalFilename.contains']
                  delete values['originalFilename.contains']
                }
              }

              if(values['status.in'] && values['status.in'].length > 0){
                var stringIn = ""
                values['status.in'].map((d)=> {
                  stringIn += d.value + ","
                })
                values['status.in'] = stringIn
              }

              formData.page = 0 //Tambahan Uci
              setPencarian(formData) //Tambahan Uci
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              // {
              //   render: (
              //     <div style={{ width: '100%' }}>
              //       {!AppMode.onPremise &&
              //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
              //       }
              //     </div>
              //   )
              // },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwps`),
                key: 'allNpwps',
                type: 'text',
                show : AppMode.onPremise ? false : true
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: AppMode.onPremise? 'sptNpwp.in' : 'npwp.in',
                labelKey: 'label', 
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
                show: !insideSpt
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.filename`),
                key: 'originalFilename.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.status`),
                key: "status.in",
                type: "text",
                isMulti: true,
                options: [
                  { label: 'PROCESS', value: 'PROCESS' },
                  { label: 'COMPLETED', value: 'COMPLETED' },
                  { label: 'DELETED', value: 'DELETED' },
                  { label: 'FAILED', value: 'FAILED' },
                  { label: 'FINISH', value: 'FINISH' },
                  { label: 'WARNING', value: 'WARNING' },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.status`),
                key: "status.contains",
                type: "text",
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.message`),
                key: 'message.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.createdBy`),
                key: 'createdBy.contains',
                type: 'text'
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.lastModifiedBy`),
              //   key: 'lastModifiedBy.contains',
              //   type: 'text'
              // },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.tanggal1`),
                key: 'createdDate.greaterOrEqualThan',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.tanggal2`),
                key: 'createdDate.lessOrEqualThan',
                type: 'text'
              },
            ]}
          />
        }
        columns={columns}
        actions={[
          new TableWrapper.action(`${t.translate('words.import')}`, 'mdi mdi-file-import', (item) => {importData(item)}, true),
          new TableWrapper.action(`${t.translate('words.downloadTemplate')}`, 'mdi mdi-file-excel', (item) => {downloadTemplate(item)}, true),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { hapus(item)}, true),
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.importDetail')}`, 'mdi mdi-history', (item) => {
            window.bunifikasiImportFilename = item.originalFilename
            history.push(`import/${item.id}/import-detail`)
          }),
          new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', (item) => { downloadTemplateFile(item) }, true),
          new TableWrapper.action(`${t.translate('words.importLog')}`, 'mdi mdi-download', (item)=>{ downloadLog(item)}),
          new TableWrapper.action(
            `${t.translate("words.retry")}`,
            "mdi mdi-repeat",
            (item) => {
              retryLog(item)
            },
            (item) => {
              if(item.status == 'WARNING'){
                return true
              }else{
                return false
              }
            }
          ),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}
export default inject('temporaryStore', 'modalStore', 'authStore')(observer(ImportData))