import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from './../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Doss1.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from 'react-md'
import ErrorService from './../../services/errorService'

const Doss1Form = ({ authStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isDisabled, setIsDisabled] = useState(false)

  const baseName = `doss1`
  const basePath = `doss1`

  useEffect(() => {
    const initialData = {
      loading: false,
      content: {
        // jmlBruto7a: 0,
        // jmlBruto7b: 0,
        // jmlPph7a: 0,
        // jmlPph7b: 0,
        // jmlPph7c: 0,
        // jmlPph7d: 0,
        // jmlPph7e: 0,
      }
    }
    async function initData(){
      try {
        setData({ loading: false, content: props.mergeForm.doss })
      } catch(e){
        ErrorService(e)
        setData({ loading: false, content: initialData })
      }
    }
    initData()

    let getSpt = JSON.parse(localStorage.getItem('spt'))
    if(getSpt.status == 'FINISH' || getSpt.status == 'SUBMIT' || props.inProcess){
      setIsDisabled(true)
    }

  }, [props.mergeForm])

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  return (
    <>
      <FormWrapper
        showCommandbar={false}
        tableForm={true}
        style={{
          maxWidth: '100%'
        }}
        className="fullWidth"
        loading={data.loading}
        actions={[]}
        baseId={`mod-form-${baseName}`}
        onChange={(formData, key, value)=> {
          formData['jmlPph7e'] = (formData['jmlPph7a'] + formData['jmlPph7b']) - (formData['jmlPph7c'] + formData['jmlPph7d'])
          props.setMergeForm({
            ...props.mergeForm,
            doss: formData
          })
        }}
        title={t.translate(`modules.${baseName}.title`)}
        hintMessage={t.translate(`modules.${baseName}.formTitle`)}
        defaultData={data.content}
        definitions={{
          header: [],
          body: [
            [
              {
                render: (
                  <TableCell colSpan={3}><strong>{t.translate(`modules.${baseName}.hint.message`)}</strong></TableCell>
                )
              },
            ],
            [
              {
                render: (
                  <TableCell style={{ width: '33%' }}><strong>{t.translate(`words.uraianPenghasilan`)}</strong></TableCell>
                )
              },
              {
                render: (
                  <TableCell style={{ width: '33%' }}><strong>{t.translate(`words.jumlahDasarPengenaanPajak`)}</strong></TableCell>
                )
              },
              {
                render: (
                  <TableCell style={{ width: '33%' }}><strong>{t.translate(`words.jumlahPPh`)}</strong></TableCell>
                )
              }
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.ddos1A`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'jmlBruto7a',
                type: 'text',
                disabled : isDisabled
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                className : 'inputRight',
                label: '',
                key: 'jmlPph7a',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.ddos1B`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                className : 'inputRight',
                label: '',
                key: 'jmlBruto7b',
                type: 'text',
                disabled : isDisabled
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                className : 'inputRight',
                decimalSeparator: decimalSeparator,
                label: '',
                key: 'jmlPph7b',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.ddos1C`)}</TableCell>
                )
              },
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              // {
              //   inputType: inputTypes.INPUT_MASK_NUMBER,
              //   thousandSeparator: '.',
              //   decimalSeparator: ',',
              //   label: '',
              //   key: 'c1Bruto',
              //   type: 'text'
              // },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                className : 'inputRight',
                decimalSeparator: decimalSeparator,
                label: '',
                key: 'jmlPph7c',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.ddos1D`)}</TableCell>
                )
              },
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              // {
              //   inputType: inputTypes.INPUT_MASK_NUMBER,
              //   thousandSeparator: '.',
              //   decimalSeparator: ',',
              //   label: '',
              //   key: 'd1Bruto',
              //   type: 'text'
              // },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                className : 'inputRight',
                label: '',
                key: 'jmlPph7d',
                type: 'text',
                disabled : isDisabled
              },
            ],
            [
              {
                render: (
                  <TableCell>{t.translate(`words.ddos1E`)}</TableCell>
                )
              },
              // {
              //   inputType: inputTypes.INPUT_MASK_NUMBER,
              //   thousandSeparator: '.',
              //   decimalSeparator: ',',
              //   label: '',
              //   key: 'e1Bruto',
              //   type: 'text',
              //   disabled: true
              // },
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                className : 'inputRight',
                label: '',
                key: 'jmlPph7e',
                type: 'text',
                disabled: true
              },
            ],
            // [
            //   {
            //     render: (
            //       <TableCell>{t.translate(`words.ddos1F`)}</TableCell>
            //     )
            //   },
            //   {
            //     inputType: inputTypes.INPUT_MASK_NUMBER,
            //     thousandSeparator: '.',
            //     decimalSeparator: ',',
            //     label: '',
            //     key: 'brutoTotal',
            //     type: 'text',
            //     disabled: true
            //   },
            //   {
            //     inputType: inputTypes.INPUT_MASK_NUMBER,
            //     thousandSeparator: '.',
            //     decimalSeparator: ',',
            //     label: '',
            //     key: 'pphTotal',
            //     type: 'text',
            //     disabled: true
            //   },
            // ]
          ]
        }}
        // onSubmit={async (data, callback) => {
        //   try {
        //     var res = {}
        //     if(match.params.id == 'new') {
        //       res = await service.post(data)
        //     } else {
        //       res = await service.put(data.id, data)
        //     }
        //     callback(t.translate('sentences.tersimpan'), false)  
        //   } catch(e){
        //     ErrorService(e)
        //     callback("", false, false)
        //     return
        //   }  
        // }}
      />
    </>
  )
}

export default inject('authStore', 'envStore')(observer(Doss1Form))
