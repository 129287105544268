import React, {useEffect, useState, useCallback, useMemo} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS, get } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './Organization.service'
import { Tooltipped, TooltipHoverModeConfig, Dialog, DialogHeader, DialogTitle, Divider, DialogContent, DialogFooter, Chip, Button, TextField, FontIcon, ListItem, List, FileInput, Card, CardContent, Table, TableCell, TableRow, TableHeader, TableBody } from 'react-md'
import { useToggle } from '@react-md/utils';
import LoadingOverlay from 'react-loading-overlay'
import errorServiceGet from '../../services/errorServiceGet'
import iziToast from 'izitoast'
import Bluebird, { resolve } from 'bluebird'
import errorService from './../../services/errorService'
import AppMode from '../../AppMode'
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import bluebird from 'bluebird'
import {useDropzone} from 'react-dropzone'
import { Container, Row, Col } from 'react-grid-system';
import AnimatedNumber from "animated-number-react";
import { format } from './../../libs/react-mpk/services/number.service'

const Organization = ({
  className       = '',
  showCommandbar  = true,
  history,
  modalStore,
  authStore,
  match
}) => {
  const multiPath = 'upload-sertel'
  const importPath= "import-bp"
  const basePath = `organization`
  const baseId = `mod-organization`
  const [organization, setOrganization] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [dialog, setDialog] = useState(false)
  let [dialogCsv, setDialogCsv] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  let [dialogItemCsv, setDialogItemCsv] = useState({})
  let [dialogMultiple,setDialogMultiple] = useState({})
  let [dialogBulk, setDialogBulk] = useState(false)
  let [dialogItemBulk, setDialogItemBulk] = useState({})
  let [dialogAuth, setDialogAuth] = useState(false)
  let [dialogAuthItem, setDialogAuthItem] = useState({})
  let [search, setSearch] = useState({})
  const [file, setFile] = useState([])
  const [select, setSelect] = useState({})
  const [error, setError] = useState({password : null, file : null});
  const [myFiles, setMyFiles] = useState([]);
  const [summary, setSummary] = useState({
    npwpCount : 0,
    npwpsExpired : 0,
    npwpNotHaveCert : 0,
    npwpHaveCert : 0,
    npwpsExpiredInTwoMonths : 0
  })

  var formatValue = (value) => format(value.toFixed(0));

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  useEffect(async () => {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    initData()
    getOrganizations()
    try {
      document.getElementsByClassName("pagination-container")[0].style.display = "none"
      document.getElementsByClassName("pagination-container")[0].style.display = "none"
      document.getElementsByClassName("pagination-container")[0].style.display = "none"
      document.getElementsByClassName("pagination-container")[0].style.display = "none"
    } catch(e){}
  }, [])

  async function initData(){
    try{
      var params = {
        page : 0,
        size : 200
      }
      var summary = await service.summarySertel(params)

      setSummary(summary.data)
      return summary.data
    }catch(e){
      errorService(e)
    }
    try{
      params.companyId = match.params.companyId
      var expired = await service.getSertelExpired()
      setSummary({
        ...summary.data,
        npwpsExpiredInTwoMonths: expired.data.length
      })
    }catch(e){
      errorService(e)
    }
  }

  const addSertel = async (item) => {
    setDialogItem(item)
    setDialog(true)
  }

   // Tambahan Uci
   const addAuthKey = async (item) => {
    let res = await service.getAuthKey(item)
    item.authKey = res.data.authKey
    setDialogAuthItem(item)
    setDialogAuth(true)
  }

  const addCsv = async (item) => {
    setDialogItemCsv(item)
    setDialogCsv(true)
  }

  const addMultipleSertel = async (item)=>{
    setDialogMultiple(item)
    setDialog(true)
  }

  const addSertelBulk = async (item) => {
    try{
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setSelect(selectedData)
    }catch(e){}

    if(selectedData.length > 0){
      setDialogItemBulk(item)
      setDialogBulk(true)
    } else {
      iziToast.info({
        title: t.translate('mpk.column.warning'),
        message: t.translate('words.recheckSelectedData')
      })
    }
    // console.log(TableWrapper.getSelected(baseId))
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      Bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.delete(di.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function exportCsvExc(item) {
    try {
        var proses = t.translate('words.exportCsv')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        Bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.exportCsv(di.id)
              window.open(proccedThis.data.data.url)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function exportCsv(item) {
    modalStore.showConfirm({
      title : t.translate('words.cetak'),
      children : t.translate('words.confirmexportCsv'),
      onSubmit : (callback) => {
        exportCsvExc(item)
        callback()
      }
    })
  }

  async function exportCsvOrgExc(item) {
    try {
        var proses = t.translate('words.exportCsv')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        Bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.exportCsvOrg(di.id)
              window.open(proccedThis.data.data.url)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function exportCsvOrg(item) {
    modalStore.showConfirm({
      title : t.translate('words.cetak'),
      children : t.translate('words.confirmexportCsv'),
      onSubmit : (callback) => {
        exportCsvOrgExc(item)
        callback()
      }
    })
  }

  async function resetCache(item) {
    try {
      var proses = t.translate('words.resetCache')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      Bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.resetCache(di.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
  }
  }

  async function reset(item) {
    modalStore.showConfirm({
      title : t.translate('words.resetCache'),
      children : t.translate('sentences.resetCache'),
      onSubmit : (callback) => {
        resetCache(item)
        callback()
      }
    })
  }

  // Tambahan Uci
  const updateAuthKeyExc = async (item)=> {
    var proses = t.translate('words.authKey')
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    try {
      let res = await service.updateAuthKey({npwp : item.npwp, authKey : item.authKey})
      iziToast.success({
        title: proses,
        message: t.translate("sentences.tersimpan")
      })
      setPloading({ loading: false, message: `` })
      localStorage.setItem(`${item.id}-auth-key`,JSON.stringify(item.authKey))
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e){
      errorService(e)
      setPloading({ loading: false, message: `` })
    }
  }

  const updateAuthKey = (item)=> {
    modalStore.showConfirm({
      title: t.translate('words.authKey'),
      children: t.translate('words.addAuthKeyConfirm'),
      onSubmit: (callback) => {
        updateAuthKeyExc(item)
        callback()
      }
    })
  }
  // Tambahan Uci

  var actions = []
  
  if (AppMode.onPremise){
    actions = [
      new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
      new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
      new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', (item) => {exportCsv(item)}, true),
      new TableWrapper.action(`${t.translate('words.csvOrgUser')}`, 'mdi mdi-account-group', (item) => {exportCsvOrg(item)}, true),
      new TableWrapper.action(`${t.translate('words.resetCache')}`, 'mdi mdi-alert-decagram', (item) => {reset(item)}, true),
    ]
  } else {
    actions = [
     new TableWrapper.action(`${t.translate('words.uploadSertel')}`, 'mdi mdi-tag', (item) => addSertelBulk(item), true),
     new TableWrapper.action(`${t.translate(`words.uploadCsv`)}`,'mdi mdi-file-import', (item) => {addCsv(item)},true),
     new TableWrapper.action(`${t.translate(`words.uploadMultipleSertel`)}`,'mdi mdi-file-export', () => history.push(`${multiPath}/uploadSertel.table`),true),
    ]
  }

  function multipleFile(event) {
    // console.log(event)
    try{
      event.target.setCustomValidity('')
      setError({file : null})
      setFile(event.target.files)
      var value = event.target.files
      if(value && value.length > 0){
        Object.values(value).map((d)=> {
          var name = d.name
          select.map((c, index)=> {
            if(c.npwp == name.split('_')[0]){ 
              c.file = d
              c.filename = d.name
            } else{
              return value
            }
          })
        })
        setSelect([...select])
      }
    }catch(e){

    }
  }
  
  const baseStyle = {
    flex: 1,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: "110px",
    height: "300px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  }
  
  const acceptStyle = {
    borderColor: "#00e676",
  }
  
  const rejectStyle = {
    borderColor: "#ff1744",
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    } ,
    [myFiles]
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple:false,
    maxFiles:'1', 
    accept:".csv"
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const files = myFiles.map((file) => (
    <li key={file.path} >
      {file.path} - {file.size} bytes{" "}
      <Button
      class="btn-sm pull-left"
        onClick={removeFile(file)}
        buttonType="icon"
        style={{marginTop:'5px'}}
      >
        <FontIcon className="mdi mdi-trash-can-outline"></FontIcon>
      </Button>
    </li>
  ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(onDrop ? acceptStyle : {}),
      ...(removeFile ? rejectStyle : {}),
    }),
    [removeFile, onDrop]
  );
 
  return (
    <>
    <LoadingOverlay
      style={{
        position: 'inherit',
        background: 'red'
      }}
      active={ploading.loading}
      spinner
      text={ploading.message}
      >
    </LoadingOverlay>
    <Dialog 
      disableFocusOnMount={true}
      initialFocus="csv-dialog"
      id="csv-dialog"
      onFocus={()=>{}}
      visible={dialogCsv}
      onRequestClose={()=> {
        setDialogCsv(false)
      }}
      style={{
        width: 1024,
        margin: '0px !important'
      }}
      width={1024}
      aria-labelledby="csv-dialog"
    >
      <DialogContent>
        <FormWrapper 
          style={{
            margin: '0px !important',
            width: '100%',
            maxWidth: '100%'
          }}
          defaultData={[]}
          action={{}}
          hintMessage={t.translate('words.uploadCsvInfo')}
          showCommandbar={false}
          baseId = {baseId}
          title={t.translate('modules.organization.title')}
          definitions={[
            {
              render:(
                <section className="container" style={{ width: "100%", maxWidth: "100%" }}>
                  <div {...getRootProps({ className: "dropzone", style })}>
                    <input {...getInputProps() } />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                  {files.length > 0 && (
                    <Button
                      onClick={removeAll}
                      themeType="outline"
                      theme="primary"
                      style={{ marginTop: "20px" }}
                    >
                      Remove All
                    </Button>
                  )}
                  <aside>
                    <h4>Files</h4>
                    <ul key="values">{files}</ul>
                  </aside>
                </section>
              )
            }
            
          ]}
          onSubmit={async (value, callback)=> {
            try {
              var proses = t.translate('words.import')
              setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
              if(myFiles.length > 0){
                const reader = new FileReader()
                var formData = new FormData()
                
                formData.append('file', myFiles[0])
                formData.append('type', 'CERT')

                var res = await service.addCsv(formData)
                
                setMyFiles([])
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogImportCsv')}`
                })
                callback("", false, false, false)
                setPloading({ loading: false, message: ''})
                setDialogCsv(false)
                history.push(`/product/company/${match.params.companyId}/import-bp`)
              } else {
                iziToast.warning({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesFail')}`
                })
                setPloading({ loading: false, message: proses })
                callback("", false, false, false)
                return
              }
            }catch(e) {
              setMyFiles([])
              setPloading({ loading: false, message: proses })
              setDialogCsv(false)
              errorService(e)
              callback("", false, false, false)
            }
        }}
        />
      </DialogContent>
  </Dialog>
    <Dialog 
      disableFocusOnMount={true}
      initialFocus="sertel-dialog"
      id="sertel-dialog"
      onFocus={()=>{}}
      visible={dialogBulk}
      onRequestClose={()=> {
        setDialogBulk(false)
      }}
      style={{
        width: 1024,
        margin: '0px !important'
      }}
      width={1024}
      aria-labelledby="sertel-dialog"
    >
      <DialogContent>
        <DataForm 
          style={{
            margin: '0px !important',
            width: '100%',
            maxWidth: '100%'
          }}
          defaultData={{
            npwp: dialogItemBulk.npwp
          }}
          // onChange={(formData, key, value)=> {
          //   if(key == 'file' && value){
          //     value.map((d)=> {
          //       var name = d.file.name
          //       select.map((c, index)=> {
          //         if(c.npwp == name.split('_')[0]){ 
          //           c.file = d
          //           c.filename = d.file.name
          //         } else{
          //           return value
          //         }
          //       })
          //     })
          //     setSelect(select)
          //   }
          // }}
          hintMessage={'Bila Anda belum menggunggah sertifikat elektronik. Silahkan unggah sertifikat elektronik Anda disini! Format File npwp_filename.p12'}
          showCommandbar={false}
          baseId = {baseId}
          title={t.translate('modules.organization.title')}
          definitions={[
            // {
            //   inputType : inputTypes.FILE_INPUT,
            //   label : t.translate('words.file'),
            //   key : 'file',
            //   type : 'file',
            //   // validation: 'required',
            //   multiple: true,
            //   multiline: true,
            //   accept: '.xls,.xlsx',
            // },
            {
              render : (
                <>
                  <div class="button-wrap">
                    <label class="button" for="uploadFile"><i class="mdi mdi-download" />FILE</label>
                    <input id="uploadFile" type="file" accept=".p12" multiple onChange={multipleFile} onInvalid={(e)=>{
                      if(e.target.files.length == 0){
                      setError({file : "The File is required"})
                      e.target.setCustomValidity(` `)
                      iziToast.warning({
                        title: t.translate('mpk.column.warning'),
                        message: `Data formulir tidak valid. Silahkan cek kembali !`
                      })}
                    }} required/>
                  </div>
                  <TextField
                    id='file'
                    placeholder="No file chosen"
                    // value={file.length > 0 ? file.length +" file telah terpilih" : ''}
                    value={file.length > 0 ? Object.values(file).map(d => (d.name)).toString() : ' '}
                    readOnly
                    style={{margin :'0px 0px 14px 5px', width:'80%', height: '60px'}}
                    // error={touched && !!error} errorText={error}
                    // onInvalid={(e)=>{
                    //   setError({file : "The File is required"})
                    //   e.target.setCustomValidity(` `)
                    //   iziToast.warning({
                    //     title: t.translate('mpk.column.warning'),
                    //     message: `Data formulir tidak valid. Silahkan cek kembali !`
                    //   })
                    // }}
                    required
                  />
                  <Button 
                    flat 
                    style={{margin :'10px 0px 0px 0px'}}
                    onClick={(e)=>{
                      e.currentTarget.files = []
                      // console.log(e)
                      try{
                        setFile([])
                        if(select && select.length > 0){
                          select.map((d)=>{
                            delete d.file
                            delete d.filename
                          })
                          setSelect([...select])
                        }
                      }catch(e){

                      }
                    }}
                  >
                    <FontIcon iconClassName="mdi mdi-delete"/>
                  </Button>
                  {error.file && 
                  <>
                    <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                      <FontIcon
                        iconClassName="mdi mdi-alert"
                        style={{fontSize:15, color: '#f1420c' }}
                        className="mpk-margin-S margin-right"
                      />
                      {error.file}
                    </div>
                  </>
                  } 
                </>
              )
            },
            {
              render : (
                <>
                  {error.file && 
                    <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                      <FontIcon
                        iconClassName="mdi mdi-alert"
                        style={{fontSize:15, color: '#f1420c' }}
                        className="mpk-margin-S margin-right"
                      />
                      {error.file}
                    </div>
                  }
                </>
              )
            },
            {
              render: (
                <>
                  <Card style={{ width: '100%', padding: 6, marginTop: '24px', marginBottom: '14px'}}>
                  <CardContent>
                    <Table style={{ width: '100%' }}>
                      <TableHeader>
                        <TableRow>
                          <TableCell>{t.translate('words.non')}</TableCell>
                          <TableCell>{t.translate('words.orgNpwp')}</TableCell>
                          <TableCell>{t.translate('words.cert')}</TableCell>
                          <TableCell>{t.translate('words.password')}</TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                      {select && select.length > 0 &&
                        <>
                        {Object.values(select).map((d, index)=> {
                          return (
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{d.npwp}</TableCell>
                              <TableCell>{d.filename}</TableCell>
                              <TableCell>
                                <TextField 
                                  key="password"
                                  type="password"
                                  theme='underlines'
                                  placeholder='Masukan password disini'
                                  onChange={(value)=>{
                                    d.password = value.target.value
                                    error.password = null
                                    value.target.setCustomValidity(``)
                                  }}
                                  onInvalid={(e)=>{
                                    setError({password : "The Password is required"})
                                    e.target.setCustomValidity(` `)
                                    iziToast.warning({
                                      title: t.translate('mpk.column.warning'),
                                      message: `Data formulir tidak valid. Silahkan cek kembali !`
                                    })
                                  }}
                                  required
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        </>
                      }
                      </TableBody>
                    </Table>
                    {error.password && 
                    <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                      <FontIcon
                        iconClassName="mdi mdi-alert"
                        style={{fontSize:15, color: '#f1420c' }}
                        className="mpk-margin-S margin-right"
                      />
                      {error.password}
                    </div>
                    }
                  </CardContent>
                </Card>
                </>
              )
            },
            {
              render: (
                <>
                <br />
                <div className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width mpk-hint" style={{ background: '#FEF6F4', color: 'black' }}>
                  <div class="container">
                    <h3 class="">Syarat dan Ketentuan Penyimpanan Data Sertifikat Elektronik</h3>
                    <div class="divider"></div>
                    <p class="">Terima kasih telah memilih layanan PT. MITRA PAJAKKU (selanjutnya disebut "Pajakku")</p>
                    <p>Syarat dan ketentuan ini mengatur secara spesifik mengenai syarat dan ketentuan penyimpanan data berupa sertifikat elektronik pada layanan aplikasi perpajakan melalui Pajakku yang merupakan bagian dari Syarat &amp; Ketentuan Umum Layanan Aplikasi Perpajakan Pajakku.</p>
                    <p>I. Umum</p>
                    <ul class="dec">
                      <li>1.1. Pajakku merupakan Penyedia Jasa Aplikasi Perpajakan (PJAP) resmi Direktorat Jenderal Pajak (DJP).</li>
                      <li>1.2. Syarat dan Ketentuan ini merupakan salah satu kewajiban Pajakku untuk memenuhi Peraturan Direktur Jenderal Pajak Nomor PER-10/PJ/2020 tentang Perubahan atas PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan.</li>
                      <li>1.3. Pengguna Layanan Aplikasi Pajakku adalah karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain, sebagai perwakilan sah untuk dan atas nama badan hukum, organisasi, atau orang lain.</li>
                      <li>1.4. Syarat dan ketentuan ini berlaku terhadap seluruh pengguna yang mengakses dan/atau menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>1.5. Sertifikat Elektronik dalam hal ini diartikan sebagai sertifikat yang bersifat elektronik yang memuat Tanda Tangan Elektronik dan identitas yang menunjukan status subjek hukum para pihak dalam transaksi elektronik yang dikeluarkan oleh Direktorat Jenderal Pajak.</li>
                      <li>1.6. Passphrase atau umum disebut kata sandi (password) merupakan serangkaian angka dan/atau huruf dan/atau karakter tertentu yang digunakan sebagai kunci untuk mengakses data yang tersimpan pada sertifikat elektronik.</li>
                    </ul>
                    <p>II. Akun Pengguna</p>
                    <ul class="dec">
                      <li>2.1. Untuk menggunakan Layanan Aplikasi Pajakku, Pengguna harus terlebih dahulu terdaftar pada Layanan Aplikasi Pajakku.</li>
                      <li>2.2. Pengguna diminta untuk melengkapi data-data yang diminta pada saat pendaftaran. Data-data yang diberikan harus benar, akurat dan lengkap.</li>
                      <li>2.3. Pengguna berkewajiban untuk menjaga kerahasiaan kata sandi. Kata sandi yang di berikan kepada karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain menjadi tanggung jawab Pengguna.</li>
                      <li>2.4. Pengguna wajib memberitahukan Pajakku jika diketahui adanya penggunaan Akun Pengguna dari pihak yang tidak berwewenang.</li>
                      <li>2.5. Atas permintaan Pengguna, Pajakku dapat membantu Pengguna untuk mengganti Sandi Akun melalui support@pajakku.com dengan melengkapi data-data yang diwajibkan.</li>
                    </ul>
                  </div>
                  <p>III. Data Pengguna</p>
                    <ul class="dec">
                      <li>3.1. Pengguna dengan ini menyatakan dan menjamin bahwa sertifikat elektronik, passphrase dan data lainnya yang didaftarkan pada Layanan Aplikasi Pajakku (selanjutnya disebut “Data Pengguna”) adalah akurat, benar, lengkap, dan sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                      <li>3.2. Pengguna bertanggung jawab atas Data Pengguna dan melepaskan Pajakku dari tuntutan apapun apabila Data Pengguna yang tersedia tidak benar atau tidak sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                      <li>3.3. Pengguna menyatakan dan menjamin bahwa:</li>
                      <li style={{ paddingRight: '56px' }}>3.3.1. Data Pengguna bebas dari data yang bukan milik Pengguna atau Pengguna tidak mempunyai hak untuk mengakses atau mengolah data tersebut;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.2. Data Pengguna yang jika di akses, olah, atau gunakan adalah bebas dari pelanggaran hak pihak ketiga termasuk namun tidak terbatas terhadap hak kekayaan intelektual;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.3. Data Pengguna bebas dari virus, trojan horses, spyware, malware, worms, time bombs, cancelbots, dan/atau segala hal yang dapat membahayakan atau merusak Layanan Aplikasi Pajakku baik secara sebagian atau keseluruhan;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.4. Data Pengguna bebas dari tujuan baik langsung maupun tidak langsung untuk melakukan perbuatan yang melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                      <li>3.4. Pajakku berhak untuk menggunakan, menyalin, mengirim, menyimpan dan melakukan back-up Data Pengguna dengan tujuan hanya untuk keperluan kewajiban perpajakan Pengguna.</li>
                      <li>3.5. Pajakku berkewajiban untuk menjaga Data Pengguna dan tidak akan memberikan Data Pengguna kepada pihak ketiga tanpa persetujuan Pengguna. Namun, Data Pengguna yang di berikan kepada pihak ketiga yang sudah diizinkan Pengguna, merupakan tanggung jawab Pengguna dan Pengguna melepaskan Pajakku dari tanggung jawab terhadap segala penyalahgunaan Data Pengguna yang dilakukan oleh pihak ketiga yang sudah diizinkan.</li>
                      <li>3.6. Pajakku berhak untuk tidak memberikan Data Pengguna kepada pihak ketiga (walaupun Pengguna telah memberikan izin) apabila Pajakku meyakini bahwa pemberian Data Pengguna tersebut dapat merugikan atau membahayakan Pajakku, melanggar Syarat dan Ketentuan ini atau melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                    </ul>
                    <p>IV. Larangan</p>
                    <ul class="dec">
                      <li>4.1. Pengguna tidak diperkenankan untuk mengakses atau mencoba mendapatkan akses selain dari yang merupakan hak atau wewenang Pengguna.</li>
                      <li>4.2. Pengguna tidak diperkenankan untuk mengubah, menyalin, meniru, membongkar, atau melakukan reverse engineering atas layanan aplikasi Pajakku yang digunakan.</li>
                      <li>4.3. Pengguna tidak diperkenankan untuk mengirimkan atau memasukkan hal yang bersifat menghina ataupun melanggar hukum, seperti data atau materi lainnya yang terlindungi hak cipta atau rahasia dagang dimana Pengguna tidak memiliki hak untuk menggunakannya dalam kondisi apapun.</li>
                      <li>4.4. Pengguna tidak diperkenankan untuk menggunakan Layanan Aplikasi Pajakku dengan tujuan yang ilegal atau tidak sah. Pajakku berhak untuk membatasi atau meniadakan sama sekali akses Pengguna untuk menggunakan Layanan Aplikasi Pajakku lebih lanjut jika penggunaan tersebut menimbulkan pelanggaran terhadap hukum yang berlaku.</li>
                      <li>4.5. Pajakku berdasarkan kebijakan sendiri, berhak untuk menangguhkan atau menghentikan akses atau penggunaan terhadap Layanan Aplikasi Pajakku tanpa pemberitahuan terlebih dahulu kepada Pengguna apabila Pengguna melakukan pelanggaran terhadap Syarat dan Ketentuan ini atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                    </ul>
                    <p>V. Persetujuan</p>
                    <ul class="dec">
                      <li>5.1. Pengguna menyatakan dan menjamin bahwa Pengguna adalah individu yang sah secara hukum untuk terikat dalam perjanjian berdasarkan hukum Republik Indonesia, secara khusus terikat dalam Syarat dan Ketentuan ini, untuk menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>5.2. Pengguna menyatakan dan menjamin untuk membebaskan Direktorat Jenderal Pajak dari segala tuntutan yang berkait dengan penyediaan Layanan Aplikasi Perpajakan Pajakku.</li>
                      <li>5.3. Pengguna memahami dan menyetujui bahwa menggunakan Layanan Aplikasi Pajakku dengan risiko Pengguna sendiri.</li>
                      <li>5.4. Pajakku dengan upaya terbaik menyediakan layanan untuk Pengguna, namun tidak memberikan jaminan bahwa penggunaan terhadap Layanan Aplikasi Pajakku akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerusakan, bug, error, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Pajakku dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</li>
                    </ul>
                    <p>VI. Batasan Tanggung Jawab Pajakku</p>
                    <ul class="dec">
                      <li>6.1. Pajakku tidak bertanggung jawab terhadap kesalahan (human error) yang Pengguna lakukan dalam menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>6.2. Pajakku tidak bertanggung jawab atas dampak dari segala kerugian yang timbul akibat tindakan pengguna yang secara langsung maupun tidak langsung dari penggunaan Layanan Aplikasi Pajakku.</li>
                      <li>6.3. Pajakku tidak bertanggung jawab atas kesalahan yang terjadi, yang termasuk namun tidak terbatas pada:</li>
                      <li style={{ paddingRight: '56px' }}>6.3.1. Kesalahan dan/atau ketidak akuratan data dalam sistem yang di masukkan Pengguna;</li>
                      <li style={{ paddingRight: '56px' }}>6.3.2. Perubahan kebijakan akibat peraturan baru dari seluruh otoritas yang berwenang; dan/atau</li>
                      <li style={{ paddingRight: '56px' }}>6.3.3. Gangguan server atau koneksi internet Pengguna saat menggunakan Layanan Aplikasi Pajakku.</li>
                    </ul>
                    <p>VII. Domisili Hukum dan Penyelesaian Perselisihan</p>
                    <ul class="dec">
                      <li>7.1. Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia.</li>
                      <li>7.2. Setiap perselisihan dan permasalahan yang timbul berkaitan dengan pelaksanaan isi Syarat dan Ketentuan ini akan diselesaikan secara musyawarah dan mufakat oleh Pajakku dan Pengguna.</li>
                      <li>7.3. Apabila penyelesaian secara musyawarah dan mufakat tidak tercapai maka Pajakku dan Pengguna setuju untuk memilih domisili hukum yang tetap dan umum di Kantor Kepaniteraan Pengadilan Negeri Jakarta Barat yang demikian dengan tidak mengurangi hak Pajakku dan Pengguna untuk menuntut pihak lainnya melalui atau dihadapan pengadilan lainnya dalam wilayah Negara Republik Indonesia.</li>
                    </ul>
                    <p>Syarat dan Ketentuan ini dapat berubah dari waktu ke waktu, Pajakku tidak akan memberikan notifikasi kepada Pengguna atas setiap perubahan Syarat dan Ketentuan, namun Pajakku akan mengunggah setiap perubahan tersebut pada laman www.pajakku.com.</p>
                    <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Syarat dan Ketentuan ini serta perubahannya.</p>
                </div>
                </>
              )
            },
            {
              inputType : inputTypes.CHECKBOX,
              label : t.translate('words.aggreed'),
              key : 'aggree',
              type : 'text',
              validation: 'required'
            },
          ]}
          onSubmit={async (values, callback)=> {
            var proses = t.translate('words.uploadSertel')
            setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
            var errors = []
            try {
              Bluebird.mapSeries(select, async (di, di2) => {
                // console.log(select, di, di2, files)
                setTimeout(async () => {
                  try {
                    var formData = new FormData()
                    formData.append('file', di.file)
                    formData.append('password', di.password)
                    formData.append('npwp', di.npwp)
                    let x = await service.uploadSertel(formData)
                  } catch (e) {
                    errors.push(await errorServiceGet(e, di, di2))
                  }
                  setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${select.length}` })
                  if ((di2 + 1) == select.length) {
                    setPloading({ loading: false, message: `` })
                    if (errors.length == 0) {
                      iziToast.success({
                        title: proses,
                        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                      })
                    } else {
                      modalStore.showInfo({
                        title: '',
                        children: (
                          <List>
                            {errors.map((er) => {
                              return <ListItem primaryText={''} secondaryText={er}></ListItem>
                            })}
                          </List>
                        )
                      })
                    }
                    try {
                      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                      if (checkboxRoot.checked) {
                        if (checkboxRoot.getAttribute('aria-checked')) {
                          checkboxRoot.click()
                          checkboxRoot.click()
                        } else {
                          checkboxRoot.click()
                        }
                      }
                      document.getElementsByClassName('mdi-reload')[0].click()
                    } catch (e) { }
                  }
                }, 1000 * di2)
              })
              setDialogBulk(false) 
              callback("", false, false, false)
            } catch(e){
              callback("", false, false, false)
            }
          }}
        />
      </DialogContent>
    </Dialog>
        <Dialog 
          disableFocusOnMount={true}
          initialFocus="sertel-dialog"
          id="sertel-dialog"
          onFocus={()=>{}}
          visible={dialog}
          onRequestClose={()=> {
            setDialog(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="sertel-dialog"
        >
          <DialogContent>
            <FormWrapper 
              style={{
                margin: '0px !important',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={{
                npwp: dialogItem.npwp
              }}
              hintMessage={'Bila Anda belum menggunggah sertifikat elektronik. Silahkan unggah sertifikat elektronik Anda disini!'}
              showCommandbar={false}
              baseId = {baseId}
              title={t.translate('modules.organization.title')}
              definitions={[
                {
                  inputType : inputTypes.FILE_INPUT,
                  label : t.translate('words.file'),
                  key : 'file',
                  accept: '.p12',
                  type : 'file',
                  validation: 'required'
                },
                {
                  inputType : inputTypes.INPUT,
                  label : t.translate('words.npwp'),
                  key : 'npwp',
                  type : 'text',
                  validation: 'required',
                  disabled: true
                },
                {
                  inputType : inputTypes.INPUT,
                  label : t.translate('words.password'),
                  key : 'password',
                  type : 'password',
                  validation: 'required'
                },
                {
                  render: (
                    <div className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width mpk-hint" style={{ background: '#FEF6F4', color: 'black' }}>
                      <div class="container">
                        <h3 class="">Syarat dan Ketentuan Penyimpanan Data Sertifikat Elektronik</h3>
                        <div class="divider"></div>
                        <p class="">Terima kasih telah memilih layanan PT. MITRA PAJAKKU (selanjutnya disebut "Pajakku")</p>
                        <p>Syarat dan ketentuan ini mengatur secara spesifik mengenai syarat dan ketentuan penyimpanan data berupa sertifikat elektronik pada layanan aplikasi perpajakan melalui Pajakku yang merupakan bagian dari Syarat &amp; Ketentuan Umum Layanan Aplikasi Perpajakan Pajakku.</p>
                        <p>I. Umum</p>
                        <ul class="dec">
                          <li>1.1. Pajakku merupakan Penyedia Jasa Aplikasi Perpajakan (PJAP) resmi Direktorat Jenderal Pajak (DJP).</li>
                          <li>1.2. Syarat dan Ketentuan ini merupakan salah satu kewajiban Pajakku untuk memenuhi Peraturan Direktur Jenderal Pajak Nomor PER-10/PJ/2020 tentang Perubahan atas PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan.</li>
                          <li>1.3. Pengguna Layanan Aplikasi Pajakku adalah karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain, sebagai perwakilan sah untuk dan atas nama badan hukum, organisasi, atau orang lain.</li>
                          <li>1.4. Syarat dan ketentuan ini berlaku terhadap seluruh pengguna yang mengakses dan/atau menggunakan Layanan Aplikasi Pajakku.</li>
                          <li>1.5. Sertifikat Elektronik dalam hal ini diartikan sebagai sertifikat yang bersifat elektronik yang memuat Tanda Tangan Elektronik dan identitas yang menunjukan status subjek hukum para pihak dalam transaksi elektronik yang dikeluarkan oleh Direktorat Jenderal Pajak.</li>
                          <li>1.6. Passphrase atau umum disebut kata sandi (password) merupakan serangkaian angka dan/atau huruf dan/atau karakter tertentu yang digunakan sebagai kunci untuk mengakses data yang tersimpan pada sertifikat elektronik.</li>
                        </ul>
                        <p>II. Akun Pengguna</p>
                        <ul class="dec">
                          <li>2.1. Untuk menggunakan Layanan Aplikasi Pajakku, Pengguna harus terlebih dahulu terdaftar pada Layanan Aplikasi Pajakku.</li>
                          <li>2.2. Pengguna diminta untuk melengkapi data-data yang diminta pada saat pendaftaran. Data-data yang diberikan harus benar, akurat dan lengkap.</li>
                          <li>2.3. Pengguna berkewajiban untuk menjaga kerahasiaan kata sandi. Kata sandi yang di berikan kepada karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain menjadi tanggung jawab Pengguna.</li>
                          <li>2.4. Pengguna wajib memberitahukan Pajakku jika diketahui adanya penggunaan Akun Pengguna dari pihak yang tidak berwewenang.</li>
                          <li>2.5. Atas permintaan Pengguna, Pajakku dapat membantu Pengguna untuk mengganti Sandi Akun melalui support@pajakku.com dengan melengkapi data-data yang diwajibkan.</li>
                        </ul>
                      </div>
                      <p>III. Data Pengguna</p>
                        <ul class="dec">
                          <li>3.1. Pengguna dengan ini menyatakan dan menjamin bahwa sertifikat elektronik, passphrase dan data lainnya yang didaftarkan pada Layanan Aplikasi Pajakku (selanjutnya disebut “Data Pengguna”) adalah akurat, benar, lengkap, dan sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                          <li>3.2. Pengguna bertanggung jawab atas Data Pengguna dan melepaskan Pajakku dari tuntutan apapun apabila Data Pengguna yang tersedia tidak benar atau tidak sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                          <li>3.3. Pengguna menyatakan dan menjamin bahwa:</li>
                          <li style={{ paddingRight: '56px' }}>3.3.1. Data Pengguna bebas dari data yang bukan milik Pengguna atau Pengguna tidak mempunyai hak untuk mengakses atau mengolah data tersebut;</li>
                          <li style={{ paddingRight: '56px' }}>3.3.2. Data Pengguna yang jika di akses, olah, atau gunakan adalah bebas dari pelanggaran hak pihak ketiga termasuk namun tidak terbatas terhadap hak kekayaan intelektual;</li>
                          <li style={{ paddingRight: '56px' }}>3.3.3. Data Pengguna bebas dari virus, trojan horses, spyware, malware, worms, time bombs, cancelbots, dan/atau segala hal yang dapat membahayakan atau merusak Layanan Aplikasi Pajakku baik secara sebagian atau keseluruhan;</li>
                          <li style={{ paddingRight: '56px' }}>3.3.4. Data Pengguna bebas dari tujuan baik langsung maupun tidak langsung untuk melakukan perbuatan yang melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                          <li>3.4. Pajakku berhak untuk menggunakan, menyalin, mengirim, menyimpan dan melakukan back-up Data Pengguna dengan tujuan hanya untuk keperluan kewajiban perpajakan Pengguna.</li>
                          <li>3.5. Pajakku berkewajiban untuk menjaga Data Pengguna dan tidak akan memberikan Data Pengguna kepada pihak ketiga tanpa persetujuan Pengguna. Namun, Data Pengguna yang di berikan kepada pihak ketiga yang sudah diizinkan Pengguna, merupakan tanggung jawab Pengguna dan Pengguna melepaskan Pajakku dari tanggung jawab terhadap segala penyalahgunaan Data Pengguna yang dilakukan oleh pihak ketiga yang sudah diizinkan.</li>
                          <li>3.6. Pajakku berhak untuk tidak memberikan Data Pengguna kepada pihak ketiga (walaupun Pengguna telah memberikan izin) apabila Pajakku meyakini bahwa pemberian Data Pengguna tersebut dapat merugikan atau membahayakan Pajakku, melanggar Syarat dan Ketentuan ini atau melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                        </ul>
                        <p>IV. Larangan</p>
                        <ul class="dec">
                          <li>4.1. Pengguna tidak diperkenankan untuk mengakses atau mencoba mendapatkan akses selain dari yang merupakan hak atau wewenang Pengguna.</li>
                          <li>4.2. Pengguna tidak diperkenankan untuk mengubah, menyalin, meniru, membongkar, atau melakukan reverse engineering atas layanan aplikasi Pajakku yang digunakan.</li>
                          <li>4.3. Pengguna tidak diperkenankan untuk mengirimkan atau memasukkan hal yang bersifat menghina ataupun melanggar hukum, seperti data atau materi lainnya yang terlindungi hak cipta atau rahasia dagang dimana Pengguna tidak memiliki hak untuk menggunakannya dalam kondisi apapun.</li>
                          <li>4.4. Pengguna tidak diperkenankan untuk menggunakan Layanan Aplikasi Pajakku dengan tujuan yang ilegal atau tidak sah. Pajakku berhak untuk membatasi atau meniadakan sama sekali akses Pengguna untuk menggunakan Layanan Aplikasi Pajakku lebih lanjut jika penggunaan tersebut menimbulkan pelanggaran terhadap hukum yang berlaku.</li>
                          <li>4.5. Pajakku berdasarkan kebijakan sendiri, berhak untuk menangguhkan atau menghentikan akses atau penggunaan terhadap Layanan Aplikasi Pajakku tanpa pemberitahuan terlebih dahulu kepada Pengguna apabila Pengguna melakukan pelanggaran terhadap Syarat dan Ketentuan ini atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                        </ul>
                        <p>V. Persetujuan</p>
                        <ul class="dec">
                          <li>5.1. Pengguna menyatakan dan menjamin bahwa Pengguna adalah individu yang sah secara hukum untuk terikat dalam perjanjian berdasarkan hukum Republik Indonesia, secara khusus terikat dalam Syarat dan Ketentuan ini, untuk menggunakan Layanan Aplikasi Pajakku.</li>
                          <li>5.2. Pengguna menyatakan dan menjamin untuk membebaskan Direktorat Jenderal Pajak dari segala tuntutan yang berkait dengan penyediaan Layanan Aplikasi Perpajakan Pajakku.</li>
                          <li>5.3. Pengguna memahami dan menyetujui bahwa menggunakan Layanan Aplikasi Pajakku dengan risiko Pengguna sendiri.</li>
                          <li>5.4. Pajakku dengan upaya terbaik menyediakan layanan untuk Pengguna, namun tidak memberikan jaminan bahwa penggunaan terhadap Layanan Aplikasi Pajakku akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerusakan, bug, error, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Pajakku dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</li>
                        </ul>
                        <p>VI. Batasan Tanggung Jawab Pajakku</p>
                        <ul class="dec">
                          <li>6.1. Pajakku tidak bertanggung jawab terhadap kesalahan (human error) yang Pengguna lakukan dalam menggunakan Layanan Aplikasi Pajakku.</li>
                          <li>6.2. Pajakku tidak bertanggung jawab atas dampak dari segala kerugian yang timbul akibat tindakan pengguna yang secara langsung maupun tidak langsung dari penggunaan Layanan Aplikasi Pajakku.</li>
                          <li>6.3. Pajakku tidak bertanggung jawab atas kesalahan yang terjadi, yang termasuk namun tidak terbatas pada:</li>
                          <li style={{ paddingRight: '56px' }}>6.3.1. Kesalahan dan/atau ketidak akuratan data dalam sistem yang di masukkan Pengguna;</li>
                          <li style={{ paddingRight: '56px' }}>6.3.2. Perubahan kebijakan akibat peraturan baru dari seluruh otoritas yang berwenang; dan/atau</li>
                          <li style={{ paddingRight: '56px' }}>6.3.3. Gangguan server atau koneksi internet Pengguna saat menggunakan Layanan Aplikasi Pajakku.</li>
                        </ul>
                        <p>VII. Domisili Hukum dan Penyelesaian Perselisihan</p>
                        <ul class="dec">
                          <li>7.1. Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia.</li>
                          <li>7.2. Setiap perselisihan dan permasalahan yang timbul berkaitan dengan pelaksanaan isi Syarat dan Ketentuan ini akan diselesaikan secara musyawarah dan mufakat oleh Pajakku dan Pengguna.</li>
                          <li>7.3. Apabila penyelesaian secara musyawarah dan mufakat tidak tercapai maka Pajakku dan Pengguna setuju untuk memilih domisili hukum yang tetap dan umum di Kantor Kepaniteraan Pengadilan Negeri Jakarta Barat yang demikian dengan tidak mengurangi hak Pajakku dan Pengguna untuk menuntut pihak lainnya melalui atau dihadapan pengadilan lainnya dalam wilayah Negara Republik Indonesia.</li>
                        </ul>
                        <p>Syarat dan Ketentuan ini dapat berubah dari waktu ke waktu, Pajakku tidak akan memberikan notifikasi kepada Pengguna atas setiap perubahan Syarat dan Ketentuan, namun Pajakku akan mengunggah setiap perubahan tersebut pada laman www.pajakku.com.</p>
                        <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Syarat dan Ketentuan ini serta perubahannya.</p>
                    </div>
                  )
                },
                {
                  inputType : inputTypes.CHECKBOX,
                  label : t.translate('words.aggreed'),
                  key : 'aggree',
                  type : 'text',
                  validation: 'required'
                },
              ]}
              onSubmit={async (values, callback)=> {
                var proses = t.translate('words.uploadSertel')
                setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                try {
                  var formData = new FormData()
                  formData.append('file', values.file)
                  formData.append('password', values.password)
                  formData.append('npwp', values.npwp)
                  document.getElementsByClassName('mdi-reload')[0].click()
                  await service.uploadSertel(formData)
                  iziToast.success({
                    title: proses,
                    message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                  })
                } catch(e){
                  var errors = []
                  errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
                  modalStore.showInfo({
                    title: '',
                    children: (
                      <List>
                        {errors.map((er)=> {
                          return <ListItem primaryText={''} secondaryText={er}></ListItem>
                        })}
                      </List>
                    )  
                  })
                }
                setDialog(false)
                setPloading({ loading: false, message: `` })
                callback("", false, false, false)
              }}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          disableFocusOnMount={true}
          initialFocus="auth-dialog"
          id="auth-dialog"
          onFocus={() => { }}
          visible={dialogAuth}
          onRequestClose={() => {
            setDialogAuth(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="auth-dialog"
        >
          <DialogContent>
            <FormWrapper
              style={{
                margin: '0px !important ',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={dialogAuthItem}
              action={{}}
              hintMessage={t.translate('words.addAuthKeyInfo')}
              showCommandbar={false}
              baseId={baseId}
              title={t.translate('words.authKey')}
              submitLabel={dialogAuthItem.authKey ? t.translate('words.updateAuthKey') : t.translate('words.addAuthKey')}
              submitIconClassName={"mdi mdi-plus"}
              definitions={[
                {
                  inputType : inputTypes.TEXTAREA,
                  label : t.translate('words.authKey'),
                  key : 'authKey',
                  type : 'text',
                  width: '100%',
                  validation: 'required'
                }
              ]}
              onSubmit={async (value, callback) => {
                try {
                  updateAuthKey(value)
                  setDialogAuth(false)
                  callback("", false, false, false)
                } catch (e) {
                  setDialogAuth(false)
                  errorService(e)
                  callback("", false, false, false)
                }
              }}
            />
          </DialogContent>
        </Dialog>
        <TableWrapper 
          useFilter={false}
          tableHeader={
            <div style={{ width: '100%', paddingBottom: '14px' }}>
              <Row>
                <Col sm={2.4}>
                  <List style={{minHeight:'145px'}} className = "mpk-paper">
                    <ListItem rightAddon={
                      <Button
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label="Add"
                      >
                        <FontIcon className="mdi mdi-book-multiple"></FontIcon>
                      </Button>
                      }
                      style={{
                        fontWeight : 500,
                        fontSize    : 30
                      }}
                      primaryText={
                        <AnimatedNumber
                          value={summary.npwpCount}
                          formatValue={formatValue}
                        />
                      } />
                      <Divider />
                      <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpCount')}</strong></p>
                      {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                    </List>
                </Col>
                <Col sm={2.4}>
                  <List style={{minHeight:'145px'}} className = "mpk-paper">
                    <ListItem rightAddon={
                      <TooltipHoverModeConfig 
                        enabled={true} delayTimeout={2} defaultDelay={2}
                        key={`commandbar-action`}
                      >
                        <Tooltipped
                          id={`auto-positioning-above`}
                          tooltip={t.translate('words.exportNotHaveCert')}
                          defaultPosition="below"
                        >
                        <Button
                          buttonType="icon"
                          style={{ background: "white", color: '#2883e5' }}
                          themeType="outline"
                          aria-label="Add"
                          onClick={async ()=>{
                            try{
                              setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                              var params = {
                                page : 0,
                                size : 200
                              }
                              if(summary.npwpNotHaveCert > 0){
                                var res = await service.exportSertelExpired(params)
                                window.open(res.data.url)
                              }else{
                                iziToast.info({
                                  title : t.translate('words.exportExpired'),
                                  message : t.translate('words.notExist')
                                })
                              }
                              setPloading({ loading: false, message: `` })
                            }catch(e){
                              errorService(e)
                              setPloading({ loading: false, message: `` })
                            }
                          }}
                        >
                          <FontIcon className="mdi mdi-book-cancel"></FontIcon>
                        </Button>
                        </Tooltipped>
                      </TooltipHoverModeConfig>
                      }
                      style={{
                        fontWeight : 500,
                        fontSize   : 30,
                        color      : '#FFC108'
                      }}
                      primaryText={
                        <AnimatedNumber
                          value={summary.npwpNotHaveCert}
                          formatValue={formatValue}
                        />
                      } />
                      <Divider />
                      <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpNotHaveCert')}</strong></p>
                      {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                    </List>
                </Col>
                <Col sm={2.4}>
                  <List style={{minHeight:'145px'}} className = "mpk-paper">
                    <ListItem rightAddon={
                      <Button
                        buttonType="icon"
                        theme="clear"
                        themeType="outline"
                        aria-label="Add"
                      >
                        <FontIcon className="mdi mdi-book-check"></FontIcon>
                      </Button>
                      }
                      style={{
                        fontWeight : 500,
                        fontSize    : 30
                      }}
                      primaryText={
                        <AnimatedNumber
                          value={summary.npwpHaveCert}
                          formatValue={formatValue}
                        />
                      } />
                      <Divider />
                      <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpHaveCert')}</strong></p>
                      {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                    </List>
                </Col>
                <Col sm={2.4}>
                  <List style={{minHeight:'145px'}} className = "mpk-paper">
                    <ListItem rightAddon={
                      <TooltipHoverModeConfig 
                        enabled={true} delayTimeout={2} defaultDelay={2}
                        key={`commandbar-action`}
                      >
                        <Tooltipped
                          id={`auto-positioning-above`}
                          tooltip={t.translate('words.npwpExpiredInTwoMonth')}
                          defaultPosition="below"
                        >
                          <Button
                            buttonType="icon"
                            style={{ background: "white", color: '#2883e5' }}
                            themeType="outline"
                            aria-label="Add"
                            onClick={async ()=>{
                              try{
                                setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                                var params = {
                                  page : 0,
                                  size : 200
                                }
                                if(summary.npwpsExpiredInTwoMonths > 0){
                                  var res = await service.exportSertelExpired2Month(params)
                                  window.open(res.data.url)
                                }else{
                                  iziToast.info({
                                    title : t.translate('words.npwpExpiredInTwoMonth'),
                                    message : t.translate('words.notExist')
                                  })
                                }
                                setPloading({ loading: false, message: `` })
                                // const blob = new Blob([res.data], {type: 'text/csv'})
                                // fileSaver.saveAs(blob, ``);
                              }catch(e){
                                errorService(e)
                                setPloading({ loading: false, message: `` })
                              }
                            }}
                          >
                            <FontIcon className="mdi mdi-book-clock"></FontIcon>
                          </Button>
                        </Tooltipped>
                      </TooltipHoverModeConfig>
                      }
                      style={{
                        fontWeight : 500,
                        fontSize   : 30,
                        color      : 'tomato'
                      }}
                      primaryText={
                        <AnimatedNumber
                          value={summary.npwpsExpiredInTwoMonths}
                          formatValue={formatValue}
                        />
                      } />
                      <Divider />
                      <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpExpiredInTwoMonth')}</strong></p>
                      {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                    </List>
                </Col>
                <Col sm={2.4}>
                  <List style={{minHeight:'145px'}} className = "mpk-paper">
                    <ListItem rightAddon={
                      <TooltipHoverModeConfig 
                        enabled={true} delayTimeout={2} defaultDelay={2}
                        key={`commandbar-action`}
                      >
                        <Tooltipped
                          id={`auto-positioning-above`}
                          tooltip={t.translate('words.exportExpired')}
                          defaultPosition="below"
                        >
                          <Button
                            buttonType="icon"
                            style={{ background: "white", color: '#2883e5' }}
                            themeType="outline"
                            aria-label="Add"
                            onClick={async ()=>{
                              try{
                                setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                                var params = {
                                  page : 0,
                                  size : 200
                                }
                                if(summary.npwpsExpired > 0){
                                  var res = await service.exportSertelExpired(params)
                                  window.open(res.data.url)
                                }else{
                                  iziToast.info({
                                    title : t.translate('words.exportExpired'),
                                    message : t.translate('words.notExist')
                                  })
                                }
                                setPloading({ loading: false, message: `` })
                                // const blob = new Blob([res.data], {type: 'text/csv'})
                                // fileSaver.saveAs(blob, ``);
                              }catch(e){
                                errorService(e)
                                setPloading({ loading: false, message: `` })
                              }
                            }}
                          >
                            <FontIcon className="mdi mdi-book-alert"></FontIcon>
                          </Button>
                        </Tooltipped>
                      </TooltipHoverModeConfig>
                      }
                      style={{
                        fontWeight : 500,
                        fontSize   : 30,
                        color      : 'tomato'
                      }}
                      primaryText={
                        <AnimatedNumber
                          value={summary.npwpsExpired}
                          formatValue={formatValue}
                        />
                      } />
                      <Divider />
                      <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpsExpired')}</strong></p>
                      {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                    </List>
                </Col>
              </Row>
            </div>
          }
          selectable={true}
          baseId="mod-organization"
          title={t.translate('modules.organization.title')}
          className={className}
          defaultData={[]}
          defaultSortBy="id"
          columns={[
            {
              label: t.translate('words.npwp'),
              searchable: true,
              sortable : true,
              key: 'npwp',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.npwp)
            },
            {
              label: t.translate('words.name'),
              searchable: true,
              sortable : true,
              key: 'name',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.name)
            },
            {
              label: t.translate('words.email'),
              searchable: true,
              sortable : true,
              key: 'email',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.email)
            },
            {
              label: t.translate('words.phone'),
              searchable: true,
              sortable : true,
              key: 'phone',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.phone)
            },
            {
              label: t.translate('words.city'),
              searchable: true,
              sortable : true,
              key: 'city',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.city)
            },
            {
              label: t.translate('words.postalCode'),
              searchable: true,
              sortable : true,
              key: 'postalCode',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.postalCode)
            },
            {
              label: t.translate('words.address'),
              searchable: true,
              sortable : true,
              key: 'address',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.address)
            },
            {
              label: t.translate('words.certExists'),
              searchable: false,
              sortable : false,
              key: 'certExists',
              type: TableWrapper.dataTypes.BOOLEAN,
              render: item => {
                if(item.certExists){
                  return <Chip className="chip-green">{'Ada'}</Chip>
                } else {
                  item.certExists = false
                  return <Chip className="chip-red">{'Tidak Ada'}</Chip>
                }
              }
            },
            // {
            //   label: t.translate('words.certFilename'),
            //   searchable: false,
            //   sortable : false,
            //   key: 'certFilename',
            //   type: TableWrapper.dataTypes.STRING,
            //   render: item => (item.certFilename)
            // },
            // {
            //   label: t.translate('words.certExpiredDate'),
            //   searchable: false,
            //   sortable : false,
            //   key: 'certExpiredDate',
            //   type: TableWrapper.dataTypes.STRING,
            //   render: item => (item.certExpiredDate)
            // },
            {
              label: t.translate('words.createdBy'),
              searchable: true,
              sortable : true,
              key: 'createdBy',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdBy)
            },
            {
              label: t.translate('words.createdDate'),
              searchable: true,
              sortable : true,
              key: 'createdAt',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdAt)
            },
            // {
            //   label: t.translate('words.lastModifiedBy'),
            //   searchable: true,
            //   sortable : true,
            //   key: 'updateBy',
            //   type: TableWrapper.dataTypes.STRING,
            //   render: item => (item.updateBy)
            // },
            // {
            //   label: t.translate('words.lastModifiedDate'),
            //   searchable: true,
            //   sortable : true,
            //   key: 'updateAt',
            //   type: TableWrapper.dataTypes.STRING,
            //   render: item => (item.updateAt)
            // },
          ]}
          actions={actions}
          itemActions={[
          new TableWrapper.action(`${t.translate('words.authKey')}`, 'mdi mdi-shield-key', (item) => addAuthKey(item)),
          new TableWrapper.action(`${t.translate('words.uploadSertel')}`, 'mdi mdi-tag', (item) => addSertel(item))
          ]}
          setPage={search.page} onFetchData={ async params => {
            return (
              new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              if(params['name']){
                params.field = "name"
                params.query = params.name
              }
              if(params['email']){
                params.field = "email"
                params.query = params.email
              }
              if(params['npwp']){
                params.field = "npwp"
                params.query = params.npwp
              }
              try {
                var data = await initData()
                params.size = data.npwpCount
                let res = await service.getOrganization(params);
                
                try {
                  document.getElementsByClassName("pagination-container")[0].style.display = "none"
                  document.getElementsByClassName("pagination-container")[0].style.display = "none"
                  document.getElementsByClassName("pagination-container")[0].style.display = "none"
                  document.getElementsByClassName("pagination-container")[0].style.display = "none"
                } catch(e){}

                var getAll = res.data
                setData(getAll)
                resolve(res)
                return {
                  data: getAll,
                  headers: {
                    'x-total-count': getAll.length
                  }
                }
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
          }}
          sideHeader={
            <DataForm 
              baseId={`${baseId}-search`}
              defaultData={search}
              submitIconClassName = 'mdi mdi-magnify'
              submitLabel={`${t.translate('words.cari')}`}
              additionalAction={[
                {
                  show: true,
                  render: ()=> (
                    <Button style={{ marginRight: 15 }} onClick={()=> {
                      setSearch({})
                      var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                      setTimeout(()=> {
                        document.getElementsByClassName('mdi-reload')[0].click()
                      }, 1000)
                    }}>{t.translate('words.hapus')}</Button>
                  )
                }
              ]}
              onSubmit={(values, callback)=> {
                if(values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
                values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
                setTimeout(()=> {
                  document.getElementsByClassName('mdi-reload')[0].click()
                  callback("", false, false)
                }, 1000)
              }}
              definitions={[
                {
                  render: (
                    <div className="mpk-data-filter">
                      <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                        <p>{t.translate(`words.pencarian`)}</p>
                      </div>
                    </div>
                  )
                },
                {
                  inputType: inputTypes.INPUT_MASK_NUMBER,
                  label: t.translate(`words.npwp`),
                  key: 'npwp',
                  type: 'text',
                  mask: '##.###.###.#-###.###',
                  maskChar: '_',
                  maskValue: 'string',
                  width: '100%'
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate(`words.name`),
                  key: 'name',
                  type: 'text',
                  width: '100%'
                },
                {
                  inputType: inputTypes.INPUT,
                  label: t.translate(`words.email`),
                  key: 'email',
                  type: 'text',
                  width: '100%'
                },
              ]}
            />
          }
          showCommandbar={showCommandbar}
          showFilterPeriod={false}
        /> 
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(Organization))