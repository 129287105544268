import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Doss1.service'
import { inject, observer } from 'mobx-react'
import LoadingOverlay from 'react-loading-overlay'
import ErrorService from './../../services/errorService'
import { Chip } from 'react-md'

const LawanTransaksi = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore,
  authStore,
  ...props
}) => {

  const basePath = `lawan-transaksi`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = 'mod-lawan-transaksi'
  let [search, setSearch] = useState({})

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <TableWrapper 
        showActionColumn={false}
        useFilter={false}
        baseId={baseId}
        loading={ploading.loading}
        title={t.translate('words.cekDoppDoss')}
        className={className}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              var searchParam = JSON.parse(localStorage.getItem('cekDoppDoss'))
              try{
                  params.allNpwp = searchParam.allNpwp
                  delete searchParam.allNpwp
                  let res = await service.getSptBySearch(searchParam, params)
                  if(res.data){
                    setTimeout(async() => {
                      let getPage = await service.cekDoppDoss(res.data)
                      delete search.page; setSearch(res.data); getPage.headers['x-total-count'] = getPage.data.length
                      setData(getPage.data)
                      resolve(getPage)
                    }, 1000);
                  }
              } catch(e){
                resolve()
                ErrorService(e)
              }
            })
          )
        }}
        columns={[
          {
            label: t.translate('words.npwp'),
            searchable: false,
            sortable : true,
            key: 'npwp',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.dossDoppResponse){
                return item.dossDoppResponse.sptNpwp
              }else{
                return "-"
              }
            }
          },
          {
            label: t.translate('words.name'),
            searchable: true,
            sortable : true,
            key: 'name',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.name)
          },
          {
            label: t.translate('words.month'),
            searchable: true,
            sortable : true,
            key: 'month',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.dossDoppResponse){
                return item.dossDoppResponse.sptMonth
              }else{
                return "-"
              }
            }
          },
          {
            label: t.translate('words.year'),
            searchable: true,
            sortable : true,
            key: 'year',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.dossDoppResponse){
                return item.dossDoppResponse.sptYear
              }else{
                return "-"
              }
            }
          },
          {
            label: t.translate('words.rev'),
            searchable: true,
            sortable : true,
            key: 'rev',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.dossDoppResponse){
                return item.dossDoppResponse.sptRev
              }else{
                return "-"
              }
            }
          },
          {
            label: t.translate('words.inProcess'),
            searchable: true,
            sortable : true,
            key: 'inProgress',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.inProgress){
                return <Chip>YA</Chip>
              } else {
                return <Chip>TIDAK</Chip>
              }
            }
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable : true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
        ]}
        actions={[
            new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { history.push(`/product/company/${match.params.companyId}/daftar-spt`) }, true),
        ]}
        itemActions={[]}
        showCommandbar={true}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(LawanTransaksi))
