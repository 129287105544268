import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpImporteSsp.service'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import { Chip, ListItem, List, Switch } from 'react-md'
import ErrorService from '../../services/errorService'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import iziToast from 'izitoast'
import { Dialog, DialogContent, Button} from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import fileSaver from 'file-saver';
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import {toJS} from 'mobx'

const OpImporteSspDetail = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  temporaryStore,
  match
}) => {

  const [data, setData] = useState([])
  const [file, setFile] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  
  const baseId = 'mod-op-importEssp'
  const basePath= 'importEssp'

  async function initData() {}

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    var file = toJS(temporaryStore).properties.importEssp
    setFile(file)
  }, [])

  return (
    <>
    <TableWrapper useFilter={false}
      tableHeader={(
        <List style={{width: '100%'}}>
          <ListItem rightAddon = {"Status : "+ file.status}>{"File ID : "+ match.params.id}</ListItem>
          <ListItem rightAddon = {Math.round(file.count/file.total * 100)+ " %"}>{"Step "+ file.step+"/2 - "+file.count+"/1" }</ListItem>
        </List>
      )}
      baseId={baseId}
      title={t.translate(`modules.${basePath}.title`)}
      className={className}
      defaultData={[]}
      defaultSortBy="line"
      columns={[
        {
          label: t.translate('words.line'),
          searchable: true,
          sortable : true,
          key: 'line',
          type: TableWrapper.dataTypes.STRING,
          render: item =>(item.line)
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item =>(
            item.messages.map((d)=>{
              return <div>
               {d.message}
              </div>
            })
          )
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.back')}`, 'mdi mdi-arrow-left', ()=>{
          history.push(`/onpremise/${basePath}`)
        })
      ]}
      itemActions={[]}
      setPage={search.page} onFetchData={ (params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search , fileId : match.params.id}
            try {
              await initData()
              let getPage = await service.validation(params)
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data[0])
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpImporteSspDetail))
