import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Doss1.service'
import ErrorService from '../../services/errorService'
import AppMode from '../../AppMode'
import { Button, CardContent, Card, Chip, FontIcon, Dialog, DialogContent } from 'react-md'
import OrganizationView from '../Organization/OrganizationPicker.table'
import OpOrganizationTable from '../OpOrganization/OpOrganizationPicker.table'
import iziToast from 'izitoast'
import UtilsService from '../../services/utilsService'
import { toJS } from 'mobx'

const DossSptCek = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: { allNpwp: false }})
  const [organization, setOrganization] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [dialogOrg, setDialogOrg] = useState(false);
  const [showOrg, setShowOrg] = useState(true);

  const baseName = `DossSptCek`
  const basePath = `daftar-spt`

  useEffect(()=>{
    getOrganizations()
  }, [])

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    
    setOrganizations(getAll)
  }
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/product/company/${match.params.companyId}/${basePath}`}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`words.cekDoppDoss`)}
      defaultData={data.content}
      style={{
        maxWidth: '95%'
      }}
      onChange={(formData, key, value)=> {}}
      definitions={[
        {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.month`),
            key: 'month',
            type: 'text',
            width: '50%',
            validation: 'required',
            options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
            ]
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.year`),
            key: 'year',
            width: '50%',
            type: 'text',
            validation: 'required',
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.allNpwp`),
            key: 'allNpwp',
            width: '100%',
            type: 'text',
            validation: 'required',
          },
          {
            show: showOrg,
            render: (
                <div style={{width: '100%'}}>
                  <Button themeType="outline" theme="primary" onClick={(e)=> setDialogOrg(true) }>{t.translate("words.pilihOrganisasi")}</Button>
                  <Button themeType="outline" theme="error" style={{ marginLeft: 10 }} onClick={(e)=> setOrganization([])} >{t.translate("words.hapusOrganisasi")}</Button>
                  <Card style={{width: '100%', marginTop: '25px'}}>
                    <CardContent>
                      {organization.length > 0 &&
                      <>
                        {organization.map((d, i)=> {
                          return <Chip onClick={()=> {
                            var organizationFilter = organization.filter((d)=> {
                              return d.id != d.id
                            })
                            setOrganization(organizationFilter)
                          }} style={{ margin: 5 }} theme="outline" rightIcon={<FontIcon>delete</FontIcon>}>{d.npwp} - {d.name}</Chip>
                        })}
                      </>
                      }
                    </CardContent>
                  </Card>
                </div>
            )
          },
          {
            render: (
                <div>
                  <Dialog
                      visible={dialogOrg}
                      onRequestClose={(e)=> setDialogOrg(false)}
                      style={{
                        width: 1024,
                        height: 600,
                      }}
                  >
                    <DialogContent>
                      {!AppMode.onPremise &&
                      <OrganizationView setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                      }
                      {AppMode.onPremise &&
                      <OpOrganizationTable setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                      }
                    </DialogContent>
                  </Dialog>
                </div>
            )
          }
      ]}
      onSubmit={async (data, callback) => {
        try {
          var formData = Object.assign({}, data)
          var npwps = []

          if(data.allNpwp){
            organizations.map((d)=> {
              npwps.push(d.npwp)
            })
          }else{
            organization.map((d)=> {
              npwps.push(d.npwp)
            })
          }

          formData.npwps = npwps
          if(formData.npwps.length == 0 && !data.allNpwp){
            iziToast.info({
              title: `${t.translate('words.Info')}`,
              message: `${t.translate('words.warningOrg')}`
            })
            callback("", false, false)
          } else {
            localStorage.setItem("cekDoppDoss", JSON.stringify(formData))
            history.push(`cek-dopp-doss`)
            callback("", false, false)
          }
        } catch(e){
          ErrorService(e)
          callback("", false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DossSptCek))
