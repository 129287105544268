import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpOwner.service'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import { Chip, ListItem, List, Button, Dialog, DialogContent, DialogHeader, DialogTitle } from 'react-md'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'

const OpOwner = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  match
}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const [exportCsv, setExportCsv] = useState(false)
  const [exportCsvOrg, setExportCsvOrg] = useState(false)
  const [companyId, setCompanyId] = useState(null)

  const basePath = 'owner'
  const baseId = 'mod-owner'

  async function initData() {}

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    let user = JSON.parse(localStorage.getItem('user'))

    if(window.location.href.indexOf('open') != -1){
      setCompanyId(match.params.companyId)
    }
    if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
      setCompanyId(user.company.id)
    }

  }, []) 

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function resetCacheBulkExc(item) {
    try {
      var proses = t.translate('words.resetCache')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.resetCache(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if(selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function resetCacheBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.resetCache'),
      children : t.translate('sentences.resetCache'),
      onSubmit : (callback) => {
        resetCacheBulkExc(item)
        callback()
      }
    })
  }

  async function exportCsvBulkOrgExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            var user = JSON.parse(localStorage.getItem('user'))
            let proccedThis = await service.exportCsvBulkOrg(selectedData, companyId)
            const blob = new Blob([proccedThis.data], {type: proccedThis.type})
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportCsvBulkOrg(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => selectedData.push(data[d]))
    
    if (selectedData.length >= 1) {
      modalStore.showConfirm({
        title : t.translate('words.exportOrgUserCsv'),
        children : t.translate('words.confirmexportCsv'),
        onSubmit : (callback) => {    
          exportCsvBulkOrgExc(item)
          callback()
        }
      })
    } else {
      setExportCsvOrg(true)
    }
  }

  async function exportCsvBulkExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.exportCsvBulk(selectedData)
            const blob = new Blob([proccedThis.data], {type: proccedThis.type})
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportCsvBulk(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []

    selected.map((d) => selectedData.push(data[d]))
    // console.log(selectedData, selected)
    if (selectedData.length >= 1) {
      modalStore.showConfirm({
        title : t.translate('words.exportCsv'),
        children : t.translate('words.confirmexportCsv'),
        onSubmit : (callback) => {
          exportCsvBulkExc(item)
          callback()
        }
      })
    } else {
      setExportCsv(true)
    }
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.bulkDelete([item.id])
      iziToast.success({
        message: `${t.translate('words.delete')} ${item.id} ${t.translate('words.prosesDone')}`
      })
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  return (
    <>
      <Dialog
        visible={exportCsv}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportCsv(false)
        }}
        style={{
          width: 800,
          // height: AppMode.onPremise ? 400 : 400
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportCsv')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-export-csv`}
            defaultData={[]}
            submitLabel={t.translate('words.kirim')}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.firstName`),
                key: 'firstName.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.lastName`),
                key: 'lastName.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.login`),
                key: 'login.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.email`),
                key: 'email.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setExportCsv(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var companyId = null
                var user = JSON.parse(localStorage.getItem('user'))
                // console.log(user, "ini apa ?")
                // if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
                //   getPage = await service.get(params)
                // }
                if(window.location.href.indexOf('open') != -1){
                  companyId = match.params.companyId
                }
                if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                  companyId = user.company.id
                }

                try{
                  var res = await service.csvBulkBlob(values, companyId)
                  const blob = new Blob([res.data], {type: 'CSV'})
                  var filename = res.headers.filename

                  iziToast.success({
                    message: `${t.translate('words.exportCsv')} ${t.translate('words.prosesDone')}`
                  })

                  fileSaver.saveAs(blob, filename);
                  setExportCsv(false)
                 
                }catch(e){
                  errorService(e)
                  setExportCsv(false)
                }
                
              } catch (e) {
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.exportCsv')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
      visible={exportCsvOrg}
      disableFocusOnMount={() => { }}
      onRequestClose={() => {
        setExportCsvOrg(false)
      }}
      style={{
        width: 800,
        // height: AppMode.onPremise ? 400 : 400
      }}
    >
      <DialogHeader>
        <DialogTitle>{t.translate('words.exportOrgUserCsv')}</DialogTitle>
      </DialogHeader>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <DataForm
          baseId={`${baseId}-export-csv-org-user`}
          defaultData={[]}
          submitLabel={t.translate('words.kirim')}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.login`),
              key: 'userLogin.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.firstName`),
              key: 'userFirstName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.lastName`),
              key: 'userLastName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'userEmail.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button style={{ marginRight: 15 }} onClick={() => setExportCsvOrg(false)}>{t.translate('words.close')}</Button>
              )
            }
          ]}
          onSubmit={async (values, callback) => {
            try {
              var companyId = null
              var user = JSON.parse(localStorage.getItem('user'))
              // console.log(user, "ini apa ?")
              // if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
              //   getPage = await service.get(params)
              // }
              if(window.location.href.indexOf('open') != -1){
                companyId = match.params.companyId
              }
              if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                companyId = user.company.id
              }

              try{
                var res = await service.csvOrgBulkBlob(values, companyId)
                const blob = new Blob([res.data], {type: 'CSV'})
                var filename = res.headers.filename
                setExportCsvOrg(false)

                iziToast.success({
                  message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesDone')}`
                })

                fileSaver.saveAs(blob, filename);
                
              }catch(e){
                errorService(e)
                setExportCsvOrg(false)
              }
              
            } catch (e) {
              errorService(e)
              iziToast.error({
                message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesFail')}`
              })
              callback('', false, false, false)
            }
          }}
        >
        </DataForm>
      </DialogContent>
    </Dialog>
      <TableWrapper useFilter={false}
        selectable = {true}
        baseId = {baseId}
        title = {t.translate(`modules.${basePath}.title`)}
        className = {className}
        defaultData={[]}
        defaultSortBy="createdDate"
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.firstName`),
                key: 'firstName.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.lastName`),
                key: 'lastName.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.signIn`),
                key: 'login.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.email`),
                key: 'email.contains',
                type: 'text'
              },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.firstName'),
            searchable: true,
            sortable : true,
            key:'firstName',
            render: item => (item.firstName)
          },
          {
            label: t.translate('words.lastName'),
            searchable: true,
            sortable : true,
            key: 'lastName',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastName)
          },
          {
            label: t.translate('words.signIn'),
            searchable: true,
            sortable : true,
            key: 'login',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.login)
          },
          {
            label: t.translate('words.email'),
            searchable: true,
            sortable : true,
            key: 'email',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.email)
          },
          {
            label: t.translate('menu.companies'),
            searchable: true,
            sortable : true,
            key: 'company',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try {
                return item.company.nama
              } catch(e) {
                return '-'
              }
            }
          },
          {
            label: t.translate('words.active'),
            searchable: true,
            sortable : true,
            key: 'activated',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.activated){
                return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
              } else {
                return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
              }
            }
          },
          {
            label: t.translate('words.banned'),
            searchable: true,
            sortable : true,
            key: 'banned',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.banned){
                return item.nama
              } else {
                return "-"
              }
            }
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable : true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable : true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdDate)
          },
          {
            label: t.translate('words.lastModifiedBy'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedBy)
          },
          {
            label: t.translate('words.lastModifiedDate'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedDate)
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
          new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
          new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', (item) => {exportCsvBulk(item)}, true),
          new TableWrapper.action(`${t.translate('words.csvOrgUser')}`, 'mdi mdi-account-group', (item) => {exportCsvBulkOrg(item)}, true),
          new TableWrapper.action(`${t.translate('words.resetCache')}`, 'mdi mdi-alert-decagram', (item) => {resetCacheBulk(item)}, true),
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
            localStorage.setItem('owner', JSON.stringify(item))
            history.push(`${basePath}/${item.id}`)}, true),
          new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => {deleteById(item)}, true),
        ]}
        setPage={search.page} onFetchData={ (params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try {
                await initData()
                let user = JSON.parse(localStorage.getItem('user'))
                let owner = JSON.parse(localStorage.getItem('owner'))
                let getPage = {}
                if(window.location.href.indexOf('open') != -1){
                  getPage = await service.findAll(params, match.params.companyId)
                }
                if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                  var companyId = user.company.id
                  getPage = await service.findAll(params, companyId)
                }
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                setData(getPage.data)
                resolve(getPage)
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpOwner))
