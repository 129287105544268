import React, {useState, useEffect} from 'react'
import { Button, CardContent, Card, Chip, FontIcon, Dialog, DialogContent } from 'react-md'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import AppMode from '../../AppMode'
import service from './DaftarSpt.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import Bluebird from 'bluebird'
import ErrorServiceGet from '../../services/errorServiceGet'
import ErrorService from '../../services/errorService'
import OrganizationView from '../Organization/OrganizationPicker.table'
import iziToast from 'izitoast'

const CetakIndukForm = ({
  modalStore,
  className = '',
  showCommandbar = true,
  history,
  match,
  authStore
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading : false, content : {indukOnly: "true"}})
  const [organization, setOrganization] = useState({})
  const [dialogOrg, setDialogOrg] = useState(false)
  const [allOrg, setAllOrg] = useState({})
  const [all, setAll] = useState(true)
  const [search, setSearch] = useState({})
  const baseId = 'mod-daftar-spt'
  const basePath = 'daftar-spt'
  const [showOrg, setShowOrg] = useState(true);

  useEffect(async () => {
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise) backTo = `/onpremise/${basePath}`

  return (
    <FormWrapper
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      style={{
        width: '100%',
        maxWidth: '100%'
      }}
      submitLabel={t.translate(`words.create`)}
      backTo={backTo}
      defaultData={data.content}
      baseId="mod-form-sample"
      title={t.translate('modules.daftarSpt.cetakInduk')}
      onChange={async(formData, key, value)=>{
        var params = {
          page: 0,
          size: 100,
          sort: 'createdDate,DESC'
        }
        var res = []
        if(key == 'all' && value == true){
           if(AppMode.onPremise){
              res = await service.getOrganizationOp(params)
            } else {
              res =await service.getOrganization(params)
            }
            setAllOrg(res.data)
            setAll(false)
        }
        if(key == 'all' && value){
          setOrganization([])
          setShowOrg(false)
        } else {
          try {
            if(formData.all == true){
              setShowOrg(false)
            } else {
              setShowOrg(true)
            }
          } catch(e){
            setShowOrg(true)
          }
        }
      }}

      definitions={[
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.indukOnly`),
          key: 'indukOnly',
          name: 'indukOnly',
          width: '50%',
          validation : 'required',
          value: "true",
          style: {
            width: '50%'
          },
        },
        {
          inputType: inputTypes.RADIO,
          label: t.translate(`words.indukAll`),
          key: 'indukOnly',
          name: 'indukOnly',
          width: '50%',
          value: "false",
          validation : 'required',
          style: {
            width: '50%'
          },
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.month1`),
          key: 'startMonth',
          width: '50%',
          type: 'text',
          validation : 'required',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
          ]
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.month2`),
          key: 'endMonth',
          width: '50%',
          type: 'text',
          validation : 'required',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' },
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.dariTahun`),
          key: 'startYear',
          width : '50%',
          type: 'text',
          validation : 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.keTahun`),
          width : '50%',
          key: 'endYear',
          type: 'text',
          validation : 'required'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.allNpwp`),
          key: 'all',
          type: 'text',
        },
        {
          show: showOrg,
          render: (
            <div style={{width: '100%'}}>
              <Button themeType="outline" theme="primary" onClick={(e)=> setDialogOrg(true) }>{t.translate("words.pilihOrganisasi")}</Button>
              <Button themeType="outline" theme="error" style={{ marginLeft: 10 }} onClick={(e)=> setOrganization([])} >{t.translate("words.hapusOrganisasi")}</Button>
              <Card style={{width: '100%', marginTop: '25px'}}>
                <CardContent>
                  {organization.length > 0 &&
                    <>
                      {organization.map((d, i)=> {
                        return <Chip onClick={()=> {
                          var organizationFilter = organization.filter((d)=> {
                            return d.id != d.id
                          })
                          setOrganization(organizationFilter)
                        }} style={{ margin: 5 }} theme="outline" rightIcon={<FontIcon>delete</FontIcon>}>{d.npwp} - {d.name}</Chip>
                      })}
                    </>
                  }
                </CardContent>
              </Card>
            </div>
          )
        },
        {
          render: (
            <div>
              <Dialog
                visible={dialogOrg}
                onRequestClose={(e)=> setDialogOrg(false)}
                style={{
                  width: 1024,
                  height: 600,
                }}
              >
                <DialogContent>
                  <OrganizationView setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                </DialogContent>
              </Dialog>
            </div>
          )
        }
      ]}
      onSubmit={async (formData, callback) => {
        var res = {}
        try {
          formData.npwps = []
          try {
            organization.map((d)=> {
              formData.npwps.push(d.npwp)
            })
          } catch(e){}
          var data = Object.assign({}, formData)
          var proses = t.translate('words.reportInduk')
          if(data.indukOnly == "true"){
            data.indukOnly = true
          } else {
            data.indukOnly = false
          }
          var params = {
            indukOnly: data.indukOnly,
            all: data.all
          }
          delete data.indukOnly
          delete data.all
          if(formData.npwps.length == 0 && !formData.all){
            iziToast.info({
              title: `${t.translate('words.Info')}`,
              message: `${t.translate('words.warningOrg')}`
            })
            callback("", false, false)
          } else {
            res = await service.indukOnlySearch(params, data)
            callback("", false, false)
            iziToast.success({
              timeout: 5000,
              title: proses,
              message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
            })
            if(AppMode.onPremise){
              history.goBack()
            } else {
              history.push(`${backTo}`)
            }
          }
        } catch(e){
          ErrorService(e)
          callback("", false, false)
          return
        }
      }}

    />
  )
}

export default inject('envStore', 'modalStore')(observer(CetakIndukForm))
