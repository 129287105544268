import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Signer.service'
import DaftarSptService from '../DaftarSpt/DaftarSpt.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import ErrorService from './../../services/errorService'
import AppMode from '../../AppMode'

const SignerForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {} })
  const [organization, setOrganization] = useState({ organization: [], select: [] })
  const [definitions, setDefinitions] = useState([])
  const [inputMasks, setInputMasks] = useState('##.###.###.#-###.###')
  const [inputMasks2, setInputMasks2] = useState('################')

  const baseName = `signer`
  const basePath = `signer`

  const getOrganizations = async (values) => {
    var params = {
      page: 0,
      size: 1000
    }
    if (isNaN(values)) {
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og) => {
        og.label = og.npwp + " - " + og.name
        if (og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch (e) {
      organization.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    console.log(organizationsFilter, "TELL ME WHYYYY !!!!!!")
    return organizationsFilter
  }

  const getOrganizationsOp = async () => {
    try {
      let res = await DaftarSptService.getOrganizationOp({
        page: 0,
        size: 20,
        sort: 'createdDate,DESC'
      })
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      // console.log(e)
      ErrorService(e)
      return []
    }
  }

  useEffect(() => {
    async function initData() {
      var organizations = null;
      if (AppMode.onPremise) {
        // getOrganizationsOp()
      } else {
        getOrganizations()
      }
      setDefinitions(definitionsFormNpwp);
      var npwp = ""
      if (match.path.indexOf('open') != -1) {
        var spt = JSON.parse(localStorage.getItem('spt'))
        npwp = spt.npwp
      } else {

      }
      if (match.params.id == 'new') {
        setData({
          loading: false, content: {
            npwpProfile: npwp,
            identity: "true",
            actAs: "true"
          }
        })
        return true
      } else {
        const res = await service.getOne(match.params.id)
        res.data.identity = res.data.identity + ""
        res.data.actAs = res.data.actAs + ""
        // organizations.map((d)=> {
        //   if(d.npwp == res.data.npwpProfile) res.data.organization = d.npwp + " - " + d.name
        // })
        if (res.data.identity == "true") {
          setDefinitions(definitionsFormNpwp)
        } else if (res.data.identity == "false") {
          setDefinitions(definitionsFormNik)
        }
        setData({ loading: false, content: res.data })
      }
    }
    initData()
  }, [])

  let backTo = ``
  if (match.path.indexOf('open') != -1) {
    if (AppMode.onPremise) {
      backTo = `/onpremise/daftar-spt/open/${match.params.sptId}/${basePath}`
    } else {
      backTo = `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/${basePath}`
    }
  } else {
    if (AppMode.onPremise) {
      backTo = `/onpremise/${basePath}`
    } else {
      backTo = `/product/company/${match.params.companyId}/${basePath}`
    }
  }

  const definitionsFormNik = [
    {
      render: (
        <p className="mpk-full full-width"><b>{t.translate(`words.wpkuasa`)}</b></p>
      )
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.wajibPajak`),
      key: 'actAs',
      name: 'wpKuasa',
      type: 'boolean',
      value: 'true',
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.kuasa`),
      key: 'actAs',
      name: 'wpKuasa',
      type: 'boolean',
      value: 'false'
    },
    {
      render: (
        <p className="mpk-full full-width"><b>{t.translate(`words.identity`)}</b></p>
      )
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.identityNpwp`),
      key: 'identity',
      name: 'identity',
      type: 'text',
      value: 'true',
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.identityNik`),
      key: 'identity',
      name: 'identity',
      type: 'text',
      value: 'false',
    },
    {
      inputType: inputTypes.INPUT_MASK_NUMBER,
      mask: inputMasks2,
      maskChar: '_',
      maskValue: 'string',
      label: t.translate(`words.nik`),
      key: 'nik',
      name: 'nik',
      type: 'text',
      width: '100%',
      validation: ['required', 'min:16'],
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.name`),
      key: 'name',
      type: 'text',
      validation: 'required',
    },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.active`),
      key: 'active',
      type: 'boolean',
      name: 'active'
    },
  ]

  const definitionsFormNpwp = [
    {
      render: (
        <p className="mpk-full full-width"><b>{t.translate(`words.wpkuasa`)}</b></p>
      )
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.wajibPajak`),
      key: 'actAs',
      name: 'wpKuasa',
      type: 'boolean',
      value: 'true'
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.kuasa`),
      key: 'actAs',
      name: 'wpKuasa',
      type: 'boolean',
      value: 'false'
    },
    {
      render: (
        <p className="mpk-full full-width"><b>{t.translate(`words.identity`)}</b></p>
      )
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.identityNpwp`),
      key: 'identity',
      name: 'identity',
      type: 'text',
      value: 'true',
    },
    {
      inputType: inputTypes.RADIO,
      label: t.translate(`words.identityNik`),
      key: 'identity',
      name: 'identity',
      type: 'text',
      value: 'false',
    },
    {
      inputType: inputTypes.INPUT_MASK_NUMBER,
      mask: inputMasks,
      maskChar: '_',
      maskValue: 'string',
      label: t.translate(`words.npwp`),
      key: 'npwp',
      name: 'npwp',
      type: 'text',
      width: '100%',
      validation: ['required', 'min:15'],
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.name`),
      key: 'name',
      type: 'text',
      validation: 'required',
    },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.active`),
      key: 'active',
      type: 'boolean',
      name: 'active'
    },
  ]

  var definitionsDefault = []

  if (match.params.id == 'new') {
    if (match.path.indexOf('open') != -1) {

    } else {
      definitionsDefault = [
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'organization',
          validation: 'required',
          labelKey: 'label',
          valueKey: 'label',
          options: organization,
        },
      ]
    }
  } else {
    definitionsDefault = [
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.organization`),
        key: 'npwpProfile',
        type: 'text',
      }
    ]
  }

  definitionsDefault = definitionsDefault.concat(definitions);
  // var isEdit = false
  // if(data && data.content && data.content.id){
  //   definitionsDefault.map((d)=> {
  //     d.disabled = true
  //   })
  // }
  if (data && data.content && data.content.id) {
    definitionsDefault.map((d) => {
      if (d.name == "active") {
        d.disabled = false
      } else {
        d.disabled = true
      }
    })
  }

  return (
    <>
      {match.params.id == "new" &&
        <FormWrapper
          loading={data.loading}
          actions={[]}
          style={{
            maxWidth: '80%'
          }}
          onChange={(formData, key, value) => {
            if (key == 'identity' && value == 'true') {
              setDefinitions(definitionsFormNpwp)
            } else if (key == 'identity' && value == 'false') {
              setDefinitions(definitionsFormNik)
            }
          }}
          backTo={backTo}
          baseId={`mod-form-${baseName}`}
          title={t.translate(`modules.${baseName}.formTitle`)}
          defaultData={data.content}
          definitions={definitionsDefault}
          onSubmit={async (data, callback) => {
            try {

              // console.log(data, "TELL ME")
              if (AppMode.onPremise) {
                data.npwpProfile = data.organization.npwp
              } else {
                if (match.params.id == "new") {
                  if (match.path.indexOf('open') != -1) {
                    var spt = JSON.parse(localStorage.getItem('spt'))
                    data.npwpProfile = spt.npwp
                  } else {
                    data.npwpProfile = data.organization.npwp
                  }
                } else {
                  data.npwpProfile = data.npwpProfile
                }
              }
              var res = {}
              var formData = Object.assign({}, data)
              formData.identity = (formData.identity == 'true');
              formData.actAs = (formData.actAs == 'true');
              delete formData.organization
              if (match.params.id == 'new') {
                res = await service.post(formData)
              } else {
                res = await service.put(formData.id, formData)
              }
              callback(t.translate('sentences.tersimpan'), false, false)
              history.push(backTo)
            } catch (e) {
              ErrorService(e)
              callback("", false, false)
              return
            }
          }}
        />
      }
      {match.params.id != "new" &&
        <FormWrapper
          loading={data.loading}
          actions={[]}
          style={{
            maxWidth: '80%'
          }}
          onChange={(formData, key, value) => {
            if (key == 'identity' && value == 'true') {
              setDefinitions(definitionsFormNpwp)
            } else if (key == 'identity' && value == 'false') {
              setDefinitions(definitionsFormNik)
            }
          }}
          backTo={backTo}
          baseId={`mod-form-${baseName}`}
          title={t.translate(`modules.${baseName}.formTitle`)}
          defaultData={data.content}
          definitions={definitionsDefault}
        />
      }
    </>
  )
}

export default inject('authStore', 'envStore')(observer(SignerForm))
