import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpLogvalidation.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button } from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'

const OpLogValidation = ({
  className       = '',
  showCommandbar  = true,
  modalStore,
  temporaryStore

}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-validationLog'
  const basePath = 'validationLog'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData(){}

  return (
    <TableWrapper useFilter={false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      sideHeader = {
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values['searchKey'] = values['name.equals'] || values['code.equals'] || values['address.equals']
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.method`),
              key: 'method.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.url`),
              key: 'url.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.value`),
              key: 'value.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.message`),
              key: 'message.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.ip`),
              key: 'ip.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.username`),
              key: 'userLogin.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.method'),
          searchable: true,
          sortable : true,
          key:'method',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.method)
        },
        {
          label: t.translate('words.url'),
          searchable: true,
          sortable : true,
          key: 'url',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.url)
        },
        {
          label: t.translate('words.value'),
          searchable: true,
          sortable : true,
          key: 'value',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.value)
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: t.translate('words.ip'),
          searchable: true,
          sortable : true,
          key: 'ip',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.ip)
        },
        {
          label: t.translate('words.user'),
          searchable: true,
          sortable : true,
          key: 'user',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.user.login)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
      ]}
      actions={[]}
      itemActions={[]}
      setPage={search.page} onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let getPage = null
              getPage = await service.get(params)
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpLogValidation))
