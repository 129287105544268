import React, { useEffect, useState, useCallback, useMemo } from "react";
import { TableWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import service from "./ImportBp.service";
import { inject, observer } from "mobx-react";
import {
  Chip,
  Button,
} from "react-md";
import errorService from "../../services/errorService";
import LoadingOverlay from "react-loading-overlay";
import DataForm from "../../libs/react-mpk/components/DataForm";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const ImportBpCsv = ({
  className = "",
  showCommandbar = true,
  history,
  match,
  modalStore,
  temporaryStore,
}) => {
  const basePath = `import-bp-csv`;
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [data, setData] = useState([]);
  const [pencarian, setPencarian] = useState([]);
  const baseId = `mod-import-bp-csv`;
  let [search, setSearch] = useState({});

  return (
    <>
      <LoadingOverlay
        style={{
          position: "inherit",
          background: "red",
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      ></LoadingOverlay>
      <TableWrapper
        useFilter={false}
        baseId={baseId}
        title={t.translate("menu.sptSync")}
        className={className}
        selectable={false}
        showActionColumn={false}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params) => {
          return new Promise(async (resolve, reject) => {
            params = {...params,...search}

            try {
              console.log(params, 'cek')
              let getPage = await service.getSptSync(match.params.id, params);

              getPage.headers["x-pagination-count"] =
              getPage.headers["x-total-count"];
              setData(getPage.data);
              resolve(getPage);
            } catch (e) {
              resolve({ data: [] });
              errorService(e);
            }
          });
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={pencarian}
            submitIconClassName="mdi mdi-magnify"
            submitLabel={`${t.translate("words.cari")}`}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button
                    style={{ marginRight: 15 }}
                    onClick={() => {
                      setSearch({});
                      var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);;
                      setPencarian(newsearch)
                      setTimeout(() => {
                        document
                          .getElementsByClassName("mdi-reload")[0]
                          .click();
                      }, 1000);
                    }}
                  >
                    {t.translate("words.hapus")}
                  </Button>
                ),
              },
            ]}
            onSubmit={(values, callback) => {
              var formData = Object.assign({}, values)
              if (values["createdDate.greaterOrEqualThan"])
                values["createdDate.greaterOrEqualThan"] = new Date(
                  values["createdDate.greaterOrEqualThan"]
                );
              if (values["createdDate.lessOrEqualThan"])
                values["createdDate.lessOrEqualThan"] = new Date(
                  values["createdDate.lessOrEqualThan"]
                );

              
              setPencarian(formData)
              setSearch(values);
              localStorage.setItem(
                `${baseId}-saved-search`,
                JSON.stringify(values)
              );
              setTimeout(() => {
                document.getElementsByClassName("mdi-reload")[0].click();
                callback("", false, false);
              }, 1000);
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div
                      className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}
                    >
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                ),
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.npwp`),
                key: "sptNpwp.equals",
                type: "text",
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month1`),
                key: 'sptMonth.equals',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ],
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.year`),
                key: 'sptYear.equals',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.rev`),
                key: 'sptRev.equals',
                type: 'text',
              },
            ]}
          />
        }
        columns={[
          {
            label: t.translate("words.type"),
            searchable: true,
            sortable: true,
            key: "type",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.type,
          },
          {
            label: t.translate('words.status'),
            searchable: true,
            sortable : true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.status && item.status == "COMPLETED"){
                return <Chip style={{ background: "#049e51", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              } 
              if(item.status && item.status == "FAILED"){
                return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              }
              if(item.status && item.status == "PROCESS"){
                return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status+"").toUpperCase()}</Chip>
              }else {
                return <Chip>{(item.status+"").toUpperCase()}</Chip>
              } 
            }
          },
          {
            label: t.translate("words.npwp"),
            searchable: true,
            sortable: true,
            key: "sptNpwp",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sptNpwp,
          },
          {
            label: t.translate("words.month"),
            searchable: true,
            sortable: true,
            key: "sptMonth ",
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => item.sptMonth,
          },
          {
            label: t.translate('words.year'),
            searchable: true,
            sortable: true,
            key: 'sptYear',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.sptYear)
          },
          {
            label: t.translate("words.rev"),
            searchable: true,
            sortable: true,
            key: "sptRev",
            type: TableWrapper.dataTypes.NUMBER,
            render: (item) => item.sptRev,
          },
          {
            label: t.translate('words.totalBp'),
            searchable: true,
            sortable: true,
            key: 'totalBp',
            type: TableWrapper.dataTypes.NUMBER,
            render: item => (item.totalBp)
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable: true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
          {
            label: t.translate("words.createdBy"),
            searchable: true,
            sortable: true,
            key: "createdBy",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdBy,
          },
          {
            label: t.translate("words.createdDate"),
            searchable: true,
            sortable: true,
            key: "createdDate",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.createdDate,
          },
          {
            label: t.translate("words.lastModifiedBy"),
            searchable: true,
            sortable: true,
            key: "lastModifiedBy",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.lastModifiedBy,
          },
          {
            label: t.translate("words.lastModifiedDate"),
            searchable: true,
            sortable: true,
            key: "lastModifiedDate",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.lastModifiedDate,
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.back')}`, 'mdi mdi-arrow-left', (item) => {
            history.goBack()
          }, true),
        ]}
        itemActions={[]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  );
};
export default inject("temporaryStore", "modalStore")(observer(ImportBpCsv));
