import { inject, observer } from 'mobx-react'
import { DropdownMenu, ListItem, FontIcon, Text, Card, Button, Dialog, DialogHeader, DialogContent, DialogTitle, DialogFooter } from 'react-md'
import React, { useEffect, useState } from 'react'
import t from 'counterpart'
import { Chip } from "@react-md/chip";
import iziToast from 'izitoast';
// import {useSnackbar} from 'notistack'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

const NotifikasiList = ({navigationStore, ...props}) => {
  const { env } = props.envStore
  const basePath = `daftar-spt`
  const [dialogCetakInduk, setDialogCetakInduk] = useState(false);
  const [coba, setCoba] = useState([notifikasiAwal])
//   const {enqueueSnackbar, closeSnackbar} = useSnackbar()
//   const handleClick = () =>{
//       enqueueSnackbar('Test')
//   }
  var notifikasiAwal = []
  var mergedArray = []
  var items = [
    {
        id:'',
        status:'',
        // proygress:'',
        message:'',
        url:'',
        type:"Show All",
        path:`${basePath}/notification`
    },
  ]
  try {
    notifikasiAwal = JSON.parse(localStorage.getItem("notifikasi"))
    mergedArray = [ ...notifikasiAwal, ...items ]
  } catch (error) {}
  // console.log(mergedArray);
  return (
    <div className="appbar-shortcut">
      <DropdownMenu
        id="notifikasi-list-shortcut"
        disableDropdownIcon
        items={[]}
        // query={{
        //     page:1,
        //     size:2
        // }}
        // items={mergedArray.map((item, index) => {
        //         return (
        //             // <div
        //             //     key={`notification/${index}`}
        //             // >
        //                 <div>
        //                     <ListItem
        //                         id={item.id}
        //                         leftAddon={d.status?<Chip style={{color:'green'}}>{d.status}</Chip>:''}
        //                         // rightAddon={item.progress}
        //                         onClick={() => {
        //                             // // console.log(d);
        //                             if (d.path) {
        //                                 navigationStore.redirectTo(
        //                                     coba.path
        //                                 )
        //                             }
        //                             else{
        //                                 //kalau menggunakan dialog
        //                                 // setDialogCetakInduk(true)
        //                                 // <Button onClick={handleClick}>Show snackbar</Button>
        //                                 iziToast.show({
        //                                     title: 'Notifikasi',
        //                                     message: `${coba.type.split('_').join(' ')} telah di proses ${coba.progress} dengan status ${coba.status}`,
        //                                     buttons: [
        //                                         ['<button>Download</button>', function (item, toast) {
        //                                                 if(coba.url){
        //                                                     window.open(coba.url)
        //                                                   } else {
        //                                                     iziToast.info({
        //                                                       title: "",
        //                                                       message: 'File belum tersedia.'
        //                                                     })
        //                                             }
        //                                         }, true],
        //                                     ],
        //                                 });
        //                             }
        //                           }}
        //                     >
        //                         <div>{coba.type}</div>
        //                         <div>{coba.message}</div>
        //                         <div>{
        //                             <div className='progress'>
        //                                 {coba.progress
        //                                 ?<Progress
        //                                     percent={d.progress}
        //                                     strokeWidth ={6}
        //                                     theme={{
        //                                     success: {
        //                                         color: '#049e51',
        //                                         trailColor : '#e0e0e0'
        //                                     },
        //                                     active: {
        //                                         color: 'yellow',
        //                                         trailColor : '#e0e0e0'
        //                                     },
        //                                     default: {
        //                                         color: 'blue',
        //                                         trailColor : '#e0e0e0'
        //                                     }
        //                                     }} 
        //                                 />
        //                                 :""
        //                                 }
                                        
        //                             </div>
                                    
        //                         }</div>
        //                     </ListItem>
        //                 </div>
        //             // </div>
        //         )
        //     })}
        >
        <Dialog
            visible={dialogCetakInduk}
            disableFocusOnMount={() => { }}
            onRequestClose={() => {
                setDialogCetakInduk(false)
            }}
            aria-labelledby="dialog-title"
            // style={{
            //     width: 800,
            // }}
        >
            <DialogHeader>
                <DialogTitle>Text</DialogTitle>
            </DialogHeader>
            <DialogContent>Text</DialogContent>
            <DialogFooter>
                <Button id="dialog-close" onClick={()=>{
                    setDialogCetakInduk(false)
                }}>
                    Close
                </Button>
            </DialogFooter>
        </Dialog>
        <FontIcon iconClassName="mdi mdi-alert"/>
      </DropdownMenu>
    </div>
  )
}

export default inject('navigationStore','envStore')(observer(NotifikasiList))