import React, { useEffect, useState } from 'react'
import { Table, TableContainer, TableHeader, TableRow, TableCell, TableBody, Button, FontIcon, DropdownMenu, BOTTOM_RIGHT_ANCHOR, TOP_RIGHT_ANCHOR, TableFooter, LinearProgress, CircularProgress, Caption, Dialog, DialogContent, Card, TooltipHoverModeConfig, Tooltipped, TextIconSpacing, DialogHeader, DialogTitle, ListItemText, ListItem, DialogFooter } from 'react-md'
import { defaultQueryParameter, dataTypes, sorts, inputTypes } from '../../libs/react-mpk/config/constant'
import t from 'counterpart'
import './DataTable.scss'
import { DataForm, Pagination } from '../../libs/react-mpk/components'
import {Flex} from '../../libs/react-mpk/components'
import {LoaderInfo} from '../../libs/react-mpk/components'
import { useIndeterminateChecked } from '@react-md/form';
import { TableCheckbox, Select } from 'react-md'
import NotFoundData from '../../libs/react-mpk/components/NotFoundData'
import moment from 'moment'
import ReactSelect from 'react-select'

const DataTable = ({
  tableFooter       = null,
  baseId            = 'mpk-data-table-id',
  title             = '',
  subtitle          = '',
  className         = '',
  columns           = [],
  itemActions       = [],
  data              = [],
  showActionColumn  = true,
  fit               = true,
  showIndex         = true,
  query             = defaultQueryParameter(),
  totalData         = 0,
  loading           = true,
  selectable        = false,
  offsetLeft        = 0,
  showMore          = false,
  showDataLength    = 0,
  isDense           = false,
  isFlex            = true,
  onChangeQuery     = () => {},
  tableActions      = false,
  actions           = [],
  npwpOptions       = [],
  hAlign            = "center",
  ...props
}) => {
  const [mounted, setMounted] = useState(false)
  const [bound, setBound] = useState({width: 0, height: 0})
  const [currentActionId, setCurrentActionId] = useState(null)
  const [currentActionStyle, setCurrentActionStyle] = useState({})
  const [sort, setSort] = useState({ sort: 'descending', sortBy: '' })
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [dialogCustom, setDialogCustom] = useState({show : false, label : ""})
  const [querys, setQuery] = useState({})
  const [selectedValue, setSelectedValue] = useState({
    npwp : [],
    tanggal : ""
  })
  var customStyles = {
    menu: (provided)=> ({
      ...provided,
      zIndex: 50,
      fontSize : '13px',
    }),
    control: base => ({
      ...base,
      height: '24px',
      minHeight: '24px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 7,
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 7,
    }),
    placeholder: (styles)=>({
      ...styles,
      paddingTop: 0,
      paddingBottom: 7,
      fontSize : '13px'
    }),
    singleValue: (styles)=>({
      ...styles,
      paddingTop: 0,
      paddingBottom: 7,
      fontSize : '13px'
    }),
    input: (styles)=>({
      ...styles,
      paddingTop: 0,
      paddingBottom: 7,
      fontSize : '13px'
    }),
    indicatorSeparator: (styles)=>({
      ...styles,
      paddingTop: 0,
      paddingBottom: 7,
      height : 5
    }),
    multiValue: (styles)=>({
      ...styles,
      height : '17px'
    }),
  }

  const getItemActions = item => {
    let newItemActions = itemActions.filter(d => (
      typeof d.show === 'boolean' 
        ? d.show
        : typeof d.show === 'function'
          ? d.show(item)
          : true
    ))
    return newItemActions
  }
  var defaultOption = [
    {label : 'This Month', value : 1},
    {label : 'Last 3 Month', value : 3},
    {label : 'Last 6 Month', value : 6},
    {label : 'Last This Year', value : 12},
    {label : 'Custom', value : 0},
  ]
  var pphOption = [
    {label : 'All Pasal', value : 'PPH22'},
    {label : 'Pasal 22', value : 'PPH22'},
    {label : 'Pasal 23', value : 'PPH23'},
    {label : 'Pasal 25', value : 'PPH25'},
    {label : 'Pasal 26', value : 'PPH26'},
    {label : 'Pasal 4(2)', value : 'PPH4a2'},
  ]

  useEffect(() => {
    if(!mounted){
      try {
        setTimeout(() => {
          const container = document.getElementById(baseId)
          if(container){
            setBound({
              width: container.clientWidth,
              height: container.clientHeight
            })
          }
        })
      } catch(e){}
      setMounted(true)
    }
  }, [baseId, mounted])

  const { rootProps, getProps } = useIndeterminateChecked(
    data.map(( item ) => item)
  );
  let selected = []
  data.map((d, index)=> {
    const checkboxProps = getProps(d);
    if(checkboxProps.checked){
      selected.push(index)
    } 
  })
  localStorage.setItem(`${baseId}-selected-item`, JSON.stringify(selected))

  const onSort = async(col)=> {
    let { setQuery=() => {}, handleFetchData=() => {}} = props
    if(col.sortable){
      if(col.key == sort.sortBy){
        if(sort.sort == sorts.descendingLong){
          setSort({ sort: sorts.ascendingLong, sortBy: col.key })
          query.sortBy = col.key
          query.sort   = sorts.ascending
          setQuery(query)
          onChangeQuery(query)
        } else {
          setSort({ sort: sorts.descendingLong, sortBy: col.key })
          query.sortBy = col.key
          query.sort   = sorts.descending
          setQuery(query)
          onChangeQuery(query)

        }
      } else {
        setSort({ sort: sorts.descendingLong, sortBy: col.key })
        query.sortBy = col.key
        query.sort   = sorts.descending
        setQuery(query)
        onChangeQuery(query)
      }
      handleFetchData()
    }
  }

  function val() {
    var x = document.getElementById("default-option").value;
    var substrac = parseInt(x)
    if(substrac === 0){
      setDialogCustom({show :true})
    }else{
      query.startMonth = parseInt(moment().subtract(substrac, 'months').format("M"))
      query.endMonth = parseInt(moment().format("M"))
      query.startYear = parseInt(moment().subtract(substrac, 'months').format("YYYY"))
      query.endYear = parseInt(moment().format("YYYY"))
      onChangeQuery(query)
    }
  }

  function valNpwp() {
    var x = document.getElementById("pph-option").value;
    query.npwps = [x]
    onChangeQuery(query)
  }

  function valDateCustom() {
    try{
      query.startMonth = parseInt(moment(querys.startDate).format("M"))
      query.endMonth = parseInt(moment(querys.endMonth).format("M"))
      query.startYear = parseInt(moment(querys.startDate).format("YYYY"))
      query.endYear = parseInt(moment(querys.endMonth).format("YYYY"))
      onChangeQuery(query)
    }catch(e){}
  }

  const tableContent = (
    <>
    <Card style={{ width: '100%' }}>
      <Table className='tesrss' dense={isDense} fullWidth hAlign={hAlign} vAlign='middle'>
        {/* <Caption className='table-title'>{title}</Caption> */}
        <TableHeader style={{zIndex:'10 !important'}} sticky>
          <TableRow>
            { selectable && 
              <TableCheckbox 
                id={`${baseId}-selectable-rows-root-checkbox`} 
                sticky="header-cell"
                {...rootProps} 
              />
            }
            { showIndex && (
              <TableCell className='table-header'
                sticky="header-cell"
              >
                {t.translate('mpk.column.index')}
              </TableCell>
            )}
            { showActionColumn && (
              <TableCell className='table-header'
                sticky="header-cell"
                style={{zIndex: data.length+10}}
              >
                {t.translate('mpk.column.actions')}
              </TableCell>
            )}
            
            {columns.map(col => (
              <TableCell style={{alignItems:'center'}} className='table-header' key={`${baseId}-${col.label}`} aria-sort={sort.sortBy == col.key ? sort.sort : 'none'} onClick={()=> onSort(col)}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className='table-body'>
          {data
          .slice(0, isShowFilter ? data.length : showDataLength > 0 ? showDataLength : data.length)
          .map((d, i) => {
            let dItemActions = getItemActions(d)
            const checkboxProps = getProps(d);
            const { checked, onChange } = checkboxProps;
            return (
              <TableRow key={`${baseId}-row-${i}`}
                selected={selectable ? checked : false}
                onClick={onChange}
              >
                { selectable && 
                  <TableCheckbox
                    id={`selectable-rows-checkbox-${i + 1}`}
                    {...checkboxProps}
                  />
                }
                { showIndex && query && (
                  <TableCell
                    // sticky="cell"
                    className="numeric "
                  >
                    {i + 1}
                  </TableCell>
                )}
                { showActionColumn 
                  ? (itemActions.length > 0 && dItemActions.length > 0 ? (
                    <TableCell
                      sticky="cell"
                      style={{
                        zIndex: (`${baseId}-${i}` === currentActionId ? (data.length - 1) + 2 : 1)
                      }}
                    >
                      {dItemActions.length === 1 ? (
                        <Button
                          buttonType="icon"
                          theme="warning"
                          onClick={() => dItemActions[0].onClick(d)}
                        >
                          <FontIcon iconClassName={dItemActions[0].iconClassName}/>
                        </Button>
                      ) : (
                        <div className="mpk-position position-relative">
                          <DropdownMenu
                            id={`${baseId}-row-actions-${i}`}
                            className="mpk-position position-relative"
                            anchor={i < 10 ? TOP_RIGHT_ANCHOR : BOTTOM_RIGHT_ANCHOR}
                            disableDropdownIcon
                            buttonType="icon"
                            onClick={(e) => {
                              // let bound = e.target.getBoundingClientRect()
                              // // console.log(e.pageX, e.pageY)
                              // // console.log(bound.left, bound.top)
                              setCurrentActionId(`${baseId}-${i}`);
                              // setTimeout(() => {
                              //   let target = document.getElementById(`${baseId}-row-actions-${i}-menu`)
                              //   // console.log(target)
                              //   if(target){
                              //     target.style.top = `${bound.top}px`
                              //     target.style.left = `${bound.left}px`
                              //   }
                              // })
                            }}
                            items={dItemActions.map(action => ({
                              children: typeof action.label === 'function' ? action.label(d) : action.label,
                              leftAddon: (
                                <FontIcon 
                                  iconClassName={
                                    typeof action.iconClassName === 'function' ? action.iconClassName(d) : action.iconClassName
                                  }
                                />
                              ),
                              onClick: () => action.onClick(d)
                            }))}
                          >
                            <FontIcon iconClassName="mdi mdi-menu"/>
                          </DropdownMenu>
                        </div>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell 
                      className="mpk-align align-center table-cell"
                      sticky="cell"
                    >
                      <FontIcon iconClassName="mdi mdi-block-helper"/>
                    </TableCell>
                  ))
                  : null
                }
                {columns.map(col => (
                  <TableCell 
                    key={`${baseId}-row-${i}-${col.label}`}
                    className={`${col.type === dataTypes.NUMBER ? 'numeric' : ''} table-cell`}
                  >
                    {col.render(d)}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
          {showMore && !isShowFilter &&
            <Caption onClick={()=>setIsShowFilter(true)} style={{position: 'absolute', right: 24, bottom: 24, width: '100%', textAlign:'right', cursor: 'pointer'}} className='show-more'>{"See More..."}</Caption>
          }
        </TableBody>
      </Table>
    </Card>
  </>
  )

  return (
    <div 
      id={baseId}
      className={`mpk-data-table ${fit ? 'fit' : ''} ${className}`}
    >
      <div className={fit ? 'fit' : ''}>
        { loading && data.length > 0 && (
          <div className="main-loader">
            <LinearProgress id={`${baseId}-linerar-progress`}/>
          </div> 
        )}
        { loading && data.length === 0 ? (
          fit ? (
            <Flex
              align={Flex.properties.align.CENTER}
              justify={Flex.properties.justify.CENTER}
              className="mpk-full full-height"
              direction={Flex.properties.direction.COLUMN}
            >
              <CircularProgress id={`${baseId}-circular-progress`}/>
              <div className="mpk-font size-M weight-B mpk-margin-N margin-top">
                {t.translate('mpk.sentence.loadingData')}
              </div> 
            </Flex>
          ) : (
            <LoaderInfo>Loading Data</LoaderInfo>
          )
        ) : ( fit ? (
            <Flex 
              direction={Flex.properties.direction.COLUMN} 
              className="mpk-full full-height full-width mpk-position position-relative table-container"
            >
              <Flex 
                className={`mpk-position position-sticky mpk-padding-N padding-left padding-top padding-right ${className}`}
                justify={Flex.properties.justify.BETWEEN}
                style={{
                  background:'white',
                  height:'50px'
                }}
              >
                <Flex 
                  fit
                  align={Flex.properties.align.CENTER}
                >
                  {title && (
                    <span className="mpk-font weight-B size-L">{title}</span>
                  )}
                  {subtitle && (
                    <span className="mpk-font weight-L size-L mpk-margin-S margin-left">{subtitle}</span>
                  )}
                </Flex>
                {tableActions &&
                  <div id="actions" style={{zIndex:'999'}}>
                    <Flex>
                      <i style={{marginRight:'9.7px'}} class="mdi mdi-filter-outline"></i>
                      {defaultOption &&
                        <div style={{width:'165px'}}>
                          <ReactSelect
                            styles={customStyles}
                            options={defaultOption}
                            value={query.label ? {label : query.label} : {label : 'Custom'}}
                            onChange={(e)=>{
                              var substrac = e.value
                              if(substrac === 0){
                                setDialogCustom({show :true, label : e.label})
                              }else{
                                query.startMonth = parseInt(moment().subtract(substrac, 'months').format("M"))
                                query.endMonth = parseInt(moment().format("M"))
                                query.startYear = parseInt(moment().subtract(substrac, 'months').format("YYYY"))
                                query.endYear = parseInt(moment().format("YYYY"))
                                query.label = e.label
                                onChangeQuery(query)
                              }
                            }}
                          /> 
                        </div>
                      }
                      {npwpOptions.length > 0 &&
                        <div style={{width:'165px'}}>
                          <ReactSelect
                            styles={customStyles}
                            options={npwpOptions}
                            value={query.npwps.length ? {label : query.npwps[0]} : false}
                            // isMulti={true}
                            onChange={(e)=>{
                              // var npwp = []
                              // e.map((d)=>{
                              //   npwp.push(d.value)
                              // })
                              // console.log(query.npwps, 'ini')
                              query.npwps = [e.value]
                              setSelectedValue({npwp : query.npwps})
                              onChangeQuery(query)
                            }}
                          /> 
                        </div>
                      }
                    </Flex>
                  </div>
                }
              </Flex>
              { data.length === 0 ? <NotFoundData /> : (<div className={`mpk-full full-width full-height ${isShowFilter || isFlex ? 'flex' : ''}`}>
                <TableContainer
                  style={{
                    height: bound.height,
                    background: 'transparent'
                  }}
                >
                  {tableContent}
                </TableContainer>
              </div>
              )}
            </Flex>
          ) : (tableContent)
        )}
        {(mounted && showMore) &&
          <Dialog
            className='dialog-uci'
            style={{        
              width: '50%',
              minHeight: '150px',
              padding : '0px !important'
            }}  
            visible={isShowFilter}
            onRequestClose={() => {
              setIsShowFilter(false)
            }}
          >
            <DialogContent id='dialog-uci'>
              {
                <Flex 
                  direction={Flex.properties.direction.COLUMN} 
                  className="mpk-full full-height full-width mpk-position position-relative table-container"
                >
                <Flex 
                  className={`mpk-position position-sticky mpk-padding-N padding-left padding-top padding-right ${className}`}
                  justify={Flex.properties.justify.BETWEEN}
                  style={{
                    background:'white',
                    height:'50px'
                  }}
                >
                  <Flex 
                    fit
                    align={Flex.properties.align.CENTER}
                  >
                    {title && (
                      <span className="mpk-font weight-B size-L">{title}</span>
                    )}
                    {subtitle && (
                      <span className="mpk-font weight-L size-L mpk-margin-S margin-left">{subtitle}</span>
                    )}
                  </Flex>
                </Flex>
                { data.length === 0 ? <NotFoundData /> : (<div className={`mpk-full full-width full-height ${isShowFilter || isFlex ? 'flex' : ''}`}>
                  <TableContainer
                    style={{
                      height: bound.height,
                      background: 'transparent'
                    }}
                  >
                    {tableContent}
                  </TableContainer>
                </div>
                )}
              </Flex>
              }
            </DialogContent>
            <DialogFooter>
              <Button onClick={()=> setIsShowFilter(false)}>{"TUTUP"}</Button>
            </DialogFooter>
          </Dialog>
        }
        <Dialog
          visible={dialogCustom.show}
          onRequestClose={() => {
            setDialogCustom({show :false})
          }}
          className="custom-dialog"
        >
        <DialogHeader>
          <ListItem style={{width:'100%', padding:'0!important'}} rightAddon={<FontIcon color='#757575' onClick={()=>{setDialogCustom({show :false})}} iconClassName='mdi mdi-close'/>}>{t.translate('words.chooseDate')}</ListItem>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={[]}
            onChange={(formData, key, value)=>{
              // setQuery(formData)
            }}
            definitions={[
              {
                inputType: inputTypes.DATE,
                label: t.translate(`words.start`),
                key: 'startDate',
                type: 'date',
                width: '50%',
              },
              {
                inputType: inputTypes.DATE,
                label: t.translate(`words.end`),
                key: 'endMonth',
                type: 'date',
                width: '50%',
              },
              // {
              //   width: '100%',
              //   render : (
              //     <Button onClick={()=>{
              //       valDateCustom()
              //       setDialogCustom({show :false})
              //     }} className='button-cutom-dialog'>{"OKE"}</Button>
              //   )
              // }
            ]}
            onSubmit={(formData, callback)=>{
              try{
                query.startMonth = parseInt(moment(formData.startDate).format("M"))
                query.endMonth = parseInt(moment(formData.endMonth).format("M"))
                query.startYear = parseInt(moment(formData.startDate).format("YYYY"))
                query.endYear = parseInt(moment(formData.endMonth).format("YYYY"))
                query.label = dialogCustom.label
                onChangeQuery(query)
                setDialogCustom({show :false})
              }catch(e){
                setDialogCustom({show :false})
              }
            }}
            >
          </DataForm>
        </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}

DataTable.dataTypes = dataTypes;
DataTable.getSelected = (baseId) => (
  localStorage.getItem(`${baseId}-selected-item`)
)

export default DataTable
