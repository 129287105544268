import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';

let service = new CrudService(`/api/users/owner`);

service.findAll = async(query, companyId)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/users/owner/${companyId}`,
    query
  })
}

service.csvBulkBlob = async (query, companyId) =>{
  return http.request({
    method : http.methods.GET,
    url : `/api/users/user/${companyId}/csv/param`,
    query
  })
}

service.csvOrgBulkBlob = async (query, companyId) =>{
  return http.request({
    method : http.methods.GET,
    url : `/api/users/user/${companyId}/organization/csv/param`,
    query
  })
}

service.getCompany = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/company/page`,
    query
  })
}

service.getKpp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/master/kpp`,
    query
  })
}
  
service.createdOne= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/users/owners`,
    data,
  })
}

service.csvBulk= async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/users/csv/bulk`,
    data,
  })
}

service.editById = async(id, data)=>{
  return http.request({
    method: http.methods.PUT,
    url: `/api/users`,
    data,
  })
}

service.bulkDelete = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `/api/users/delete`,
    data,
  })
}

service.deleteById = async (id)=>{
  return http.request({
    method: http.methods.DELETE,
    url: `/api/users/${id}`,
  })
}

service.resetCache = async(data, id) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/users/user/${id}/resetCache`,
    data
  })
}

service.resetCacheBulk = async(data, companyId) => {
  return http.request({
    method: http.methods.POST,
    url: `api/users/user/${companyId}/resetCache`,
    data,
  })
}

service.exportCsvBulk = async(data) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/users/csv/bulk`,
    data
  })
}

service.exportCsvBulkOrg = async(data, companyId) => {
  return http.request({
    method: http.methods.POST,
    url : `/api/users/user/${companyId}/organization/csv/bulk`,
    data
  })
}
export default service;