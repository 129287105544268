import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './MasterNpwp.service'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, FontIcon } from 'react-md'
import errorService from '../../services/errorService'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'

const MasterNpwpView = ({
  modalStore,
  className       = '',
  showCommandbar  = true,
  history,
  match
}) => {

  const basePath = `master-npwp`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const baseId = 'mod-log-export'
  const [exportDialog, setExportDialog] = useState(false);
  const [pencarian, setPencarian] = useState([]) //Tambahan Uci

  useEffect(()=> {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      var x = JSON.parse(localStorage.getItem(`${baseId}-saved-search`))
      delete x['npwpProfile.contains']
      delete x['npwp.contains']
      setSearch(x)
      multiSearch(x)
    }
  }, [])

  const multiSearch = (values) =>{
    try{
      var formData = Object.assign({}, values)
      if(values){
        Object.keys(values).map((d)=>{
          var kolom = d.split('.')[0]
          var params = d.split('.')[1]
          if(kolom && params){
            if(params === 'in'){
              if(kolom === "rev" || kolom === 'year' || kolom === 'type' || kolom === 'status' || kolom === 'refFileId'){
                d = kolom+".equals"
                formData[d] = values[kolom+"."+params]
                delete formData[kolom+"."+params]
              }else{
                d = kolom+".contains"
                formData[d] = formData[kolom+"."+params]
                delete formData[kolom+"."+params]
              }
            }
          }
        })

        setPencarian(formData)
      }
    }catch(e){}
  }

  async function handlePemadananNpwp(item) {
    try {
        var proses = t.translate('words.pemadananNpwp')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var ids = []
        selected.map((d)=> ids.push(data[d].id))

        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
       
        try {
          let res = await service.pemadananNpwp(ids)

          if(res.data){
            iziToast.success({
              title : t.translate('words.pemadananNpwp'),
              message : res.data.message ? res.data.message : "Berhasil !"
            })
          }
          setPloading({ loading: false})
        } catch(e){
          setPloading({ loading: false})
          errorService(e)
        }
    } catch(e) {
        setPloading({ loading: false})
        errorService(e)
    }
  }

  async function pemadananNpwp(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))

    if(selected.length > 0){
      modalStore.showConfirm({
        title : t.translate('words.pemadananNpwp'),
        children : `Apakah anda yakin akan Tarik 16 Digit data terpilih (${selected.length}) ?`,
        onSubmit : (callback) => {
          handlePemadananNpwp(selected)
          callback()
        }
      })
    }else{
      iziToast.info({
        title : t.translate('words.pemadananNpwp'),
        message : `Tidak Ada data yang dipilih, silahkan periksa kembali!`,
      })
    }
  }

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <TableWrapper 
        useFilter={false}
        showActionColumn = {false}
        baseId={baseId}
        title={t.translate(`modules.masterNpwp.title`)}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try{
                var getPage;
                if(window.location.href.indexOf('open') != -1){
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  getPage = await service.getBySpt(params, spt.id)
                } else {
                  getPage = await service.get(params)
                }

                setData(getPage.data)
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                resolve()
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={pencarian}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setPencarian(newsearch)
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              var formData = Object.assign({}, values)

              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              formData.page = 0
              setPencarian(formData)
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.organizationNpwp`),
                key: 'organizationNpwp.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.input`),
                key: 'input.contains',
                type: 'text'
              },

              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.message`),
              //   key: 'message.contains',
              //   type: 'text'
              // },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.organizationNpwp'),
            searchable: true,
            sortable : true,
            key: 'organizationNpwp',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try{
                return item.organizationNpwp
              }catch(e){
                return "-"
              }
            }
          },
          {
            label: t.translate('words.Input'),
            searchable: true,
            sortable : true,
            key: 'input',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.input)
          },
          {
            label: t.translate('words.npwp16'),
            searchable: false,
            sortable: true,
            key: 'npwp16',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try {
                if (item.npwp16 == "Y") {
                  return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
                }  else {
                  return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
                } 
              } catch(e){
                return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
              }
            }
          },
          {
            label: t.translate('words.nitku'),
            searchable: false,
            sortable: true,
            key: 'nitku',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try {
                if (item.npwp16 == "Y") {
                  return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
                }  else {
                  return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
                } 
              } catch(e){
                return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
              }
            }
          },
          {
            label: t.translate('words.success'),
            searchable: true,
            sortable : true,
            key: 'success',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try {
                if (item.success) {
                  return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
                }  else {
                  return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
                } 
              } catch(e){
                return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
              }
            }
          },
          {
            label : t.translate('words.message'),
            searchable : true,
            sortable : true,
            key : 'message',
            type : TableWrapper.dataTypes.STRING,
            render : item => (item.message)
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable : true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable : true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => ((item.createdDate))
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.pemadananNpwp')}`, 'mdi mdi-sync-circle', (item) => { pemadananNpwp()}, true),
          // new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
        ]}
        itemActions={[
          // new TableWrapper.action(`${t.translate('words.edit')}`, 'mdi mdi-eye', (item) => history.push(`${basePath}/${item.id}`), true),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('modalStore')(observer(MasterNpwpView))
