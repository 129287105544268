import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import TableCUstom from './TableCustom'

const KodeObjekPajak = ({
  authStore,
  inSpt,
  match,
  modalStore,
  ...props
}) => {
	
  function formatNpwp(value) {
    if (typeof value === 'string') {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
  }

  return (
    <TableCUstom
      fit
      hAlign = {'left'}
      showMore={true}
      showDataLength={4}
      showIndex={false}
      title={props.title}
      showActionColumn={false}
      data={props.data}
      loading={false}
      columns={[
        {
          key: "kop",
          label: "Kode Objek Pajak",
          render: item =>item.kop,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "dpp",
          label: "DPP",
          render: item => {
            try{
              return item.jumlahDpp.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
            }catch(e){
              return "-"
            }
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "pph",
          label: "PPh",
          render: item =>{
            try{
              return item.jumlahPph.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
            }catch(e){
              return "-"
            }
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
      ]}
    />
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(KodeObjekPajak))