import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './Doss2.service'
import { inject, observer } from 'mobx-react'
import { Chip, ListItem, List, Button } from 'react-md'
import ErrorService from '../../services/errorService'
import errorService from '../../services/errorService'
import AppMode from '../../AppMode'

const Doss2HistoryDelete = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  modalStore,
  temporaryStore
}) => {

  const basePath = `doss2`
  const baseId = 'mod-bp-history-delete'
  let [search, setSearch] = useState({})
  
  var actions = [
    new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
      if(AppMode.onPremise){
        history.push(`/onpremise/${basePath}`)
      } else {
        history.push(`/product/company/${match.params.companyId}/${basePath}`)
      }
    }, true)
  ]
  if(window.location.href.indexOf('open') != -1){
    actions = [
      new TableWrapper.action(`${t.translate('words.kembali')}`, 'mdi mdi-arrow-left', (item) => { 
        if(AppMode.onPremise){
          history.push(`/onpremise/daftar-spt/open/${match.params.id}/${basePath}`)
        } else {
          history.push(`/product/company/${match.params.companyId}/daftar-spt/open/${match.params.id}/${basePath}`)
        }
      }, true)
    ]
  }

  return (
    <>
      <TableWrapper 
        showFilter={false}
        useFilter={false}
        baseId = {baseId}
        title = {t.translate('words.deleteHistory')}
        className = {className}
        defaultData ={[]}
        isShowFilter={false}
        showActionColumn = {false}
        setPage={search.page} 
        onFetchData = {(params) => {
          return (
              new Promise(async (resolve, reject) => {
                params = {...params,...search}
                try{  
                  let getPage = await service.historyDelete(match.params.id, params)
                  delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                  resolve(getPage)
                } catch(e){
                  resolve()
                  ErrorService(e)
                }
              })
          )
        }}
        sideHeader={
          <div></div>
        }
        columns = {[
            {
                label : t.translate('words.username'),
                searchable : false,
                sortable : true,
                key : 'userUsername',
                type : TableWrapper.dataTypes.STRING,
                render : (item) => (item.userUsername)
            },
            {
              label: t.translate('words.deletedDate'),
              searchable: true,
              sortable: true,
              key: 'createdDate',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdDate)
            },
            // {
            //   label: t.translate('words.lastModifiedDate'),
            //   searchable: true,
            //   sortable: true,
            //   key: 'lastModifiedDate',
            //   type: TableWrapper.dataTypes.STRING,
            //   render: item => (item.lastModifiedDate)
            // },
        ]}
        itemActions = {[]}
        showCommandbar={showCommandbar}
        showFilterePeriod={false}
        actions={actions}
      />
    </>
  )
}
export default inject('temporaryStore', 'modalStore')(observer(Doss2HistoryDelete))