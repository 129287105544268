import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'
import axios from 'axios'

let service = null
let serviceUrl = `/api/sso/core/setting/mail` //'/core/setting/mail'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/core/setting/mail');
  serviceUrl = '/api/eunifikasi/core/setting/mail'
} else {
  service = new CrudService('/core/setting/mail');
}

// service.uploadSertel = async(data)=> {
//   return axios({
//     method: http.methods.POST,
//     url: `/api/sso/cert/upload`,
//     data
//   })
// }

service.getByCompany = async(companyId, query)=> {
    if(AppMode.onPremise){
      return http.request({
        method: http.methods.GET,
        url: `${serviceUrl}/company/${companyId}`,
        query
      })
    } else {
      var companyId = btoa(companyId+"_secret!Email25b95b7d08-47fe-42a3-b427-f43b8f856a2d");
      return axios({
        method: http.methods.GET,
        url: `${serviceUrl}/company/${companyId}`
      })
    }
    
}

service.updateEmail = async(data)=> {
  if(AppMode.onPremise){
    return http.request({
      method: http.methods.PUT,
      url: `${serviceUrl}`,
      data
    })
  } else {
    data.key =  btoa(data.companyId+"_secret!Email25b95b7d08-47fe-42a3-b427-f43b8f856a2d");
    delete data.companyId
    return axios({
      method: http.methods.PUT,
      url: `${serviceUrl}`,
      data: data
    })
  }
}

service.getByCompanyId = async(companyId, query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/mailer/setting/company/${companyId}`,
    query
  })
}

service.updateByCompanyId = async(data)=> {
  return http.request({
    method: http.methods.PUT,
    url: `/mailer/setting`,
    data
  })
}

export default service;