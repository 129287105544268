import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpApprovalFlow.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button, Checkbox } from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'

const OpApprovalFlow = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-flow-approval'
  const basePath = 'flow-approval'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-serach`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  })

  async function initData() {}

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      let proccedThis = await service.bulkDelete(selectedData)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      try{
        var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
        if(checkboxRoot.checked){
          if(checkboxRoot.getAttribute('aria-checked')){
            checkboxRoot.click()
            checkboxRoot.click()
          } else {
            checkboxRoot.click()
          }
        }
        document.getElementsByClassName('mdi-reload')[0].click()
      }catch(e){}
      // bluebird.mapSeries(selectedData, async (di, di2)=> {
      //   setTimeout(async ()=> {
      //     try {
      //       let proccedThis = await service.bulkDelete(di.id)
      //     } catch(e){
      //       errors.push(await errorServiceGet(e, di, di2))
      //     }
      //     setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
      //     if((di2+1) == selectedData.length){
      //       setPloading({ loading: false, message: `` })
      //       if(errors.length == 0){
      //         iziToast.success({
      //           title: proses,
      //           message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      //         })
      //       } else {
      //         modalStore.showInfo({
      //           title: '',
      //           children: (
      //             <List>
      //               {errors.map((er)=> {
      //                 return <ListItem primaryText={''} secondaryText={er}></ListItem>
      //               })}
      //             </List>
      //           )  
      //         })
      //       }
      //       try{
      //         var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      //         if(checkboxRoot.checked){
      //           if(checkboxRoot.getAttribute('aria-checked')){
      //             checkboxRoot.click()
      //             checkboxRoot.click()
      //           } else {
      //             checkboxRoot.click()
      //           }
      //         }
      //         document.getElementsByClassName('mdi-reload')[0].click()
      //       }catch(e){}
      //     }
      //   }, 1000 * di2)
      // })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }
  
  return (
    <TableWrapper useFilter={false}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values['searchKey.equals'] = values['name.equals'] || values['code.equals'] || values['address.equals']
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.description`),
              key: 'description.equals',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key: 'name',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.name),
        },
        {
          label: t.translate('words.company'),
          searchable: true,
          sortable : true,
          key:'company',
          render: item => {
            if(item && item.company && item.company.nama){
              return item.company.nama
            } else {
              return "-"
            }
          },
        },
        {
          label: t.translate('words.default'),
          searchable: true,
          sortable : true,
          key:'default',
          // render: item => (item.useDefault + ""),
          render: item => {
            var checked = false;
            if(item.useDefault){
              checked = true
            } else {
              checked = false
            }
            return <Checkbox checked={checked} onChange={async (e, value)=> {
              item.useDefault = !item.useDefault;
              await service.setDefault(item);
              document.getElementsByClassName('mdi-reload')[0].click()
            }} />
          }
        },
        {
          label: t.translate('words.keterangan'),
          searchable: true,
          sortable : true,
          key:'keterangan',
          render: item => (item.keterangan),
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.createdDate
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.lastModifiedDate
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`/onpremise/${basePath}/new`), true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => history.push(`/onpremise/${basePath}/${item.id}`))
      ]}
      setPage={search.page} onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let getPage = null
              getPage = await service.get(params)
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpApprovalFlow))
