import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './DaftarSpt.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import Hint from '../../libs/react-mpk/components/Hint/Hint'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, Radio, FontIcon } from 'react-md'
import errorService from '../../services/errorService'
import ErrorService from '../../services/errorService'
import { TableCheckbox } from 'react-md'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import fileSaver from 'file-saver'
import SptService from '../DaftarSpt/DaftarSpt.service'
import UtilsService from './../../services/utilsService'
import Bluebird from 'bluebird'
import { autorun, toJS } from 'mobx';
import PermissionConstant from '../PermissionConstant'
import { isEmpty } from 'lodash'
import NotifikasiList from './NotifikasiList'
// import { SnackbarProvider } from 'notistack';

const DaftarSpt = ({
  modalStore,
  className = '',
  showCommandbar = true,
  history,
  match,
  authStore
}) => {
  //asdsad
  const basePath = `daftar-spt`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const [fetchParam, setFetchParam] = useState([]); //Tambahan Uci
  let [defaultSearch, setDefaultSearch] = useState({}) //Tambahan Uci
  const [showDialog, setShowDialog] = useState(false)
  const [dialogData, setDialogData] = useState({})
  const [organization, setOrganization] = useState([])
  const baseId = 'mod-daftar-spt'
  const [dialogCetakInduk, setDialogCetakInduk] = useState(false);
  const [dialogOpPdf, setDialogOpPdf] = useState(false);
  const [dialogOpPdfBpe, setDialogOpPdfBpe] = useState(false)
  const [dialogOpSptSummaryIndukPdf, setDialogOpSptSummaryIndukPdf] = useState(false)
  var [enableApproval, setEnableApproval] = useState(false)
  var [enableApprovalCore, setEnableApprovalCore] = useState(false)
  const [approvalState, setApprovalState] = useState({
    approvalDialog: false
  })
  const [approvalStateCore, setApprovalStateCore] = useState({
    approvalDialogCore: false
  })
  const [required, setRequired] = useState('required')
  const [exportDialog, setExportDialog] = useState(false);
  const [sptSummary, setDialogOpSptSummary] = useState(false);
  const [hasSummarySptBunifikasiNtte, setHasSummarySptBunifikasiNtte] = useState(false)
  const [hasSummaryRekapNtpnNtte, setHasSummaryRekapNtpnNtte] = useState(false)
  const [hasSummaryBp, setHasSummaryBp] = useState(false)
  const [hasSummarySptBunifikasiNtteCore, setHasSummarySptBunifikasiNtteCore] = useState(false)
  const [hasSummaryRekapNtpnNtteCore, setHasSummaryRekapNtpnNtteCore] = useState(false)
  const [hasSummaryBpCore, setHasSummaryBpCore] = useState(false)
  var insideSpt = false
  const [exportType, setExportType] = useState("0")
  const [notifikasi, setNotifikasi] = useState(false)

  //Tambahan Uci
  useEffect(async () => {
    try{
      var defaultsearch = {}
      if (localStorage.getItem(`default-search`)) {
        defaultsearch = JSON.parse((localStorage.getItem(`default-search`)))
        delete defaultsearch['npwp.contains']
        delete defaultsearch['npwpProfile.contains']
        setDefaultSearch(defaultsearch)
        console.log(search, defaultsearch)
        setSearch({...search, ...defaultsearch})
        setFetchParam({...search, ...defaultsearch})
      }else{
        setDefaultSearch({})
      }
    }catch(e){}
  }, [data])
    //Tambahan Uci

  useEffect(async () => {
    if (localStorage.getItem(`${baseId}-saved-search`)) {
      var x = JSON.parse(localStorage.getItem(`${baseId}-saved-search`))
      delete x['npwpProfile.contains']
      delete x['npwp.contains']
      setSearch(x)
      multiSearch(x)
    }
    if(AppMode.onPremise){
      setEnableApprovalCore(false)
    } else {
      setEnableApprovalCore(true)
    }
    try {
      if(AppMode.onPremise){
        let getAppInfo = await service.getAppInfo()

        if (getAppInfo) {
          let appInfoData = getAppInfo.data

          if (appInfoData) {
            if (appInfoData.enableTemplateRoleEunifikasi) {
              setEnableApproval(true)
            }

            if (appInfoData.summarySptBunifikasiNtte) {
              setHasSummarySptBunifikasiNtte(true)
            }

            if (appInfoData.summaryRekapNtpnNtte) {
              setHasSummaryRekapNtpnNtte(true)
            }

            if (appInfoData.summaryBp) {
              setHasSummaryBp(true)
            }
          }
        }
      } else {
        let getAppInfoCore = await service.getAppInfoCore()

        if (getAppInfoCore) {
          let appInfoDataCore = getAppInfoCore.data

          if (appInfoDataCore) {
            if (appInfoDataCore.summarySptBunifikasiNtte) {
              setHasSummarySptBunifikasiNtteCore(true)
            }

            if (appInfoDataCore.summaryRekapNtpnNtte) {
              setHasSummaryRekapNtpnNtteCore(true)
            }

            if (appInfoDataCore.summaryBp) {
              setHasSummaryBpCore(true)
            }
          }
        }
      }
    } catch (e) { }
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
  }, [])

  const multiSearch = (values) =>{
    try{
      var formData = Object.assign({}, values)
      if(values){
        Object.keys(values).map((d)=>{
          var kolom = d.split('.')[0]
          var params = d.split('.')[1]
          if(kolom && params){
            if(params === 'in'){
              if(kolom === "rev" || kolom === 'year'){
                d = kolom+".equals"
                formData[d] = values[kolom+"."+params]
                delete formData[kolom+"."+params]
              }else{
                d = kolom+".contains"
                formData[d] = formData[kolom+"."+params]
                delete formData[kolom+"."+params]
              }
            }
          }
        })
        setFetchParam(formData)
      }
    }catch(e){}
  }
 
  const getOrganizations = async (values)=> {
    try{
      var params = {
        page: 0,
        size: 500
      }
      if(isNaN(values)){
        params['field'] = 'name'
        params['query'] = values
      } else {
        params['field'] = 'npwp'
        params['query'] = values
      }
      let organization = await service.getOrganization(params)
      var getAll = organization.data
      var organizationsFilter = []
      try {
        getAll.map((og)=> {
          og.label = og.npwp + " - " + og.name
          organizationsFilter.push({
            value: og,
            label: og.label
          })
        })
      } catch(e){
        organization.data.map((d)=> {
          d.label = d.npwp + " - " + d.name
          organizationsFilter.push({
            value: d,
            label: d.label
          })
        })
      }
      setOrganization(organizationsFilter)
      return organizationsFilter
    }catch(e){
      
    }
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      ErrorService(e)
      return []
    }
  }

  async function submitExc(item) {
    try {
      var proses = t.translate('words.submitSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            var enabledApproval = false
            var hasPermission = []
            try { hasPermission = JSON.parse(localStorage.getItem("rpUrl")) } catch(e){}
            if(hasPermission == null || hasPermission == undefined) hasPermission = []
            if(hasPermission.length > 0){
              var filterA = hasPermission.filter((url)=> { return url == "GET::/approval" })
              var filterB = hasPermission.filter((url)=> { return url == "GET::/approval/approve" })
              var filterC = hasPermission.filter((url)=> { return url == "GET::/approval/cancel" })
              var filterD = hasPermission.filter((url)=> { return url == "GET::/approval/pengajuan" })
              if(filterA.length > 0 || filterB.length > 0 || filterC.length > 0 || filterD.length > 0){
                enabledApproval = true
              } else {
                enableApproval = false
              }
            }
            if(enabledApproval){
              let proccedThis = await service.submitSptApproval(di.id)
            } else {
              let proccedThis = await service.submitSpt(di.id)
            }
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function submit(item) {
    modalStore.showConfirm({
      title: t.translate('words.submitSPT'),
      children: t.translate('words.confirmSubmitSpt'),
      onSubmit: (callback1) => {
        callback1()
        modalStore.showConfirm({
          dialogstyle: {
            width: '100px',
          },
          title: t.translate('words.submitSPT'),
          children: <div className="dialogInduk mpk-hint"><Hint>{t.translate('words.induk24')}</Hint></div>,
          onSubmit: (callback) => {
            submitExc(item)
            callback()
          }
        })
      }
    })
  }

  async function postExc(item) {
    try {
      var proses = t.translate('words.postSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.postingSpt(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function post(item) {
    modalStore.showConfirm({
      title: t.translate('words.postSPT'),
      children: t.translate('words.confirmPostSpt'),
      onSubmit: (callback) => {
        postExc(item)
        callback()
      }
    })
  }

  async function checkExc(item) {
    try {
      var proses = t.translate('words.check')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.checkSpt({
              month: di.month,
              year: di.year,
              npwp: di.npwp
            })
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function check(item) {
    modalStore.showConfirm({
      title: t.translate('words.check'),
      children: t.translate('words.confirmCheck'),
      onSubmit: (callback) => {
        checkExc(item)
        callback()
      }
    })
  }

  async function cetakIndukExc(isAll) {
    try {
      var proses = t.translate('words.reportInduk')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      // console.log(selectedData)
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if (selected.length > 0) {
        let res = await service.indukOnly(selectedData, isAll)
        // let res = await service.downloadAll(selectedData)
        // window.open(res.data.url)
        setPloading({ loading: false, message: `` })
        setDialogCetakInduk(false)
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
        })
      }
    } catch (e) {
      // console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  // async function cetakIndukExc(item, isAll) {
  //   try {
  //     var proses = t.translate('words.reportInduk')
  //     var selected = JSON.parse(TableWrapper.getSelected(baseId))
  //     var selectedData = []
  //     selected.map((d) => selectedData.push(data[d]))
  //     setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
  //     if (selected.length > 0) {
  //       if (selected.length == 1) {
  //         let proccedThis = await service.report({
  //           sptNpwp: selectedData[0].npwp,
  //           sptYear: selectedData[0].year,
  //           sptMonth: selectedData[0].month,
  //           sptRev: selectedData[0].rev,
  //         })
  //         const blob = new Blob([proccedThis.data.data.url], { type: 'pdf' })
  //         fileSaver.saveAs(blob, `${proccedThis.data.data.filename}`)
  //         setPloading({ loading: false, message: `` })
  //         iziToast.success({
  //           title: proses,
  //           message: `${t.translate('words.proses')} ${proses} ${t.translate('words.berhasilDicetak')}`
  //         })
  //       } else {
  //         // let res = await service.downloadAll(selectedData)
  //         // window.open(res.data.url)
  //         setPloading({ loading: false, message: `` })
  //         iziToast.info({
  //           title: proses,
  //           message: `Fitur Bulk Belum tersedia silahkan pilih satu data`
  //         })
  //       }
  //     }
  //   } catch (e) {
  //     // console.log(e)
  //     setPloading({ loading: false, message: `` })
  //     errorService(e)
  //   }
  //   try {
  //     var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
  //     if (checkboxRoot.checked) {
  //       if (checkboxRoot.getAttribute('aria-checked')) {
  //         checkboxRoot.click()
  //         checkboxRoot.click()
  //       } else {
  //         checkboxRoot.click()
  //       }
  //     }
  //     document.getElementsByClassName('mdi-reload')[0].click()
  //   } catch (e) { }
  // }

  async function cetakInduk(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    // console.log(selected)
    if(isEmpty(selected)){
      history.push(`${basePath}/induk`)
    } else {
      setDialogCetakInduk(true)
    }
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.delete(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate('words.delete'),
      children: t.translate('words.hapusIni'),
      onSubmit: (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function pembetulanExec(item) {
    try {
      var proses = t.translate('words.pembetulan')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.pembetulan(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function pembetulan(item) {
    modalStore.showConfirm({
      title: t.translate('words.pembetulan'),
      children: t.translate('words.pembetulanIni'),
      onSubmit: (callback) => {
        pembetulanExec(item)
        callback()
      }
    })
  }

  async function archiveSPT(item) {
    modalStore.showConfirm({
      title: t.translate('words.archiveSPT'),
      children: t.translate('words.confirmArchiveSPT'),
      onSubmit: (callback) => {
        archiveSPTExec(item)
        callback()
      }
    })
  }

  async function archiveSPTExec(item) {
    try {
      var proses = t.translate('words.archiveSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      var validasiStatus = []
      selected.map((d) => {
        selectedData.push(data[d].id)
        validasiStatus.push(data[d].status)
      })
      var onlyFinish = validasiStatus.filter((d)=> {
        return d != 'FINISH'
      })
      if(onlyFinish.length == 0){
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        let res = await service.OpArchive(selectedData)
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
        })
        try {
          var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
          if (checkboxRoot.checked) {
            if (checkboxRoot.getAttribute('aria-checked')) {
              checkboxRoot.click()
              checkboxRoot.click()
            } else {
              checkboxRoot.click()
            }
          }
          document.getElementsByClassName('mdi-reload')[0].click()
        } catch (e) { }
      } else {
        iziToast.info({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesFailArship')}`
        })
        try {
          var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
          if (checkboxRoot.checked) {
            if (checkboxRoot.getAttribute('aria-checked')) {
              checkboxRoot.click()
              checkboxRoot.click()
            } else {
              checkboxRoot.click()
            }
          }
          document.getElementsByClassName('mdi-reload')[0].click()
        } catch (e) { }
      }
    } catch (e) {
      errorService(e)
    }
  }

  async function downloadXlsSearch(item) {
    if(AppMode.onPremise){
      history.push(`/onpremise/daftar-spt/export`)
    } else {
      history.push(`/product/company/${match.params.companyId}/daftar-spt/export`)
    }
  }

  async function downloadXls(item) {
    var exportType = localStorage.getItem("exportType")
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length > 0){
      if(AppMode.onPremise) {
        modalStore.showConfirm({
          title: "",
          children: (
              <div>
                <h1>{t.translate('words.downloadExportBp')}</h1>
                <p>{t.translate('words.confirmExportBp')}</p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "0") }} value="0" name="exportChoice" label={t.translate('words.export1')}></Radio>
                </p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "1")  }} value="1" name="exportChoice" label={t.translate('words.export2')}></Radio>
                </p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "2") }} value="2" name="exportChoice" label={t.translate('words.export3')}></Radio>
                </p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "4") }} value="4" name="exportChoice" label={t.translate('words.export5')}></Radio>
                </p>
                {hasSummarySptBunifikasiNtte &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "5") }} value="5" name="exportChoice" label={t.translate('words.export7')}></Radio>
                  </p>
                }
                {hasSummaryRekapNtpnNtte &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "6") }} value="6" name="exportChoice" label={t.translate('words.export8')}></Radio>
                  </p>
                }
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "7") }} value="7" name="exportChoice" label={t.translate('words.export9')}></Radio>
                </p>
                {hasSummaryBp &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "8") }} value="8" name="exportChoice" label={t.translate('words.export10')}></Radio>
                  </p>
                }
                {hasSummaryBp &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "9") }} value="9" name="exportChoice" label={t.translate('words.export11')}></Radio>
                  </p>
                }
                {/*<p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "5") }} value="5" name="exportChoice" label={t.translate('words.export7')}></Radio>
                </p> baris ini hanya muncul ketika mempunyai lisensi*/}
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "3") }} value="3" name="exportChoice" label={t.translate('words.export4')}></Radio>
                </p>
              </div>
          ),
          onSubmit: (callback) => {
            downloadXlsExec(item)
            callback()
          }
        })
      } else {
        modalStore.showConfirm({
          title: "",
          children: (
              <div>
                <h1>{t.translate('words.downloadExportBp')}</h1>
                <p>{t.translate('words.confirmExportBp')}</p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "0") }} value="0" name="exportChoice" label={t.translate('words.export1')}></Radio>
                </p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "1")  }} value="1" name="exportChoice" label={t.translate('words.export2')}></Radio>
                </p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "2") }} value="2" name="exportChoice" label={t.translate('words.export3')}></Radio>
                </p>
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "4") }} value="4" name="exportChoice" label={t.translate('words.export5')}></Radio>
                </p>
                {hasSummarySptBunifikasiNtteCore &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "5") }} value="5" name="exportChoice" label={t.translate('words.export7')}></Radio>
                  </p>
                }
                {hasSummaryRekapNtpnNtteCore &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "6") }} value="6" name="exportChoice" label={t.translate('words.export8')}></Radio>
                  </p>
                }
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "7") }} value="7" name="exportChoice" label={t.translate('words.export9')}></Radio>
                </p>
                {hasSummaryBpCore &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "8") }} value="8" name="exportChoice" label={t.translate('words.export10')}></Radio>
                  </p>
                }
                {hasSummaryBpCore &&
                  <p>
                    <Radio onChange={(value)=> { localStorage.setItem("exportType", "9") }} value="9" name="exportChoice" label={t.translate('words.export11')}></Radio>
                  </p>
                }
                <p>
                  <Radio onChange={(value)=> { localStorage.setItem("exportType", "3") }} value="3" name="exportChoice" label={t.translate('words.export4')}></Radio>
                </p>
              </div>
          ),
          onSubmit: (callback) => {
            downloadXlsExec(item)
            callback()
          }
        })
      }
    } else {
      downloadXlsSearch()
    }
  }

  async function downloadXlsExec() {
    var exportType = localStorage.getItem("exportType")
    if(exportType == "0" || exportType == "1" || exportType == "2" || exportType == "4" || exportType == "5" || exportType == "6" || exportType == "7" || exportType == "8" || exportType == "9"){
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      if(selected.length > 0){
        downloadXlsExec2() 
      } else {
        iziToast.info({
          title: t.translate("words.Info"),
          message: t.translate("words.pilihLebihDariSatu")
        })
      }
    } else {
      downloadXlsSearch()
    }
  }

  async function downloadXlsExec2() {
    var exportType = localStorage.getItem("exportType")
    try {
      var proses = t.translate('words.downloadExportBp')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if(exportType == "0"){
        if (selected.length > 0) {
          if(AppMode.onPremise){
            let res = await service.downloadAllXlsOP(selectedData)
          } else {
            // if (selected.length == 1) {
            //   let res = await service.donwloadIdXls(selectedData[0])
            //   window.open(res.data.url)
            // } else {
              let res = await service.downloadAllXls(selectedData)
            // }
          }
        }
      } else if(exportType == "1"){
        if(AppMode.onPremise){
          let res = await service.downloadAllXlsDetailOP(selectedData)
        } else {
          let res = await service.downloadAllXlsDetail(selectedData)
        }
      } else if(exportType == "4") {
        if(AppMode.onPremise){
          let res = await service.downloadAllXlsSummarySPTDetailOP(selectedData)
        } else {
          let res = await service.downloadAllXlsSummarySPTDetail(selectedData)
        }
      } else if(exportType == "5") {
        if(AppMode.onPremise){
          let res = await service.downloadSummaryVIIOP(selectedData)
        } else {
          let res = await service.downloadSummaryVIICore(selectedData)
        }
      } else if(exportType == "6") {
        if(AppMode.onPremise){
          let res = await service.downloadSummaryVIIIOP(selectedData)
        } else {
          let res = await service.downloadSummaryVIIICore(selectedData)
        }
      } else if(exportType == "7") {
        if(AppMode.onPremise){
          let res = await service.downloadSummaryIXOP(selectedData)
        } else {
          let res = await service.downloadSummaryIXCore(selectedData)
        }
      } else if(exportType == "8") {
        if(AppMode.onPremise){
          let res = await service.downloadSummaryXOP(selectedData)
        } else {
          let res = await service.downloadSummaryXCore(selectedData)
        }
      } else if(exportType == "9") {
        if(AppMode.onPremise){
          let res = await service.downloadSummaryXIOP(selectedData)
        } else {
          let res = await service.downloadSummaryXICore(selectedData)
        }
      } else {
        if(AppMode.onPremise){
          let res = await service.downloadAllXlsSummarySPTOP(selectedData)
        } else {
          let res = await service.downloadAllXlsSummarySPT(selectedData)
        }
      }
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
      })
    } catch (e) {
      // console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function download(item) {
    modalStore.showConfirm({
      title: t.translate('words.downloadSPT'),
      children: t.translate('words.confirmSptDownload'),
      onSubmit: (callback) => {
        downloadExc(item)
        callback()
      }
    })
  }

  async function downloadExc() {
    try {
      var proses = t.translate('words.downloadSPT')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if (selected.length > 0) {
        let res = await service.donwloadId(selectedData)
        // let res = await service.downloadAll(selectedData)
        // window.open(res.data.url)
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
        })
      }
    } catch (e) {
      // console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function downloadSearch(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setShowDialog(true)
  }

  // Tambahan uci
  async function saveIndukBulkExec(item) {
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    try {
      var proses = t.translate('words.saveIndukBulk')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=>{
        var item = data[d]
        if (item.status === "POSTING") {
          selectedData.push(item.id)
        }
      })
      
      if(selectedData.length > 0){
        let res = await service.saveIndukBulk(selectedData)

        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: res.data.message
        })
      }else{
        setPloading({ loading: false, message: `` })
        iziToast.warning({
          title: t.translate('words.warning'),
          message: t.translate('words.selectedPosting')
        })
      }
    } catch (e) {
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function saveIndukBulk(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length > 0){
      modalStore.showConfirm({
        title: t.translate('words.saveIndukBulk'),
        children: t.translate('words.areYouSure'),
        onSubmit: (callback) => {
          saveIndukBulkExec(item)
          callback()
        }
      })
    }else{
      iziToast.info({
        title: t.translate("words.Info"),
        message: t.translate("words.recheckSelectedData")
      })
    }
  }

  async function saveDoppDossBulkExec(item) {
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    try {
      var proses = t.translate('words.saveDoppDossBulk')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=>{
        var item = data[d]
        if (item.status !== "FINISH") {
          selectedData.push(item.id)
        }
      })

      if(selectedData.length > 0){
      
        let res = await service.saveDoppDossBulk(selectedData)

        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: res.data.message
        })
      }else{
        setPloading({ loading: false, message: `` })
        iziToast.warning({
          title: t.translate('words.warning'),
          message: t.translate('words.selectedFinish')
        })
      }
    } catch (e) {
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function saveDoppDossBulk(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length > 0){
      modalStore.showConfirm({
        title: t.translate('words.saveDoppDossBulk'),
        children: t.translate('words.areYouSure'),
        onSubmit: (callback) => {
          saveDoppDossBulkExec(item)
          callback()
        }
      })
    }else{
      iziToast.info({
        title: t.translate("words.Info"),
        message: t.translate("words.recheckSelectedData")
      })
    }
  }

  async function handlePemadananNpwp(item) {
    try {
        var proses = t.translate('words.pemadananNpwp')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var ids = []
        
        selected.map((d)=> ids.push(data[d].id))
      console.log(ids, 'cek')
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
       
        try {
          let res = await service.pemadananNpwp(ids)

          if(res.data){
            iziToast.success({
              title : proses,
              message : res.data.message ? res.data.message : "Berhasil !"
            })
          }
          setPloading({ loading: false})
        } catch(e){
          setPloading({ loading: false})
          errorService(e)
        }
    } catch(e) {
      setPloading({ loading: false})
      errorService(e)
    }
  }

  async function pemadananNpwp(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))

    if(selected.length > 0){
      modalStore.showConfirm({
        title : t.translate('words.pemadananNpwp'),
        children : `Apakah anda yakin akan Tarik 16 Digit data terpilih (${selected.length}) ?`,
        onSubmit : (callback) => {
          handlePemadananNpwp(selected)
          callback()
        }
      })
    }else{
      iziToast.info({
        title : t.translate('words.pemadananNpwp'),
        message : `Tidak Ada data yang dipilih, silahkan periksa kembali!`,
      })
    }
  }
  // Tambahan Uci

  var actions = [
    // new TableWrapper.action(`${t.translate('words.reportInduk')}`, 'mdi mdi-file-pdf', (item)=>{cetakInduk(item)}, true),
    // new TableWrapper.action(`${t.translate('words.downloadSPTSearch')}`, 'mdi mdi-folder-download', (item) => { downloadSearch(item)}, true),
    new TableWrapper.action(`${t.translate('words.saveDoppDossBulk')}`, 'mdi mdi-sim', (item) => { saveDoppDossBulk() }, true),
    new TableWrapper.action(`${t.translate('words.saveIndukBulk')}`, 'mdi mdi-sim-alert', (item) => { saveIndukBulk() }, true),
    new TableWrapper.action(`${t.translate('words.cekDoppDoss')}`, 'mdi mdi-playlist-check', (item) => { history.push(`${basePath}/cek`) }, true),
    new TableWrapper.action(`${t.translate('words.submitSPT')}`, 'mdi mdi-arrow-up-bold-box', (item) => { submit(item) }, true),
    new TableWrapper.action(`${t.translate('words.postSPT')}`, 'mdi mdi-flash', (item) => { post(item) }, true),
    // new TableWrapper.action(`${t.translate('words.checkSPT')}`, 'mdi mdi-check', (item) => { check(item)}, true),
    new TableWrapper.action(`${t.translate('words.revisiSpt')}`, 'mdi mdi-checkbox-marked-outline', (item) => { pembetulan(item) }, true),
    //new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
    new TableWrapper.action(`${t.translate('words.AddBulk')}`, 'mdi mdi-book-plus-multiple', () => history.push(`${basePath}/newBulk`), true),
    new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
    // new TableWrapper.action(`${t.translate('words.notifikasi')}`, 'mdi md/i-alert', () => history.push(`internal/table`), true),
  ]

  async function handlePengajuanApproval() {
    modalStore.showConfirm({
      title: t.translate('words.pengajuanApproval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handlePengajuanApprovalExec()
        callback()
      }
    })
  }

  async function handlePengajuanApprovalExec() {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => {
      var item = data[d]
      if (item.status == "POSTING") {
        selectedData.push(item)
      }
    })
    var stateId = selectedData.reduce((p, d) => {
      if (d.flowState) p.push(d.flowState.id);
      return p;
    }, []);
    var states = await service.getState(stateId);
    var stateMap = states.data.reduce((p, d) => {
      p[d.id] = d;
      return p;
    }, {})
    setApprovalState({
      approvalDialog: true,
      approvalList: selectedData,
      approvalType: "Pengajuan",
      stateMap: stateMap,
      stateList: states.data
    })
  }

  async function handlePengajuanApprovalCore() {
    modalStore.showConfirm({
      title: t.translate('words.pengajuanApproval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handlePengajuanApprovalCoreExec()
        callback()
      }
    })
  }

  async function handlePengajuanApprovalCoreExec() {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => {
      var item = data[d]
      if (item.status == "POSTING") {
        selectedData.push(item)
      }
    })
    var stateId = selectedData.reduce((p, d) => {
      if (d.flowState) p.push(d.flowState.id);
      return p;
    }, []);
    var states = await service.getStateCore(stateId);
    var stateMap = states.data.reduce((p, d) => {
      p[d.id] = d;
      return p;
    }, {})
    setApprovalStateCore({
      approvalDialogCore: true,
      approvalList: selectedData,
      approvalType: "Pengajuan",
      stateMap: stateMap,
      stateList: states.data
    })
  }

  async function handleApproval() {
    modalStore.showConfirm({
      title: t.translate('words.approval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handleApprovalExec()
        callback()
      }
    })
  }

  async function handleApprovalExec() {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => {
      var item = data[d]
      if (item.approvalStatus == "WAITING") {
        selectedData.push(item)
      }
    })
    var stateId = selectedData.reduce((p, d) => {
      if (d.flowState) p.push(d.flowState.id);
      return p;
    }, []);
    var states = await service.getState(stateId);
    var stateMap = states.data.reduce((p, d) => {
      p[d.id] = d;
      return p;
    }, {})
    setApprovalState({
      approvalDialog: true,
      approvalList: selectedData,
      approvalType: "Approval",
      stateMap: stateMap,
      stateList: states.data
    })
  }

  async function handleApprovalCore() {
    modalStore.showConfirm({
      title: t.translate('words.approval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handleApprovalCoreExec()
        callback()
      }
    })
  }

  async function handleApprovalCoreExec() {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => {
      var item = data[d]
      if (item.approvalStatus == "WAITING") {
        selectedData.push(item)
      }
    })
    var stateId = selectedData.reduce((p, d) => {
      if (d.flowStateId) p.push(d.flowStateId);
      return p;
    }, []);
    // console.log(stateId, "FLOWSTATE")
    var states = await service.getStateCore(stateId);
    // console.log(states, "STATELIST")
    var stateMap = states.data.reduce((p, d) => {
      p[d.id] = d;
      return p;
    }, {})
    setApprovalStateCore({
      approvalDialogCore: true,
      approvalList: selectedData,
      approvalType: "Approval",
      stateMap: stateMap,
      stateList: states.data
    })
  }

  async function handleCancelApproval() {
    modalStore.showConfirm({
      title: t.translate('words.cancelApproval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handleCancelApprovalExec()
        callback()
      }
    })
  }

  async function handleCancelApprovalExec() {
    var proses = t.translate('words.cancelApproval')
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => selectedData.push(data[d]))
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    var selainWaitingDanApproved = []
    var datas = []
    var dataExc = []
    if (selectedData.length > 0) {
      selectedData.map((d) => {
        if (d.approvalStatus == 'WAITING' || d.approvalStatus == 'APPROVED') {
          datas.push(d)
          dataExc.push({
            formType: "EUNIFIKASI",
            formId: d.id,
            organizationId: null
          })
        } else {
          selainWaitingDanApproved.push(d)
        }
      })
    }
    if (selainWaitingDanApproved.length > 0) {
      iziToast.warning({
        title: proses,
        message: `${t.translate('words.cancelApprovalHint')}`
      })
      setPloading({ loading: false, message: `` })
    } else {
      try {
        await service.cancelBulk(dataExc);
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
        })
      } catch (e) {
        setPloading({ loading: false, message: `` })
        ErrorService(e)
      }
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function handleCancelApprovalCore() {
    modalStore.showConfirm({
      title: t.translate('words.cancelApproval'),
      children: t.translate('words.prosesSpt'),
      onSubmit: (callback) => {
        handleCancelApprovalCoreExec()
        callback()
      }
    })
  }

  async function handleCancelApprovalCoreExec() {
    var proses = t.translate('words.cancelApproval')
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => selectedData.push(data[d]))
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    var selainWaitingDanApproved = []
    var datas = []
    var dataExc = []
    if (selectedData.length > 0) {
      selectedData.map((d) => {
        if (d.approvalStatus == 'WAITING' || d.approvalStatus == 'APPROVED') {
          datas.push(d)
          // dataExc.push({
          //   formType: "EUNIFIKASI",
          //   formId: d.id,
          //   organizationId: null
          // })
          dataExc.push(d.id)
        } else {
          selainWaitingDanApproved.push(d)
        }
      })
    }
    if (selainWaitingDanApproved.length > 0) {
      iziToast.warning({
        title: proses,
        message: `${t.translate('words.cancelApprovalHint')}`
      })
      setPloading({ loading: false, message: `` })
    } else {
      try {
        await service.cancelBulkCore(dataExc);
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
        })
      } catch (e) {
        setPloading({ loading: false, message: `` })
        ErrorService(e)
      }
    }
    try {
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if (checkboxRoot.checked) {
        if (checkboxRoot.getAttribute('aria-checked')) {
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch (e) { }
  }

  async function downloadOpPdf(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpPdf(true)
  }

  async function downloadOpPdfBpe(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpPdfBpe(true)
  }

  async function downloadOpPdfInduk(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpSptSummaryIndukPdf(true)
  }

  async function summarySPt(item) {
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
    setDialogOpSptSummary(true)
  }

  if (AppMode.onPremise){
    actions.unshift(new TableWrapper.action(`${t.translate('words.pdfBp')}`, 'mdi mdi-file-pdf', (item) => { downloadOpPdf(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.pdfBpe')}`, 'mdi mdi-file-pdf', (item) => { downloadOpPdfBpe(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.pdfSummaryInduk')}`, 'mdi mdi-file-pdf', (item) => { downloadOpPdfInduk(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.exportBpOp')}`, 'mdi mdi-file-excel', (item) => { downloadXls(item) }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.archive')}`, 'mdi mdi-archive', (item) => { archiveSPT(item) }, true))
    // actions.unshift(new TableWrapper.action(`${t.translate('words.pdfSummary')}`, 'mdi mdi-file-excel', (item) => { summarySPt(item) }, true))
  } else {
    actions.unshift(new TableWrapper.action(`${t.translate('words.reportInduk')}`, 'mdi mdi-file-pdf', (item)=>{cetakInduk(item)}, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.downloadExportBp')}`, 'mdi mdi-file-excel', (item) => { downloadXls(item) }, true))
    // .unshift(new TableWrapper.action(`${t.translate('words.downloadSPT')}`, 'mdi mdi-file-pdf', (item) => { download(item) }, true));
    // actions.unshift(new TableWrapper.action(`${t.translate('words.downloadSPT')}`, 'mdi mdi-file-pdf', (item) => { download(item) }, true));
  }

  if (AppMode.onPremise && enableApproval) {
    actions.unshift(new TableWrapper.action(`${t.translate('words.cancelApproval')}`, 'mdi mdi-close', () => { handleCancelApproval() }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.approval')}`, 'mdi mdi-format-list-checks', () => { handleApproval() }, true))
    actions.unshift(new TableWrapper.action(`${t.translate('words.pengajuanApproval')}`, 'mdi mdi-clipboard-check-outline', () => { handlePengajuanApproval() }, true))
  } else if(enableApprovalCore){
    try {
      var hasPermission = []
      try { hasPermission = JSON.parse(localStorage.getItem("rpUrl")) } catch(e){}
      if(hasPermission == null || hasPermission == undefined) hasPermission = []
      if(hasPermission.length > 0){
        var filter = hasPermission.filter((url)=> { return url == "GET::/approval/cancel" })
        if(filter.length > 0) actions.unshift(new TableWrapper.action(`${t.translate('words.cancelApproval')}`, 'mdi mdi-close', () => { handleCancelApprovalCore() }, true))
      }
      if(hasPermission.length > 0){
        var filter = hasPermission.filter((url)=> { return url == "GET::/approval/approve" })
        if(filter.length > 0) actions.unshift(new TableWrapper.action(`${t.translate('words.approval')}`, 'mdi mdi-format-list-checks', () => { handleApprovalCore() }, true))
      }
      if(hasPermission.length > 0){
        var filter = hasPermission.filter((url)=> { return url == "GET::/approval/pengajuan" })
        if(filter.length > 0) actions.unshift(new TableWrapper.action(`${t.translate('words.pengajuanApproval')}`, 'mdi mdi-clipboard-check-outline', () => { handlePengajuanApprovalCore() }, true))
      }
    } catch(e){}
  }


  if(actions) actions.unshift(new TableWrapper.action(`${t.translate('words.pemadananNpwp')}`, 'mdi mdi-sync-circle', (item) => { pemadananNpwp()}, true))

  var approvalTitle = t.translate('words.pengajuanApproval')
  var approvalDef = [
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.note`),
      key: 'globalNote',
      type: 'text',
      width: '100%'
    },
  ]
  if ((approvalState && approvalState.approvalType)) {
    if (approvalState.approvalType == "Approval") {
      approvalTitle = t.translate('words.approval')
      approvalDef = []
      if (approvalState.stateList && approvalState.stateList.length > 0) {
        approvalState.stateList.map((d) => {
          var options = []
          d.actions.map((e) => {
            options.push({
              label: e.name,
              value: e.name + "." + e.id,
              item: e
            })
          })
          approvalDef.push({
            inputType: inputTypes.SELECT,
            label: "Flow state " + d.name,
            key: 'globalAction.' + d.id,
            type: 'text',
            width: '100%',
            options: options
          })
        })
      }
      approvalDef.push({
        inputType: inputTypes.INPUT,
        label: t.translate(`words.note`),
        key: 'globalNote',
        type: 'text',
        width: '100%'
      })
    }
  }
  if ((approvalStateCore && approvalStateCore.approvalType)) {
    if (approvalStateCore.approvalType == "Approval") {
      approvalTitle = t.translate('words.approval')
      approvalDef = []
      if (approvalStateCore.stateList && approvalStateCore.stateList.length > 0) {
        approvalStateCore.stateList.map((d) => {
          var options = []
          d.actions.map((e) => {
            options.push({
              label: e.name,
              value: e.name + "." + e.id,
              item: e
            })
          })
          approvalDef.push({
            inputType: inputTypes.SELECT,
            label: "Flow state " + d.name,
            key: 'globalAction.' + d.id,
            type: 'text',
            width: '100%',
            options: options
          })
        })
      }
      approvalDef.push({
        inputType: inputTypes.INPUT,
        label: t.translate(`words.note`),
        key: 'globalNote',
        type: 'text',
        width: '100%'
      })
    }
  }

  var exportData = ()=> {
    setExportDialog(true)
  }

  var exportSelected = ()=> {

  }

  var exportAll = ()=> {

  }

  var columns = [
    {
      label: t.translate('words.npwp'),
      searchable: true,
      sortable: true,
      key: 'npwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.npwp)
    },
    {
      label: t.translate('words.npwp16'),
      searchable: false,
      sortable: true,
      key: 'npwp16',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        try {
          if (item.npwp16 == "Y") {
            return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
          }  else {
            return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
          } 
        } catch(e){
          return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
        }
      }
    },
    {
      label: t.translate('words.nitku'),
      searchable: false,
      sortable: true,
      key: 'nitku',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        try {
          if (item.npwp16 == "Y") {
            return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
          }  else {
            return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
          } 
        } catch(e){
          return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
        }
      }
    },
    {
      label: t.translate('words.name'),
      searchable: true,
      sortable: true,
      key: 'name',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.name)
    },
    {
      label: t.translate('words.month'),
      searchable: true,
      sortable: true,
      key: 'month',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (item.month)
    },
    {
      label: t.translate('words.year'),
      searchable: true,
      sortable: true,
      key: 'year',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (item.year)
    },
    {
      label: t.translate('words.rev'),
      searchable: true,
      sortable: true,
      key: 'rev',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (item.rev)
    },
    {
      label: t.translate('words.status'),
      searchable: true,
      sortable: true,
      key: 'status',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.status == "POSTING") {
          return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "COMPLETED") {
          return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FINISH") {
          return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FAILED") {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "DELETED") {
          return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else {
          return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        }
      }
    },
    {
      label : t.translate('words.approvalStatus'),
      searchable : true,
      sortable : true,
      key : 'approvalStatus',
      type : TableWrapper.dataTypes.STRING,
      render : item =>{
        if (item.approvalStatus !== null && item.approvalStatus !== undefined) {
          return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.approvalStatus + "").toUpperCase()}</Chip>
        } else {
          return "-"
        }
      }
    },
    {
      label : t.translate('words.approvalState'),
      searchable : true,
      sortable : true,
      key : 'currentState',
      type : TableWrapper.dataTypes.STRING,
      render: item => (item.currentState)
    },
    {
      label: t.translate('words.message'),
      searchable: true,
      sortable: true,
      key: 'message',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.message)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable: true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable: true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdDate)
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  // Hide kolom approval
  if (AppMode.onPremise && !enableApproval){
    delete columns[8] 
    delete columns[9]
  }else if(enableApprovalCore){
    var hasPermission = []
    try { hasPermission = JSON.parse(localStorage.getItem("rpUrl")) } catch(e){}
    if(hasPermission == null || hasPermission == undefined) hasPermission = []
    if(hasPermission.length > 0){
      var filterA = hasPermission.filter((url)=> { return url == "GET::/approval" })
      var filterB = hasPermission.filter((url)=> { return url == "GET::/approval/approve" })
      var filterC = hasPermission.filter((url)=> { return url == "GET::/approval/cancel" })
      var filterD = hasPermission.filter((url)=> { return url == "GET::/approval/pengajuan" })
      console.log(filterA.length, filterB.length, filterC.length, filterD.length)
      if(filterA.length == 0 || filterB.length == 0 || filterC.length == 0 || filterD.length == 0){
        delete columns[8] 
        delete columns[9]
      }
    }
  }

  // // if(!AppMode.onPremise){
  // //    delete columns[6]
  // // }
  // // Handle Permission

  // try {
  //   if(!AppMode.onPremise){
  //     var newActions = Object.assign([], actions)
  //     var filterHasAddPermission = hasPermission.filter((d)=> {
  //       return d == PermissionConstant["POST_SPT_Service_POST_SPT"]
  //     })
  //     if(filterHasAddPermission.length == 0){
  //       actions = actions.filter((d)=> {
  //         return d.iconClassName != "mdi mdi-plus"
  //       })
  //     }
  //   }
  // } catch(e){}
  // // Handle Permission

  // // Handle Permission Approval Pengajuan
  // try {
  //   if(!AppMode.onPremise){
  //     var newActions = Object.assign([], actions)
  //     var filterHasPengajuanApproval = hasPermission.filter((d)=> {
  //       return d == PermissionConstant["GET_Approval_Pengajuan"]
  //     })
  //     if(filterHasPengajuanApproval.length == 0){
  //       actions = actions.filter((d)=> {
  //         return d.iconClassName != "mdi mdi-clipboard-check-outline"
  //       })
  //     }
  //   }
  // } catch(e){}
  // // Handle Permission Approval Pengajuan

  // //Handle Permission Approval Cancel
  // try{
  //   if(!AppMode.onPremise){
  //     var filterHasCancelApproval = hasPermission.filter((d)=>{
  //       return d == PermissionConstant["GET_Approval_Cancel"]
  //     })
  //     if(filterHasCancelApproval.length == 0){
  //       actions = actions.filter((d)=>{
  //         return d.iconClassName != "mdi mdi-close"
  //       })
  //     }
  //   }
  // }catch(e){}
  // //Handle Permission Approval Cancel

  // //Handle Permission Approval Approve
  // try{
  //   if(!AppMode.onPremise){
  //     var filterHasApproveApproval = hasPermission.filter((d)=>{
  //       return d == PermissionConstant["GET_Approval_Approve"]
  //     })
  //     if(filterHasApproveApproval.length == 0){
  //       actions = actions.filter((d)=>{
  //         return d.iconClassName != "mdi mdi-format-list-checks"
  //       })
  //     }
  //   }
  // }catch(e){}
  // //Handle Permission Approval Approve

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <Dialog
        visible={dialogCetakInduk}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogCetakInduk(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.reportInduk')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '14px', width: "45%" }} onClick={(item) =>{
            var isAll = true
            cetakIndukExc(isAll)
          }}>{t.translate('words.indukOnly')}</Button>
          <Button themeType="outline" style={{ marginRight: '12px', width : '45%'}} onClick={(item) => {
            var isAll = false
            cetakIndukExc(isAll)
          }}>{t.translate('words.all')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={()=> exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={()=> exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={dialogOpPdf}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpPdf(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfBp')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-bp-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    var filter = organization.filter((org) => {
                      return org.label.indexOf(inputValues) != -1
                    })
                    return filter
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.merge`),
                key: 'merge',
                type: 'text',
                width: '33%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.mergeAll`),
                key: 'mergeAll',
                type: 'text',
                width: '33%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '33%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpPdf(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                try {
                  values.npwp.map((d) => {
                    if (d.value == "") { } else {
                      if (d.value && d.value.npwp) {
                        npwps.push(d.value.npwp)
                      }
                    }
                  })
                } catch(e){}
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opPdfBp(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfBp')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpPdf(false)
              } catch (e) {
                setDialogOpPdf(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfBp')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={dialogOpPdfBpe}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpPdfBpe(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfBpe')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-bpe-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    var filter = organization.filter((org) => {
                      return org.label.indexOf(inputValues) != -1
                    })
                    return filter
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpPdfBpe(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                try {
                  values.npwp.map((d) => {
                    if (d.value == "") { } else {
                      if (d.value && d.value.npwp) {
                        npwps.push(d.value.npwp)
                      }
                    }
                  })
                } catch(e){ }
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opPdfBpe(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfBpe')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpPdfBpe(false)
              } catch (e) {
                setDialogOpPdfBpe(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfBpe')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={sptSummary}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpSptSummary(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfSummary')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-spt-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    var filter = organization.filter((org) => {
                      return org.label.indexOf(inputValues) != -1
                    })
                    return filter
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpSptSummary(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                values.npwp.map((d) => {
                  if (d.value == "") { } else {
                    if (d.value && d.value.npwp) {
                      npwps.push(d.value.npwp)
                    }
                  }
                })
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opSptSummary(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfSummary')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpSptSummary(false)
              } catch (e) {
                setDialogOpSptSummary(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.pdfSummary')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={dialogOpSptSummaryIndukPdf}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogOpSptSummaryIndukPdf(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.pdfSummaryInduk')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-bpe-pdf`}
            defaultData={{
              merge: false,
              mergeAll: false,
              allNpwp: false
            }}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    var filter = organization.filter((org) => {
                      return org.label.indexOf(inputValues) != -1
                    })
                    return filter
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'masa1',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'masa2',
                validation: 'required',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'tahun1',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'tahun2',
                validation: 'required',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'allNpwp',
                type: 'text',
                width: '100%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setDialogOpSptSummaryIndukPdf(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                try {
                  if(values.allNpwp == false){
                    values.npwp.map((d) => {
                      if (d.value == "") { } else {
                        if (d.value && d.value.npwp) {
                          npwps.push(d.value.npwp)
                        }
                      }
                    })
                  }
                } catch(e){
                  // console.log(e)
                }
                try {
                  if (values.allNpwp) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) {
                  // console.log(e)
                 }
                // formData.npwp = npwps
                var orgNpwps = []
                var orgIds = []
                if (formData.npwp && formData.npwp.length > 0) {
                  formData.npwp.map((d) => {
                    orgNpwps.push(d.npwp)
                    orgIds.push(d.id)
                  })
                }
                formData.orgNpwps = orgNpwps
                formData.orgIds = orgIds
                delete formData.npwp
                let res = await service.opSptSummaryIndukPdf(formData)
                // window.open(res.data.url)
                callback(`${t.translate('words.pdfSummaryInduk')} ${t.translate('words.prosesDone')}${t.translate('words.cekLog')}`, false, false, false)
                setDialogOpSptSummaryIndukPdf(false)
              } catch (e) {
                setDialogOpSptSummaryIndukPdf(false)
                errorService(e)
                // console.log(e)
                iziToast.error({
                  message: `${t.translate('words.pdfSummaryInduk')} ${t.translate('words.prosesFail')}${t.translate('words.cekLog')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      
      <Dialog
        visible={approvalState.approvalDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setApprovalState({
            approvalDialog: false
          })
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{approvalTitle}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            style={{
              maxWidth: '100%'
            }}
            baseId={`${baseId}-pengajuan-approval`}
            defaultData={dialogData}
            hintShowIcon={true}
            definitions={approvalDef}
            onChange={(formData, key, value) => {
              try {
                if (key.split('.')[0] == 'globalAction') {
                  // console.log(value, "TELL ME")
                  formData.actionId = value.split('.')[1]
                }
              } catch (e) { }
              // noteOnChange(e, v) {
              //   if(this.typingObj) clearTimeout(this.typingObj);

              //   this.typingObj = setTimeout(async ()=> {
              //     var formState = {...this.props.formState}
              //     this.props.approvalList.forEach((d, i)=> {
              //       if(!formState[d.id]) formState[d.id] = {}
              //       formState[d.id].note = v;
              //     })

              //     this.props.initialize(formState);
              //   }, this.typingTimeout);
              // }

              // actionOnChange(e, v, pv, f) {
              //   var flowStateId = f.split(".")[1];

              //   var formState = {...this.props.formState}
              //   this.props.approvalList.forEach((d, i)=> {
              //     if(d.flowState === flowStateId) {
              //       if(!formState[d.id]) formState[d.id] = {}
              //       formState[d.id].actionId = v;
              //     }
              //   });

              //   this.props.initialize(formState);
              // }
            }}
            onSubmit={async (values, callback) => {
              try {
                var proses = t.translate('words.pengajuanApproval')
                var list = []
                approvalState.approvalList.map((d, i) => {
                  var v = values[d.id];
                  if (!v) v = {};
                  if (values.globalAction) {
                    v.actionId = values.globalAction[d.flowState.id]
                  }
                  // console.log(values, "TELL ME")
                  var data = {
                    ...v,
                    actionId: values.actionId,
                    note: values.globalNote,
                    formType: "EUNIFIKASI",
                    formId: d.id,
                    organizationId: null,
                    companyId: JSON.parse(localStorage.getItem('user')).company.id,
                    npwp: d.npwp,
                    month: d.month,
                    year: d.year,
                    pembetulan: d.rev,
                    status: d.status,
                  };
                  if (d.flowState) data.stateId = d.flowState.id
                  list.push(data);
                })
                if (approvalState.approvalType == 'Pengajuan') {
                  await service.pengajuanBulk(list)
                } else {
                  await service.actionBulk(list)
                }
                setApprovalState({
                  approvalDialog: false
                })
                callback("", false, false, false)
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } catch (e) {
                callback("", false, false, false)
                ErrorService(e)
                setApprovalState({
                  approvalDialog: false
                })
              }
              try {
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if (checkboxRoot.checked) {
                  if (checkboxRoot.getAttribute('aria-checked')) {
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (e) { }
            }}
          ></DataForm>
        </DialogContent>
      </Dialog>
      {/* Approval Core Dialog */}
      <Dialog
        visible={approvalStateCore.approvalDialogCore}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setApprovalStateCore({
            approvalDialogCore: false
          })
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{approvalTitle}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            style={{
              maxWidth: '100%'
            }}
            baseId={`${baseId}-pengajuan-approval`}
            defaultData={dialogData}
            hintShowIcon={true}
            definitions={approvalDef}
            onChange={(formData, key, value) => {
              try {
                if (key.split('.')[0] == 'globalAction') {
                  // console.log(value, "TELL ME")
                  formData.actionId = value.split('.')[1]
                }
              } catch (e) { }
              // noteOnChange(e, v) {
              //   if(this.typingObj) clearTimeout(this.typingObj);

              //   this.typingObj = setTimeout(async ()=> {
              //     var formState = {...this.props.formState}
              //     this.props.approvalList.forEach((d, i)=> {
              //       if(!formState[d.id]) formState[d.id] = {}
              //       formState[d.id].note = v;
              //     })

              //     this.props.initialize(formState);
              //   }, this.typingTimeout);
              // }

              // actionOnChange(e, v, pv, f) {
              //   var flowStateId = f.split(".")[1];

              //   var formState = {...this.props.formState}
              //   this.props.approvalList.forEach((d, i)=> {
              //     if(d.flowState === flowStateId) {
              //       if(!formState[d.id]) formState[d.id] = {}
              //       formState[d.id].actionId = v;
              //     }
              //   });

              //   this.props.initialize(formState);
              // }
            }}
            onSubmit={async (values, callback) => {
              try {
                var proses = t.translate('words.pengajuanApproval')
                var list = []
                approvalStateCore.approvalList.map((d, i) => {
                  var v = values[d.id];
                  if (!v) v = {};
                  if (values.globalAction) {
                    v.actionId = values.globalAction[d.flowState.id]
                  }
                  // console.log(values, "TELL ME")
                  var data = {
                    ...v,
                    actionId: values.actionId,
                    note: values.globalNote,
                    formType: "EUNIFIKASI",
                    formId: d.id,
                    organizationId: null,
                    companyId: match.params.companyId,
                    npwp: d.npwp,
                    month: d.month,
                    year: d.year,
                    rev: d.rev,
                    status: d.status,
                  };
                  if (d.flowState) data.stateId = d.flowState.id
                  list.push(data);
                })
                if (approvalStateCore.approvalType == 'Pengajuan') {
                  await service.pengajuanBulkCore(list)
                } else {
                  await service.actionBulkCore(list)
                }
                setApprovalStateCore({
                  approvalDialogCore: false
                })
                callback("", false, false, false)
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } catch (e) {
                callback("", false, false, false)
                ErrorService(e)
                setApprovalState({
                  approvalDialog: false
                })
              }
              try {
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if (checkboxRoot.checked) {
                  if (checkboxRoot.getAttribute('aria-checked')) {
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (e) { }
            }}
          ></DataForm>
        </DialogContent>
      </Dialog>
      {/* approval Dialog Core */}
      <Dialog
        visible={showDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.downloadSPTSearch')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={dialogData}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues) => {
                  if (AppMode.onPremise) {
                    if (inputValues.length % 2) {
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    var filter = organization.filter((org) => {
                      return org.label.indexOf(inputValues) != -1
                    })
                    return filter
                  }
                },
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'start_month',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'end_month',
                type: 'text',
                width: '50%',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.dariTahun`),
                key: 'start_year',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.keTahun`),
                key: 'end_year',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.lastRev`),
                key: 'last_rev',
                type: 'text',
                width: '50%'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allOrg`),
                key: 'allOrg',
                type: 'text',
                width: '50%'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setShowDialog(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              // console.log(values)
              try {
                var npwps = []
                var formData = Object.assign({}, values)
                values.npwp.map((d) => {
                  if (d.value == "") { } else {
                    if (d.value && d.value.npwp) {
                      npwps.push(d.value.npwp)
                    }
                  }
                })
                try {
                  if (formData.allOrg) {
                    let organizations = UtilsService.getOrganizations(toJS(authStore))
                    organizations.map((d) => {
                      npwps.push(d.npwp)
                    })
                  }
                } catch (e) { }
                formData.npwp = npwps
                let res = await service.downloadSearch(formData)
                window.open(res.data.url)
                callback(`${t.translate('words.downloadSPTSearch')} ${t.translate('words.prosesDone')}`, false, false, false)
                setShowDialog(false)
              } catch (e) {
                setShowDialog(false)
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.downloadSPTSearch')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.daftarSpt.title')}
        className={className}
        selectable={true}
        defaultData={[]}
        // commandbar={{
        //   // rightCorner:(
        //   //   // <SnackbarProvider>
        //   //     <NotifikasiList
        //   //     query={{
        //   //       page: 1,
        //   //       size: 2
        //   //     }}
        //   //   />
        //   //   // </SnackbarProvider>
            
        //   // )
        // }}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params) => {
          return (
            new Promise(async (resolve, reject) => {
              //tambahan uci
              var defaultsearch = {}
              if(localStorage.getItem('default-search')){
                defaultsearch = JSON.parse(localStorage.getItem('default-search'))
              }
              params = { ...params, ...search, ...defaultsearch }
              //tambahan uci
              // params = {...params,...search}
              try {
                delete params['npwpProfile.in']
                let getPage = await service.get(params)
                setData(getPage.data)
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                resolve()
                ErrorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={fetchParam}
            submitIconClassName='mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button id={'btn-delete-default'} style={{ marginRight: 15 }} onClick={() => {
                    setSearch({})
                    setFetchParam({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setFetchParam(newsearch)
                    //Tambahan Uci
                    if(localStorage.getItem('default-search')){
                      document.getElementById('delete-default-search').click()
                      localStorage.removeItem(`default-search`)
                      setDefaultSearch({})
                    }
                    //Tambahan Uci
                    setTimeout(() => {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback) => {
              var formData = Object.assign({}, values) //Tambahan Uci
              if (values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if (values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              if(AppMode.onPremise){
                if(values['npwpProfile.in'] && values['npwpProfile.in'].length > 0){
                  var stringIn = ""
                  values['npwpProfile.in'].map((d)=> {
                    stringIn += d.value + ","
                  })
                  values['npwp.in'] = stringIn
                } else {
                  delete values['sptNpwp.in']
                }
              } else {
                if(values['npwpProfile.in'] && values['npwpProfile.in'].length > 0){
                  var stringIn = ""
                  values['npwpProfile.in'].map((d)=> {
                    stringIn += d.value.npwp + ","
                  })
                  values['npwp.in'] = stringIn
                } else {
                  delete values['sptNpwp.in']
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['npwp.in'] = allNpwpString
                  delete values.allNpwps
                }

                if(formData['status.in']){
                  if(Object.values(formData['status.in']).length > 0){
                    var stringIn = ""
                    Object.values(formData['status.in']).map((d) => {
                      stringIn += d.value + ","
                    })
                    formData['status.in'] = values['status.in']
                    values['status.in'] = stringIn
                  }
                }

                if((values['name.contains']+"").indexOf(',') != -1){
                  values['name.in'] = values['name.contains']
                  delete values['name.contains']
                }

                if((values['year.equals']+"").indexOf(',') != -1){
                  values['year.in'] = values['year.equals']
                  delete values['year.equals']
                }

                if((values['rev.equals']+"").indexOf(',') != -1){
                  values['rev.in'] = values['rev.equals']
                  delete values['rev.equals']
                }
              }
              
              formData.page = 0
              setFetchParam(formData)
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(() => {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              // {
              //   render: (
              //     <div style={{ width: '100%' }}>
              //       {!AppMode.onPremise &&
              //         <Button onClick={()=> exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
              //       }
              //     </div>
              //   )
              // },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwps`),
                key: 'allNpwps',
                type: 'text',
                show : AppMode.onPremise ? false : true,
                disabled : defaultSearch&& defaultSearch['npwp.in'] ? true : false//tambahan uci
              },
              {
                inputType: inputTypes.TEXTAREA,
                label: t.translate(`words.npwp`),
                key: 'npwp.in',
                type: 'text',
                disabled : defaultSearch&& defaultSearch['npwp.in'] ? true : false,//tambahan uci
                show : defaultSearch&& defaultSearch['npwp.in'] ? true : false//tambahan uci
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwpProfile.in',
                labelKey: 'label', 
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
                show: !insideSpt && !defaultSearch['npwp.in'],
                isDisabled : defaultSearch&& defaultSearch['npwp.in'] ? true : false//tambahan uci
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.name`),
                key: 'name.contains',
                type: 'text'
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month1`),
                key: 'month.greaterOrEqualThan',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ],
                disabled : defaultSearch&& defaultSearch['month.greaterOrEqualThan'] ? true : false//tambahan uci
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month2`),
                key: 'month.lessOrEqualThan',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ],
                disabled : defaultSearch&& defaultSearch['month.lessOrEqualThan'] ? true : false//tambahan uci
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.year`),
                key: 'year.equals',
                type: 'text',
                disabled : defaultSearch&& defaultSearch['year.equals'] ? true : false//tambahan uci
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.rev`),
                key: 'rev.equals',
                type: 'text',
                disabled : defaultSearch&& defaultSearch['year.equals'] ? true : false//tambahan uci
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.status`),
                key: 'status.in',
                type: 'text',
                options: [
                  { label: 'CREATED', value: 'CREATED' },
                  { label: 'POSTING', value: 'POSTING' },
                  { label: 'COMPLETED', value: 'COMPLETED' },
                  { label: 'FINISH', value: 'FINISH' },
                  { label: 'FAILED', value: 'FAILED' },
                ],
                isMulti: true,
              },
              {
                render: (
                  <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
                  <FontIcon
                    iconClassName="mdi mdi-information"
                    style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
                    className="mpk-margin-S margin-right"
                  />
                  {t.translate('words.infoMultiple')}
                </div>
                )
              },
              // {
              //   inputType: inputTypes.SELECT,
              //   label: t.translate(`words.approvalStatus`),
              //   key: 'approvalStatus.equals',
              //   type: 'text',
              //   options: [
              //     { label: 'WAITING', value: 'WAITING' },
              //     { label: 'APPROVED', value: 'APPROVED' },
              //     { label: 'REJECTED', value: 'REJECTED' }
              //   ]
              // },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal1`),
                key: 'createdDate.greaterOrEqualThan',
                type: 'date'
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal2`),
                key: 'createdDate.lessOrEqualThan',
                type: 'date'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.createdBy`),
                key: 'createdBy.contains',
                type: 'text'
              },
            ]}
          />
        }
        columns={columns}
        // columns={[
        //   {
        //     label: t.translate('words.npwp'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'npwp',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.npwp)
        //   },
        //   {
        //     label: t.translate('words.name'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'name',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.name)
        //   },
        //   {
        //     label: t.translate('words.month'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'month',
        //     type: TableWrapper.dataTypes.NUMBER,
        //     render: item => (item.month)
        //   },
        //   {
        //     label: t.translate('words.year'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'year',
        //     type: TableWrapper.dataTypes.NUMBER,
        //     render: item => (item.year)
        //   },
        //   {
        //     label: t.translate('words.rev'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'rev',
        //     type: TableWrapper.dataTypes.NUMBER,
        //     render: item => (item.rev)
        //   },
        //   {
        //     label: t.translate('words.status'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'status',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => {
        //       if (item.status == "POSTING") {
        //         return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "COMPLETED") {
        //         return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "FINISH") {
        //         return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "FAILED") {
        //         return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else if (item.status == "DELETED") {
        //         return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       } else {
        //         return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        //       }
        //     }
        //   },
        //   // {
        //   //   label : t.translate('words.approvalStatus'),
        //   //   searchable : true,
        //   //   sortable : true,
        //   //   key : 'approvalStatus',
        //   //   type : TableWrapper.dataTypes.STRING,
        //   //   render : item =>{
        //   //     if (item.approvalStatus !== null) {
        //   //       return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.approvalStatus + "").toUpperCase()}</Chip>
        //   //     } else {
        //   //       return "-"
        //   //     }
        //   //   }
        //   // },
        //   {
        //     label: t.translate('words.message'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'message',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.message)
        //   },
        //   {
        //     label: t.translate('words.createdBy'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'createdBy',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.createdBy)
        //   },
        //   {
        //     label: t.translate('words.createdDate'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'createdDate',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.createdDate)
        //   },
        //   {
        //     label: t.translate('words.lastModifiedBy'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'lastModifiedBy',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.lastModifiedBy)
        //   },
        //   {
        //     label: t.translate('words.lastModifiedDate'),
        //     searchable: true,
        //     sortable: true,
        //     key: 'lastModifiedDate',
        //     type: TableWrapper.dataTypes.STRING,
        //     render: item => (item.lastModifiedDate)
        //   },
        // ]}
        actions={actions}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.BukaSpt')}`, 'mdi mdi-import', (item) => {
            window.bunifikasiopenspt = true
            localStorage.setItem('spt', JSON.stringify(item))
            if (AppMode.onPremise) {
              // window.location.href = `/#/onpremise/${basePath}/open/${item.id}/dashboard`
              history.push(`${basePath}/open/${item.id}/dashboard`)
            } else {
              // window.location.href = `${basePath}/open/${item.id}/dashboard`
              history.push(`${basePath}/open/${item.id}/dashboard`)
            }
            //history.push(`${basePath}/open/${item.id}/dashboard`)
          }, true),
          new TableWrapper.action(`${t.translate('words.historySpt')}`, 'mdi mdi-history', (item) => {
            localStorage.setItem('spt', JSON.stringify(item))
            history.push(`${basePath}/${item.id}/history`)
          })
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('modalStore', 'authStore')(observer(DaftarSpt))
