import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import axios from 'axios'

let service = new CrudService('/api/log-email/page');

service.findAll = async(query, companyId)=>{
  return http.request({
    method : http.methods.GET,
    url :`/api/log-email/page?companyId.equals=${companyId}`,
    query
  })
}

service.exportSummary = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/log-email/summary`,
    data,
    config: {
      responseType: 'blob',
    },
  })
}

service.resend = async(data)=> {
  return http.request({
    method : http.methods.POST,
    url : `/api/log-email/resend`,
    data
  })
}

export default service;