import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpPaymentNotification.service'
import { Chip, ListItem, List, Button, Dialog, DialogContent, DialogTitle} from 'react-md'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from '../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import servicePn from './../OpLogCetakNotification/OpLogCetakNotification.service'
import serviceBpn from './../OpLogCetakBpn/OpLogCetakBpn.service'
import serviceLap from './../OpLogCetakPn/OpLogCetakPn.service'
import { format } from '../../libs/react-mpk/services/number.service'
import LoadingOverlay from 'react-loading-overlay'

const OpPaymentNotification = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  temporaryStore,
  match
}) => {
  const [data, setData] = useState([])
  const [getStatus, setStatus] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const [dialog, setDialog] = useState(false)
  const [dialogPn, setDialogPn] = useState(false)
  const [dialogBpn, setDialogBpn] = useState(false)

  const baseId = 'mod-op-payment-notification'
  const [basePath, setBasePath] = useState('paymentNotification')

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, []) 

  async function initData() {
    try{
      const status = await service.coreStatus()
      setStatus(status.data)
    } catch{

    }
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.deleteBulk(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function printPnExc(item) {
    try {
      var proses = t.translate('words.printPn')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      if(selected.length == 0){
        selectedData = [1]
      } else {
        selected.map((d)=> selectedData.push(data[d].id))
      }
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            if(selected.length == 0){
              let proccedThis = await service.generatePnBulk(selectedData)
            } else {
              let proccedThis = await service.generatePn(selectedData)
            }
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.donePn')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
      errorService(e)
    }
    setDialogPn(false)
  }

  async function printPn(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length == 0){
      setDialogPn(true)
    } else {
      modalStore.showConfirm({
        title : t.translate('words.printPn'),
        children : t.translate('words.confirmPrint'),
        onSubmit : (callback) => {
          printPnExc(item)
          callback()
        }
      })
    }
  }

  async function printBpnExc(item) {
    try {
      var proses = t.translate('words.printBpn')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      if(selected.length == 0){
        selectedData = [1]
      } else {
        selected.map((d)=> selectedData.push(data[d].id))
      }
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            if(selected.length == 0){
              let proccedThis = await service.generateBpn(item)
            } else {
              let proccedThis = await service.generateBulk(selectedData)
            }
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneInquiry')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
      errorService(e)
    }
    setDialogBpn(false)
  }

  async function printBpn(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    if(selected.length == 0){
      setDialogBpn(true)
    } else {
      modalStore.showConfirm({
        title : t.translate('words.printBpn'),
        children : t.translate('words.confirmPrint'),
        onSubmit : (callback) => {
          printBpnExc(item)
          callback()
        }
      })
    }
  }

  async function laporanExc(item) {
    try {
      var proses = t.translate('words.printLapBpn')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = ["1"]
      // selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.generate(item)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneInquiry')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
      errorService(e)
    }
    setDialog(false)
  }

  async function laporan(item) {
    setDialog(true)
  }

  async function tarikNtpnExc(item) {
    try {
      var proses = t.translate('words.pullNtpn')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.pullNtpn(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneNtpn')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function tarikNtpn(item) {
    modalStore.showConfirm({
      title : t.translate('words.pullNtpn'),
      children : t.translate('words.confirmPullNtpn'),
      onSubmit : (callback) => {
        tarikNtpnExc(item)
        callback()
      }
    })
  }

  async function checkNtpnExc(item) {
    try {
      var proses = t.translate('words.checkNtpn')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.checkNtpn(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneNtpn')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function checkNtpn(item) {
    modalStore.showConfirm({
      title : t.translate('words.checkNtpn'),
      children : t.translate('words.confirmCheckNtpn'),
      onSubmit : (callback) => {
        checkNtpnExc(item)
        callback()
      }
    })
  }

  async function downloadByIdExc(item) {
    try {
      let res = await servicePn.generateBulk(item)
      const blob = new Blob([res.data], {type: res.type})
      fileSaver.saveAs(blob, 'download.csv');
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function downloadById(item) {
    modalStore.showConfirm({
      title : t.translate('words.download'),
      children : t.translate('words.confirmDownload'),
      onSubmit : (callback) => {
        downloadByIdExc(item)
        callback()
      }
    })
  }

  async function hapusExc(item) {
    try {
      let res = await service.delete(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function widgetFinetExc(item) {
    let paymentMethodName = item.paymentAccount.orderType.code
    let status = item.status
    let invoiceCode = item.orderNo

    if(paymentMethodName !== 'FINPAY'){
      iziToast.warning({
        title : "Widget FinPay",
        message : "Payment notification tidak di-order menggunakan FinPay."
      })
      return
    }

    if(status !== "Order Butuh Konfirmasi"){
      iziToast.warning({
        title : "Widget FinPay",
        message : 'Payment notification tidak dalam status "Order butuh konfirmasi"'
      })

      return
    }

    try {
      let res = await service.getFinnetWidget(invoiceCode)
      let widgetUrl = res.data.message
      window.open(widgetUrl, "_blank");
      // document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function widgetFinet(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        widgetFinetExc(item)
        callback()
      }
    })
  }

  return (
    <>
    <Dialog
      id="laporan-bpn"
      visible={dialog}
      disableFocusOnMount={true}
      onRequestClose={()=> setDialog(false)}
      style={{
        width: 800
      }}
    >
      <DialogContent>
        <div>
          <FormWrapper
            showCommandbar={false}
            hintMessage={t.translate(`words.printLapBpn`)}
            onSubmit={async (values, callback)=> {
              if(values.toDate) values.toDate = new Date(values.toDate).toISOString()
              if(values.fromDate) values.fromDate = new Date(values.fromDate).toISOString()
              await laporanExc(values)
            }}
            submitLabel={t.translate(`words.cetak`)}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> setDialog(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            definitions={[
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate('words.fromDate'),
                key: 'fromDate',
                type: 'date',
                width: '50%'
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate('words.toDate'),
                key: 'toDate',
                type: 'date',
                width: '50%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.npwp'),
                key: 'npwp',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisPajak'),
                key: 'jenisPajak',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisSetoran'),
                key: 'jenisSetoran',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan1'),
                key: 'bulan1',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan2'),
                key: 'bulan2',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.tahunPajak'),
                key: 'year',
                type: 'text',
              },
            ]}
          />
        </div>
      </DialogContent>
    </Dialog>
    <Dialog
      id="print-pn"
      visible={dialogPn}
      disableFocusOnMount={true}
      onRequestClose={()=> setDialogPn(false)}
      style={{
        width: 800
      }}
    >
      <DialogContent>
        <div>
          <FormWrapper
            showCommandbar={false}
            hintMessage={t.translate(`words.printPn`)}
            onSubmit={async (values, callback)=> {
              await printPnExc(values)
            }}
            submitLabel={t.translate(`words.cetak`)}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> setDialogPn(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.npwp'),
                key: 'npwp',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisPajak'),
                key: 'jenisPajak',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisSetoran'),
                key: 'jenisSetoran',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan1'),
                key: 'bulan1',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan2'),
                key: 'bulan2',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.tahunPajak'),
                key: 'year',
                type: 'text',
              },
            ]}
          />
        </div>
      </DialogContent>
    </Dialog>
    <Dialog
      id="print-bpn"
      visible={dialogBpn}
      disableFocusOnMount={true}
      onRequestClose={()=> setDialogBpn(false)}
      style={{
        width: 800
      }}
    >
      <DialogContent>
        <div>
          <FormWrapper
            showCommandbar={false}
            hintMessage={t.translate(`words.printBpn`)}
            onSubmit={async (values, callback)=> {
              await printBpnExc(values)
            }}
            submitLabel={t.translate(`words.cetak`)}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> setDialogBpn(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.npwp'),
                key: 'npwp',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisPajak'),
                key: 'jenisPajak',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.jenisSetoran'),
                key: 'jenisSetoran',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan1'),
                key: 'bulan1',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.bulan2'),
                key: 'bulan2',
                type: 'text',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate('words.tahunPajak'),
                key: 'year',
                type: 'text',
              },
              {
                inputType: inputTypes.RADIO,
                label: t.translate(`words.tidakGabung`),
                key: 'gabungNpwp',
                name: 'gabungNpwp',
                width: '50%',
                value: "Standar",
              },
              {
                inputType: inputTypes.RADIO,
                label: t.translate(`words.gabungBerdasarkanNpwp`),
                key: 'gabungNpwp',
                name: 'gabungNpwp',
                width: '50%',
                value: "Npwp",
              },
            ]}
          />
        </div>
      </DialogContent>
    </Dialog>
    <LoadingOverlay
      style={{
        position: 'inherit',
        background: 'red'
      }}
      active={ploading.loading}
      spinner
      text={ploading.message}
      >
    </LoadingOverlay>
    <TableWrapper useFilter={false}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      setPage={search.page} onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let getPage = null
              params.sortBy = params.sort.split(',')[0]
              getPage = await service.getPage(params)
              if(getPage.data == ""){
                getPage = []
                getPage.headers = {
                  'x-total-count': 0
                }
              }
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render : (
                <div>
                  {getStatus.success &&
                    <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.connected")}</Chip>
                  }
                  {!getStatus.success &&
                    <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.notConnected")}</Chip>
                  }
                </div>
              )
            },
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orderNo`),
              key: 'orderNo.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.status`),
              key: 'status.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.username`),
              key: 'additionalUsername.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.orderNo'),
          searchable: true,
          sortable : true,
          key:'orderNo',
          render: item => (item.orderNo)
        },
        {
          label: t.translate('words.total'),
          searchable: true,
          sortable : true,
          key:'amountTotal',
          render: item => (format(item.amountTotal))
        },
        {
          label: t.translate('words.bank'),
          searchable: true,
          sortable : true,
          key:'bank',
          render: item => (item.paymentAccount.orderType.name)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key:'status',
          render: item => (item.status)
        },
        {
          label: t.translate('words.orderActive'),
          searchable: true,
          sortable : true,
          key:'orderActive',
          render: item => (item.orderActive)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
        new TableWrapper.action(`${t.translate('words.pullNtpn')}`, 'mdi mdi-book-open', (item) =>{tarikNtpn(item)}, true),
        new TableWrapper.action(`${t.translate('words.checkNtpn')}`, 'mdi mdi-check-circle', (item) => {checkNtpn(item)}, true),
        new TableWrapper.action(`${t.translate('words.printPn')}`, 'mdi mdi-printer', (item) => {printPn(item)}, true),
        new TableWrapper.action(`${t.translate('words.printBpn')}`, 'mdi mdi-clipboard-text', (item)=>{printBpn(item)}, true),
        new TableWrapper.action(`${t.translate('words.printLapBpn')}`, 'mdi mdi-book', (item) => {laporan(item)}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item)=> {
          temporaryStore.setProperties("payment_notification", item)
          history.push(`/onpremise/paymentNotification/${item.id}`)
        }),
        new TableWrapper.action(`${t.translate('words.widgetFinet')}`, 'mdi mdi-send', (item)=> {widgetFinetExc(item)}),
        new TableWrapper.action(`${t.translate('words.cetak')}`, 'mdi mdi-printer', (item)=>{downloadById(item)}),
        new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', (item)=> {hapus(item)}),
      ]}
      showCommandbar={showCommandbar}
    />
  </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpPaymentNotification))
