import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/ssp'
let serviceObject = '/bp/object'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/ssp');
  serviceUrl = '/api/eunifikasi/ssp'
  serviceObject = '/api/eunifikasi/bp/object'
} else {
  service = new CrudService('/ssp');
}

service.exportCurrent = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/export`,
    data
  })
}
service.exportAll = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/export/search`,
    query
  })
}
service.getTaxObjectCode = async(type)=> {
    return http.request({
      method: http.methods.GET,
      url: `${serviceObject}?size=400`,
    })
}
service.sptSummary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/summary/pbk`,
    query
  })
}
service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/summary/pbk`,
    query
  })
}
service.getPage = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/spt/pbk`,
    query
  })
}
service.history = async(id)=> {
  return http.request({
    method: http.methods.GET,
    url : `${serviceUrl}/history/${id}`
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

export default service;