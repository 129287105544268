import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import axios from 'axios';

var env = {
    clientAuth: 'Basic YWJiZWI4OWEtMmMwMC00YzE3LWIyNzYtMDA4ODAwZWQyYmU3OjQzZDQyYWMxLTk3NWEtNGE4OC1iM2Q0LTE3NWYwMWQ5YTYzMw=='
}

let service = new CrudService('/');

service.login = async(data)=> {
  var res = await axios.post('/oauth/token', data, {
    headers: {
      Authorization: env.clientAuth,
      'content-type': 'application/x-www-form-urlencoded'
    }
  })
  // set header
  axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
  axios.defaults.withCredentials = true;
  localStorage.setItem('token', JSON.stringify(res.data));
  return res
}

service.checkMfa = async(username)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/check-mfa/${username}`
  })
}

service.logout = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/logout`,
    query
  })
}

service.me = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/account/me`,
    query
  })
}

service.status = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/management/health`,
    query
  })
}

service.status = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/management/info`,
    query
  })
}

service.resetPassword = async(query)=> {
  var res = await axios.post('/api/account/reset_password/init', query, {
    headers: {
      'content-type': 'application/json'
    }
  })
  return res
}

// http://192.168.3.111:8080/api/organization/user/access/all
// http://192.168.3.111:8080/management/info
// project :
// name: "Pengolahan Pajak Terpadu"
// version: "2.0.17"

// http://192.168.3.111:8080/api/logout
// http://192.168.3.111:8080/management/health


export default service;