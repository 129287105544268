import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Bp26.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import { FontIcon, Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle, TextField, Table, TableBody, TableRow, TableCell } from 'react-md'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from '../../services/errorService'
import ErrorService from './../../services/errorService'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import SptService from '../DaftarSpt/DaftarSpt.service'
import UtilsService from './../../services/utilsService'
import Bluebird from 'bluebird'

const Bp26 = ({
  className = '',
  showCommandbar = true,
  history,
  match,
  modalStore,
  temporaryStore,
  authStore
}) => {

  const basePath = `bp26`
  const [totalLnBruto, setTotalLnBruto] = useState(0)
  const [totalLnPph, setTotalLnPph] = useState(0)
  const [totalLnBruto2, setTotalLnBruto2] = useState(0)
  const [totalLnPph2, setTotalLnPph2] = useState(0)
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const baseId = 'mod-bp26'
  let [search, setSearch] = useState({})
  let [defaultSearch, setDefaultSearch] = useState({}) //default search
  const [selectable, isSelectable] = useState(true)
  const [statusSpt, setStatusSpt] = useState("")
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogEmailBulk, setShowDialogEmailBulk] = useState(false)
  const [showSyncDialog, setShowSyncDialog] = useState(false)
  const [taxobjectCode, setTaxObjectCode] = useState([])
  const [dialogData, setDialogData] = useState({})
  const [organization, setOrganization] = useState([])
  const [input, setInput] = useState({bulk : '', single : ''});
  const [tags, setTags] = useState([]);
  const [error, setError] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [exportDialog, setExportDialog] = useState(false);
  const [fetchParam, setFetchParam] = useState([])
  const [approvalStat,setApprovalStat] = useState("");
  const [getLogFiles, setLogFiles] = useState([]) //Tambahan Uci
  const [pencarian, setPencarian] = useState([])
  var [enableApproval, setEnableApproval] = useState(false)
  var type = 'csv'

  var options = [
    { label: 'COMPLETED', value: 'COMPLETED' },
    { label: 'FINISH', value: 'FINISH' },
    { label: 'DELETED', value: 'DELETED' },
  ]

  var dalamSpt = false
  try {
    if (window.location.href.indexOf('open') != -1) {
      dalamSpt = true
    } else {
      dalamSpt = false
    }
  } catch(e){}

  useEffect(async () => {
    if (dalamSpt) {
      setDefaultSearch({})
    } else {
      var defaultsearch = {}
      if (localStorage.getItem(`default-search`)) {
        defaultsearch = JSON.parse((localStorage.getItem(`default-search`)))
        delete defaultsearch['npwp.contains']
        delete defaultsearch['npwpProfile.contains']
        setDefaultSearch(defaultsearch)
        setSearch({...search, ...defaultsearch})
        setPencarian({...search, ...defaultsearch})
      }else{
        setDefaultSearch({})
      }
      //Tambahan Uci
    }
  }, [data])

  useEffect(async() => {
    if (dalamSpt) {
      if (localStorage.getItem(`${baseId}-saved-search`)) {
        var inSptSearch = JSON.parse((localStorage.getItem(`${baseId}-in-saved-search`)))
        delete inSptSearch['npwpProfile.contains']
        delete inSptSearch['npwp.contains']
        inSptSearch == null ? setSearch({}) : setSearch(inSptSearch)
        multiSearch(inSptSearch)
      }
      isSelectable(true)
      getLogFile() //Tambahan Uci
    } else {
      if (localStorage.getItem(`${baseId}-saved-search`)) {
        var x = JSON.parse(localStorage.getItem(`${baseId}-saved-search`))
        delete x['npwpProfile.contains']
        delete x['npwp.contains']
        setSearch(x)
        multiSearch(x)
      }
    }
    try {
      let getSpt = JSON.parse(localStorage.getItem('spt'))
      setStatusSpt(getSpt.status)
      setApprovalStat(getSpt.approvalStatus)
    } catch (e) { }

    if (AppMode.onPremise) {
      getOrganizationsOp()
      try{
        let getAppInfo = await service.getAppInfo()
        if (getAppInfo) {
          let appInfoData = getAppInfo.data

          if (appInfoData) {
            if (appInfoData.enableTemplateRoleEunifikasi) {
              setEnableApproval(true)
            }
          }
        }
      }catch(e){}
    } else {
      getOrganizations()
    }
    try {
      document.getElementById('mod-bp26-send-email-form-trigger').setAttribute('type', 'button')
    } catch (e) { }
  }, [])

  useEffect(()=>{
    try{
      if(localStorage.getItem(`${baseId}-email-${dalamSpt}`)){
        setInput({single : JSON.parse(localStorage.getItem(`${baseId}-email-${dalamSpt}`))})
      }
      if(localStorage.getItem(`${baseId}-emailbulk-${dalamSpt}`)){
        setInput({bulk : JSON.parse(localStorage.getItem(`${baseId}-emailbulk-${dalamSpt}`))})
      }
    }catch(e){}
  }, [])

  async function initData() {
    setTimeout(async ()=> {
      try {
        if (dalamSpt) {
          let spt = JSON.parse(localStorage.getItem('spt'))
          let totalLn = await service.sptSummary({
            sptNpwp: spt.npwp,
            sptYear: spt.year,
            sptMonth: spt.month,
            sptRev: spt.rev,
          })
          setDialogData({
            "npwp.contains": spt.npwp,
            "sptMonth.greaterOrEqualThan": spt.month + "",
            "sptMonth.lessOrEqualThan": spt.month + "",
            "sptYear.greaterOrEqualThan": spt.year,
            "sptYear.lessOrEqualThan": spt.year
          })
          setTotalLnBruto(totalLn.data.bruto)
          setTotalLnPph(totalLn.data.pph)
        } else {
          let totalLn = await service.summary()
          setTotalLnBruto(totalLn.data.bruto)
          setTotalLnPph(totalLn.data.pph)
        }
      } catch (e) {
        errorService(e)
      }
    }, 2000)
  }

  // Tambahan Uci
  const getLogFile = async () => {
    var params = {
      page: 0,
      size: 20
    }
    var spt = JSON.parse(localStorage.getItem('spt'))
    params.sptNpwp = spt.npwp
    params.sptYear = spt.year
    params.sptMonth = spt.month
    params.sptRev = spt.rev
    delete params['npwpProfile.in']

    try{
      let res = await service.getLogFiles(params)
      var optionLogFiles = []
      if(res.data){
        res.data.forEach(logFile => {
          optionLogFiles.push({
            value : logFile.id,
            label : logFile.originalFilename,
          })
        })
      }
      setLogFiles(optionLogFiles)
      // console.log(optionLogFiles, "apa")
    }catch(e){
      errorService(e)
    }
    // return organizationsFilter
  }
  // Tambahan Uci

  const multiSearch = (values) =>{
    try{
      var formData = Object.assign({}, values)
      if(values){
        Object.keys(values).map((d)=>{
          var kolom = d.split('.')[0]
          var params = d.split('.')[1]
          if(kolom && params){
            if(params === 'in'){
              if(kolom === "rev" || kolom === 'year' || kolom === 'status' || kolom === 'refFileId'){
                d = kolom+".equals"
                formData[d] = values[kolom+"."+params]
                delete formData[kolom+"."+params]
              }else{
                d = kolom+".contains"
                formData[d] = formData[kolom+"."+params]
                delete formData[kolom+"."+params]
              }
            }
          }
        })

        setPencarian(formData)
      }
    }catch(e){}
  }

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      errorService(e)
      return []
    }
  }

  async function reportBulkExc(item) {
    try {
      var proses = t.translate('words.report')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.postReport(selectedData)
            window.open(proccedThis.data.data.url)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == 1) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function reportExc(item) {
    try {
      var proses = t.translate('words.report')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getReport(di.id)
            window.open(proccedThis.data.data.url)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function report(item) {
    modalStore.showConfirm({
      title: t.translate('words.cetak'),
      children: t.translate('words.confirmReport'),
      onSubmit: (callback) => {
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d) => selectedData.push(data[d]))
        if (selectedData.length == 1) {
          reportExc(item)
        } else {
          reportBulkExc(item)
        }
        callback()
      }
    })
  }
  async function resendExc(item) {
    try {
      var proses = t.translate('words.kirimUlangEmail')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      if(selectedData.length > 0){
        bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.postResend(selectedData)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
      } else {
        setPloading({ loading: false, message: `` })
      }
  } catch(e) {
      errorService(e)
    }
  }

  async function resend(item) {
    modalStore.showConfirm({
      title: t.translate('words.kirimUlangEmail'),
      children: t.translate('words.confirmResendEmail'),
      onSubmit : (callback) => {
        resendExc(item)
        callback()
      }
    })
  }
  async function reuploadExc(item) {
    try {
      var proses = t.translate('words.reupload')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getReupload(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function reupload(item) {
    modalStore.showConfirm({
      title: t.translate('words.reupload'),
      children: t.translate('words.confirmReupload'),
      onSubmit: (callback) => {
        reuploadExc(item)
        callback()
      }
    })
  }

  async function requestExc(item) {
    try {
      var proses = t.translate('words.request')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getRequestReport(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function request(item) {
    modalStore.showConfirm({
      title: t.translate('words.request'),
      children: t.translate('words.confirmRequest'),
      onSubmit: (callback) => {
        requestExc(item)
        callback()
      }
    })
  }

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.delete(di.id)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title: t.translate('words.delete'),
      children: t.translate('words.hapusIni'),
      onSubmit: (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function email(item) {
    setShowDialog(true)
  }

  async function getTaxObjectCode(type) {
    let res = await service.getTaxObjectCode(type)
    var taxobjectCode = []
    res.data.map((d) => {
      d.label = `${d.code} - ${d.description}`
      taxobjectCode.push({ value: d.code, label: `${d.code} - ${d.description}`, rates: d.rates })
      setTaxObjectCode(taxobjectCode)
    })
    return taxobjectCode
  }

  async function emailBulk(item) {
    setShowDialogEmailBulk(true)

    await getTaxObjectCode("PPH26")
    if (AppMode.onPremise) {
      await getOrganizationsOp()
    } else {
      await getOrganizations()
    }
  }

  var columns = [
    {
      label: t.translate('words.status'),
      searchable: true,
      sortable: true,
      key: 'status',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.status == "UPLOADING") {
          return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "COMPLETED") {
          return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FINISH") {
          return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "FAILED") {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else if (item.status == "DELETED") {
          return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        } else {
          return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
        }
      }
    },
    {
      label: t.translate('words.no'),
      searchable: true,
      sortable: true,
      key: 'no',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.no) {
          return item.no
        } else {
          return "-"
        }
      }
    },
    {
      label: t.translate('words.revNo'),
      searchable: true,
      sortable: true,
      key: 'revNo',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if (item.revNo) {
          return item.revNo
        } else {
          return "0"
        }
      }
    },
    {
      label: t.translate('words.type'),
      searchable: true,
      sortable: true,
      key: 'type',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.type)
    },
    {
      label: t.translate('words.objectCode'),
      searchable: true,
      sortable: true,
      key: 'objectCode',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.objectCode)
    },
    {
      label: t.translate('menu.report'),
      searchable: true,
      sortable: true,
      key: 'report',
      type: TableWrapper.dataTypes.BOOLEAN,
      render: item => {
        if (item.report) {
          return <Chip>YA</Chip>
        } else {
          return <Chip>TIDAK</Chip>
        }
      }
    },
    {
      label: t.translate('words.npwpProfile'),
      searchable: true,
      sortable: true,
      key: 'sptNpwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptNpwp),
      show : dalamSpt ? false : true
    },
    // {
    //   label: t.translate('words.npwp16'),
    //   searchable: false,
    //   sortable: true,
    //   key: 'npwp16',
    //   type: TableWrapper.dataTypes.STRING,
    //   render: item => {
    //     try {
    //       if (item.npwp16 == "Y") {
    //         return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
    //       }  else {
    //         return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
    //       } 
    //     } catch(e){
    //       return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
    //     }
    //   }
    // },
    // {
    //   label: t.translate('words.nitku'),
    //   searchable: false,
    //   sortable: true,
    //   key: 'nitku',
    //   type: TableWrapper.dataTypes.STRING,
    //   render: item => {
    //     try {
    //       if (item.npwp16 == "Y") {
    //         return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
    //       }  else {
    //         return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
    //       } 
    //     } catch(e){
    //       return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
    //     }
    //   }
    // },
    {
      label: t.translate('words.name'),
      searchable: true,
      sortable: true,
      key: 'name',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.name)
    },
    {
      label: t.translate('words.identity'),
      searchable: true,
      sortable: true,
      key: 'identity',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.identity)
    },
    {
      label: t.translate('words.email'),
      searchable: true,
      sortable: true,
      key: 'email',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.email)
    },
    {
      label: t.translate('words.reference'),
      searchable: true,
      sortable: true,
      key: 'reference',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.reference)
    },
    {
      label: t.translate('words.bruto'),
      searchable: true,
      sortable: true,
      key: 'bruto',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (format(item.bruto))
    },
    {
      label: t.translate('words.rates'),
      searchable: true,
      sortable: true,
      key: 'rates',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (format(item.rates))
    },
    {
      label: t.translate('words.pph'),
      searchable: true,
      sortable: true,
      key: 'pph',
      type: TableWrapper.dataTypes.NUMBER,
      render: item => (format(item.pph))
    },
    {
      label: t.translate('words.date'),
      searchable: true,
      sortable: true,
      key: 'date',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.date)
    },
    {
      label: t.translate('words.sptMonth'),
      searchable: true,
      sortable: true,
      key: 'sptMonth',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptMonth),
      show : dalamSpt ? false : true
    },
    {
      label: t.translate('words.sptYear'),
      searchable: true,
      sortable: true,
      key: 'sptYear',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptYear),
      show : dalamSpt ? false : true
    },
    {
      label: t.translate('words.revisiSpt'),
      searchable: true,
      sortable: true,
      key: 'sptRev',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptRev),
      show : dalamSpt ? false : true
    },
    {
      label: t.translate('words.message'),
      searchable: true,
      sortable: true,
      key: 'message',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.message)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable: true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable: true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdDate)
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable: true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  if (dalamSpt) {
    // delete columns[5]
    // delete columns[6]
    // delete columns[7]
    var columns = [
      {
        label: t.translate('words.status'),
        searchable: true,
        sortable: true,
        key: 'status',
        type: TableWrapper.dataTypes.STRING,
        render: item => {
          if (item.status == "UPLOADING") {
            return <Chip style={{ background: "#FFC108", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
          } else if (item.status == "COMPLETED") {
            return <Chip style={{ background: "#2096F3", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
          } else if (item.status == "FINISH") {
            return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
          } else if (item.status == "FAILED") {
            return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
          } else if (item.status == "DELETED") {
            return <Chip style={{ background: "grey", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
          } else {
            return <Chip style={{ background: "black", color: 'white' }}>{(item.status + "").toUpperCase()}</Chip>
          }
        }
      },
      {
        label: t.translate('words.no'),
        searchable: true,
        sortable: true,
        key: 'no',
        type: TableWrapper.dataTypes.STRING,
        render: item => {
          if (item.no) {
            return item.no
          } else {
            return "-"
          }
        }
      },
      {
        label: t.translate('words.revNo'),
        searchable: true,
        sortable: true,
        key: 'revNo',
        type: TableWrapper.dataTypes.STRING,
        render: item => {
          if (item.revNo) {
            return item.revNo
          } else {
            return "0"
          }
        }
      },
      {
        label: t.translate('words.type'),
        searchable: true,
        sortable: true,
        key: 'type',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.type)
      },
      {
        label: t.translate('words.objectCode'),
        searchable: true,
        sortable: true,
        key: 'objectCode',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.objectCode)
      },
      {
        label: t.translate('menu.report'),
        searchable: true,
        sortable: true,
        key: 'report',
        type: TableWrapper.dataTypes.BOOLEAN,
        render: item => {
          if (item.report) {
            return <Chip>YA</Chip>
          } else {
            return <Chip>TIDAK</Chip>
          }
        }
      },
      {
        label: t.translate('words.name'),
        searchable: true,
        sortable: true,
        key: 'name',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.name)
      },
      // {
      //   label: t.translate('words.npwp16'),
      //   searchable: false,
      //   sortable: true,
      //   key: 'npwp16',
      //   type: TableWrapper.dataTypes.STRING,
      //   render: item => {
      //     try {
      //       if (item.npwp16 == "Y") {
      //         return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
      //       }  else {
      //         return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
      //       } 
      //     } catch(e){
      //       return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
      //     }
      //   }
      // },
      // {
      //   label: t.translate('words.nitku'),
      //   searchable: false,
      //   sortable: true,
      //   key: 'nitku',
      //   type: TableWrapper.dataTypes.STRING,
      //   render: item => {
      //     try {
      //       if (item.npwp16 == "Y") {
      //         return <Chip style={{ background: "#8BC34A", color: 'white' }}>YA</Chip>
      //       }  else {
      //         return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
      //       } 
      //     } catch(e){
      //       return <Chip style={{ background: "grey", color: 'white' }}>TIDAK</Chip>
      //     }
      //   }
      // },
      {
        label: t.translate('words.identity'),
        searchable: true,
        sortable: true,
        key: 'identity',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.identity)
      },
      {
        label: t.translate('words.email'),
        searchable: true,
        sortable: true,
        key: 'email',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.email)
      },
      {
        label: t.translate('words.reference'),
        searchable: true,
        sortable: true,
        key: 'reference',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.reference)
      },
      {
        label: t.translate('words.bruto'),
        searchable: true,
        sortable: true,
        key: 'bruto',
        type: TableWrapper.dataTypes.NUMBER,
        render: item => (format(item.bruto))
      },
      {
        label: t.translate('words.rates'),
        searchable: true,
        sortable: true,
        key: 'rates',
        type: TableWrapper.dataTypes.NUMBER,
        render: item => (format(item.rates))
      },
      {
        label: t.translate('words.pph'),
        searchable: true,
        sortable: true,
        key: 'pph',
        type: TableWrapper.dataTypes.NUMBER,
        render: item => (format(item.pph))
      },
      {
        label: t.translate('words.date'),
        searchable: true,
        sortable: true,
        key: 'date',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.date)
      },
      {
        label: t.translate('words.message'),
        searchable: true,
        sortable: true,
        key: 'message',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.message)
      },
      {
        label: t.translate('words.createdBy'),
        searchable: true,
        sortable: true,
        key: 'createdBy',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.createdBy)
      },
      {
        label: t.translate('words.createdDate'),
        searchable: true,
        sortable: true,
        key: 'createdDate',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.createdDate)
      },
      {
        label: t.translate('words.lastModifiedBy'),
        searchable: true,
        sortable: true,
        key: 'lastModifiedBy',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.lastModifiedBy)
      },
      {
        label: t.translate('words.lastModifiedDate'),
        searchable: true,
        sortable: true,
        key: 'lastModifiedDate',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.lastModifiedDate)
      },
    ]
  }

  async function syncBtnExc(item) {
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    setTimeout(async() => {
      try {
        var spt = JSON.parse(localStorage.getItem('spt'))
        var proses = t.translate('words.syncBp')
        item.npwp = spt.npwp
        item.year = spt.year
        item.month = spt.month
        let proccedThis = await service.syncBp(item)
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
        })
      } catch (e) {
        setPloading({ loading: false, message: "" })
        errorService(e)
      }
    }, 1000);
  }

  async function syncBp(values, callback){
    callback()
    setShowSyncDialog(false)
    modalStore.showConfirm({
      title: t.translate('words.syncBp'),
      children: t.translate('words.syncConfirm'),
      onSubmit: (callback) => {
        syncBtnExc(values)
        callback()
      }
    })
  }

  async function syncBtnDialog(item) {
    setShowSyncDialog(true)
  }

  var insideSpt = false
  var actions = [
    // new TableWrapper.action(`${t.translate('words.syncBp')}`, 'mdi mdi-sync', () => syncBtnDialog(), true),
    new TableWrapper.action(`${t.translate('words.request')}`, 'mdi mdi-file', (item) => { request(item) }, true), //Tambahan Uci //UNI-2988
    new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => {
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      if(selected.length == 0){
        history.push(`${basePath}/bp26-bulk`)
      } else {
        report(item)
      }
    }, true),
    new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
    new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
  ]
  if (dalamSpt) {
    insideSpt = true
    actions = [
      new TableWrapper.action(`${t.translate('words.syncBp')}`, 'mdi mdi-sync', () => syncBtnDialog(), true),
      new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
      new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => {
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        if(selected.length == 0){
          history.push(`${basePath}/bp26-bulk`)
        } else {
          report(item)
        }
      }, true),
      /// new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => { report(item) }, true),
      new TableWrapper.action(`${t.translate('words.request')}`, 'mdi mdi-file', (item) => { request(item) }, true),
      // new TableWrapper.action(`${t.translate('words.reupload')}`, 'mdi mdi-file-upload', (item) => { reupload(item) }, true),
    ]
    var hasPermission = []
    try { hasPermission = JSON.parse(localStorage.getItem("rpUrl")) } catch(e){}
    if(hasPermission == null || hasPermission == undefined) hasPermission = []
    if(AppMode.onPremise){
      if(enableApproval){
        if (statusSpt !== 'FINISH' || approvalStat !== "APPROVED") {
          actions.push(
            // new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
            new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item) }, true),
            new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => {
              localStorage.removeItem('bpform')
              history.push(`${basePath}/new`)
            }, true),
          )
        }
      }else{
        if (statusSpt !== 'FINISH') {
          actions.push(
            // new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
            new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item) }, true),
            new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => {
              localStorage.removeItem('bpform')
              history.push(`${basePath}/new`)
            }, true),
          )
        }
      }
    }else{
      if(hasPermission.length > 0){
        var filter = hasPermission.filter((url)=> { return url == "GET::/approval" }) 
        if(filter.length > 0){
          if (statusSpt !== 'FINISH' || approvalStat !== "APPROVED") {
            actions.push(
              // new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
              new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item) }, true),
              new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => {
                localStorage.removeItem('bpform')
                history.push(`${basePath}/new`)
              }, true),
            )
          }
        }else{
          if (statusSpt !== 'FINISH') {
            actions.push(
              // new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
              new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item) }, true),
              new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => {
                localStorage.removeItem('bpform')
                history.push(`${basePath}/new`)
              }, true),
            )
          }
        }
      }
    }
  }
  // } else {
  //   actions.push(new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true))
  //   actions.push(
  //     new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => {
  //       var selected = JSON.parse(TableWrapper.getSelected(baseId))
  //       if(selected.length == 0){
  //         history.push(`${basePath}/bp26-bulk`)
  //       } else {
  //         report(item)
  //       }
  //     }, true),
  //   )
  //   // actions.push(new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => { report(item) }, true))
  // }

  if(!AppMode.onPremise){
    // actions.push(new TableWrapper.action(`${t.translate('words.kirimUlangEmail')}`, 'mdi mdi-email-send', (item) => { resend(item) }, true))
  }
  //if(AppMode.onPremise == false){
  // actions.push(new TableWrapper.action(`${t.translate('words.kirimEmailBulk')}`, 'mdi mdi-email-multiple', (item) => { emailBulk(item)}, true))
  actions.push(new TableWrapper.action(`${t.translate('words.kirimEmail')}`, 'mdi mdi-email', (item) => { email(item) }, true))
  actions.push(new TableWrapper.action(`${t.translate('words.kirimEmailBulk')}`, 'mdi mdi-email-multiple', (item) => { emailBulk(item) }, true))
  //}

  var itemActions = [
    new TableWrapper.action(`${t.translate('words.history')}`, 'mdi mdi-history', (item) => {
      localStorage.setItem('bp', JSON.stringify(item))
      history.push(`${basePath}/${item.id}/history`)
    }, true),
    new TableWrapper.action(`${t.translate('words.deleteHistory')}`, 'mdi mdi-delete-forever', (item) => {
      localStorage.setItem('bp', JSON.stringify(item))
      history.push(`${basePath}/${item.id}/delete-history`)
    }, true),
    
    // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true)
  ]
  if (dalamSpt) {
    itemActions = [
      new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => history.push(`${basePath}/${item.id}`), (item) => {
        if((item.status == "DELETED" || item.status == "CANCELED" || statusSpt == "FINISH") && approvalStat == "APPROVED"){
          return false
        } else {
          return true
        }
      }),
      new TableWrapper.action(`${t.translate('words.view')}`, 'mdi mdi-eye', (item) => history.push(`${basePath}/${item.id}`), (item) => {
        if((item.status == "DELETED" || item.status == "CANCELED" || statusSpt == "FINISH") && approvalStat == "APPROVED"){
          return true
        } else {
          return false
        }
      }),
      new TableWrapper.action(`${t.translate('words.history')}`, 'mdi mdi-history', (item) => {
        localStorage.setItem('bp', JSON.stringify(item))
        history.push(`${basePath}/${item.id}/history`)
      }, true),
      new TableWrapper.action(`${t.translate('words.deleteHistory')}`, 'mdi mdi-delete-forever', (item) => {
        localStorage.setItem('bp', JSON.stringify(item))
        history.push(`${basePath}/${item.id}/delete-history`)
      }, true),
      
      // new TableWrapper.action(`${t.translate('words.Report')}`, 'mdi mdi-file-pdf', (item) => {report(item)}, true),
      // new TableWrapper.action(`${t.translate('words.reupload')}`, 'mdi mdi-file-upload', (item) => {reupload(item)}, true),
      // new TableWrapper.action(`${t.translate('words.request')}`, 'mdi mdi-file', (item) => { request(item)}, true),
      // new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
    ]
  }

  var searchDef = [
    {
      render: (
        <div className="mpk-data-filter">
          <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
            <p>{t.translate(`words.pencarian`)}</p>
          </div>
        </div>
      )
    },
    // {
    //   render: (
    //     <div style={{ width: '100%' }}>
    //       {!AppMode.onPremise &&
    //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
    //       }
    //     </div>
    //   )
    // },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.allNpwps`),
      key: 'all',
      type: 'text',
      show : AppMode.onPremise ? false : true,
      disabled : defaultSearch&& defaultSearch['npwp.in'] ? true : false,//tambahan uci
    },
    {
      inputType: inputTypes.TEXTAREA,
      label: t.translate(`words.npwp`),
      key: 'npwp.in',
      type: 'text',
      disabled : defaultSearch&& defaultSearch['npwp.in'] ? true : false,//tambahan uci
      show : defaultSearch&& defaultSearch['npwp.in'] ? true : false//tambahan uci
    },
    {
      inputType: inputTypes.REACT_SELECT,
      label: t.translate(`words.npwp`),
      key: 'npwpProfile.in',
      labelKey: 'label',
      valueKey: 'label',
      data: organization,
      options: organization,
      async: true,
      defaultOptions: organization,
      isMulti: true,
      loadOptions: async (inputValues)=> {
        if(AppMode.onPremise){
          if(inputValues.length % 2){
            var options = await getOrganizationsOp(inputValues)
            return options
          } else {
            return organization
          }
        } else {
          if(inputValues.length % 2){
            var options = await getOrganizations(inputValues)
            return options
          } else {
            return organization
          }
        }
      },
      show: !insideSpt && !defaultSearch['npwp.in'], //Tambahan Uci
      isDisabled : defaultSearch&& defaultSearch['npwp.in'] ? true : false//tambahan uci
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month1`),
      key: 'month.greaterOrEqualThan',
      type: 'text',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ],
      disabled : defaultSearch&& defaultSearch['month.greaterOrEqualThan'] ? true : false//tambahan uci
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month2`),
      key: 'month.lessOrEqualThan',
      type: 'text',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ],
      disabled : defaultSearch&& defaultSearch['month.lessOrEqualThan'] ? true : false//tambahan uci
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.year`),
      key: 'year.equals',
      type: 'text',
      disabled : defaultSearch&& defaultSearch['year.equals'] ? true : false//tambahan uci
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.rev`),
      key: 'rev.equals',
      type: 'text',
      disabled : defaultSearch&& defaultSearch['year.equals'] ? true : false//tambahan uci
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.email`),
      key: 'email.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.name`),
      key: 'name.contains',
      type: 'text'
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.identity`),
      key: 'identity.contains',
      type: 'text'
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.reference`),
      key: 'reference.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.objectCode`),
      key: 'objectCode.contains',
      type: 'text'
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.no`),
      key: 'no.contains',
      type: 'text'
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
    {
      inputType: inputTypes.DATEPICKER,
      label: t.translate(`words.date`),
      key: 'date.equals',
      type: 'date'
    },
    {
      inputType: inputTypes.REACT_SELECT,
      label: t.translate(`words.status`),
      key: 'status.equals',
      type: 'text',
      labelKey: 'label',
      valueKey: 'label',
      options: [
        { label: 'CREATED', value: 'CREATED' },
        { label: 'POSTING', value: 'POSTING' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'FINISH', value: 'FINISH' },
        { label: 'FAILED', value: 'FAILED' },
        { label: 'DELETED', value: 'DELETED' },
      ],
      isMulti : true
    },
    {
      render: (
        <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
        <FontIcon
          iconClassName="mdi mdi-information"
          style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
          className="mpk-margin-S margin-right"
        />
        {t.translate('words.infoMultiple')}
      </div>
      )
    },
  ]

  if (dalamSpt) {
    searchDef = [
      {
        render: (
          <div className="mpk-data-filter">
            <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
              <p>{t.translate(`words.pencarian`)}</p>
            </div>
          </div>
        )
      },
      // {
      //   render: (
      //     <div style={{ width: '100%' }}>
      //       {!AppMode.onPremise &&
      //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
      //       }
      //     </div>
      //   )
      // },
      // Tambahan Uci
      {
        inputType: inputTypes.SELECT,
        label: t.translate(`words.fileImport`),
        key: 'refFileId.equals',
        type: 'text',
        options: getLogFiles
      },
      {
        render: (
          <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
          <FontIcon
            iconClassName="mdi mdi-information"
            style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
            className="mpk-margin-S margin-right"
          />
          {t.translate('words.infoMultiple')}
        </div>
        )
      },
      // End Tambahan Uci
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.email`),
        key: 'email.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.name`),
        key: 'name.contains',
        type: 'text'
      },
      {
        render: (
          <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
          <FontIcon
            iconClassName="mdi mdi-information"
            style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
            className="mpk-margin-S margin-right"
          />
          {t.translate('words.infoMultiple')}
        </div>
        )
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.identity`),
        key: 'identity.contains',
        type: 'text'
      },
      {
        render: (
          <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
          <FontIcon
            iconClassName="mdi mdi-information"
            style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
            className="mpk-margin-S margin-right"
          />
          {t.translate('words.infoMultiple')}
        </div>
        )
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.reference`),
        key: 'reference.contains',
        type: 'text'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.objectCode`),
        key: 'objectCode.contains',
        type: 'text'
      },
      {
        render: (
          <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
          <FontIcon
            iconClassName="mdi mdi-information"
            style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
            className="mpk-margin-S margin-right"
          />
          {t.translate('words.infoMultiple')}
        </div>
        )
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.no`),
        key: 'no.contains',
        type: 'text'
      },
      {
        render: (
          <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
          <FontIcon
            iconClassName="mdi mdi-information"
            style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
            className="mpk-margin-S margin-right"
          />
          {t.translate('words.infoMultiple')}
        </div>
        )
      },
      {
        inputType: inputTypes.DATEPICKER,
        label: t.translate(`words.date`),
        key: 'date.equals',
        type: 'date'
      },
      {
        inputType: inputTypes.REACT_SELECT,
        label: t.translate(`words.status`),
        key: 'status.equals',
        type: 'text',
        options: [
          { label: 'CREATED', value: 'CREATED' },
          { label: 'POSTING', value: 'POSTING' },
          { label: 'COMPLETED', value: 'COMPLETED' },
          { label: 'FINISH', value: 'FINISH' },
          { label: 'FAILED', value: 'FAILED' },
          { label: 'DELETED', value: 'DELETED' },
        ],
        isMulti : true
      },
      {
        render: (
          <div className="message mpk-font weight-B mpk-flex align-center" style={{ marginTop:'-24px',width: '100%', fontSize: 10, padding: '8px 0', marginBottom: '16px', color:'hsl(0, 0%, 50%)' }}>
          <FontIcon
            iconClassName="mdi mdi-information"
            style={{fontSize:10, color:'hsl(0, 0%, 50%)'}}
            className="mpk-margin-S margin-right"
          />
          {t.translate('words.infoMultiple')}
        </div>
        )
      },
    ]
  }

  var hintMessage = t.translate('words.belumDiPosting')
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if (dalamSpt) {
      var c = spt.postingDate
      if (c) {
        hintMessage = t.translate('words.tanggalPosting') + spt.postingDate
      }
    } else {
      hintMessage = ''
    }
  } catch (e) { }

  //Tag Email
  const onChange = (e, type) => {
    try{
      const { value } = e.target;
      if(type === "single"){
        setInput({single : value});
        localStorage.setItem(`${baseId}-email-${dalamSpt}`, JSON.stringify(value))
      }else{
        setInput({bulk : value});
        localStorage.setItem(`${baseId}-emailbulk-${dalamSpt}`, JSON.stringify(value))
      }
    }catch(e){}
  };

  //validasi email
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function isValid(email) {
    let error = null;

    if (isInList(email)) {
      error = `${email} Sudah diinputkan`;
    }

    if (!validateEmail(email)) {
      error = `${email} bukan alamat email yang valid`;
    }

    if (error) {
      setError(error)

      return false;
    }

    setError(null)
    return true;
  }


  const onKeyDown = (e, type) => {
    try{
      const { key } = e;
      const trimmedInput = type == 'single' ? input.single.trim() : input.bulk.trim();

      if (key === "Enter" || key == 'Tab') {
        e.preventDefault();
        if(trimmedInput.includes(",")){
          var emailKoma = trimmedInput.split(",")
            if(emailKoma){
              var approveEmail = []
              emailKoma.map((d)=>{
                if (d && this.isValid(d.trim())) {
                  approveEmail.push(d.trim())
                }
              })
              setTags(prevState => [...prevState, ...approveEmail]);
              if(type == "single"){setInput({single : ''});}else{setInput({bulk : ''});}
            }
        }else{
          if (isValid(trimmedInput) && trimmedInput) {
            setTags(prevState => [...prevState, trimmedInput]);
            if(type == "single"){setInput({single : ''});}else{setInput({bulk : ''});}
          }
        }
        // document.getElementById('mod-bp-send-email-form-trigger').setAttribute('type', 'button')
        // if(validateEmail(trimmedInput) && !isInList(trimmedInput)){
        //   setTags(prevState => [...prevState, trimmedInput]);
        //   setInput('');
        // }else{
        //   iziToast.error({
        //     icon: 'mdi mdi-information',
        //     message: t.translate('words.validateEmail')
        //   })
        //   return
        // }
      }
      setIsKeyReleased(false);
    }catch(e){}
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const deleteTag = (index) => {
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  const onPaste = (evt, type) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));
      setTags(prevState => [...prevState, ...toBeAdded]);
      if(type == "single"){setInput({single : ''});}else{setInput({bulk : ''});}
    }
  }

  function isInList(email) {
    return tags.includes(email);
  }

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = async (dataMod) => {
    setExportDialog(false)
    var exportData = []
    if(dataMod){
      exportData = dataMod
    } else {
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      exportData = selectedData
      if(selected.length == 0){
        exportData = data
      }
    }
    try {
      var proses = t.translate('words.exportData')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.exportCurrent(exportData, { category: type})
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      window.open(res.data.url)
      type = 'csv'
    } catch(e){
      type = 'csv'
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  var exportAll = async () => {
    setExportDialog(false)
    var exportAllExc = false
    var dataSelected = []
    try {
      var cQuery = JSON.parse(localStorage.getItem(`${baseId}-query`))
      var params = fetchParam; params.size = 200;
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      if(type == "pdf"){
        // console.log('arahin ke cetak search')
        history.push(`${basePath}/bp-bulk`)
        type = 'csv'
      }else{
        // INIT DATA
        if (dalamSpt) {
          let spt = JSON.parse(localStorage.getItem('spt'))
          params.sptNpwp = spt.npwp
          params.sptYear = spt.year
          params.sptMonth = spt.month
          params.sptRev = spt.rev
          params['npwp.equals'] = spt.npwp
          params['year.equals'] = spt.year
          params['month.equals'] = spt.month
          params['rev.equals'] = spt.rev
          let getPage = await service.getBySpt(params)
          delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
          dataSelected = getPage.data
          if(parseInt(getPage.headers['x-total-count']) > 100){
            exportAllExc = true
          } else {
            exportAllExc = false
          }
        } else {
          let getPage = await service.get(params)
          delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
          dataSelected = getPage.data
          if(parseInt(getPage.headers['x-total-count']) > 100){
            exportAllExc = true
          } else {
            exportAllExc = false
          }
        }
        // INIT DATA
        if(exportAllExc){
          var proses = t.translate('words.exportData')
          fetchParam.category = type
          var exportParams = Object.assign({}, params)
          try { delete exportParams.endDate } catch(e){}
          try { delete exportParams.startDate } catch(e){}
          try { delete exportParams.page } catch(e){}
          try { delete exportParams.size } catch(e){}
          try { delete exportParams.sort } catch(e){}
          try { delete exportParams.search } catch(e){}
          try { delete exportParams.sptNpwp } catch(e){}
          try { delete exportParams.sptYear } catch(e){}
          try { delete exportParams.sptMonth } catch(e){}
          try { delete exportParams.sptRev } catch(e){}
          let res = await service.exportAll(exportParams)
          setPloading({ loading: false, message: `` })
          iziToast.success({
            title: proses,
            message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
          })
          type = 'csv'
        } else {
          exportSelected(dataSelected)
        }
      }  
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }  

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
        <DataForm
            baseId={`${baseId}-search`}
            defaultData={[]}
            submitIconClassName=' '
            submitLabel={`${t.translate('words.exportDataAll')}`}
            onChange={(formData, key, val)=> {
              if(key == 'type'){
                type = val
              }
              // console.log(type)
            }}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button themeType="outline" theme="primary"  style={{ marginRight: '12px' }} onClick={() => {
                    exportSelected()
                  }}>{t.translate('words.exportDataSelected')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback) => {
              exportAll()
            }}
            definitions={[
              {
                inputType : inputTypes.SELECT,
                label : t.translate('words.type'),
                key : 'type',
                // validation: 'required',
                options: [
                  { label: 'CSV', value: 'csv' },
                  { label: 'PDF', value: 'pdf' },
                  { label: 'XLS', value: 'xls' },
                ]
              },
            ]}
          />
          {/* <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button> */}
        </DialogContent>
      </Dialog>
      <Dialog
        visible={showDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setTags([])
          setError(null)
          setShowDialog(false)
        }}
        style={{
          width: 800,
          // height: AppMode.onPremise ? 400 : 400
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.kirimEmail')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={{
              sendOneByOne: false
            }}
            hintShowIcon={true}
            hintMessage={t.translate('words.askEmail')}
            submitLabel={t.translate('words.kirim')}
            definitions={[
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.sendOneByOne`),
                key: 'sendOneByOne',
                width: '50%',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                render: (
                  <>
                    <TextField
                      value={input.single}
                      key='email'
                      style={{ width: '100%', height: '55px' }}
                      label="Email"
                      onKeyDown={(e)=>onKeyDown(e, 'single')}
                      onKeyUp={onKeyUp}
                      onChange={(e)=>onChange(e, 'single')}
                      onPaste={(e)=>onPaste(e, 'single')}
                      className={'tx' + (error && " has-error")}
                    />
                    {error && <p className="error">{error}</p>}
                  </>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              {
                render: (
                  <div className="tags">
                    {tags.map((tag, index) => (
                      <div className="tag">
                        {tag}
                        <button type='button' onClick={() => deleteTag(index)}>x</button>
                      </div>
                    ))}
                  </div>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => {
                    setTags([])
                    setError(null)
                    setShowDialog(false)}
                  }>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var selected = JSON.parse(TableWrapper.getSelected(baseId))
                var selectedData = []
                selected.map((d) => selectedData.push(data[d].id))
                if(input.single){
                  var stringIn = ""
                  let emails = input.single.split(",")
                  
                  if (tags && tags.length > 0) {
                    emails = [...tags, ...emails]
                  }
                  if(emails){
                    Object.values(emails).map((d) => {
                      stringIn += (d+"").trim() + ","
                    })
                    var fixEmails = stringIn.slice(0, -1)
                    values.email = fixEmails
                  }
                }else{
                  if (tags && tags.length > 0) {
                    var stringIn = ""
                    tags.map((d) => {
                      stringIn += d + ","
                    })
                    values.email = stringIn
                  }
                }
                // delete formData.email

                if(AppMode.onPremise){
                let res = await service.sendMail(selectedData, { customEmail: values.email, sendOneByOne: values.sendOneByOne }) 
                  if(res.status == 200) setTags([])
                } else {
                  let res = await service.sendMail(selectedData, { email: values.email, sendOneByOne: values.sendOneByOne })
                  if(res.status == 200) setTags([])
                }
                callback(`${t.translate('words.kirimEmail')} ${t.translate('words.prosesDone')}`, false, false, false)
                localStorage.removeItem(`${baseId}-email-${dalamSpt}`)
                setShowDialog(false)
              } catch (e) {
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.kirimEmail')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={showSyncDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowSyncDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.SyncBupot')}</DialogTitle>
        </DialogHeader>
        <DataForm
          baseId={`${baseId}-sync-dialog`}
          submitIconClassName=' '
          submitLabel={`${t.translate('words.sync')}`}
          defaultData={{
            noBupot: '',
            revNo: 0
          }}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.noBupotPungut`),
              key: 'noBupot',
              type: 'text',
              width: '100%',
              validation: 'required'
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.revNo`),
              type: 'number',
              key: 'revNo',
              type: 'text',
              width: '100%',
              validation: 'required'
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => {
                  setShowSyncDialog(false)
                }}>{t.translate('words.tutup')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback) => {
            syncBp(values, callback)
          }}
        >

        </DataForm>
      </Dialog>
      <Dialog
        visible={showDialogEmailBulk}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setError(null)
          setTags([])
          setShowDialogEmailBulk(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.kirimEmailBulk')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-send-email`}
            defaultData={{
              ...dialogData,
              allStatus: true
            }}
            hintShowIcon={true}
            hintMessage={t.translate('words.askEmail')}
            submitLabel={t.translate('words.kirim')}
            onChange={async (formData, key, value) => {
              if (key == 'type.equals') {
                await getTaxObjectCode(value)
                setDialogData(formData)
              }
            }}
            definitions={[
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.sendOneByOne`),
                key: 'sendOneByOne',
                width: '50%',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                render: (
                  <>
                    <TextField
                      value={input.bulk}
                      key='email'
                      style={{ width: '100%', height: '55px' }}
                      label="Email"
                      onKeyDown={(e)=>onKeyDown(e, 'bulk')}
                      onKeyUp={onKeyUp}
                      onChange={(e)=>onChange(e, 'bulk')}
                      onPaste={(e)=>onPaste(e, 'bulk')}
                      className={'tx ' + (error && " has-error")}
                    />
                    {error && <p className="error">{error}</p>}
                  </>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              {
                render: (
                  <div className="tags">
                    {tags.map((tag, index) => (
                      <div className="tag">
                        {tag}
                        <button type='button' onClick={() => deleteTag(index)}>x</button>
                      </div>
                    ))}
                  </div>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              {
                render: (
                  <h4 style={{ width: '100%' }}><b>{t.translate('words.cariBerdasarkanPencarian')}</b> </h4>
                ),
                show: () => {
                  return AppMode.onPremise ? true : true
                }
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.npwp`),
              //   key: 'npwp.contains',
              //   type: 'text',
              //   width: '100%',
              //   show: insideSpt
              // },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwp`),
                key: 'all',
                type: 'text',
                width: '100%',
                show: !insideSpt
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp.in',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
                show: !insideSpt
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.identity`),
                key: 'identity.contains',
                type: 'text',
                width: '100%'
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.typeBP`),
                key: 'type.equals',
                width: '100%',
                type: 'text',
                options: [
                  { label: "PPh Pasal 15", value: "PPH15" },
                  { label: "PPh Pasal 22", value: "PPH22" },
                  { label: "PPh Pasal 23", value: "PPH23" },
                  { label: "PPh Pasal 4a2", value: "PPH4-2" }
                ]
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.kodeObjekPajak`),
                key: 'objectCode.contains',
                type: 'text',
                width: '100%',
                options: taxobjectCode
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAwal`),
                key: 'month.greaterOrEqualThan',
                type: 'text',
                width: '25%',
                validation : 'required',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.masaAkhir`),
                key: 'month.lessOrEqualThan',
                type: 'text',
                width: '25%',
                validation : 'required',
                options: [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                  { label: '5', value: 5 },
                  { label: '6', value: 6 },
                  { label: '7', value: 7 },
                  { label: '8', value: 8 },
                  { label: '9', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.dariTahun`),
                key: 'year.greaterOrEqualThan',
                type: 'text',
                width: '25%',
                validation : 'required'
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.keTahun`),
                key: 'year.lessOrEqualThan',
                type: 'text',
                width: '25%',
                validation : 'required'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allStatus`),
                key: 'allStatus',
                type: 'text',
                width: '100%',
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.status`),
                key: 'status.in',
                type: 'text',
                width: '100%',
                isMulti: true,
                options: options
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() =>{ 
                    setTags([])
                    setError(null)
                    setShowDialogEmailBulk(false)
                  }}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var formData = Object.assign({}, values)
                formData['month.lessOrEqualThan'] = parseInt(formData['month.lessOrEqualThan'])
                formData['month.greaterOrEqualThan'] = parseInt(formData['month.greaterOrEqualThan'])
                if (formData['status.in'] && formData['status.in'].length > 0) {
                  var stringIn = ""
                  formData['status.in'].map((d) => {
                    stringIn += d.value + ","
                  })
                  formData['status.in'] = stringIn
                }
                if(values.allStatus){
                  var allStatusString = ""
                  options.map((d) => {
                    allStatusString += d.value + ","
                  })
                  formData['status.in'] = allStatusString
                  delete formData.allStatus
                }
                if (formData['npwp.in'] && formData['npwp.in'].length > 0) {
                  var stringIn = ""
                  formData['npwp.in'].map((d) => {
                    if (AppMode.onPremise) {
                      stringIn += d.npwp + ","
                      delete formData.allNpwp
                    } else {
                      stringIn += d.value.npwp + ","
                    }
                  })
                  formData['npwp.in'] = stringIn
                }
                if(values.allNpwp){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  formData['npwp.in'] = allNpwpString
                  if(AppMode.onPremise) delete formData['npwp.in']
                }
                if(input.bulk){
                  var stringIn = ""
                  let emails = input.bulk.split(",")
                  
                  if (tags && tags.length > 0) {
                    emails = [...tags, ...emails]
                  }
                  if(emails){
                    Object.values(emails).map((d) => {
                      stringIn += (d+"").trim() + ","
                    })
                    var fixEmails = stringIn.slice(0, -1)
                    values.email = fixEmails
                  }
                }else{
                  if (tags && tags.length > 0) {
                    var stringIn = ""
                    tags.map((d) => {
                      stringIn += d + ","
                    })
                    values.email = stringIn
                  }
                }
                delete formData.email
                if (AppMode.onPremise) {
                  formData['month.greaterOrEqualThan'] = formData['sptMonth.greaterOrEqualThan']
                  delete formData['sptMonth.greaterOrEqualThan']
                  formData['month.lessOrEqualThan'] = formData['sptMonth.lessOrEqualThan']
                  delete formData['sptMonth.lessOrEqualThan']
                  formData['year.greaterOrEqualThan'] = formData['sptYear.greaterOrEqualThan']
                  delete formData['sptYear.greaterOrEqualThan']
                  formData['year.lessOrEqualThan'] = formData['sptYear.lessOrEqualThan']
                  delete formData['sptYear.lessOrEqualThan']
                }
                setTags([])
                if (AppMode.onPremise) {
                  formData.customEmail = values.email
                  let res = await service.sendMailBulkOp(formData, values.email)
                  if(res.status == 200) setTags([])
                } else {
                  // formData.customEmail = values.email
                  let res = await service.sendMailBulk(formData, values.email)
                  if(res.status == 200) setTags([])
                }
                callback(`${t.translate('words.kirimEmailBulk')} ${t.translate('words.prosesDone')}`, false, false, false)
                localStorage.removeItem(`${baseId}-emailbulk-${dalamSpt}`)
                setShowDialogEmailBulk(false)
              } catch (e) {
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.kirimEmailBulk')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
       <TableWrapper useFilter={false}
        tableFooter={(
          <Table style={{ width: '100%' }} dense disableBorders>
            <TableBody>
              <TableRow>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpPph')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnPph2)}</b></TableCell>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpBruto')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnBruto2)}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpPph2')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnPph)}</b></TableCell>
                <TableCell style={{width: '150px'}}><b>{t.translate('words.totalBpBruto2')}</b></TableCell>
                <TableCell style={{width: '10px'}}>:</TableCell>
                <TableCell><b style={{ float: 'right' }}>{format(totalLnBruto)}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        hintMessage={hintMessage}
        baseId="mod-bp26"
        title={t.translate('modules.bp26.title')}
        className={className}
        selectable={selectable}
        //   hintMessage={t.translate('modules.bp26.hint.message')}
        //   hintIconClassName="mdi mdi-information"
        //   hintMore={t.translate('modules.bp26.hint.more')}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params) => {
          return (
            new Promise(async (resolve, reject) => {
              //tambahan uci
              var defaultsearch = {}
              if(!dalamSpt && localStorage.getItem('default-search')){
                defaultsearch = JSON.parse(localStorage.getItem('default-search'))
              }
              params = { ...params, ...search, ...defaultsearch }
              //tambahan uci
              // params = {...params,...search}
              try {
                if(AppMode.onPremise){
                  if(params['npwpProfile.in'] && params['npwpProfile.in'].length > 0){
                    var stringIn = ""
                    params['npwpProfile.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    params['npwp.in'] = stringIn
                  }  else {
                    delete params['npwp.in']
                  }
                } else {
                  if(params['npwpProfile.in'] && params['npwpProfile.in'].length > 0){
                    var stringIn = ""
                    params['npwpProfile.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    params['npwp.in'] = stringIn
                  }  else {
                    delete params['npwp.in']
                  }
                }
              } catch(e){}
              try {
                await initData()
                let getPage = null
                if (dalamSpt) {
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  params.sptNpwp = spt.npwp
                  params.sptYear = spt.year
                  params.sptMonth = spt.month
                  params.sptRev = spt.rev
                  delete params['npwpProfile.in']
                  getPage = await service.getBySpt(params)
                } else {
                  delete params['npwpProfile.in']
                  getPage = await service.get(params)
                }
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                try {
                  if(getPage.headers['x-summary'] == "true"){
                    setTotalLnBruto2(parseFloat(getPage.headers['x-bruto']))
                    setTotalLnPph2(parseFloat(getPage.headers['x-pph']))
                  } else {
                    setTotalLnBruto2(0)
                    setTotalLnPph2(0)
                  }
                } catch(e){
                  setTotalLnBruto2(0)
                  setTotalLnPph2(0)
                }
                setFetchParam(params)
                setData(getPage.data)
                resolve(getPage)
              } catch (e) {
                setFetchParam(params)
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={pencarian}
            submitIconClassName='mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            onChange={(formData, key, val)=> {
              setFetchParam(formData)
            }}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button id={'btn-delete-default'} style={{ marginRight: 15 }} onClick={() => {
                    setSearch({})
                    if(dalamSpt){
                      var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-in-saved-search`); localStorage.removeItem(`${baseId}-query`);
                      setPencarian(newsearch)
                    } else {
                      var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                      setPencarian(newsearch)
                      //Tambahan Uci
                      if(!dalamSpt && localStorage.getItem('default-search')){
                        document.getElementById('delete-default-search').click()
                        localStorage.removeItem(`default-search`)
                        setDefaultSearch({})
                      }
                      //Tambahan Uci
                    }
                    setTimeout(() => {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback) => {
              values.search = true
              var formData = Object.assign({}, values) //Tambahan Uci
              if (values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
              if (AppMode.onPremise) {
                if (values['npwpProfile.in'] && values['npwpProfile.in'].length > 0) {
                  var stringIn = ""
                  values['npwpProfile.in'].map((d) => {
                    stringIn += d.value + ","
                  })
                  values['npwp.in'] = stringIn
                } else {
                  delete values['npwp.in']
                }
              } else {
                if (values['npwpProfile.in'] && values['npwpProfile.in'].length > 0) {
                  var stringIn = ""
                  values['npwpProfile.in'].map((d) => {
                    stringIn += d.value.npwp + ","
                  })
                  values['npwp.in'] = stringIn
                } else {
                  delete values['npwp.in']
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['npwp.in'] = allNpwpString
                  delete values.allNpwps
                }
              }
              if(formData['status.equals']){
                if(Object.values(formData['status.equals']).length > 0){
                  var stringIn = ""
                  Object.values(formData['status.equals']).map((d) => {
                    stringIn += d.value + ","
                  })
                  formData['status.equals'] = values['status.equals']
                  values['status.in'] = stringIn
                  delete values['status.equals']
                }
              }

              if((values['name.contains']+"").indexOf(',') != -1){
                values['name.in'] = values['name.contains']
                delete values['name.contains']
              }

              if((values['year.equals']+"").indexOf(',') != -1){
                values['year.in'] = values['year.equals']
                delete values['year.equals']
              }

              if((values['rev.equals']+"").indexOf(',') != -1){
                values['rev.in'] = values['rev.equals']
                delete values['rev.equals']
              }

              if((values['objectCode.contains']+"").indexOf(',') != -1){
                values['objectCode.in'] = values['objectCode.contains']
                delete values['objectCode.contains']
              }

              if((values['identity.contains']+"").indexOf(',') != -1){
                values['identity.in'] = values['identity.contains']
                delete values['identity.contains']
              }

              if((values['no.contains']+"").indexOf(',') != -1){
                values['no.in'] = values['no.contains']
                delete values['no.contains']
              }

              if((values['refFileId.contains']+"").indexOf(',') != -1){
                values['refFileId.in'] = values['refFileId.contains']
                delete values['refFileId.contains']
              }
              
              if(dalamSpt){
                formData.page = 0
                setPencarian(formData)
                values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-in-saved-search`, JSON.stringify(values))
              } else {
                formData.page = 0
                setPencarian(formData)
                values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              }
              setTimeout(() => {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={searchDef}
          />
        }
        columns={columns}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(Bp26))