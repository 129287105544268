
import React from 'react'
import { FontIcon } from 'react-md'
import { Link } from '..'
import { inject, observer } from 'mobx-react'
import './CompanyCard.scss'

const CompanyCard = ({
  className = '',
  href      = '',
  thumbnail = false,
  value     = {},
  ...props
}) => {
  return (      
    <Link 
      to={href}
      className={thumbnail ? 'mpk-full full-width' : ''}
      onClick={ e => {
        e.preventDefault()
        e.stopPropagation()
        try {
          // Tambahan Uci
          localStorage.removeItem(`default-search`)
          localStorage.removeItem(`mod-daftar-spt-saved-search`)
          localStorage.removeItem(`mod-bp-saved-search`)
          localStorage.removeItem(`mod-bp26-saved-search`)
          localStorage.removeItem(`mod-doss2-saved-search`)
          localStorage.removeItem(`mod-pbk-saved-search`)
          localStorage.removeItem(`mod-ssp-saved-search`)
          localStorage.removeItem(`mod-bpe-saved-search`)
          // Tambahan Uci
        } catch(e){}
        props.navigationStore.redirectTo(href)
      }}
    >
      <div className={`mpk-company-card mpk-border solid dark ${className} ${thumbnail ? 'thumbnail mpk-flex align-center border-bottom' : 'border-all thin '}`}>
        <div className="image mpk-flex justify-center align-center">
          { value.icon ? (
            <img src={value.icon} alt={value.name}/>
          ) : (
            <FontIcon iconClassName="mdi mdi-atom"/>
          )}
        </div>
        <div className={`mpk-padding-N padding-all ${thumbnail ? '' : 'mpk-border thin solid dark border-top'}`}>
          <div className="mpk-font weight-B">{value.name}</div>
          <div className="mpk-font size-NS">{value.industry}</div>
        </div>
      </div>
    </Link>
  )
}

export default inject('navigationStore')(observer(CompanyCard))

// import React from 'react'
// import { FontIcon } from 'react-md'
// import { Link } from '..'
// import { inject, observer } from 'mobx-react'
// import './CompanyCard.scss'
// import { toast } from "../../services";

// const CompanyCard = ({
//   className = '',
//   href = '',
//   thumbnail = false,
//   value = {},
//   disabled = false,
//   ...props
// }) => {
//   let subscriptions = value.subscriptions
//   console.log(subscriptions)
//   const { host } = props.envStore.env.sso;

//   return disabled ? (
//     <div
//       className={thumbnail ? 'mpk-full full-width' : ''}
//     >
//       <div className={`mpk-company-card mpk-border solid dark ${className} ${thumbnail ? 'thumbnail mpk-flex align-center border-bottom' : 'border-all thin '}`}>
//         <div className="image mpk-flex justify-center align-center">
//           {value.icon ? (
//             <img src={host + value.icon} alt={value.name} />
//           ) : (
//             <FontIcon iconClassName="mdi mdi-atom" />
//           )}
//         </div>
//         <div className={`mpk-padding-N padding-all ${thumbnail ? '' : 'mpk-border thin solid dark border-top'}`}>
//           <div className="mpk-font weight-B">{value.name}</div>
//           <div className="mpk-font size-NS">{value.industry}</div>
//         </div>
//       </div>
//     </div>
//   ) : (
//     <Link
//       to={subscriptions.length > 0 ? href : '#'}
//       className={thumbnail ? 'mpk-full full-width' : ''}
//       onClick={e => {
//         if (subscriptions.length > 0) {
//           e.preventDefault()
//           e.stopPropagation()
//           props.navigationStore.redirectTo(href)
//         } else {
//           toast.error(`Anda tidak memilik subscription (langganan) untuk mengelola ${value.name}. Hubungi ADMIN perusahaan atau beli paket produk untuk ${value.name} terlebih dahulu!`)
//         }
//       }}
//     >
//       <div className={`mpk-company-card mpk-border solid dark ${className} ${thumbnail ? 'thumbnail mpk-flex align-center border-bottom' : 'border-all thin '}`}>
//         <div className="image mpk-flex justify-center align-center">
//           {value.icon ? (
//             <img src={host + value.icon} alt={value.name} />
//           ) : (
//             <FontIcon iconClassName="mdi mdi-atom" />
//           )}
//         </div>
//         <div className={`mpk-padding-N padding-all ${thumbnail ? '' : 'mpk-border thin solid dark border-top'}`}>
//           <div className="mpk-font weight-B">{value.name}</div>
//           <div className="mpk-font size-NS">{value.industry}</div>
//         </div>
//       </div>
//     </Link>

//   )
// }

// export default inject('navigationStore', 'envStore')(observer(CompanyCard))
