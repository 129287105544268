import React, { useEffect, useState } from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import LoadingOverlay from 'react-loading-overlay'
import { inject, observer } from 'mobx-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { Commandbar, Flex, LoaderInfo } from '../../libs/react-mpk/components';
import t from 'counterpart'
import { cloneDeep, groupBy, merge, sortBy } from 'lodash';
import { Col, Row } from 'react-grid-system';
import { format } from 'morgan';
import TableCUstom from './TableCustom'
import service from './DashboardSpt.service'
import moment from 'moment';
import StatusLaporTable from './StatusLapor.table';
import DaftarSptTable from './DaftarSpt.table';
import KurangLebihBayarTable from './KurangLebihBayar.table';
import KodeObjekPajakTable from './KodeObjekPajak.table';
import Plot from 'react-plotly.js';
import NotFoundData from '../../libs/react-mpk/components/NotFoundData';
import errorService from '../../services/errorService';

const DashboardSpt = ({
  authStore,
  match,
  modalStore
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({
    loading: false, content: {
      totalBp: 0,
      totalBpSs: 0,
      totalSpt: 0,
      result: {},
      taxObjectCodes: [],
      taxObjectCodesTotalDpp: 0,
      taxObjectCodesTotalPph: 0,
      spts: [],
    }
  })
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [chartDefinitions, setChartDefinitions] = useState([]);
  const [genDefaultQuery, setgenDefaultQuery] = useState({
    startMonth : parseInt(moment().subtract(12, 'months').format("M")),
    startYear : parseInt(moment().subtract(12, 'months').format("YYYY")),
    endMonth : parseInt(moment().format("M")),
    endYear : parseInt(moment().format("YYYY")),
    npwps : []
  });
  const [datass, setdatass] = useState({data : {}, dataConfig : {}, chartConfig : {}})
  const [data2, setdatas2] = useState({data : {}, dataConfig : {}, chartConfig : {}})
  const [colorway, setColorway] = useState([])
  const [dataChart, setdataChart] = useState({data : {}, dataConfig : {}, chartConfig : {}})
  const [formDataOk, setFormDataOk] = useState({})
  const [loading, setLoading] = useState(false)
  var inSpt = false
  if(window.location.href.indexOf('open') != -1) inSpt = true
  var dataConfig ={ 
    type: "pie",
    hole: .75,
    textinfo : 'none',
    domain: {
      x: [ 0, 0.7],
      y: [ 0, 1]
    }
  }

  var dataConfig2 ={ 
    type: "bar", 
    showlegend: true,
    hovertemplate: 'Rp %{y:,3f}<extra></extra>',
    responsive: true,
  }

  var chartConfig= {
    layout : {
      autosize: true,
      // height: 480,
      title : '<b>Jumlah PPh Terutang</b>',
      font : {
        color : "#404040",
        size : '21px',
        family : 'Roboto'
      },
      margin : {
        t : 50,
        b : 0,
        l : 50
      },
      barmode: 'group',
      legend: {
        "y" : 0.5,
        "x" : 0.7,
        font : {
          size : 14,
          family : 'roboto'
        },
      },
      colorway : colorway
    }
  }

  var chartConfig2= {
    layout : {
      autosize: true,
      height : 480,
      title : '<b>Total PPh Terutang</b>',
      yaxis: {
        title: (`<b>${t.translate('words.pphTerutangRp')}</b>`+"").toUpperCase(),
        gridcolor: '#C2C2C2',
      },
      xaxis: {
        title: (`<b>${t.translate('words.monthEn')}</b>`+"").toUpperCase(),
        font : {
          size : '21px',
        },
      },
      margin : {
        t : 50,
        b : 0,
        r : 50,
        l : 80
      },
      barmode: 'group',
      hovermode: 'closest',
      hoverlabel: { bgcolor: "#FFF" },
      legend: {
        "orientation": "h",
        "xanchor" : "center",  
        "x" : 0.5,
        "y" : -0.2,
        'traceorder':'reversed',
        font : {
          size : "13px"
        }
      },
      colorway : colorway,
    }
  }

  useEffect(async()=>{
    try{
      if (inSpt) {
        var spt = JSON.parse(localStorage.getItem("spt"));
        await setFormDataOk({
          npwps: spt.npwp,
          startMonth: spt.month + "",
          endMonth: spt.month + "",
          startYear: spt.year + "",
          endYear: spt.year + "",
          rev: spt.rev + ""
        })
        try {
          var okForm = {
            npwps: [spt.npwp],
            startMonth: spt.month + "",
            endMonth: spt.month + "",
            startYear: spt.year + "",
            endYear: spt.year + ""
          }
          let res = null
          var tmpD = JSON.parse(localStorage.getItem("uni_dashboard_in"))
          if(tmpD != null){
            if(tmpD.dId != spt.id){
              res = await service.postDashboardInSpt(okForm, spt.id);
            } else {
              res = {
                data: tmpD
              }
            }
          } else {
            res = await service.postDashboardInSpt(okForm, spt.id);
          }
          try {
            var taxObjectCodesTotalDpp = 0;
            var taxObjectCodesTotalPph = 0
            res.data.taxObjectCodes.map((d) => {
              taxObjectCodesTotalDpp += d.dpp
              taxObjectCodesTotalPph += d.pph
            })
            res.data.taxObjectCodesTotalDpp = taxObjectCodesTotalDpp
            res.data.taxObjectCodesTotalPph = taxObjectCodesTotalPph
          } catch (e) { }
          res.data.dyear = spt.year
          res.data.dmonth = spt.month
          res.data.drev = spt.rev
          res.data.dId = spt.id
          localStorage.setItem(`uni_dashboard_in`, JSON.stringify(res.data))
          setData({ loading: false, content: res.data })
        } catch (e) {
          errorService(e)
        }
      }else{
        var params = Object.assign({}, genDefaultQuery)
        if(params.label) delete params.label
        let res = await service.postDashboard(params)
        setData({content : res.data})
      }
    }catch(e){}
  },[genDefaultQuery])

  useEffect(async()=>{
    try{
      setLoading(true)
      setTimeout(() => {    
        olahData(data.content.result, dataConfig)
        olahData2(data.content.result, dataConfig2)
        setLoading(false)
      }, 3000);
    }catch(e){}
  },[data])

  var formatValue = (value) => format(value.toFixed(0));

  var contentTaxObjectCodes = []
  var contentTaxObjectCodesDpp = 0
  var contentTaxObjectCodesPph = 0
  var total = []
  var donutData = []
  var summary = []

  try {
    Object.entries(data.content).map((d)=>{
      if(typeof d !== 'object'){
        summary.push(d)
      }
    })
  } catch(e){}
  try{
    var datas1 = groupBy(data.content.sptAll, (d)=> d.month)
    for(let key of Object.keys(datas1)){
      var tes = datas1[key].map((a) => a.nominal);
      var sum = tes.reduce(function (a, b) {
        return a + b;
      }, 0);
      donutData.push({
        periode : moment().month(key).format("MMMM"),
        nominal : sum,
        status : 'nihil'
      })
    }
  }catch(e){}
  try{
    var datas1 = groupBy(data.content.sptAll, (d)=> d.month)
    for(let key of Object.keys(datas1)){
      var test = datas1[key]
      var test2 = groupBy(test, (d)=> d.status)
      total.push({
        bp : test2,
        periode : moment().month(key).format("MMMM")
      })
    }
  }catch(e){}
  try {
    data.content.taxObjectCodes.map((d, counter)=> {
      contentTaxObjectCodesDpp += d.dpp
      contentTaxObjectCodesPph += d.pph
      contentTaxObjectCodes.push({
        no: counter + 1,
        kop: d.code + " - " + d.description,
        jumlahDpp: d.dpp,
        jumlahPph: d.pph
      })
    })
  } catch(e){}
  var contentSptAll = []
  try {
    data.content.sptAll.map((d, counter)=> {
      contentSptAll.push({
        no: counter + 1,
        d21: d.status,
        d22: d.npwp,
        d23: d.month,
        d24: d.year,
        d25: d.rev,
        d26: d.ntte,
        d27: d.bpeDate,
        d28: d.nominal
      })
    })
  } catch(e){}

 function olahData(data, dataConfig){
  try{
    let res = data
    const totalData = [];
    var colorway = []
    for (let keys of Object.keys(res)) {
      var warna = ""
      var category = ""
        if(keys === "pph22"){
          warna = "#B1C5F6"
          category = "Pasal 22"
        }else if(keys === "pph15"){
          warna = "#FDDD48"
          category = "Pasal 15"
        }else if(keys === "pph15Ss"){
          warna = "#ffdf53"
          category = "Pasal 15 ss"
        }else if(keys == "pph4a2"){
          warna = "#C2C2C2"
          category = "Pasal 4(2)"
        }else if(keys == "pph4a2Ss"){
          warna = "#d5d5d5"
          category = "Pasal 4(2) ss"
        }else if(keys == "pph23"){
          warna = "#E57A00"
          category = "Pasal 23"
        }else if(keys == "pph26"){
          warna = "#3267E3"
          category = "Pasal 26"
        }else{
          warna = "#43936C"
          category = keys.charAt(2).toUpperCase() + keys.slice(3)
        }
      if(colorway.indexOf(warna) === -1) {
        colorway.push(warna);
      }
      if(typeof res[keys].pph !== 'string'){
        totalData.push({
          key: category,
          value: res[keys].pph,
          category : 'string'
        });
      }
    }
    setColorway(colorway)
    let finalData = []
    try {    
      let groupByCategory = groupBy(totalData, d => (d.category))
      for(let key of Object.keys(groupByCategory)){
        let group = merge(cloneDeep(dataConfig), {
          x: [],
          y: [],
          name: key
        })
        groupByCategory[key] = sortBy(groupByCategory[key], (d) => (d.key))
        for(let item of groupByCategory[key]){
          group.x.push(item.key)
          group.y.push(item.value)
        }
  
        if(dataConfig.type === 'pie') {
          group.labels = group.x;
          group.values = group.y;
        }
  
        finalData.push(group)
      }
      setdatass({data : finalData})
    } catch (e) {
      // console.log(e)
    }
  }catch(e){}
 }

 function olahData2(data, dataConfig){
  try{
    let res = data
    const totalData = [];
    var colorway = []
    for (let keys of Object.keys(res)) {
      var warna = ""
      var category = ""
      if(keys === "pph22"){
        warna = "#B1C5F6"
        category = "Pasal 22"
      }else if(keys === "pph15"){
        warna = "#FDDD48"
        category = "Pasal 15"
      }else if(keys === "pph15Ss"){
        warna = "#ffdf53"
        category = "Pasal 15 ss"
      }else if(keys == "pph4a2"){
        warna = "#C2C2C2"
        category = "Pasal 4(2)"
      }else if(keys == "pph4a2Ss"){
        warna = "#d5d5d5"
        category = "Pasal 4(2) ss"
      }else if(keys == "pph23"){
        warna = "#E57A00"
        category = "Pasal 23"
      }else if(keys == "pph26"){
        warna = "#3267E3"
        category = "Pasal 26"
      }else{
        warna = "#43936C"
        category = keys.charAt(2).toUpperCase() + keys.slice(3)
      }
      if(colorway.indexOf(warna) === -1) {
        colorway.push(warna);
      }
      for (let key of Object.keys(res[keys])) {
        var group = res[keys]
        if(typeof group[key] !== 'string'){
          totalData.push({
            category: key,
            key: category,
            value: group[key],
          });
        }
      }
    }
    let finalData = []
    try {    
      let groupByCategory = groupBy(totalData, d => (d.category))
      for(let key of Object.keys(groupByCategory)){
        let group = merge(cloneDeep(dataConfig), {
          x: [],
          y: [],
          name: key
        })
        groupByCategory[key] = sortBy(groupByCategory[key], (d) => (d.key))
        for(let item of groupByCategory[key]){
          group.x.push(item.key)
          group.y.push(item.value)
        }
  
        if(dataConfig.type === 'pie') {
          group.labels = group.x;
          group.values = group.y;
        }
  
        finalData.push(group)
      }
      setdatas2({data : finalData})
    } catch (e) {
      // console.log(e)
    }
  }catch(e){}
 }

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <Flex
        direction={Flex.properties.direction.COLUMN}
        fit
      >
      <Commandbar 
        className="mpk-position position-sticky dashboard-spt-bar"
        style={{
          zIndex: 10,
          top: 0
        }}
        title={(t.translate('menu.dashboard')+"").toUpperCase()}
       />
      {loading ?
        <LoaderInfo>{t.translate('mpk.sentence.loadingData')}</LoaderInfo> 
      :
        <Flex
        className={`mpk-form-wrapper mpk-full full-width tesrss`}
        direction={Flex.properties.direction.COLUMN}
        fit
        style={{zoom : '125%', padding : '0 24px'}}
      >
        <Row style={{width:'100%', minHeight:'300px'}} gutterWidth={0}>
          <Col lg={3}>
          <div 
            className={`mpk-view-data-chart mpk-paper mpk-full full-width full-height dashboard-new`}
          >
            {datass.data.length > 0 ? (
              <Plot
                className="mpk-full full-width full-height"
                data={datass.data}
                config={dataConfig}
                {...chartConfig}
              />
            ) : (
              <NotFoundData />
            )}
            </div>
          </Col>
          <Col lg={8.8} offset={{ md: 0.2 }}>
            <DaftarSptTable inSpt={inSpt ? true : false} data={contentSptAll} genDefaultQuery={genDefaultQuery} setgenDefaultQuery={setgenDefaultQuery}/>
          </Col>
        </Row>
        <Row style={{width:'100%', minHeight:'400px', marginTop : '24px'}} gutterWidth={0}>
          <div 
            className={`mpk-view-data-chart mpk-paper mpk-full full-width full-height dashboard-new`}
          >
            {data2.data.length > 0 ? (
              <Plot
                className="mpk-full full-width full-height"
                data={data2.data}
                {...chartConfig2}
              />
            ) : (
              <NotFoundData />
            )}
            </div>
        </Row>
        <Row style={{width:'100%', minHeight:'300px'}} gutterWidth={10}>   
          <Col style={{marginTop:'24px'}} sm={3}>
            <StatusLaporTable data={total}/>
          </Col>
          <Col style={{marginTop:'24px'}} sm={3}>
            <KurangLebihBayarTable data={donutData}/>  
          </Col> 
          <Col style={{marginTop:'24px'}} sm={3}>
            <KodeObjekPajakTable data={contentTaxObjectCodes} title={"Kode Objek Pajak"} />
          </Col>
          <Col style={{marginTop:'24px'}} sm={3}>
            <TableCUstom
              fit
              isDense={false}
              showMore={false}
              showDataLength={3}
              showIndex={false}
              title='Summary'
              showActionColumn={false}
              data={[{
                spt : data.content.totalSpt,
                },
                {
                  bp : data.content.totalBp,
                },{
                bpss : data.content.totalBpSs,
                }
              ]
              }
              loading={false}
              columns={[
                {
                  key: "total",
                  label: "",
                  render: item =>{
                    return (Object.keys(item)+ "").toUpperCase()
                  },
                  searchable: true,
                  sortable: true,
                  type: "string",
                },
                {
                  key: "total",
                  label: "Jumlah",
                  render: item =>{
                    return Object.values(item)
                  },
                  searchable: true,
                  sortable: true,
                  type: "string",
                },
              ]}
          />
          </Col>
        </Row>
      </Flex>
      }
    </Flex>
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(DashboardSpt))