import React from 'react'
import { inject, observer } from 'mobx-react'
import { DropdownMenu, FontIcon, MenuItem, Chip, ListSubheader, BadgeContainer, Badge } from 'react-md'
import { ListItem, Text, Card, Button, Dialog, DialogHeader, DialogContent, DialogTitle, DialogFooter } from 'react-md'

const Notification = (props, match, history) => {
    var notificationData = []
    var items = []
    var count = 0
    try {
        notificationData = JSON.parse(localStorage.getItem('notifSertel'))
        if(notificationData !== null){
            count = notificationData.length
        }
        notificationData.map((d, index) => {
            if(index < 5){
                items.push(
                    <ListItem
                        leftAddon={<Chip style={{ color: 'red' }}>Expired soon</Chip>}
                        primaryText={d.npwp}
                        secondaryText={d.certExpiredDate}
                    ></ListItem>
                )
            }
        })
    } catch (e) { }

    return (
        <div className="mpk-notification">
            <BadgeContainer>
            <DropdownMenu
                id="mpk-notification-menu-sertel"
                items={[
                    <ListSubheader>List Notification</ListSubheader>,
                    ...items,
                    <ListItem primaryText="Load More ... " onClick={()=> {
                       window.location.href = `/#/onpremise/sertel`
                    }}></ListItem>
                ]}
                disableDropdownIcon
            >
                <FontIcon iconClassName="mdi mdi-key" />
            </DropdownMenu>
            <Badge theme='clear' id="custom-badged-button-badge">{count}</Badge>
            </BadgeContainer>
        </div>
    )
}

export default inject('envStore', 'navigationStore')(observer(Notification));