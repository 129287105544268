import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './ScheduleEmail.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'
import { List, ListItem, ListSubheader, FontIcon } from 'react-md'
import EmailConfigService from '../EmailConfig/EmailConfig.service'
import DaftarSptService from './../DaftarSpt/DaftarSpt.service'
import moment from 'moment'

const DaftarSptForm = ({ authStore, envStore, match, history, setScheduleEmailConfig }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {
    useProxy: false
  }})
  const [dataDetails, setDataDetails] = useState([])
  const [useProxy, setUseProxy] = useState(false)

  const baseName = `scheduleEmail`
  const basePath = `scheduleEmail`

  async function initData(){
    try {
      var authStoreJson = toJS(authStore)
      let emailConfig = await EmailConfigService.getByCompanyId(match.params.companyId) //tambahan uci
      let res = await service.getByCompany(match.params.companyId, authStoreJson.user.username, emailConfig.data.id)
      //Tambahan Uci
      if(!res.data.limitPerDay) res.data.limitPerDay = res.data.limit_per_day
      res.data.id = emailConfig.data.id
      //Tambahan Uci
      res.data.limitPerDay += ""
      setScheduleEmailConfig(res.data)
      setData({ loading: false, content: res.data })
      let resDetails = await service.getDetails(res.data.id)
      setDataDetails(resDetails.data)
    } catch(e){
      setData({ loading: false})
      ErrorService(e)
    }
  }

  useEffect(() => {
    initData()
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise){
    backTo = `/onpremise/${basePath}`
  }

  var onSubmit = async (data,callback) => {
    try {
      var formData = Object.assign({}, data)
      var authStoreJson = toJS(authStore)
      var lastModifiedBy = authStoreJson.user.username
      formData.lastModifiedBy = lastModifiedBy
      let settingEmail = await EmailConfigService.getByCompany(match.params.companyId)
      settingEmail.data.settingEmailId = settingEmail.data.id
      settingEmail.data.login = authStoreJson.user.username
      settingEmail.data.userId =  authStoreJson.user.id
      delete settingEmail.data.id
      formData = { ...formData, ...settingEmail.data}
      let organizations = UtilsService.getOrganizations(toJS(authStore))
      organizations.map((d)=> {
        delete d.permission
      })
      formData.organizations = organizations
      var res = await service.putByCompany(formData.id, formData)
      callback(t.translate('sentences.tersimpan'), false, false, false)   
    } catch(e){
      ErrorService(e)
      callback("", false, false, false)
      return
    }
  }

  async function handleRemove(id){
    try {
      let remove = await service.removeItem(id)
      initData()
    } catch(e){
      ErrorService(e)
    }
  }

  async function handleSave(formData){
    try{
      let organizations = UtilsService.getOrganizations(toJS(authStore))
      organizations.map((d)=> {
        delete d.permission
      })
      formData.organizations = organizations
      let res = await service.saveItem(formData)
      initData() //Tambahan Uci
    }catch(e){
      ErrorService(e)
      initData()//Tambahan Uci
    }
  }

  return (
    <FormWrapper
      style={{
        maxWidth: '100%'
      }}
      loading={data.loading}
      actions={[]}
      baseId={`mod-form-${baseName}`}
      showCommandbar={false}
      defaultData={data.content}
      onChange={(formData, key, value)=> {
        if(key == "tanggal"){
          try {
            handleSave(formData)
            // initData()//Tambahan Uci
          } catch(e){}
        }
        setScheduleEmailConfig(formData)
      }}
      definitions={[
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.active`),
          key: 'active',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.limitPerHari`),
          key: 'limitPerDay',
          width: '50%',
          type: 'text',
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.tanggal`),
          key: 'tanggal',
          width: '100%',
          type: 'date'
        },
        {
          render : (
            <List style={{width: '100%'}}>
              {dataDetails.map((d, i)=>{
                let currentYear = moment().year()
                let bulan2Digit = String(d.bulan).padStart(2, 0)
                let tanggal2Digit = String(d.tanggal).padStart(2, 0)
                let selectedDate = `${currentYear}-${bulan2Digit}-${tanggal2Digit}`
                let momentCurrentDate = moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                let momentOneMonthAgoDate = moment(selectedDate, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD')
                return(
                <ListItem key={i} rightAddon={<FontIcon onClick={()=>{ handleRemove(d.id)}} iconClassName="mdi mdi-delete"></FontIcon>}
                  primaryText={t.translate('words.date')+" : "+ d.tanggal + ", " +t.translate('words.month')+" : "+ d.bulan}
                  secondaryText = {t.translate('words.ketEmail')+momentOneMonthAgoDate+ " " +t.translate('words.till')+" "+momentCurrentDate}
                />
                )
              })}
            </List>
          )
        }
      ]}
      // onSubmit={async (data, callback) => {
      //   try {
      //     var formData = Object.assign({}, data)
      //     var authStoreJson = toJS(authStore)
      //     var lastModifiedBy = authStoreJson.user.username
      //     formData.lastModifiedBy = lastModifiedBy
      //     let settingEmail = await EmailConfigService.getByCompany(match.params.companyId)
      //     settingEmail.data.settingEmailId = settingEmail.data.id
      //     settingEmail.data.login = authStoreJson.user.username
      //     settingEmail.data.userId =  authStoreJson.user.id
      //     delete settingEmail.data.id
      //     formData = { ...formData, ...settingEmail.data}

      //     var organization = await DaftarSptService.getOrganization({
      //       page: 0,
      //       size: 10000
      //     })
      //     formData.organizations = organization.data
      //     formData.organizations.map((d)=> {
      //       d.id = match.params.companyId
      //     })

      //     // let organizations = UtilsService.getOrganizations(toJS(authStore))
      //     // organizations.map((d)=> {
      //     //   delete d.permission
      //     // })
      //     // formData.organizations = organizations
      //     var res = await service.putByCompany(formData.id, formData)
      //     callback(t.translate('sentences.tersimpan'), false, false, false)   
      //   } catch(e){
      //     ErrorService(e)
      //     callback("", false, false, false)
      //     return
      //   }
      // }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DaftarSptForm))
