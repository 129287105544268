import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS, get } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import { Tooltipped, TooltipHoverModeConfig, Dialog, DialogHeader, DialogTitle, Divider, DialogContent, DialogFooter, Chip, Button, TextField, FontIcon, ListItem, List, FileInput, Card, CardContent, Table, TableCell, TableRow, TableHeader, TableBody } from 'react-md'
import { useToggle } from '@react-md/utils';
import LoadingOverlay from 'react-loading-overlay'
import errorServiceGet from '../../services/errorServiceGet'
import iziToast from 'izitoast'
import Bluebird, { resolve } from 'bluebird'
import errorService from './../../services/errorService'
import AppMode from '../../AppMode'
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import bluebird from 'bluebird'
import { useDropzone } from 'react-dropzone'
import { Container, Row, Col } from 'react-grid-system';
import AnimatedNumber from "animated-number-react";
import { format } from './../../libs/react-mpk/services/number.service'
import { http } from '../../libs/react-mpk/services'

const Organization = ({
    className = '',
    showCommandbar = true,
    history,
    modalStore,
    authStore,
    match,
    envStore
}) => {

    const ploading = useState({ loading: false, message: '' })
    const baseId = "company-sertel"
    const [search, setSearch] = useState({})

    useEffect(() => {
    }, [])

    return (
        <>
            <LoadingOverlay
                style={{
                    position: 'inherit',
                    background: 'red'
                }}
                active={ploading.loading}
                spinner
                text={ploading.message}
            >
            </LoadingOverlay>
            <TableWrapper
                useFilter={false}
                baseId="mod-company"
                title={t.translate('modules.company.title')}
                className={className}
                defaultData={[]}
                defaultSortBy="id"
                columns={[
                    {
                        label: t.translate('words.code'),
                        searchable: true,
                        sortable: true,
                        key: 'groupCode',
                        type: TableWrapper.dataTypes.STRING,
                        render: item => (item.groupCode)
                    },
                    {
                        label: t.translate('words.name'),
                        searchable: true,
                        sortable: true,
                        key: 'name',
                        type: TableWrapper.dataTypes.STRING,
                        render: item => (item.name)
                    },
                    {
                        label: t.translate('words.email'),
                        searchable: true,
                        sortable: true,
                        key: 'email',
                        type: TableWrapper.dataTypes.STRING,
                        render: item => (item.email)
                    },
                ]}
                actions={[]}
                itemActions={[
                    new TableWrapper.action(`${t.translate('words.openCompany')}`, 'mdi mdi-import', (item) => {
                        localStorage.setItem('company', JSON.stringify(item))
                        history.push(`/product/company/${item.id}`)
                    })
                ]}
                setPage={search.page} onFetchData={async params => {
                    let { companies, isSupport } = authStore.user
                    var newParams = {
                        ...params,
                        ...search
                    }
                    if (newParams.name) {
                        newParams.field = "name"
                        newParams.query = newParams.name
                        delete newParams.name
                    }
                    if (newParams.email) {
                        newParams.field = "email"
                        newParams.query = newParams.email
                        delete newParams.email
                    }
                    newParams.page = newParams.page + 1
                    delete newParams.endDate
                    delete newParams.startDate
                    delete newParams.sort
                    if (isSupport) {
                        var res = await http.request({
                            method: http.methods.STREAM,
                            url: `${envStore.env.sso.host}/api/user_console/company_support`,
                            query: newParams,
                            config: {},
                            options: {
                                useDefaultHost: false,
                                useDefaultBaseUrl: false,
                                ignoreInterceptor: false
                            }
                        })
                        return {
                            data: res.data,
                            headers: {
                                'x-total-count': res.headers['x-pagination-count']
                            }
                        }
                    } else {
                        let compani = []
                        companies.map((d) => {
                            compani.push({
                                name: d.name,
                                id: d.id,
                                address: d.address,
                                email: d.email,
                                city: d.city,
                                industry: d.industry
                            })
                        })
                        if(search['name']){
                          compani = compani.filter((d)=> {
                            return d.name.indexOf(search['name']) != -1  
                          })
                        }
                        if(search['email']){
                            compani = compani.filter((d)=> {
                              return d.email.indexOf(search['email']) != -1  
                            })
                          }
                        return {
                            data: compani,
                            headers: {
                              'x-total-count': compani.length
                            }
                        }
                    }
                }}
                sideHeader={
                    <DataForm
                        baseId={`${baseId}-search`}
                        defaultData={search}
                        submitIconClassName='mdi mdi-magnify'
                        submitLabel={`${t.translate('words.cari')}`}
                        additionalAction={[
                            {
                                show: true,
                                render: () => (
                                    <Button style={{ marginRight: 15 }} onClick={() => {
                                        setSearch({})
                                        var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                                        setTimeout(() => {
                                            document.getElementsByClassName('mdi-reload')[0].click()
                                        }, 1000)
                                    }}>{t.translate('words.hapus')}</Button>
                                )
                            }
                        ]}
                        onSubmit={(values, callback) => {
                            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
                            setTimeout(() => {
                                document.getElementsByClassName('mdi-reload')[0].click()
                                callback("", false, false)
                            }, 1000)
                        }}
                        definitions={[
                            {
                                render: (
                                    <div className="mpk-data-filter">
                                        <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                                            <p>{t.translate(`words.pencarian`)}</p>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: t.translate(`words.name`),
                                key: 'name',
                                type: 'text',
                                width: '100%'
                            },
                            {
                                inputType: inputTypes.INPUT,
                                label: t.translate(`words.email`),
                                key: 'email',
                                type: 'text',
                                width: '100%'
                            },
                        ]}
                    />
                }
                showCommandbar={showCommandbar}
                showFilterPeriod={false}
            />
        </>
    )
}

export default inject('temporaryStore', 'modalStore', 'authStore', 'envStore')(observer(Organization))