import React, { useMemo, useState, useCallback } from "react";
import { inputTypes } from "../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { useDropzone } from "react-dropzone";
import AppMode from "../../AppMode";
import ErrorService from "./../../services/errorService";
import service from "./UploadSertel.service";
import { Button, FontIcon } from "react-md";
import errorService from "./../../services/errorService";
import iziToast from "izitoast";
import LoadingOverlay from 'react-loading-overlay'

const FormSample = ({ envStore, navigationStore, match }) => {
  const { inputTypes, definition } = FormWrapper;
  const [myFiles, setMyFiles] = useState([]);
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const basePath = `organization`;

  const baseStyle = {
    flex: 1,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: "110px",
    height: "300px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  
  const acceptStyle = {
    borderColor: "#00e676",
  };
  
  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop, accept: ".p12"
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const files = myFiles.map((file) => (
    <li>
      {file.path} - {file.size} bytes{" "}
      <Button
      class="btn-sm pull-left"
        onClick={removeFile(file)}
        buttonType="icon"
        style={{marginTop:'5px'}}
      >
        <FontIcon className="mdi mdi-trash-can-outline"></FontIcon>
      </Button>
    </li>
  ));
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(onDrop ? acceptStyle : {}),
      ...(removeFile ? rejectStyle : {}),
    }),
    [removeFile, onDrop]
  );
  
  return (
    <>
    <LoadingOverlay
      style={{
        position: 'inherit',
        background: 'red'
      }}
      active={ploading.loading}
      spinner
      text={ploading.message}
      >
    </LoadingOverlay>
    <FormWrapper
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      style={{
        maxWidth: "100%",
        width: "900px",
        display: "block",
        overflow: "auto",
      }}
      backTo={`/product/company/${match.params.companyId}/organization`}
      baseId="mod-form-sample"
      title={t.translate("modules.upload-sertel.title")}
      hintMessage={t.translate("modules.upload-sertel.hint.message")}
      hintShowIcon={false}
      definitions={[
        {
          render: (
            <section className="container" style={{ width: "100%", maxWidth: "100%" }}>
              <div {...getRootProps({ className: "dropzone", style})}>
                <input {...getInputProps()}  />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              {files.length > 0 && (
                <Button
                  onClick={removeAll}
                  themeType="outline"
                  theme="primary"
                  style={{ marginTop: "20px" }}
                >
                  Remove All
                </Button>
              )}
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            </section>
          ),
        },
      ]}
      onSubmit={ async (data, callback) => {
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        try{
          if(myFiles.length > 0){
            var formData = new FormData()
            formData.append('files', myFiles)
            
            var res = await service.uploadCert(formData)


            callback(t.translate('sentences.tersimpan'), false)
            navigationStore.redirectTo(`/product/company/${match.params.companyId}/${basePath}`)
          } else {
            iziToast.warning({
              title : t.translate('words.uploadMultipleSertel'),
              message : t.translate('words.noOrg')
            })
            setPloading({ loading: false, message: t.translate('words.noOrg') })
            callback("", false, false, false)
            return
          }
        }catch(e){
          // console.log(myFiles)
          errorService(e)
          setPloading({ loading: false, message: ''})
          callback("", false, false, false)
          return
        }
      }}
    />
    </>
  );
};

export default inject("envStore","navigationStore")(observer(FormSample));
