import { crud, http } from '../../libs/react-mpk/services';


let service = new crud('/file');

service.uploadCert = async(data)=>{
    return http.request({
      method : http.methods.POST,
      url : `/file/upload/cert`,
      data
    })
  }
export default service;