import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import Script from "react-load-script";
import routes from "./routes";
import "./App.scss";
import { init } from "./libs/react-mpk/hocs";
import qs from 'query-string';
import customInit from "./libs/custom/customInit"
import AppMode from './AppMode'
import { Client, Message } from '@stomp/stompjs'
import iziToast from 'izitoast'
import { autorun, toJS, values } from 'mobx';
import { inject, observer } from 'mobx-react'
import axios from 'axios'

const App = ({ match, temporaryStore, envStore, navigationStore, authStore }) => {
  const [checked, setChecked] = useState(false);

  useEffect(()=> {
    axios.interceptors.request.use(
      function (config) {
        config.headers = {
          ...config.headers,
          'X-Request-Timeout': 1000,
          'ngrok-skip-browser-warning': true
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }, [])

  useEffect(() => {
    if (!checked && window.location.pathname === '/') {
      const parsedUrl = qs.parseUrl(window.location.href);
      navigationStore.redirectTo(`/${envStore.env.applicationType}${Object.keys(parsedUrl.query).length > 0 ? `?${qs.stringify(parsedUrl.query)}` : ''}`)
      setChecked(true);
    }
  }, [checked])

  // Tambahan Uci
  try{
    var title = document.title.split(":")
    document.title = title[1] ? ("e-Bunifikasi :"+title[1]+ "") : "e-Bunifikasi"
  }catch(e){
    document.title = "e-Bunifikasi"
  }

  useEffect(()=>{
    document.title = "e-Bunifikasi"
  }, [envStore])
  // Tambahan Uci
  
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  return (
    <div className="App mpk-full viewport-height">
      {Object.keys(envStore.env.widgetInterface).map((key) => (
        <Script
          key={`widget-interface-${key}`}
          url={envStore.env.widgetInterface[key].url}
        />
      ))}
      <Router history={navigationStore.browserHistory}
        className="mpk-position relative"
      >
        {routes()}
      </Router>
      {/* {envStore.kbs && envStore.kbs.length > 0 ? (
        <SupportMpk appName={appInfo.name} kbs={kbs} />
      ) : null} */}
    </div>
  );
}

var initOnPremise = AppMode.onPremise
var initApp = init
if (initOnPremise) {
  initApp = customInit
}

// SSO or OnPremise
export default initApp()(inject('temporaryStore', 'modalStore', 'authStore')(observer(App)));