import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpInquiry.service'
import { Chip, ListItem, List, Button} from 'react-md'
import bluebird from 'bluebird'
import { inject, observer } from 'mobx-react'
import errorServiceGet from './../../services/errorServiceGet'
import errorService from './../../services/errorService'
import fileSaver from 'file-saver';
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'

const OpInquiry = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  match
}) => {
  const [data, setData] = useState([])
  const [getStatus, setStatus] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})

  const baseId = 'mod-op-inquiry'
  const [basePath, setBasePath] = useState('inquiry')

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, []) 

  async function initData() {
    try{
      const status = await service.coreStatus()
      setStatus(status.data)
    } catch{

    }
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.delete(di.id)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function exportCsvExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.csvBulk({
              idList: selectedData
            })
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneInquiry')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportCsv(item) {
    modalStore.showConfirm({
      title : t.translate('words.exportCsv'),
      children : t.translate('words.confirmexportCsv'),
      onSubmit : (callback) => {
        exportCsvExc(item)
        callback()
      }
    })
  }

  async function checkInquiryExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.checkInquiry(selectedData)
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.doneInquiry')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function checkInquiry(item) {
    modalStore.showConfirm({
      title : t.translate('words.checkInquiry'),
      children : t.translate('words.confirmCheckInquiry'),
      onSubmit : (callback) => {
        checkInquiryExc(item)
        callback()
      }
    })
  }

  async function csvfilter(item) {
    var errors = []
    try {
      let res = await service.csvFilter(match.params)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
      errors.push(await errorServiceGet(e))
    }
    if(errors.length == 0){
      iziToast.success({
        title: t.translate('words.success'),
        message: `${t.translate('words.proses')} ${t.translate('words.doneInquiry')}`
      })
    }
  }

  return (
    <>
    <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
    </LoadingOverlay>
    <TableWrapper useFilter={false}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      setPage={search.page} onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let getPage = null
              getPage = await service.getPage(params)
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render : (
                <div>
                  {getStatus.success &&
                    <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.connected")}</Chip>
                  }
                  {!getStatus.success &&
                    <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.notConnected")}</Chip>
                  }
                </div>
              )
            },
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              type: 'text',
              mask: '##.###.###.#-###.###',
              maskChar: '_',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.city`),
              key: 'city.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.address`),
              key: 'address.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable : true,
          key:'npwp',
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key: 'name',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.name)
        },
        {
          label: t.translate('words.city'),
          searchable: true,
          sortable : true,
          key: 'city',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.city)
        },
        {
          label: t.translate('words.address'),
          searchable: true,
          sortable : true,
          key: 'address',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.address)
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.success){
              return <Chip style={{ background: "#4CAF50", color: 'white'}}>{t.translate(`words.success`+"").toUpperCase()}</Chip>
            } else {
              if(!item.name && item.message){
                return <Chip style={{ background: "#F44336", color: 'white'}}>{t.translate(`words.failed`).toUpperCase()}</Chip>
              } else{
                return <Chip style={{ background: "#0D47A1", color: 'white'}}>{t.translate("words.process").toUpperCase()}</Chip>
              }
            }
          }
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: t.translate('words.id'),
          searchable: true,
          sortable : true,
          key: 'id',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.id)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.inquiry')}`, 'mdi mdi-account-convert', (item) => { checkInquiry(item)}, true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
        new TableWrapper.action(`${t.translate('words.csvbySelected')}`, 'mdi mdi-printer', (item) => {exportCsv(item)}, true),
        new TableWrapper.action(`${t.translate('words.csvbyFilter')}`, 'mdi mdi-printer', (item) => {csvfilter(item)}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
          localStorage.setItem('inquiry', JSON.stringify(item))
          history.push(`${basePath}/${item.id}`)}, (item)=>{
            if(item.status == "SUCCESS"){
              return false
            }
            return true
          }),
      ]}
      showCommandbar={showCommandbar}
    />
  </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpInquiry))
