import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './DaftarSpt.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'
import { Button, CardContent, Card, Chip, FontIcon, Dialog, DialogContent } from 'react-md'
import OrganizationView from './../Organization/OrganizationPicker.table'
import OpOrganizationTable from '../OpOrganization/OpOrganizationPicker.table'
import iziToast from 'izitoast'

const DaftarSptBulk = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: false, content: {}})
  const [organization, setOrganization] = useState([])
  const [allOrg, setAllOrg] = useState([])
  const [all, setAll] = useState(true)
  const [dialogOrg, setDialogOrg] = useState(false);
  const [showOrg, setShowOrg] = useState(true);

  const baseName = `daftarSpt`
  const basePath = `daftar-spt`

  useEffect(() => {
    
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise) backTo = `/onpremise/${basePath}`

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate(`modules.${baseName}.createBulk`)}
      defaultData={data.content}
      onChange={(formData, key, value)=> {
        if(key == 'all' && value){
          setOrganization([])
          setShowOrg(false)
        } else {
          try {
            if(formData.all == true){
              setShowOrg(false)
            } else {
              setShowOrg(true)
            }
          } catch(e){
            setShowOrg(true)
          }
        }
        if(key == 'all' && value == false) setAll(true)
      }}
      style={{
        maxWidth: '100%'
      }}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.month`),
          key: 'month',
          type: 'text',
          width: '50%',
          validation: 'required',
          options: [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.year`),
          key: 'year',
          width: '50%',
          type: 'text',
          validation: 'required',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.allNpwp`),
          key: 'all',
          width: '100%',
          type: 'text',
        },
        {
          show: showOrg,
          render: (
            <div style={{width: '100%'}}>
              <Button themeType="outline" theme="primary" onClick={(e)=> setDialogOrg(true) }>{t.translate("words.pilihOrganisasi")}</Button>
              <Button themeType="outline" theme="error" style={{ marginLeft: 10 }} onClick={(e)=> setOrganization([])} >{t.translate("words.hapusOrganisasi")}</Button>
              <Card style={{width: '100%', marginTop: '25px'}}>
                <CardContent>
                  {organization.length > 0 &&
                    <>
                      {organization.map((d, i)=> {
                        return <Chip onClick={()=> {
                          var organizationFilter = organization.filter((d)=> {
                            return d.id != d.id
                          })
                          setOrganization(organizationFilter)
                        }} style={{ margin: 5 }} theme="outline" rightIcon={<FontIcon>delete</FontIcon>}>{d.npwp} - {d.name}</Chip>
                      })}
                    </>
                  }
                </CardContent>
              </Card>
            </div>
          )
        },
        {
          render: (
            <div>
              <Dialog
                visible={dialogOrg}
                onRequestClose={(e)=> setDialogOrg(false)}
                style={{
                  width: 1024,
                  height: 600,
                }}
              >
                <DialogContent>
                  {!AppMode.onPremise &&
                    <OrganizationView setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                  }
                  {AppMode.onPremise &&
                    <OpOrganizationTable setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                  }
                </DialogContent>
              </Dialog>
            </div>
          )
        }
      ]}
      onSubmit={async (data, callback) => {
        var formData = Object.assign({}, data)
        var res = {}
        var npwps = []
        try {
          if(organization && organization.length > 0){
            organization.map((d) => {
              if (d == "") { } else {
                if (d && d.npwp) {
                  npwps.push(d.npwp)
                }
              }
            })
            formData.npwps = npwps
            delete formData.all
          } else if(!formData.all || formData.all == false){
            iziToast.info({
              title: `${t.translate('words.Info')}`,
              message: `${t.translate('words.warningOrg')}`
            })
            callback("", false, false, false)
            return
          }
          res = await service.createBulk(formData, data.all)
          callback(t.translate('sentences.tersimpan'), false, false, false)
          if(AppMode.onPremise){
            history.goBack()
          } else {
            history.push(`${backTo}`)
          }
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(DaftarSptBulk))
