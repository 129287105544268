import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import TableCUstom from './TableCustom'

const KurangLebihBayar = ({
  authStore,
  inSpt,
  match,
  modalStore,
  ...props
}) => {
	
  function formatNpwp(value) {
    if (typeof value === 'string') {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
  }

  return (
    <TableCUstom
      fit
      showIndex={false}
      showMore={true}
      showDataLength={4}
      title='Kurang Lebih Bayar'
      showActionColumn={false}
      data={props.data}
      loading={false}
      columns={[
        {
          key: "periode",
          label: "Periode",
          render: item =>item.periode,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "status",
          label: "Status",
          render: item => item.status,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "nominal",
          label: "Nominal",
          render: item => {
            return item.nominal.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
      ]}
    />
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(KurangLebihBayar))