import React, {useEffect, useState, useCallback, useMemo} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS, get } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './OpSertel.service'
import { Tooltipped, TooltipHoverModeConfig, Divider, Chip, Button, List, ListItem, FontIcon} from 'react-md'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import errorService from './../../services/errorService'
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import { Container, Row, Col } from 'react-grid-system';
import AnimatedNumber from "animated-number-react";
import { format } from './../../libs/react-mpk/services/number.service'

const Organization = ({
  className       = '',
  showCommandbar  = true,
  history,
  modalStore,
  authStore,
  match
}) => {
  const basePath = `sertel`
  const baseId = `mod-sertel`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const [summary, setSummary] = useState({
    npwpCount : 0,
    npwpsExpired : 0,
    npwpNotHaveCert : 0,
    npwpHaveCert : 0,
    npwpsExpiredInTwoMonths : 0
  })

  var formatValue = (value) => format(value.toFixed(0));

  useEffect(() => {
    initData()
  }, [])

  async function initData(){
    try{
      var params = {
        page : 0,
        size : 200
      }
      var summary = await service.getSertelSummaryOp(params)

      setSummary(summary.data)
    }catch(e){
      errorService(e)
    }
    try{
      params.companyId = match.params.companyId
      var expired = await service.getSertelExpiredOp()
      setSummary({npwpsExpiredInTwoMonths:expired.data.length})
    }catch(e){
      errorService(e)
    }
  }
  
  return (
    <>
    <LoadingOverlay
      style={{
        position: 'inherit',
        background: 'red'
      }}
      active={ploading.loading}
      spinner
      text={ploading.message}
      >
    </LoadingOverlay>
      <TableWrapper 
        useFilter={false}
        tableHeader={
          <div style={{ width: '100%', paddingBottom: '14px' }}>
            <Row>
              <Col sm={2.4}>
                <List style={{minHeight:'145px'}} className = "mpk-paper">
                  <ListItem rightAddon={
                    <Button
                      buttonType="icon"
                      theme="clear"
                      themeType="outline"
                      aria-label="Add"
                    >
                      <FontIcon className="mdi mdi-book-multiple"></FontIcon>
                    </Button>
                    }
                    style={{
                      fontWeight : 500,
                      fontSize    : 30
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={summary.npwpCount}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpCount')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
              </Col>
              <Col sm={2.4}>
                <List style={{minHeight:'145px'}} className = "mpk-paper">
                  <ListItem rightAddon={
                    <TooltipHoverModeConfig 
                      enabled={true} delayTimeout={2} defaultDelay={2}
                      key={`commandbar-action`}
                    >
                      <Tooltipped
                        id={`auto-positioning-above`}
                        tooltip={t.translate('words.exportNotHaveCert')}
                        defaultPosition="below"
                      >
                      <Button
                        buttonType="icon"
                        style={{ background: "white", color: '#2883e5' }}
                        themeType="outline"
                        aria-label="Add"
                        onClick={async ()=>{
                          try{
                            setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                            var params = {
                              page : 0,
                              size : 200
                            }
                            if(summary.npwpNotHaveCert > 0){
                              var res = await service.exportSertelExpired(params)
                              window.open(res.data.url)
                            }else{
                              iziToast.info({
                                title : t.translate('words.exportExpired'),
                                message : t.translate('words.notExist')
                              })
                            }
                            setPloading({ loading: false, message: `` })
                          }catch(e){
                            errorService(e)
                            setPloading({ loading: false, message: `` })
                          }
                        }}
                      >
                        <FontIcon className="mdi mdi-book-cancel"></FontIcon>
                      </Button>
                      </Tooltipped>
                    </TooltipHoverModeConfig>
                    }
                    style={{
                      fontWeight : 500,
                      fontSize   : 30,
                      color      : '#FFC108'
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={summary.npwpNotHaveCert}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpNotHaveCert')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
              </Col>
              <Col sm={2.4}>
                <List style={{minHeight:'145px'}} className = "mpk-paper">
                  <ListItem rightAddon={
                    <Button
                      buttonType="icon"
                      theme="clear"
                      themeType="outline"
                      aria-label="Add"
                    >
                      <FontIcon className="mdi mdi-book-check"></FontIcon>
                    </Button>
                    }
                    style={{
                      fontWeight : 500,
                      fontSize    : 30
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={summary.npwpHaveCert}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpHaveCert')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
              </Col>
              <Col sm={2.4}>
                <List style={{minHeight:'145px'}} className = "mpk-paper">
                  <ListItem rightAddon={
                    <TooltipHoverModeConfig 
                      enabled={true} delayTimeout={2} defaultDelay={2}
                      key={`commandbar-action`}
                    >
                      <Tooltipped
                        id={`auto-positioning-above`}
                        tooltip={t.translate('words.npwpExpiredInTwoMonth')}
                        defaultPosition="below"
                      >
                        <Button
                          buttonType="icon"
                          style={{ background: "white", color: '#2883e5' }}
                          themeType="outline"
                          aria-label="Add"
                          onClick={async ()=>{
                            try{
                              setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                              var params = {
                                page : 0,
                                size : 200
                              }
                              if(summary.npwpsExpiredInTwoMonths > 0){
                                var res = await service.exportSertelExpired2Month(params)
                                window.open(res.data.url)
                              }else{
                                iziToast.info({
                                  title : t.translate('words.npwpExpiredInTwoMonth'),
                                  message : t.translate('words.notExist')
                                })
                              }
                              setPloading({ loading: false, message: `` })
                              // const blob = new Blob([res.data], {type: 'text/csv'})
                              // fileSaver.saveAs(blob, ``);
                            }catch(e){
                              errorService(e)
                              setPloading({ loading: false, message: `` })
                            }
                          }}
                        >
                          <FontIcon className="mdi mdi-book-clock"></FontIcon>
                        </Button>
                      </Tooltipped>
                    </TooltipHoverModeConfig>
                    }
                    style={{
                      fontWeight : 500,
                      fontSize   : 30,
                      color      : 'tomato'
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={summary.npwpsExpiredInTwoMonths}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpExpiredInTwoMonth')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
              </Col>
              <Col sm={2.4}>
                <List style={{minHeight:'145px'}} className = "mpk-paper">
                  <ListItem rightAddon={
                    <TooltipHoverModeConfig 
                      enabled={true} delayTimeout={2} defaultDelay={2}
                      key={`commandbar-action`}
                    >
                      <Tooltipped
                        id={`auto-positioning-above`}
                        tooltip={t.translate('words.exportExpired')}
                        defaultPosition="below"
                      >
                        <Button
                          buttonType="icon"
                          style={{ background: "white", color: '#2883e5' }}
                          themeType="outline"
                          aria-label="Add"
                          onClick={async ()=>{
                            try{
                              setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                              var params = {
                                page : 0,
                                size : 200
                              }
                              if(summary.npwpsExpired > 0){
                                var res = await service.exportSertelExpired(params)
                                window.open(res.data.url)
                              }else{
                                iziToast.info({
                                  title : t.translate('words.exportExpired'),
                                  message : t.translate('words.notExist')
                                })
                              }
                              setPloading({ loading: false, message: `` })
                              // const blob = new Blob([res.data], {type: 'text/csv'})
                              // fileSaver.saveAs(blob, ``);
                            }catch(e){
                              errorService(e)
                              setPloading({ loading: false, message: `` })
                            }
                          }}
                        >
                          <FontIcon className="mdi mdi-book-alert"></FontIcon>
                        </Button>
                      </Tooltipped>
                    </TooltipHoverModeConfig>
                    }
                    style={{
                      fontWeight : 500,
                      fontSize   : 30,
                      color      : 'tomato'
                    }}
                    primaryText={
                      <AnimatedNumber
                        value={summary.npwpsExpired}
                        formatValue={formatValue}
                      />
                    } />
                    <Divider />
                    <p style={{ margin: 0, marginTop: 10, padding: '0px 15px', color: '#757580' }}><strong>{t.translate('words.npwpsExpired')}</strong></p>
                    {/* <ListItem secondaryText={<strong>{t.translate('words.deposit')}</strong>} /> */}
                  </List>
              </Col>
            </Row>
          </div>
        }
        selectable={true}
        baseId="mod-organization"
        title={t.translate('modules.organization.title')}
        className={className}
        defaultData={[]}
        defaultSortBy="id"
        columns={[
          {
            label: t.translate('words.npwp'),
            searchable: true,
            sortable : true,
            key: 'npwp',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.npwp)
          },
          {
            label: t.translate('words.certExists'),
            searchable: false,
            sortable : false,
            key: 'certExists',
            type: TableWrapper.dataTypes.BOOLEAN,
            render: item => {
              if(item.certExists){
                return <Chip className="chip-green">{'Ada'}</Chip>
              } else {
                item.certExists = false
                return <Chip className="chip-red">{'Tidak Ada'}</Chip>
              }
            }
          },
          {
            label: t.translate('words.name'),
            searchable: true,
            sortable : true,
            key: 'name',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              return item.certAlias.toString().split('-')[0].toUpperCase()
            }
          },
          {
            label: t.translate('words.certUploadDate'),
            searchable: true,
            sortable : true,
            key: 'certUploadDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              return moment(item.certUploadDate).format('YYYY-MM-DD')
            }
          },
          {
            label: t.translate('words.certExpiredDate'),
            searchable: true,
            sortable : true,
            key: 'certExpiredDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.certExpiredDate)
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable : true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
        ]}
        actions={[]}
        itemActions={[]}
        setPage={search.page} onFetchData={ async params => {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              if(params['name']){
                params.field = "name"
                params.query = params.name
              }
              if(params['email']){
                params.field = "email"
                params.query = params.email
              }
              if(params['npwp']){
                params.field = "npwp"
                params.query = params.npwp
              }
              try {
                await initData()

                let res = await service.getOrganization(params);
                var getAll = res.data
                setData(getAll)
                return {
                  data: getAll,
                  headers: {
                    'x-total-count': getAll.length
                  }
                }
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              if(values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.npwp`),
                key: 'npwp.contains',
                type: 'text',
                mask: '##.###.###.#-###.###',
                maskChar: '_',
                maskValue: 'string',
                width: '100%'
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.certExists`),
                key: 'certExists.equals',
                type: 'text',
                width: '100%',
                options : [
                  { label : "Ada Sertifikat", value : 'true' },
                  { label : "Tidak Ada Sertifikat", value : 'false' }
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.certExpiredDate`),
                key: 'certExpiredDate.lessOrEqualThan',
                type: 'text',
                width: '100%'
              },
            ]}
          />
        }
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      /> 
    </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(Organization))