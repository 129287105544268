import React, { useEffect, useState } from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { clearFormat } from './../../libs/react-mpk/services/number.service'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './DoppDokumenLain.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Checkbox
} from 'react-md'
import ErrorService from './../../services/errorService'
import _ from 'lodash'
import errorService from './../../services/errorService'
import LoadingOverlay from 'react-loading-overlay'
import iziToast from 'izitoast'
import AppMode from './../../AppMode'

const DoppDokumenLainForm = ({ authStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {} })
  const [isDisabled, setIsDisabled] = useState(false)
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [checkList, setCheckList] = useState([])

  const baseName = `doppDokumenLain`
  const basePath = `doppDokumenLain`

  const initialData = {
    loading: false,
    content: {}
  }
  
  const initData = async()=> {
    try {
      var content = props.mergeForm.documents
      content.map((d)=> {
        d.sortCode = parseInt(d.objectCode.split("-").join(""))
      })
      content = _.sortBy(content, 'sortCode')
      setCheckList(Object.assign([], content))
      var contentFormData = {}
      content.map((d, index)=> {
        contentFormData[`${d.id}_bruto`] = d.bruto
        contentFormData[`${d.id}_pph`] = d.pph
      })
      if(props.mergeForm.dopp){
        setData({ loading: false, content: props.mergeForm.dopp })
      } else {
        setData({ loading: false, content: contentFormData })
      }
    } catch (e) {
      ErrorService(e)
      setData({ loading: false, content: initialData })
    }
  }

  useEffect(() => {
    initData()
    let getSpt = JSON.parse(localStorage.getItem('spt'))
    if (getSpt.status == 'FINISH' || getSpt.status == 'SUBMIT') {
      setIsDisabled(true)
    }
  }, [props.mergeForm])

  var thousandSeparator = "."
  var decimalSeparator = ","
  try {
    if (localStorage.getItem('lang') == 'en') {
      thousandSeparator = ","
      decimalSeparator = "."
    }
  } catch (e) { }

  var formBody = []

  const handleCheck = async(d, index)=> {
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
    try {
      var formData = data.content
      if(formData[`${d.id}_bruto`] > 0 || formData[`${d.id}_pph`] > 0){
        iziToast.info({
          message: t.translate("words.validasiDoss")
        })
        setPloading({ loading: false, message: `` })
      } else {
        let res = await service.checkDokumenLain({
          id: d.id,
          active: !d.active
        })
        //
        var content = props.mergeForm.documents
        content.map((d)=> {
          d.sortCode = parseInt(d.objectCode.split("-").join(""))
        })
        content = _.sortBy(content, 'sortCode')
        var exampleData = content
        exampleData[index].active = !d.active
        setPloading({ loading: false, message: `` })
      }
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  try {
    var content = props.mergeForm.documents
    content.map((d)=> {
      d.sortCode = parseInt(d.objectCode.split("-").join(""))
    })
    content = _.sortBy(content, 'sortCode')
    var exampleData = content
    if(AppMode.onPremise){
      formBody = [
        [
          // {
          //   render: (
          //     <TableCell style={{ width: '0.5%' }}><strong>{t.translate(`words.action`)}</strong></TableCell>
          //   )
          // },
          {
            render: (
              <TableCell style={{ width: '0.5%' }}><strong>{t.translate(`words.nomor`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '35%', whiteSpace: 'normal' }}><strong>{t.translate(`words.doppDokumenLain1`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '10%' }}><strong>{t.translate(`words.kop`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '27%' }}><strong>{t.translate(`words.doppDokumenLain2`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '27%' }}><strong>{t.translate(`words.doppDokumenLain3`)}</strong></TableCell>
            )
          },
        ],
      ]
      exampleData.map((d, index)=> {
        formBody.push([
          // {
          //   render: (
          //     <TableCell><Checkbox checked={d.active} onChange={(c)=> { handleCheck(d, index) }} /></TableCell>
          //   )
          // },
          {
            render: (
              <TableCell>{index + 1}</TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ whiteSpace: 'normal' }}>{d.name}</TableCell>
            )
          },
          {
            render: (
              <TableCell>{d.objectCode}</TableCell>
            )
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            className: 'inputRight',
            label: '',
            key: `${d.id}_bruto`,
            type: 'text',
            disabled: !d.active
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            className: 'inputRight',
            label: '',
            key: `${d.id}_pph`,
            type: 'text',
            disabled: !d.active
          },
        ])
      })
    } else {
      formBody = [
        [
          {
            render: (
              <TableCell style={{ width: '0.5%' }}><strong>{t.translate(`words.action`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '0.5%' }}><strong>{t.translate(`words.nomor`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '35%', whiteSpace: 'normal' }}><strong>{t.translate(`words.doppDokumenLain1`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '10%' }}><strong>{t.translate(`words.kop`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '27%' }}><strong>{t.translate(`words.doppDokumenLain2`)}</strong></TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ width: '27%' }}><strong>{t.translate(`words.doppDokumenLain3`)}</strong></TableCell>
            )
          },
        ],
      ]
      exampleData.map((d, index)=> {
        formBody.push([
          {
            render: (
              <TableCell><Checkbox checked={d.active} onChange={(c)=> { handleCheck(d, index) }} /></TableCell>
            )
          },
          {
            render: (
              <TableCell>{index + 1}</TableCell>
            )
          },
          {
            render: (
              <TableCell style={{ whiteSpace: 'normal' }}>{d.name}</TableCell>
            )
          },
          {
            render: (
              <TableCell>{d.objectCode}</TableCell>
            )
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            className: 'inputRight',
            label: '',
            key: `${d.id}_bruto`,
            type: 'text',
            disabled: !d.active || props.inProcess //Tambahan Uci
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            className: 'inputRight',
            label: '',
            key: `${d.id}_pph`,
            type: 'text',
            disabled: !d.active || props.inProcess //Tambahan Uci
          },
        ])
      })
    }
  } catch (e) { }

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
      >
      </LoadingOverlay>
      <FormWrapper
        tableForm={true}
        showCommandbar={false}
        style={{
          maxWidth: '100%'
        }}
        className="fullWidth"
        loading={data.loading}
        onChange={async (formData, key, value) => {
          // if(key != 'dppTotal' || key != 'pphTotal'){
          //   formData['dppTotal'] = clearFormat(formData['a1Dpp']) + clearFormat(formData['b1Dpp']) + clearFormat(formData['c1Dpp']) + clearFormat(formData['d1Dpp'])
          //   formData['pphTotal'] = clearFormat(formData['a1Pph']) + clearFormat(formData['b1Pph']) + clearFormat(formData['c1Pph']) + clearFormat(formData['d1Pph'])
          // }
          props.setMergeForm({
            ...props.mergeForm,
            dopp: formData
          })
        }}
        baseId={`mod-form-${baseName}`}
        title={t.translate(`modules.${baseName}.formTitle`)}
        hintMessage={t.translate(`modules.${baseName}.formTitle`)}
        defaultData={data.content}
        definitions={{
          header: [],
          body: formBody
        }}
      // onSubmit={async (data, callback) => {
      //   var res ={}
      //   try {
      //     res = await service.put(data.id, data)
      //     callback(t.translate('sentences.tersimpan'), false)   
      //   } catch(e){
      //     ErrorService(e)
      //     callback("", false, false)
      //     return
      //   } 
      // }}
      />
    </>
  )
}

export default inject('authStore', 'envStore')(observer(DoppDokumenLainForm))
