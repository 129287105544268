import { http } from '../../libs/react-mpk/services';
import CrudService from '../../services/crudService';
import AppMode from '../../AppMode'

let service = null
var serviceUrl = '/spt'
var serviceInduk = '/bp/induk'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/spt');
  serviceUrl = '/api/eunifikasi/spt'
  serviceInduk = '/api/eunifikasi/bp/induk'
} else {
  service = new CrudService('/spt');
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

service.getAppInfo = async (query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/application-info`,
    query
  })
}

service.getAppInfoCore = async (query)=> {
  return http.request({
    method: http.methods.GET,
    // url: `/approval/application`,
    url: `/export/custom-feature-license`,
    query
  })
}

service.getState = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/state`,
    data
  })
}

service.getStateCore = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/approval/flow/state`,
    data
  })
}

service.pengajuanBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/pengajuan/bulk`,
    data
  })
}

service.pengajuanBulkCore = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/approval/flow/pengajuan`,
    data
  })
}

service.actionBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/action/bulk`,
    data
  })
}

service.actionBulkCore = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/approval/flow/action`,
    data
  })
}

service.cancelBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/mainFlow/cancel/bulk`,
    data
  })
}

service.cancelBulkCore = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/cancel-approval`,
    data
  })
}

service.getOrganizationOp = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/api/eunifikasi/organization-users/organization`,
    query
  })
}

service.getCertStatus = async(npwp)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/cert/search?npwp=${npwp}`
  })
}

service.pembetulan = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/rev/${id}`
  })
}

service.checkSpt = async(data) => {
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/check`,
    data,
  })
}

service.postingSpt = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/posting/${id}`,
  })
}

service.submitSpt = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/submit/${id}`,
    config: {
      headers: {
        "X-Approval-Enabled": false
      }
    }
  })
}

service.submitSptApproval = async(id) => {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/submit/${id}`,
    config: {
      headers: {
        "X-Approval-Enabled": true
      }
    }
  })
}

service.history = async(id, query) => {
  return http.request({
    method : http.methods.GET,
    url : `${serviceUrl}/history/${id}`,
    query
  })
}

service.summary = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/spt/debt`,
    query,
  })
}

service.report = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceInduk}/report`,
    query
  })
}

service.donwloadId = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/spt/pdf`,
    data
  })
}

service.downloadAll = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/download`,
    data,
  })
}

service.donwloadIdXls = async(sptId)=> {
  return http.request({
    method: http.methods.GET,
    // url: `/export/spt/summary/bp/${sptId}`
    url: `/export/summary-i/${sptId}`
  })
}

service.downloadAllXls = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-i`,
    data,
  })
}

service.downloadAllXlsOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/bp`,
    url: `/api/eunifikasi/export/summary-i`,
    data: {
      ids: data
    },
  })
}

service.downloadAllXlsSearch = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-i/search?all=${all}`,
    data,
  })
}

service.downloadAllXlsSearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/bp/search?all=${all}`,
    url: `/api/eunifikasi/export/summary-i/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadAllXlsDetail = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-ii`,
    data,
  })
}

service.downloadAllXlsDetailOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/bp/detail`,
    url: `/api/eunifikasi/export/summary-ii`,
    data: {
      ids: data
    },
  })
}

service.downloadAllXlsDetailSearch = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-ii/search?all=${all}`,
    data,
  })
}

service.downloadAllXlsDetailSearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/bp/detail/search?all=${all}`,
    url: `/api/eunifikasi/export/summary-ii/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadAllXlsSummarySPT = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-iii`,
    data,
  })
}

service.downloadAllXlsSummarySPTOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary`,
    url: `/api/eunifikasi/export/summary-iii`,
    data: {
      ids: data
    },
  })
}

service.downloadAllXlsSummarySPTSearch = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-iii/search?all=${all}`,
    data,
  })
}

service.downloadAllXlsSummarySPTSearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/search?all=${all}`,
    url: `/api/eunifikasi/export/summary-iii/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadAllXlsSummarySPTDetail = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-iv`,
    data,
  })
}

service.downloadAllXlsSummarySPTDetailOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/detail`,
    url: `/api/eunifikasi/export/summary-iv`,
    data: {
      ids: data
    },
  })
}

service.downloadAllXlsSummarySPTDetailSearch = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-iv/search?all=${all}`,
    data,
  })
}

service.downloadAllXlsSummarySPTDetailSearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/detail/search?all=${all}`,
    url: `/api/eunifikasi/export/summary-iv/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadSummaryVIIOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/summary-astra-i`,
    url: `/api/eunifikasi/export/summary-vii`,
    data: {
      ids: data
    },
  })
}

service.downloadSummaryVIISearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    // url: `/api/eunifikasi/export/spt/summary/summary-astra-i/search?all=${all}`,
    url: `/api/eunifikasi/export/summary-vii/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadSummaryVIICore = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-vii`,
    data,
  })
}

service.downloadSummaryVIISearchCore = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-vii/search?all=${all}`,
    data,
  })
}

service.downloadSummaryVIIIOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-viii`,
    data: {
      ids: data
    },
  })
}

service.downloadSummaryVIIISearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-viii/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadSummaryVIIICore = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-viii`,
    data,
  })
}

service.downloadSummaryVIIISearchCore = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-viii/search?all=${all}`,
    data,
  })
}

service.downloadSummaryIXOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-ix`,
    data: {
      ids: data
    },
  })
}

service.downloadSummaryIXSearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-ix/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadSummaryIXCore = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-ix`,
    data,
  })
}

service.downloadSummaryIXSearchCore = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-ix/search?all=${all}`,
    data,
  })
}

service.downloadSummaryXOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-x`,
    data: {
      ids: data
    },
  })
}

service.downloadSummaryXSearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-x/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadSummaryXCore = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-x`,
    data,
  })
}

service.downloadSummaryXSearchCore = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-x/search?all=${all}`,
    data,
  })
}

service.downloadSummaryXIOP = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-xi`,
    data: {
      ids: data
    },
  })
}

service.downloadSummaryXISearchOP = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/export/summary-xi/search?all=${all}`,
    data: {
      request: data
    },
  })
}

service.downloadSummaryXICore = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-xi`,
    data,
  })
}

service.downloadSummaryXISearchCore = async(data, all)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/summary-xi/search?all=${all}`,
    data,
  })
}

service.downloadSearch = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/download`,
    data,
  })
}

service.opPdfBp = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/spt/summary/bp/pdf`,
    data
  })
}

service.opPdfBpe = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/spt/summary/bpe/pdf`,
    data
  })
}

service.opSptSummaryIndukPdf = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/api/eunifikasi/spt/summary/induk/pdf`,
    data
  })
}

service.opSptSummary = async(data)=>{
  return http.request({
    method : http.methods.POST,
    url : `/api/eunifikasi/spt/summary`,
    data
  })
}

service.OpSptSummaryIdXls = async(data)=>{
  return http.request({
    method : http.methods.GET,
    url : `/api/eunifikasi/spt/summary`,
    data
  })
}

service.OpArchive = async(data)=>{
  return http.request({
    method : http.methods.POST,
    url : `/api/eunifikasi/spt/archive`,
    data
  })
}

service.createBulk = async (data, all)=> {
  if(all == undefined || all == 'udenfined' || all == null || all == 'null'){
    all = false
  }
  data.month = parseInt(data.month)
  data.year = parseInt(data.year)
  return http.request({
    method: http.methods.POST,
    url: `${serviceUrl}/multi?all=${all}`,
    data
  })
}

service.indukOnly = async (data, indukOnly)=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/spt/pdf?indukOnly=${indukOnly}`,
    data
  })
}

service.indukOnlySearch = async (query, data )=> {
  return http.request({
    method: http.methods.POST,
    url: `/export/spt/pdf/search`,
    data,
    config: {
      params: query
    }
  })
}

service.saveIndukBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/induk/save/bulk`,
    data
  })
}

service.saveDoppDossBulk = async (data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/doppss/save/bulk`,
    data
  })
}

service.pemadananNpwp = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/spt/pemadanan`,
    data
  })
}

export default service;