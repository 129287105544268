import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpLogCetakOrder.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button, Chip} from 'react-md'
import errorService from '../../services/errorService'
import iziToast from 'izitoast'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import fileSaver from 'file-saver'
import LoadingOverlay from 'react-loading-overlay'

const OpLogCetakOrder = ({
  className       = '',
  showCommandbar  = true,
  modalStore,

}) => {
  const [data, setData] = useState([])
  const [getStatus, setStatus] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const baseId = 'mod-op-logCetakOrder'
  const basePath = 'logOrder'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData(){
    try{
      const status = await service.coreStatus()
      setStatus(status.data)
    } catch(e){
      errorService(e)
    }
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.deleteBulk([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function downloadByIdExc(item) {
    try {
      let res = await service.download(item.id)
      const blob = new Blob([res.data], {type: 'application/zip'})
      fileSaver.saveAs(blob, res.headers.filename);
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function downloadById(item) {
    modalStore.showConfirm({
      title : t.translate('words.download'),
      children : t.translate('words.confirmDownload'),
      onSubmit : (callback) => {
        downloadByIdExc(item)
        callback()
      }
    })
  }

  return (
    <>
    <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
    >
    </LoadingOverlay>
    <TableWrapper useFilter={false}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      selectable={true}
      defaultSortBy="createdDate"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render : (
                <div>
                  {getStatus.success &&
                    <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.connected")}</Chip>
                  }
                  {!getStatus.success &&
                    <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.notConnected")}</Chip>
                  }
                </div>
              )
            },
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.typeTax`),
              key: 'jenisPajak.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.jenisSetoran`),
              key: 'jenisSetoran.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.month1`),
              key: 'bulan1.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.month2`),
              key: 'bulan2.equals',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.year`),
              key: 'year.equals',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable : true,
          key:'npwp',
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.jenisPajak'),
          searchable: true,
          sortable : true,
          key:'jenisPajak',
          render: item => (item.jenisPajak.code)
        },
        {
          label: t.translate('words.jenisSetoran'),
          searchable: true,
          sortable : true,
          key:'jenisSetoran',
          render: item => (item.jenisSetoran.code)
        },
        {
          label: t.translate('words.masaAwal'),
          searchable: true,
          sortable : true,
          key:'masaAwal',
          render: item => (item.bulan1.code)
        },
        {
          label: t.translate('words.masaAkhir'),
          searchable: true,
          sortable : true,
          key:'masaAkhir',
          render: item => (item.bulan2.code)
        },
        {
          label: t.translate('words.year'),
          searchable: true,
          sortable : true,
          key:'year',
          render: item => (item.year)
        },
        {
          label: t.translate('words.total'),
          searchable: true,
          sortable : true,
          key:'total',
          render: item => (item.totalItem)
        },
        {
          label: t.translate('words.status'),
          searchable: true,
          sortable : true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.success){
              return <Chip style={{ background: "#8BC34A", color: 'white'}}>{t.translate("words.ya")}</Chip>
            } else {
              return <Chip style={{ background: "#F44336", color: 'white'}}>{t.translate("words.tidak")}</Chip>
            }
          }
        },
        {
          label: t.translate('words.message'),
          searchable: true,
          sortable : true,
          key: 'message',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.message)
        },
        {
          label: t.translate('words.id'),
          searchable: true,
          sortable : true,
          key: 'id',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.id)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', ()=> {deleteBulk()}) 
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.download')}`, 'mdi mdi-download', (item)=>{downloadById(item)})  
      ]}
      setPage={search.page} onFetchData={(params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let getPage = null
              getPage = await service.get(params)
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpLogCetakOrder))
