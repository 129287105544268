import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './OpUserOrg.service'
import _ from 'lodash'
import ErrorService from './../../services/errorService'

const OpUserOrgForm = ({ 
  match, 
  navigationStore 
}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [organization, setOrganization] = useState([])
  const [users, setUsers] = useState([])
  const [customRules, setCustomRules] = useState({})

  const basePath = 'userOrganisasi'
  const baseId = 'mod-userOrganisasi-form'

  useEffect(() => {   
    getOrganizationsOp()
    getUsers()
    initData()
  }, [])

  async function initData(){
    if(match.params.id == 'new') {
      try {
        setData({loading: false})
      } catch(e){
        ErrorService(e)
      }
    } else {
      const res = await service.getOne(match.params.id)
      let org = await service.getOrganizationOp({page: 0,
        size: 100,
        sort: 'createdDate,DESC'})

      org.data.map((d)=>{
        if(d.npwp == res.data.npwp){
          res.data.organization =  d.npwp
          res.data.login = d.name
          res.data.city = d.city
          res.data.postalCode = d.postalCode
          res.data.email = d.email
          res.data.phone = d.phone
          res.data.address = d.address
          res.data.active = d.active
        }
      })
      res.data.user = res.data.user.id
      setData({loading: false, content: res.data})
    }
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getOrganizationOp(params)
      // let res = JSON.parse(localStorage.getItem('org'))

      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      ErrorService(e)
      return []
    }
  }

  const getUsers = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await service.getUser(params)
      // let res = JSON.parse(localStorage.getItem('user'))

      let user = res.data.filter((d)=>{
        return d.authorities[0].name != "ROLE_EPPT_ADMIN"
      })
      user.map((d)=> {
        d.label = d.login
        d.value = d
      })
      setUsers(user)
      return user
    } catch(e){
      ErrorService(e)
    }
  }
  
  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo = {`/onpremise/${basePath}`}
      baseId = {`${baseId}`}
      title = {t.translate(`modules.${basePath}.formTitle`)}
      onChange = {(formData, key, value)=> {
        if(key == 'organization'){
          formData.npwp = formData.organization.npwp
          formData.name = formData.organization.name
          formData.city = formData.organization.city
          formData.postalCode = formData.organization.postalCode
          formData.email = formData.organization.email
          formData.phone = formData.organization.phone
          formData.address = formData.organization.address
          formData.active = formData.organization.active
        }
      }}
      customRules = {customRules}
      defaultData = {data.content}
      definitions = {[
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.organization`),
          key: 'organization',
          validation: 'required',
          labelKey: 'label',
          valueKey: 'label',
          data: organization,
          options: organization,
          async: true,
          defaultOptions: organization,
          // isMulti: true,
          loadOptions: async (inputValues)=> {
            if(inputValues.length % 2){
              var options = await getOrganizationsOp(inputValues)
              return options
            } else {
              return organization
            }
          },
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.user`),
          key : 'user',
          validation: 'required',
          labelKey: 'label', 
          valueKey: 'label',
          data: users,
          options : users,
          async: true,
          defaultOptions: users,
          loadOptions: async (inputValues)=> {
            var filter = users.filter((org)=> {
              return org.label.indexOf(inputValues) != -1
            })
            return filter
          }
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.npwp`),
          key: 'npwp',
          type: 'text',
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          width: '100%',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.name'),
          key: 'name',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.city'),
          key: 'city',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.postalCode'),
          key: 'postalCode',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.email'),
          key: 'email',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.phone'),
          key: 'phone',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('words.address'),
          key: 'address',
          type: 'text',
          disabled : true
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate('words.active'),
          key: 'active',
          disabled : true,
        },
      ]}
      onSubmit={async (data, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, data)
          let user = JSON.parse(localStorage.getItem('user'))
          // // console.log(formData)
          delete data.organization.value
          delete data.user.value
          if(match.params.id == 'new') {
            res = await service.createdOne({
              npwp : formData.npwp,
              organization : formData.organization,
              user : formData.user
            })
          } else {
            res = await service.editById(formData.id, {
              npwp : formData.npwp,
              organization : formData.organization,
              user : formData.user
            })
          }
          callback(t.translate('sentences.tersimpan'), false, false)
          navigationStore.redirectTo(`/onpremise/${basePath}`)     
        } catch(e){
          ErrorService(e)
          callback("", false, false, false)
          return
        } 
      }} 
    />
  )
}

export default inject('authStore', 'envStore', 'navigationStore')(observer(OpUserOrgForm))
