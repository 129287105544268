import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import TableCUstom from './TableCustom'
import { Chip, List, ListItem } from 'react-md'

const LimaBesar = ({
  authStore,
  inSpt,
  match,
  modalStore,
  ...props
}) => {
	
  function formatNpwp(value) {
    if (typeof value === 'string') {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
  }

  return (
    <TableCUstom
      fit
      showMore={true}
      showDataLength={2}
      showIndex={false}
      title='Status Lapor'
      showActionColumn={false}
      data={props.data}
      loading={false}
      columns={[
        {
          key: "periode",
          label: "Periode",
          render: item =>item.periode,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "status",
          label: "Status",
          render: item => {
            return (
              <List>
                {Object.keys(item.bp).map((name) =>{ 
                  var chips = null
                  if (name == "POSTING") {
                    chips = <Chip className='chip' style={{ background: "#FFC108", color: 'white' }}>{(name + "").toUpperCase()}</Chip>
                  } else if (name == "COMPLETED") {
                    chips = <Chip className='chip' style={{ background: "#2096F3", color: 'white' }}>{(name + "").toUpperCase()}</Chip>
                  } else if (name == "FINISH") {
                    chips = <Chip className='chip' style={{ background: "#8BC34A", color: 'white' }}>{(name + "").toUpperCase()}</Chip>
                  } else if (name == "FAILED") {
                    chips = <Chip className='chip' style={{ background: "#F44336", color: 'white' }}>{(name + "").toUpperCase()}</Chip>
                  } else if (name == "DELETED") {
                    chips = <Chip className='chip' style={{ background: "grey", color: 'white' }}>{(name + "").toUpperCase()}</Chip>
                  } else {
                    chips = <Chip style={{ background: "black", color: 'white' }}>{(name + "").toUpperCase()}</Chip>
                  }
                  return <ListItem>{chips}</ListItem>
                })}
              </List>
            )
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "count",
          label: "NPWP",
          render: item => {
            return (
              <List>
                {Object.values(item.bp).map(name => <ListItem>{name.length}</ListItem>)}
              </List>
            )
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
      ]}
    />
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(LimaBesar))