import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import TableCUstom from './TableCustom'
import service from './DashboardSpt.service'

const DaftarSpt = ({
  authStore,
  inSpt,
  match,
  modalStore,
  ...props
}) => {
  const [organization, setOrganization] = useState([])
	
  function formatNpwp(value) {
    if (typeof value === 'string') {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
  }

  useEffect(async()=>{
    if(!inSpt){
      getOrganizations()
    }
  },[inSpt])


  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og.npwp,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d.npwp,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  return (
    <TableCUstom
      fit
      npwpOptions={organization.length > 0 ? organization : []}
      tableActions={!inSpt}
      isFlex={true}
      isDense={true}
      title='Daftar SPT'
      showActionColumn={false}
      data={props.data}
      loading={false}
      tableFooter={
        <div>{"test"}</div>
      }
      query={props.genDefaultQuery}
      onChangeQuery={(formData)=>{
        delete formData.page
        delete formData.size
        delete formData.sort
        delete formData.sortBy
        delete formData.startDate
        delete formData.keyword
        delete formData.endDate
        delete formData.column
        delete formData.columnCriteria
        delete formData.sortCriteria
        props.setgenDefaultQuery({...props.genDefaultQuery, ...formData})
      }}
      columns={[
        {
          key: "status",
          label: "Status Lapor",
          render: item =>item.d21,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "npwp",
          label: "NPWP",
          render: item =>{
            return formatNpwp(item.d22)
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "month",
          label: "Masa",
          render: item => item.d23,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "year",
          label: "Tahun",
          render: item => item.d24,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "rev",
          label: "Pembetulan",
          render: item => item.d25,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "ntte",
          label: "NTTE",
          render: item => item.d26,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "date",
          label: "date",
          render: item => item.d27,
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          key: "nominal",
          label: "Nominal",
          render: item => {
            try{
              return item.d28.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
            }catch(e){
              return "-"
            }
          },
          searchable: true,
          sortable: true,
          type: "string",
        },
      ]}
    />
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(DaftarSpt))