import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/npwp'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/npwp');
  serviceUrl = '/api/eunifikasi/npwp'
} else {
  service = new CrudService('/npwp');
}

service.request = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `${serviceUrl}/request/report`,
    query
  })
}

service.pemadananNpwp = async(data)=> {
    return http.request({
      method: http.methods.POST,
      url: `/master/npwp/pemadanan`,
      data
    })
  }

export default service;