import React, { useEffect, useState } from 'react'
import { Table, TableContainer, TableHeader, TableRow, TableCell, TableBody, Button, FontIcon, DropdownMenu, BOTTOM_RIGHT_ANCHOR, TOP_RIGHT_ANCHOR, TableFooter, LinearProgress, CircularProgress } from 'react-md'
import { defaultQueryParameter, dataTypes, sorts } from '../../config/constant'
import t from 'counterpart'
import './DataTable.scss'
import Pagination from '../Pagination'
import Flex from '../Flex'
import LoaderInfo from '../LoaderInfo'
import { useIndeterminateChecked } from '@react-md/form';
import { TableCheckbox } from 'react-md'
import NotFoundData from '../NotFoundData'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import { TooltipHoverModeConfig, Tooltipped } from '@react-md/tooltip';

const DataTable = ({
  baseId = 'mpk-data-table-id',
  className = '',
  columns = [],
  itemActions = [],
  data = [],
  showActionColumn = true,
  fit = true,
  showIndex = true,
  query = defaultQueryParameter(),
  totalData = 0,
  loading = true,
  selectable = false,
  offsetLeft = 0,
  onChangeQuery = () => { },
  showHeader = true,
  ...props
}) => {
  const [mounted, setMounted] = useState(false)
  const [bound, setBound] = useState({ width: 0, height: 0 })
  const [currentActionId, setCurrentActionId] = useState(null)
  const [currentActionStyle, setCurrentActionStyle] = useState({})
  const [sort, setSort] = useState({ sort: 'descending', sortBy: '' })
  const resources = props.authStore.user.resources

  const getItemActions = item => {
    let newItemActions = itemActions.filter(d => (
      typeof d.show === 'boolean'
        ? d.show
        : typeof d.show === 'function'
          ? d.show(item)
          : true
    ))
    let newNewItemActions = _.filter(newItemActions, function (d) {
      if (d.resourceUri) {
        if (resources.indexOf(d.resourceUri) >= 0) {
          return d
        }
      } else {
        return d
      }
    });
    return newNewItemActions
  }

  useEffect(() => {
    if (!mounted) {
      try {
        setTimeout(() => {
          const container = document.getElementById(baseId)
          if (container) {
            setBound({
              width: container.clientWidth,
              height: container.clientHeight
            })
          }
        }, 500)
      } catch (e) { }
      setMounted(true)
    }
  }, [baseId, mounted])

  const { rootProps, getProps } = useIndeterminateChecked(
    data.map((item) => item)
  );
  let selected = []
  data.map((d, index) => {
    const checkboxProps = getProps(d);
    if (checkboxProps.checked) {
      selected.push(index)
    }
  })
  localStorage.setItem(`${baseId}-selected-item`, JSON.stringify(selected))

  const onSort = async (col) => {
    let { setQuery = () => { }, handleFetchData = () => { } } = props
    if (col.sortable) {
      if (col.key == sort.sortBy) {
        if (sort.sort == sorts.descendingLong) {
          setSort({ sort: sorts.ascendingLong, sortBy: col.key })
          query.sortBy = col.key
          query.sort = sorts.ascending
          setQuery(query)
          onChangeQuery(query)
        } else {
          setSort({ sort: sorts.descendingLong, sortBy: col.key })
          query.sortBy = col.key
          query.sort = sorts.descending
          setQuery(query)
          onChangeQuery(query)

        }
      } else {
        setSort({ sort: sorts.descendingLong, sortBy: col.key })
        query.sortBy = col.key
        query.sort = sorts.descending
        setQuery(query)
        onChangeQuery(query)
      }
      handleFetchData()
    }
  }

  const tableContent = (
    <Table fullWidth className="mpk-animation slide-in">
      {showHeader && (
        <TableHeader sticky>
          <TableRow>
            {selectable &&
              <TableCheckbox
                id={`${baseId}-selectable-rows-root-checkbox`}
                sticky="header-cell"
                {...rootProps}
              />
            }
            {showIndex && (
              <TableCell

              >
                {t.translate('mpk.column.index')}
              </TableCell>
            )}
            {showActionColumn && (
              <TableCell
                sticky="header-cell"
                style={{ zIndex: data.length + 10 }}
              >
                {t.translate('mpk.column.actions')}
              </TableCell>
            )}

            {columns.map(col => (
              <TableCell key={`${baseId}-${col.label}`} aria-sort={sort.sortBy == col.key ? sort.sort : 'none'} onClick={() => onSort(col)}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHeader>
      )}
      <TableBody>
        {data.map((d, i) => {
          let dItemActions = getItemActions(d)
          const checkboxProps = getProps(d);
          const { checked, onChange } = checkboxProps;
          return (
            <TableRow key={`${baseId}-row-${i}`}
              selected={selectable ? checked : false}
              onClick={onChange}
            >
              {selectable &&
                <TableCheckbox
                  id={`selectable-rows-checkbox-${i + 1}`}
                  {...checkboxProps}
                  sticky
                />
              }
              {showIndex && query && (
                <TableCell
                  className="numeric"
                >
                  {((query.page - 1) * query.size) + (i + 1)}
                </TableCell>
              )}
              {showActionColumn
                ? (itemActions.length > 0 && dItemActions.length > 0 ? (
                  <TableCell
                    sticky="cell"
                    className="actions"
                    style={{
                      zIndex: (`${baseId}-${i}` === currentActionId ? (data.length - 1) + 2 : 1)
                    }}
                  >
                    {dItemActions.length === 1 ? (
                      <div className="mpk-position position-relative mpk-align align-center item-action">

                        <TooltipHoverModeConfig
                          enabled={true} delayTimeout={2} defaultDelay={2}
                          key={`commandbar-action-${i}`}
                        >
                          <Tooltipped
                            id={`auto-positioning-above ${dItemActions[0].label}`}
                            tooltip={`${dItemActions[0].label}`}
                            defaultPosition="below"
                          >
                            <Button
                              buttonType="icon"
                              theme="warning"
                              onClick={() => dItemActions[0].onClick(d, i)}
                              style={{ background: 'transparent !important' }}
                            >
                              <FontIcon iconClassName={dItemActions[0].iconClassName} />
                            </Button>

                          </Tooltipped>
                        </TooltipHoverModeConfig>
                      </div>
                    ) : (
                      <div className="mpk-position position-relative mpk-align align-center item-action">
                        <DropdownMenu
                          id={`${baseId}-row-actions-${i}`}
                          className="mpk-position position-relative"
                          anchor={i < 10 ? TOP_RIGHT_ANCHOR : BOTTOM_RIGHT_ANCHOR}
                          disableDropdownIcon
                          buttonType="icon"
                          onClick={(e) => {
                            // let bound = e.target.getBoundingClientRect()
                            // // console.log(e.pageX, e.pageY)
                            // // console.log(bound.left, bound.top)
                            setCurrentActionId(`${baseId}-${i}`);
                            // setTimeout(() => {
                            //   let target = document.getElementById(`${baseId}-row-actions-${i}-menu`)
                            //   // console.log(target)
                            //   if(target){
                            //     target.style.top = `${bound.top}px`
                            //     target.style.left = `${bound.left}px`
                            //   }
                            // })
                          }}
                          items={dItemActions.map(action => ({
                            children: typeof action.label === 'function' ? action.label(d) : action.label,
                            leftAddon: (
                              <FontIcon
                                iconClassName={
                                  typeof action.iconClassName === 'function' ? action.iconClassName(d) : action.iconClassName
                                }
                              />
                            ),
                            onClick: () => action.onClick(d, i)
                          }))}
                        >
                          <FontIcon iconClassName="mdi mdi-menu" />
                        </DropdownMenu>
                      </div>
                    )}
                  </TableCell>
                ) : (
                  <TableCell
                    className="mpk-align align-center"
                    sticky="cell"
                  >
                    <FontIcon iconClassName="mdi mdi-block-helper" />
                  </TableCell>
                ))
                : null
              }
              {columns.map(col => (
                <TableCell
                  key={`${baseId}-row-${i}-${col.label}`}
                  className={`${col.type === dataTypes.NUMBER ? 'numeric' : ''}${fit ? '' : 'cell-table'}`}
                >
                  {col.render(d, i)}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
      {/* { query && (
        <TableFooter 
          sticky
        >
          <TableRow>
            <TableCell colSpan="100%">
              <Pagination
                baseId={`${baseId}-pagination`}
                page={query.page}
                size={query.size}
                totalData={totalData}
                style={{
                  position: 'sticky',
                  left: 0
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      )} */}
    </Table>
  )

  return (
    <div
      id={baseId}
      className={`mpk-data-table ${fit ? 'fit' : ''} ${className}`}
    >
      <div className={fit ? 'fit' : ''}>
        {loading && data.length > 0 && (
          <div className="main-loader">
            <LinearProgress id={`${baseId}-linerar-progress`} />
          </div>
        )}
        {loading && data.length === 0 ? (
          fit ? (
            <Flex
              align={Flex.properties.align.CENTER}
              justify={Flex.properties.justify.CENTER}
              className="mpk-full full-height"
              direction={Flex.properties.direction.COLUMN}
            >
              <CircularProgress id={`${baseId}-circular-progress`} />
              <div className="mpk-font size-M weight-B mpk-margin-N margin-top">
                {t.translate('mpk.sentence.loadingData')}
              </div>
              {/* <LoaderInfo 
                className="mpk-padding-N padding-top padding-bottom"
                style={{background: 'white'}}
              >
                {t.translate('mpk.sentence.loadingData')}
              </LoaderInfo> */}
            </Flex>
          ) : (
            <LoaderInfo>Loading Data</LoaderInfo>
          )
        ) : (fit ? (
          <Flex
            direction={Flex.properties.direction.COLUMN}
            className="mpk-full full-height full-width mpk-position position-relative"
          >
            <div className="mpk-full full-width flex">
              {data.length === 0 ? <NotFoundData /> : (
                <TableContainer
                  style={{
                    height: bound.height,
                    background: 'transparent'
                  }}
                >
                  {tableContent}
                </TableContainer>
              )}
            </div>
            {/* <div className="pagination-container mpk-padding-S padding-left padding-right mpk-flex align-center">
                <Pagination
                  baseId={`${baseId}-pagination`}
                  className="flex"
                  page={query.page}
                  size={query.size}
                  totalData={totalData}
                />
              </div> */}
          </Flex>
        ) : (tableContent)
        )}
      </div>
    </div>
  )
}

DataTable.dataTypes = dataTypes;
DataTable.getSelected = (baseId, asList = false) => {
  if (asList) return JSON.parse(localStorage.getItem(`${baseId}-selected-item`))
  else return localStorage.getItem(`${baseId}-selected-item`)
}

export default inject('authStore')(observer(DataTable))
