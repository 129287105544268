import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp.service'
import SptService from './../DaftarSpt/DaftarSpt.service'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import Bluebird from 'bluebird'
import RSelect from 'react-select'
import AppMode from '../../AppMode'
import { Button, CardContent, Card, Chip, FontIcon, Dialog, DialogContent } from 'react-md'
import OrganizationView from './../Organization/OrganizationPicker.table'
import OpOrganizationTable from '../OpOrganization/OpOrganizationPicker.table'
import iziToast from 'izitoast'

const BpBulkForm = ({ authStore, envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [organization, setOrganization] = useState([])
  const [taxobjectCode, setTaxObjectCode] = useState([])
  const [dialogOrg, setDialogOrg] = useState(false);
  const [showOrg, setShowOrg] = useState(true);
  const [inSpt, setInSpt] = useState(false);

  const baseName = `bp-bulk`
  const basePath = `bp`

  async function getTaxObjectCode(type) {
    setTimeout(async () => {
      let res = await service.getTaxObjectCode(type)
      var taxobjectCode = []
      res.data.map((d) => {
        if (d.ss == false) {
          if (type == 'PPH4-2') {
            var bannedCode = ['28-404-01', '28-404-02', '28-404-03', '28-404-04', '28-404-05', '28-404-06', '28-404-07', '28-404-08', '28-404-09', '28-404-10', '28-404-11', '28-401-01', '28-401-02', '28-407-01', '28-407-02',]
            var codeInBanned = bannedCode.filter((bannedD) => {
              return bannedD == d.code
            })
            if (codeInBanned.length > 0) { } else {
              taxobjectCode.push({ label: `${d.code} - ${d.description}`, value: d.code })
            }
          } else {
            taxobjectCode.push({ label: `${d.code} - ${d.description}`, value: d.code })
          }
          setTaxObjectCode(taxobjectCode)
        }
      })
    })
  }

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 1000
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      ErrorService(e)
      return []
    }
  }

  useEffect(() => {
    async function initData(){
      try {
        // if (AppMode.onPremise) {
        //   try {
        //     await getOrganizationsOp()
        //   } catch(e){}
        // } else {
        //   try {
        //     await getOrganizations()
        //   } catch(e){}
        // }
      } catch(e){}
      if (window.location.href.indexOf('open') != -1) {
        var spt = JSON.parse(localStorage.getItem('spt'))
        setData({loading: false, content: {
          allRev: false,
          allRevNo: true,
          all: false,
          'month.greaterOrEqualThan': spt.month,
          'month.lessOrEqualThan': spt.month,
          'year.greaterOrEqualThan': spt.year,
          'year.lessOrEqualThan': spt.year
        }})
        setOrganization([spt])
        setInSpt(false)
      } else {
        setData({loading: false, content: {
          allRev: false,
          allRevNo: true,
          all: false
        }})
        setInSpt(true)
      }
    }
    initData()
  }, [])

  var backTo = `/product/company/${match.params.companyId}/${basePath}`
  if(AppMode.onPremise){ 
    backTo = `/onpremise/${basePath}`
  }
  if (window.location.href.indexOf('open') != -1) {
    backTo = `/product/company/${match.params.companyId}/daftar-spt/open/${match.params.sptId}/${basePath}`
    
    if(AppMode.onPremise) backTo = `/onpremise/daftar-spt/open/${match.params.sptId}/${basePath}`
  }

  var options = [
    { label: 'COMPLETED', value: 'COMPLETED' },
    { label: 'FINISH', value: 'FINISH' },
    { label: 'DELETED', value: 'DELETED' },
  ]

  return (
    <FormWrapper
      style={{
        maxWidth: '100%'
      }}
      loading={data.loading}
      actions={[]}
      backTo={backTo}
      baseId={`mod-form-${baseName}`}
      title={t.translate('words.titleBp')}
      submitLabel={t.translate('words.report')}
      defaultData={data.content}
      onChange={async (formData, key, value) => {
        if (key == 'type.equals') {
          await getTaxObjectCode(value)
        }
        if(key == 'all' && value){
          setOrganization([])
          setShowOrg(false)
        } else {
          try {
            if(formData.all == true){
              setShowOrg(false)
            } else {
              setShowOrg(true)
            }
          } catch(e){
            setShowOrg(true)
          }
        }
        if(key == 'allRevNo' && value){
          formData.allRevYes = false
          formData.allRevNo = true
        }
        if(key == 'allRevYes' && value){
          formData.allRevYes = true
          formData.allRevNo = false
        }
      }}
      definitions={[
        {
          inputType: inputTypes.INPUT,
          label: t.translate(`words.identity`),
          key: 'identity.contains',
          type: 'text',
          width: '100%',
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate(`words.typeBP`),
          key: 'type.equals',
          width: '100%',
          type: 'text',
          options: [
            { label: "PPh Pasal 15", value: "PPH15" },
            { label: "PPh Pasal 22", value: "PPH22" },
            { label: "PPh Pasal 23", value: "PPH23" },
            { label: "PPh Pasal 4a2", value: "PPH4-2" }
          ]
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.kodeObjekPajak`),
          key: 'objectCode.contains',
          type: 'text',
          width: '100%',
          options: taxobjectCode
        },
        {
          show: inSpt,
          validation: 'required',
          inputType: inputTypes.SELECT,
          label: t.translate(`words.masaAwal`),
          key: 'month.greaterOrEqualThan',
          type: 'text',
          width: '25%',
          options: [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 },
            { label: '10', value: 10 },
            { label: '11', value: 11 },
            { label: '12', value: 12 },
          ]
        },
        {
          show: inSpt,
          validation: 'required',
          inputType: inputTypes.SELECT,
          label: t.translate(`words.masaAkhir`),
          key: 'month.lessOrEqualThan',
          type: 'text',
          width: '25%',
          options: [
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 },
            { label: '10', value: 10 },
            { label: '11', value: 11 },
            { label: '12', value: 12 },
          ]
        },
        {
          show: inSpt,
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.dariTahun`),
          key: 'year.greaterOrEqualThan',
          type: 'text',
          width: '25%',
          validation: 'required'
        },
        {
          show: inSpt,
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: t.translate(`words.keTahun`),
          key: 'year.lessOrEqualThan',
          type: 'text',
          width: '25%',
          validation: 'required'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.allStatus`),
          key: 'allStatus',
          type: 'text',
          width: '100%',
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: t.translate(`words.status`),
          key: 'status.in',
          type: 'text',
          width: '100%',
          isMulti: true,
          options: options
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.revisiAkhir`),
          key: 'allRevNo',
          type: 'text',
          width: '100%',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.semuaRevisi`),
          key: 'allRevYes',
          type: 'text',
          width: '100%',
        },
        {
          show: inSpt,
          inputType: inputTypes.CHECKBOX,
          label: t.translate(`words.allNpwp`),
          key: 'all',
          width: '100%',
          type: 'text',
        },
        {
          show: (showOrg && inSpt),
          render: (
            <div style={{width: '100%'}}>
              <Button themeType="outline" theme="primary" onClick={(e)=> setDialogOrg(true) }>{t.translate("words.pilihOrganisasi")}</Button>
              <Button themeType="outline" theme="error" style={{ marginLeft: 10 }} onClick={(e)=> setOrganization([])} >{t.translate("words.hapusOrganisasi")}</Button>
              <Card style={{width: '100%', marginTop: '25px'}}>
                <CardContent>
                  {organization.length > 0 &&
                    <>
                      {organization.map((d, i)=> {
                        return <Chip onClick={()=> {
                          var organizationFilter = organization.filter((d)=> {
                            return d.id != d.id
                          })
                          setOrganization(organizationFilter)
                        }} style={{ margin: 5 }} theme="outline" rightIcon={<FontIcon>delete</FontIcon>}>{d.npwp} - {d.name}</Chip>
                      })}
                    </>
                  }
                </CardContent>
              </Card>
            </div>
          )
        },
        {
          show: inSpt,
          render: (
            <div>
              <Dialog
                visible={dialogOrg}
                onRequestClose={(e)=> setDialogOrg(false)}
                style={{
                  width: 1024,
                  height: 600,
                }}
              >
                <DialogContent>
                  {!AppMode.onPremise &&
                    <OrganizationView setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                  }
                  {AppMode.onPremise &&
                    <OpOrganizationTable setOrganization={setOrganization} organization={organization} setDialogOrg={setDialogOrg} />
                  }
                </DialogContent>
              </Dialog>
            </div>
          )
        }
      ]}
      onSubmit={async (values, callback) => {
        try {
          var res = {}
          var formData = Object.assign({}, values)
          if(inSpt){
            var spt = JSON.parse(localStorage.getItem('spt'))
          }
          if (formData['status.in'] && formData['status.in'].length > 0) {
            var stringIn = ""
            formData['status.in'].map((d) => {
              stringIn += d.value + ","
            })
            formData['status.in'] = stringIn
          }
          if(values.allStatus){
            var allStatusString = ""
            options.map((d) => {
              allStatusString += d.value + ","
            })
            formData['status.in'] = allStatusString
            delete formData.allStatus
          }
          if(formData.allRevNo){
            formData.allRev = false
            delete formData.allRevNo 
          }
          if(formData.allRevYes){
            formData.allRev = true
            delete formData.allRevYes 
          }
          var npwps = ""
          var npwpsz = []
          organization.map((d)=> {
            npwps += d.npwp + ","
            npwpsz.push(d.npwp)
          })
          formData["npwp.in"] = npwps
          try {
            formData['npwp.in'] = formData['npwp.in'].slice(0,-1)
            formData['status.in'] = formData['status.in'].slice(0,-1)
          } catch(e){}
          if(npwpsz.length == 0 && !values.all){
            iziToast.info({
              title: `${t.translate('words.Info')}`,
              message: `${t.translate('words.warningOrg')}`
            })
            callback("", false, false)
          } else {
            var proses = t.translate('words.report')
            if(AppMode.onPremise){
              let res = await service.reportBulkOp(formData, formData.all)
            } else {
              let res = await service.reportBulk(formData, formData.all)
            }
            callback("", false, false)
            iziToast.success({
              timeout: 5000,
              title: proses,
              message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
            })
            if(AppMode.onPremise){
              history.goBack()
            } else {
              if (window.location.href.indexOf('open') != -1) {
                history.goBack()
              } else {
                history.push(`/product/company/${match.params.companyId}/log-export`)
              }
            }
          }
        } catch(e){
          ErrorService(e)
          callback("", false, false)
          return
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(BpBulkForm))
