import CrudService from '../../services/crudService';
import { http } from '../../libs/react-mpk/services';
import AppMode from '../../AppMode'

let service = null
let serviceUrl = '/bp/doppss'
if(AppMode.onPremise){
  service = new CrudService('/api/eunifikasi/bp/doppss');
  serviceUrl = '/api/eunifikasi/bp/doppss'
} else {
  service = new CrudService('/bp/doppss');
}

service.saveData = async(data)=> {
    return http.request({
        method: http.methods.POST,
        url: `${serviceUrl}`,
        data,
    })
}

service.getSptBySearch = async(data, query)=> {
  return http.request({
      method: http.methods.POST,
      url: `/spt/search`,
      data,
      config : {
        params : query
      }
  })
}

service.cekDoppDoss = async(data, query)=> {
  return http.request({
      method: http.methods.POST,
      url: `/bp/doppss/check`,
      data
  })
}

service.getOrganization = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/core/host/organization`,
    query
  })
}

export default service;