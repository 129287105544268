import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './IndukArchive.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  List, ListItem
} from 'react-md'
import ErrorService from './../../services/errorService'
import SignerService from './../Signer/Signer.service'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'

const IndukArchiveForm = ({ authStore, envStore, modalStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [signer, setSigner] = useState([])
  const [signers, setSigners] = useState(true)
  const [ploading, setPloading] = useState({ loading: false, message: '' })

  const baseName = `induk-archive`
  const basePath = `induk-archive`

  useEffect(() => {
    initData()
  }, [])

  async function initData(){
    getSigner({
      "actAs.equals": true,
      "active.equals": true
    })
    try {
      let spt = JSON.parse(localStorage.getItem('spt'))
      const res = await service.getDefault({
        sptNpwp: spt.npwp,
        sptYear: spt.year,
        sptMonth: spt.month,
        sptRev: spt.rev
      }) 
      if(res.data.c1 == true){
        res.data.wpkuasa = 'true'
      } else if(res.data.c2 == false){
        res.data.wpkuasa = 'true'
      } else {
        res.data.wpkuasa = 'true'
      }
      setData({ loading: false, content: res.data })
    } catch(e){
      setData({ loading: false })
      ErrorService(e)
    }
  }

  async function getSigner(query){
    try {
      setSigners(false)
      var signer = await SignerService.get(query)
      setSigner(signer.data)
      setSigners(true)
    } catch(e){
      ErrorService(e)
    }
  }

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}

  async function reportExc(){
    try {
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var spt = JSON.parse(localStorage.getItem('spt'))
      let res = await service.report({
        sptNpwp: spt.npwp,
        sptYear: spt.year,
        sptMonth: spt.month,
        sptRev: spt.rev,
      })
      if(res.data && res.data.status == 1){
        window.open(res.data.data.url)
        if(res.data && !res.data.message) res.data.message = t.translate(`words.berhasilDicetak`)
        if(res.data && res.data.message){
          iziToast.success({
            title: '',
            message: res.data.message
          })
        } 
      } else {
        if(res.data && !res.data.message) res.data.message = t.translate(`words.berhasilDicetak`)
        if(res.data && res.data.message){
          iziToast.info({
            title: '',
            message: res.data.message
          })
        }
      }
      setPloading({ loading: false, message: `` })
    } catch(e){
      // console.log(e)
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  async function report(){
    modalStore.showConfirm({
      title: t.translate('words.cetak'),
      children: t.translate('words.confirmReport'),
      onSubmit: (callback)=> {
        reportExc()
        callback()
      }
    })
  }

  var hintMessage = t.translate('words.belumDiPosting')
  try {
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(window.location.href.indexOf('open') != -1){
      var c = spt.postingDate
      if(c){
        hintMessage = t.translate('words.tanggalPosting') + spt.postingDate 
      }
    } else {
      hintMessage = ''
    }
  } catch(e){}
  
  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <FormWrapper
        tableForm={true}
        style={{
          maxWidth: '100%'
        }}
        actions={[
          {
            label: t.translate('words.report'),
            iconClassName: 'mdi mdi-file-pdf',
            onClick: report
          },
          {
            label: t.translate('words.reload'),
            iconClassName: 'mdi mdi-reload',
            onClick: initData
          }
        ]}
        className="fullWidth"
        loading={data.loading}
        onChange={async (formData, key, value)=> {
          if(key == 'wpkuasa' && value == 'true'){
            getSigner({
              "actAs.equals": true,
              "active.equals": true
            })
          }
          if(key == 'wpkuasa' && value == 'false'){
            getSigner({
              "actAs.equals": false,
              "active.equals": true
            })
          }
        }}
        baseId={`mod-form-${baseName}`}
        title={t.translate(`modules.${baseName}.formTitle`)}
        hintMessage={hintMessage}
        defaultData={data.content}
        definitions={{
          header: [
            {
              render: (
                <TableCell>{t.translate(`words.induk1`)}</TableCell>
              )
            },
            {
              render: (
                <TableCell>{t.translate(`words.induk2`)}</TableCell>
              )
            },
            {
              render: (
                <TableCell>{t.translate(`words.induk3`)}</TableCell>
              )
            },
            {
              render: (
                <TableCell style={{ width: '20%'}}>{t.translate(`words.induk4`)}</TableCell>
              )
            },
            {
              render: (
                <TableCell>{t.translate(`words.induk5`)}</TableCell>
              )
            }
          ],
          body: [
            [
              {
                render: (
                  <TableCell colSpan={5}><b>{t.translate(`words.induk6`)}</b></TableCell>
                )
              }
            ],
            [
              {
                render: (
                  <TableCell>1</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk7`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b11PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b11PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b11PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>2</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk8`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b12PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b12PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b12PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>3</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk9`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b13PphDebtTotal',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b13PphDpTotal',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b13PphDepositTotal',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell colSpan={5}><b>{t.translate(`words.induk10`)}</b></TableCell>
                )
              }
            ],
            [
              {
                render: (
                  <TableCell>4</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk11`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b24PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b24PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b24PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>5</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk12`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b25PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b25PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b25PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>6</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk13`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b26PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b26PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b26PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>7</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk14`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b27PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b27PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b27PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>8</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk15`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b28PphDebt',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b28PphDp',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b28PphDeposit',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>9</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk16`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b29PphDebtTotal',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b29PphDpTotal',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'b29PphDepositTotal',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell colSpan={5}><b>{t.translate(`words.induk17`)}</b></TableCell>
                )
              }
            ],
            [
              {
                render: (
                  <TableCell>10</TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.induk18`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'pphDebtTotal',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'pphDpTotal',
                type: 'text',
                disabled : true
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'pphDepositTotal',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>11</TableCell>
                )
              },
              {
                render: (
                  <TableCell colSpan={3}>{t.translate(`words.induk19`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'pphCorrectedTotal',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell>12</TableCell>
                )
              },
              {
                render: (
                  <TableCell colSpan={3}>{t.translate(`words.induk20`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                thousandSeparator: thousandSeparator,
                decimalSeparator: decimalSeparator,
                isNumericString: true,
                className : 'inputRight',
                label: '',
                key: 'pphDepositCorrectedTotal',
                type: 'text',
                disabled : true
              },
            ],
            [
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              {
                render: (
                  <TableCell colSpan={4}>{t.translate(`words.induk21`)}</TableCell>
                )
              },
            ],
            [
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.wpkuasa`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.SELECT,
                label: '',
                key: 'wpkuasa',
                type: 'text',
                options: [
                  { label: t.translate('words.wajibPajak'), value: 'true' },
                  { label: t.translate('words.kuasa'), value: 'false' },
                ],
                style: {
                  marginTop: '15px',
                },
                validation: 'required',
                colSpan: 4
              },
            ],
            [
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.namaPenandatangan`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.AUTOCOMPLETE,
                label: '',
                key: 'c3',
                validation: 'required',
                colSpan: 4,
                data: signer,
                labelKey: 'name', 
                valueKey: 'name',
                show: signers
              },
            ],
            [
              {
                render: (
                  <TableCell></TableCell>
                )
              },
              {
                render: (
                  <TableCell>{t.translate(`words.tanggal`)}</TableCell>
                )
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: '',
                key: 'date',
                type: 'date',
                validation: 'required',
                colSpan: 3
              },
            ],
            // [
            //   {
            //     render: (
            //       <TableCell></TableCell>
            //     )
            //   },
            //   {
            //     render: (
            //       <TableCell>{t.translate(`words.pernyataan`)}</TableCell>
            //     )
            //   },
            //   {
            //     inputType: inputTypes.CHECKBOX,
            //     label: t.translate(`words.induk24`),
            //     key: 'pernyataan',
            //     type: 'text',
            //     validation: 'required',
            //     style: {
            //       marginTop: '5px',
            //       marginBottom: '5px',
            //       whiteSpace: 'pre-wrap'
            //     },
            //     colSpan: 3
            //   },
            //   // {
            //   //   render: (
            //   //     <TableCell style={{ whiteSpace: 'pre-wrap' }}>
            //   //       <b><p>{t.translate(`words.induk24`)}</p></b>
            //   //     </TableCell>
            //   //   )
            //   // },
            // ],
            // [
            //   {
            //     render: (
            //       <TableCell></TableCell>
            //     )
            //   },
            //   {
            //     render: (
            //       <TableCell colSpan={4} style={{ whiteSpace: 'pre-wrap' }}>
            //         <b><p>{t.translate(`words.induk24`)}</p></b>
            //       </TableCell>
            //     )
            //   },
            // ],
          ]
        }}
        onSubmit={async (data, callback) => {
          var res = {}
          if(data.wpkuasa == 'true'){
            data.c1 = true
          } else if(data.wpkuasa == 'false'){
            data.c2 = true
          } else {
            data.c1 = true
            data.c2 = false
          }
          try {
            res = await service.put(data.id, data)
            callback(t.translate('sentences.tersimpan'), false)   
          } catch(e){
            ErrorService(e)
            callback("", false, false)
            return
          }
        }}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'modalStore')(observer(IndukArchiveForm))
